/* Full-width blog image */

@font-face {
  font-family:Montserrat;
  src: url(../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf);
}
.blog-header {
    width: 100%;
    height: 500px; /* Adjust based on preference */
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .blog-header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  }
  
  .blog-header .container {
    padding: 20px;
  }
  
  .blog-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family:Montserrat;

  }
  
  .blog-meta {
    font-size: 1.2rem;
    font-family:Montserrat;
  }
  
  /* Blog content section */
  .blog-content-section {
    padding: 50px 0;
  }
  
  .blog-content-section .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .blog-content {
    font-size: 1.1rem;
    line-height: 1.8;
  }
  