/* General body styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

/* Popup container */
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
}

/* Popup content styles */
.popup-content {
    background-color: white;
    padding: 20px; /* Consistent padding */
    border-radius: 10px; /* Rounded corners */
    width: 90%; /* Full width on mobile */
    max-width: 500px; /* Maximum width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Prevent content overflow */
    position: relative; /* Ensure positioning context for the close button */
}

/* Close button styles */
.close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

/* Header styles */
.new-subscribe h2 {
    text-align: center;
    font-size: 24px; /* Larger heading */
    margin-bottom: 20px;
    color: #333;
}

/* Label styles */
label {
    margin-top: 10px;
    display: block;
    font-size: 14px;
    color: #333;
}

/* Input and textarea styles */
input,
textarea {
    width: 100%;
    padding: 12px; /* Padding for better look */
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 8px; /* Rounded input fields */
    outline: none;
    transition: border-color 0.3s;
}

input:focus,
textarea:focus {
    border-color: #007BFF; /* Blue outline on focus */
}

/* Submit button styles */
button[type='submit'] {
    margin-top: 9px;
    padding: 12px;
    /* background-color: #28a745; */
    color: white;   
    border: none;
    /* border-radius: 8px; */
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .popup-content {
        padding: 15px; /* Less padding for smaller screens */
    }

    .close-button {
        font-size: 24px; /* Smaller close button */
    }

    .new-subscribe h2 {
        font-size: 20px; /* Smaller heading on mobile */
    }

    label {
        font-size: 14px; /* Adjust label font size */
    }

    input,
    textarea {
        font-size: 14px; /* Adjust input font size */
    }

    button[type='submit'] {
        font-size: 16px; /* Keep button font size larger for clarity */
    }
}

@media (max-width: 480px) {
    .popup-content {
        padding: 10px; /* Further reduce padding for small devices */
    }

    .new-subscribe h2 {
        font-size: 18px; /* Adjust heading for small devices */
    }

    label {
        font-size: 12px; /* Adjust label font size */
    }

    input,
    textarea {
        font-size: 12px; /* Adjust input font size */
    }

    button[type='submit'] {
        font-size: 14px; /* Keep button font size larger for clarity */
    }
}
