/* Apply background to the widget but not on the content */
.widget.widget_offer.wide-new {
    background-size: cover;
    padding: 20px;
  }
  
  /* Styling for the offer content */
  .offer-content {
    background: none; /* Remove background from content */
    color: white; /* Set text color to white */
    text-align: center;
    padding: 20px;
  }
  
  /* Styling for the box title */
  .box-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  /* Styling for the banner logo */
  .banner-logo img {
    max-width: 150px;
  }
  
  /* Styling for the offer details */
  .offer-details {
    margin-bottom: 20px;
  }
  
  .offer-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .offer-num {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
  }
  
  .offer-num:hover {
    text-decoration: underline;
  }
  
  /* Styling for the button */
/* Transparent "Read More" button */
.offer-new .th-btn.style2.th-icon {
    background-color: transparent !important; /* No background */
    color: white; /* Set text color to white */
    border: 2px solid white; /* Optional: Add a white border */
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease !important; /* Smooth hover transition */
  }
  
  /* On hover, you can invert the colors or add other effects */
  .offer-new .th-btn.style2.th-icon:hover {
    background-color: white !important; /* White background on hover */
    color: black !important; /* Black text on hover */
  }
  
  