/* General Reset for Padding and Margin */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* .sticky-header {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
} */

/* Main Header Section */
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo Styling */
.logo img {
  width: 225px; /* Adjust the logo size as needed */
}




/* Links in Menu */


.main-menu a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #F9AF2A; /* Underline color */
  transition: width 0.3s ease;
}

.main-menu a:hover::after {
  width: 100%; /* Full underline on hover */
}

.main-menu a:hover {
  color: #F9AF2A; /* Change color on hover */
}

/* Dropdown Container */





/* Dropdown Styling */


li:hover .dropdown {
  display: block; /* Show on hover */
}

/* Hover Effects for Dropdown Items */
.dropdown-section li {
  padding: 5px 0; /* Ensure consistent padding for list items */
}



/* Button Styling */
.header-button {
  display: flex;
}

.th-btn {
  background-color: #DF1F2C;
  color: white;
  padding: 10px 20px;

  height: 50px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}
.header-button .th-btn:hover{
  color: #DF1F2C !important;
}

/* .th-btn:hover {
  background-color: #007bff;
} */

/* Responsive Design */

/* Flight Icon Animation */
@keyframes flightArrival {
  0% {
    opacity: 0;
    transform: translateX(-100px); /* Start off-screen to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* End at the default position */
  }
}

/* Flight Icon Styling */
.icon {
  margin-right: 5px; /* Space between the icon and text */
  vertical-align: middle; /* Align icon with the text */
  transition: color 0.3s ease; /* Only animate color for icon */
  opacity: 0; /* Initially hidden */
}

/* Show icon when dropdown is visible */
.dropdown-section li .icon {
  opacity: 1; /* Make the icon visible */
  animation: flightArrival 0.5s forwards; /* Animate the icon when dropdown is visible */
}

/* Scrollable if necessary */
.dropdown-container {
  display: flex; /* Aligns items horizontally */
  align-items: stretch; 
}

.dropdown-container::-webkit-scrollbar {
  width: 8px;
}

.dropdown-container::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}
/* 


.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Background color for the header */
  /* padding: 10px 20px; /* Padding around the header */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  /* z-index: 1000; Ensure the header is above other content  */
/* } */
 


nav ul {
  list-style: none; /* Remove bullets from the list */
  display: flex; /* Display list items in a row */
  gap: 15px; /* Space between links */
}

nav ul li a {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Link color */
  transition: color 0.3s; /* Smooth color transition on hover */
}

nav ul li a:hover {
  color: #F9AF2A; /* Change color on hover */
}

.sticky-header a:hover {
  color: #F9AF2A !important;
}




/* Main Menu Styling */
.main-menu {
  display: flex;
  list-style: none;
  gap: 0; /* Remove gap between menu items */
}

.main-menu li {
  position: relative;
}

.main-menu a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px; /* Adjust padding for alignment */
  transition: color 0.3s ease, padding 0.3s ease;
  line-height: 1.5;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: #fff;
}

.main-menu li:hover .dropdown {
  display: block;
}

/* Dropdown Container */
.dropdown-container {
  display: flex;
  background-color: #fff;
  color: #333;
  padding: 10px; /* Reduce padding for compact layout */
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
}

/* Dropdown Sections */
.dropdown-section {
  flex: 1;
  padding: 10px; /* Reduce padding between sections */
}

/* Section Heading */
.dropdown-section h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px; /* Adjust heading margin */
}

/* List Items */
.dropdown-section ul {
  list-style: none;
  padding: 0;
}

.dropdown-section li {
  margin-bottom: 5px; /* Reduce margin between list items */
  font-size: 14px; /* Adjust font size for compactness */
}

.dropdown-section a {
  text-decoration: none !important ; /* No underline */
  color: #333 !important; /* Default color */
  display: flex !important;
  align-items: center !important; /* Align icon and text if applicable */
  transition: color 0.3s ease !important; 
  border-bottom: none !important;       /* Smooth transition on hover */
}

/* Ensure no underline or border when hovering */
.dropdown-section a:hover {
  color: #F9AF2A; /* Change color on hover */
  text-decoration: none !important; /* Ensure no underline on hover */
  border: none !important; /* Ensure no border on hover */
  background: none !important; 
  border-bottom: none !important; /* Ensure no background color change */
}

/* Optional: Icon adjustments */
.dropdown-section a img {
  margin-right: 5px ; /* Add space between icon and text */
}

.divider {
  width: 1px;
  background-color: #ddd;
  margin: 0 10px; /* Margin around the divider */
  height: auto;  /* Reduce margin around the divider */
}

/* Hover Effect */
.dropdown-section li:hover {
  border-radius: 4px;
  cursor: pointer;
}






/* Dropdown Container */
.dropdownnew {
  display: none; /* Initially hidden */
  position: absolute; /* Positioned below the menu item */
  top: 100%; /* Aligns the dropdown below the link */
  left: 0; /* Aligns to the left of the parent */
  z-index: 1000; /* Ensures it appears above other content */
  background-color: #fff; /* Background color for dropdown */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Show dropdown on hover */
.main-menu li:hover .dropdownnew {
  display: block; /* Show dropdown when parent is hovered */
}

.dropdown {
  max-height: 400px; /* Max height for dropdown */
  overflow: auto; /* Scroll if content overflows */
}

/* Dropdown Container */
.dropdown-containernew {
  display: grid; /* Use grid layout for dropdown */
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  padding: 10px; /* Padding for dropdown content */
  width: 300px; /* Set fixed width for dropdown */
  max-height: 400px; /* Max height for dropdown to enable scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Dropdown Sections */
.dropdown-sectionnew {
  padding: 0 10px; /* Padding between sections */
}

/* Section Heading */
.dropdown-sectionnew h3 {
  font-size: 18px; /* Font size for heading */
  font-weight: bold; /* Bold heading */
  margin-bottom: 10px; /* Space below heading */
}

/* List Items */
.dropdown-sectionnew ul {
  list-style: none; /* No bullet points */
  padding: 0; /* No padding */
  margin: 0; /* No margin */
}

/* List Item Styling */
.dropdown-sectionnew li {
  margin-bottom: 5px; /* Space between list items */
}

/* Links in Dropdown */
.dropdown-sectionnew a {
  text-decoration: none; /* No underline */
  color: #333; /* Default text color */
  display: flex; /* Flex to align icon and text */
  align-items: center; /* Center align items */
  transition: color 0.3s; /* Smooth color transition */
}

.dropdown-sectionnew a:hover {
  color: #F9AF2A; /* Change color on hover */
}

/* Scrollable if necessary */
.dropdown-containernew::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.dropdown-containernew::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners for scrollbar */
}


.dropdown-sectionnew li .icon {
  opacity: 1; /* Make the icon visible */
  animation: flightArrival 0.5s forwards; /* Animate the icon when dropdown is visible */
}


/* CSS to ensure text stays on one line */
.link-container {
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}





/* Default styles */
.mobile-menu-toggle {
  display: none; /* Hide by default */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333; /* Color of the toggle button */
  transition: background 0.3s ease; /* Smooth background transition */
}


/* Hover effect for the toggle button */
.mobile-menu-toggle:hover {
  background: rgba(0, 0, 0, 0.1); /* Slightly darker background on hover */
  border-radius: 5px; /* Rounded corners for better aesthetics */
}

/* Active state when the menu is open */
.mobile-menu-toggle.active {
  color: #e63946; /* Change color when active */
  transform: rotate(90deg); /* Optional: Rotate the icon */
}

/* Menu styles */
.main-menu {
  display: flex; /* Default display for larger screens */
}

/* Hide the menu on mobile */
@media (max-width: 768px) {
  .main-menu {
    display: none; /* Hide the menu on mobile by default */
    flex-direction: column; /* Stack menu items vertically */
    position: absolute; /* Position it absolutely */
    top: 60px; /* Adjust as per your header height */
    left: 0; /* Align to the left */
    background-color: white; /* Background color for the menu */
    width: 100%; /* Full width */
    z-index: 1000; /* Bring it in front of other content */
  }

  /* Show menu when toggle button is active */
  .main-menu.open {
    display: flex; /* Show the menu when open */
  }
  .mobile-menu-toggle {
    display: block; /* Show the toggle button on mobile */
  }
  .logo{
    width:100% !important;
    text-align: left !important;
  }
  .main-header{
    padding: 20px !important;
  }
  .dropdown{
    width: 100% !important;
  }
  
  .logo img {
    width: 180px;
}
li:focus .dropdown{
  display: block !important;
}
}


