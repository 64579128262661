.video-slider-wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Aspect ratio 16:9 */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  background-color: #000; /* Background color for the video container */
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Adjusted play button */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border: 2px solid #ff5a5f;
}

.play-button:before {
  content: '';
  border-style: solid;
  border-width: 10px 0 10px 16px;
  border-color: transparent transparent transparent #ff5a5f;
}

.play-button:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: translate(-50%, -50%) scale(1.1);
}

.play-button:active {
  transform: translate(-50%, -50%) scale(0.9);
}

/* Custom button styles */
.custom-button {
  background: linear-gradient(135deg, #f9af2a, #ff5a5f) !important; /* Gradient background */
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  position: absolute; /* Make button position absolute */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect centering */
}

/* Positioning left and right buttons */
.custom-button-left {
  left: -60px; /* Position left button outside */
}

.custom-button-right {
  right: -60px; /* Position right button outside */
}

/* Hover effect */
.custom-button:hover {
  background: linear-gradient(135deg, #ff5a5f, #f9af2a) !important; /* Reverse gradient on hover */
  transform: scale(1.05); /* Slightly scale up */
}

/* Active effect */
.custom-button:active {
  transform: scale(0.95); /* Scale down on click */
}

/* Optional: Arrow styles */
.custom-button:before {
  font-size: 30px; /* Adjust size of the arrow */
  color: white; /* Change arrow color to white for contrast */
}


.youtube-section {
  text-align: center;
  padding: 50px 20px;
  /* background-color: #f9f9f9; Light background for contrast */
}

.youtube-heading {
  font-size: 2.5rem; /* Large font size for prominence */
  color: #f9af2a; /* Primary color */
  margin-bottom: 20px;
}

.youtube-description {
  font-size: 1.2rem; /* Slightly smaller for readability */
  color: #333; /* Dark color for contrast */
}
@media (max-width: 768px) {
  .custom-button-right {
      display: none;
  }
}
@media (max-width: 1024px) {
  .custom-button-right {
      display: none;
  }
}




/* Custom button styles */
.custom-button {
  background: linear-gradient(135deg, #f9af2a, #ff5a5f) !important;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.custom-button-left {
  left: -60px;
}

.custom-button-right {
  right: -60px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .custom-button-left {
    left: -40px; /* Adjust position for smaller screens */
  }

  .custom-button-right {
    right: -40px;
  }
}

@media (max-width: 768px) {
  .custom-button-left,
  .custom-button-right {
    display: none; /* Hide buttons on mobile */
  }
}

@media (max-width: 600px) {
  .video-container {
    width: 100%; /* Ensure videos take full width on smaller screens */
  }

  .custom-button-left,
  .custom-button-right {
    display: none; /* Hide buttons on smaller devices */
  }
}
