.global-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    z-index: 9999;
    overflow: hidden; /* Prevent overflow */
  }
  
  .loader-icon {
    font-size: 50px; /* Adjust size as necessary */
    animation: fly 3s linear infinite; /* Straight flight */
  }
  
  .loader-text {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
  }
  
  @keyframes fly {
    0% {
      transform: translateX(-100%); /* Start off-screen left */
    }
    100% {
      transform: translateX(100%); /* Exit off-screen right */
    }
  }
  
  .global-loader .fa-plane {
    background: linear-gradient(45deg, red, yellow); /* Gradient from red to yellow */
    -webkit-background-clip: text; /* For Safari */
    -webkit-text-fill-color: transparent; /* Fill text with gradient */
    color: transparent; /* Ensure color is transparent */
    font-size: 50px; /* Adjust icon size as needed */
    display: inline-block; /* Ensure proper size for gradient */
    margin-right: 5px; /* Space between icon and text */
    transition: color 0.3s ease; /* Optional transition for hover effects */
  }
  
  /* Optional: Change gradient on hover */
  .global-loader .fa-plane:hover {
    background: linear-gradient(45deg, yellow, red); /* Reverse the gradient on hover */
  }
  