@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&amp;display=swap");
body {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  color: rgb(114, 112, 112);
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease 0s;
}

a:hover {
  text-decoration: none;
  transition: all 0.5s ease 0s;
}

a,
p,
li,
td,
span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.faq-section.new-section .accordion-body p.faq-text {
  /* font-size: 20px !important; */
  line-height: 35px;
  font-weight: 400;
}

h1,
/* h2 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
} */

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

div::after {
  content: "";
  display: table;
}

.link-btn {
  font-weight: 600;
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  border: 0px solid #e92c45;
  background: #e92c45;
}

.link-btn:hover {
  background-color: rgb(226, 52, 100);
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
}

.div {
  transition: all 0.5s ease 0s;
}

.div:hover {
  transition: all 0.5s ease 0s;
}

.com-colo-abou {
}

.com-colo-abou p,
li {
  color: rgb(136, 136, 136);
}
:root {
  --topspac: 70px;
}
@media screen and (max-width: 992px) {
  :root {
    --topspac: 54px;
  }
}
.inn-page-bg {
  position: relative;
  overflow: hidden;
  background: rgb(243, 243, 243);
  margin-top: var(--topspac);
}
.inn-page-bg.com-colo {
  margin-top: 0;
}
.inn-page-con-bg {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgba(150, 150, 150, 0.4) 0px -2px 7px;
  padding: 70px 20px 30px;
}

::-webkit-input-placeholder {
  color: rgb(107, 122, 136);
}

.head {
  background: rgb(255, 255, 255);
  padding: 15px 0px;
  transition: opacity 2s ease-in 0s;
}

.head_left {
  padding-left: 0px !important;
}

.head_left img {
}

.head_right_all {
  padding-right: 1px !important;
}

.head_right {
}

.head_right ul {
  padding: 0px;
  margin: 0px auto;
  float: right;
}

.head_right ul li {
  display: inline-block;
  vertical-align: bottom;
}

.head_right ul li a {
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}

.head_right ul li:first-child a {
  padding-right: 7px;
  color: rgb(94, 100, 105);
}

.head_right ul li:nth-child(2) a {
  padding-right: 7px;
  color: rgb(94, 100, 105);
}

.head_right ul li:nth-child(3) a {
}

.head_right ul li a i {
}

.head_right ul li a .fa-facebook {
  background: rgb(59, 89, 152);
}

.head_right ul li a .fa-google-plus {
  background: rgb(221, 75, 57);
}

.head_right ul li a .fa-twitter {
  background: rgb(85, 172, 238);
}

.head_right ul li a .fa-linkedin {
  background: rgb(0, 119, 181);
}

.head_right ul li a .fa-whatsapp {
  background: rgb(62, 173, 25);
}

.head_right ul li:nth-child(2) {
}

.spe-title {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

.spe-title h2 {
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-top: 0px;
  text-transform: capitalize;
  font-size: 44px;
  color: rgb(37, 61, 82);
}

.spe-title h2 span {
  color: rgb(244, 54, 79);
  font-size: 44px;
  font-weight: 600;
}
.tit-inn-pg h1 {
}
.tit-inn-pg h1 span {
}
.spe-title p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 300;
  padding-top: 12px;
  color: rgb(136, 136, 136);
  line-height: 30px;
}

.spe-title-1 {
}

.spe-title-1 h2 {
  color: rgb(37, 61, 82);
}

.spe-title-1 h2 span {
}

.spe-title-1 p {
  color: rgb(78, 89, 99);
}

.title-line {
  margin-top: -10px;
}

.tl-1 {
  width: 80px;
  height: 1px;
  display: inline-block;
  background: rgb(238, 238, 238);
}

.tl-2 {
  display: inline-block;
  height: 12px;
  margin: 0px 5px;
  position: relative;
  top: 5px;
  width: 12px;
  border: 1px solid rgb(235, 193, 49);
  border-radius: 50px;
}

.tl-3 {
  width: 80px;
  height: 1px;
  display: inline-block;
  background: rgb(238, 238, 238);
}

.menu_book {
  float: right;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.nav > li > a {
  font-weight: 600;
  font-size: 14px;
}

.dropdown-menu > li > a {
  font-weight: 500;
  font-size: 14px;
}

.menu_book a {
  font-weight: 700;
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(26, 165, 216), rgb(19, 134, 177));
}

.menu_book a:hover {
  color: rgb(255, 255, 255);
  background: rgb(76, 178, 219);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px;
}

.dropdown-menu > li > a {
  transition: all 0.5s ease 0s;
}

.dropdown-menu > li > a {
}

.dropdown-menu > li > a:hover {
  transition: all 0.5s ease 0s;
  background-color: rgb(26, 165, 216);
  cursor: pointer;
  padding-left: 25px;
}

.tourz-hom-ser {
  position: relative;
  overflow: hidden;
}

.tourz-hom-ser ul {
}
.ban-shrt-cut-link {
  float: left;
  width: 100%;
  padding-top: 30px;
}
.tourz-hom-ser ul li,
.ban-shrt-cut-link ul li {
  float: left;
  width: 33.333%;
  padding: 0px 15px;
}
.ban-shrt-cut-link ul li {
  width: auto;
  padding: 0 12px 12px 0;
}
.ban-shrt-cut-link ul li a {
  background: #e8095a00;
  width: 90px;
  height: 90px;
  display: block;
  padding: 18px 2px 10px 2px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  /* font-family: 'Arimo', sans-serif; */
  /* position: relative; */
  /* overflow: hidden; */
  line-height: 24px;
  border: 1px solid #9fa8a0;
}
.ban-shrt-cut-link ul li img {
  display: table;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 5px;
  width: 36px;
}
.hom-quick-acc {
  position: relative;
  background-image: linear-gradient(
    225deg,
    rgb(240, 240, 255) 0%,
    rgb(227, 210, 255) 99%
  );
  border-radius: 45px;
  padding: 40px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 12px -3px;
}

.cta-1 {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 60px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  color: rgb(255, 255, 255);
  font-size: 13px;
  transition: all 0.5s ease 0s;
  background: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
}

.hom-quick-acc:hover .cta-1 {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(29, 29, 33, 0.09) 0px 14px 12px -3px;
  border: 2px solid rgb(0, 0, 0);
}

.cta-2 {
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120);
  padding: 12px 40px;
  font-size: 16px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 500;
  text-align: center;
  display: block;
}

.cta-3-sml {
}

.hom-quick-acc-1 {
}

.hom-quick-acc-2 {
  background-image: linear-gradient(
    225deg,
    rgb(255, 237, 254) 0%,
    rgb(255, 210, 210) 99%
  );
}

.hom-quick-acc-3 {
  background-image: linear-gradient(
    135deg,
    rgb(253, 252, 251) 0%,
    rgb(226, 209, 195) 100%
  );
}

.hom-quick-acc-4 {
  background-image: linear-gradient(
    to top,
    rgb(204, 237, 208) 0%,
    rgb(238, 255, 236) 100%
  );
}

.hom-quick-acc-5 {
  background-image: linear-gradient(
    to top,
    rgb(234, 241, 184) 0%,
    rgb(250, 255, 218) 100%
  );
}

.hom-quick-acc h2 {
  color: rgb(0, 0, 0);
  font-size: 23px;
  font-weight: 500;
  line-height: 38px;
  padding-bottom: 30px;
  margin: 0px;
}

.hom-quick-acc h2 span {
  font-family: "Playfair Display", serif;
  font-size: 38px;
  font-weight: 500;
  display: block;
  line-height: 38px;
}

.hom-quick-acc img {
  position: relative;
  width: 90%;
  right: 0px;
  bottom: 0px;
}

.tourz-pop-ser-btn {
}

.tourz-pop-ser-btn img {
}

.tourz-top-phone {
  border: 1px solid rgb(154, 114, 154);
  border-radius: 50px;
}

.autocomplete-content ul li a {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.profile-btn {
  border: none;
  height: 30px;
  line-height: 26px;
  color: rgb(255, 255, 255);
  padding: 2px 10px;
  border-radius: 2px;

  background: linear-gradient(rgb(232, 9, 90), rgb(212, 8, 82));
}

.profile-btn:hover {
  color: rgb(255, 255, 255);
}

.autocomplete-content li img {
  float: left;
  left: 0px;
  top: 15px;
  width: 26px !important;
  height: 26px !important;
}

.autocomplete-content li {
}

.al {
  float: left;
  position: relative;
  width: calc(15%);
}

.al::after {
  font-size: 22px;
  transition: all 0.5s ease 0s;
  right: -12px;
  top: 5px;
  color: rgb(216, 200, 191);
}

.head-pro {
  padding: 10px 0px 0px;
  display: inline-block;
  position: relative;
}

.head-pro img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  transition: all 0.5s ease 0s;
  animation: 5s ease 0s infinite normal both running roatepro;
  position: absolute;
  left: 0px;
  top: 15px;
}

@keyframes roatepro {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.head-pro div {
  padding: 0px 0px 0px 44px;
}

.head-pro b {
  padding: 0px;
  font-size: 10px;
  font-weight: 500;
  color: rgb(116, 106, 99);
  line-height: 15px;
  text-transform: uppercase;
}

.head-pro h4 {
  margin: -1px 0px 0px;
  font-size: 14px;
  line-height: 15px;
}

.fclick {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
}

.tourz-search {
  padding: 80px 0px 60px;
  /* background: url("../public/images/banne.png") center bottom / 100% no-repeat rgb(255, 214, 0); */
  position: relative;
  margin-top: var(--topspac);
}

.tourz-search-1 {
  padding: 0px 0px 20px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  position: relative;
}

.tourz-search-1 h1 {
  font-size: 56px;
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
}

.tourz-search-1 p {
  font-size: 20px;
}

.ban-search {
  width: 90%;
  position: relative;
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 10px;
  margin: 30px auto 0px;
  display: table;
  box-shadow: rgba(29, 29, 33, 0.09) 0px 2px 12px -3px;
  transition: all 0.5s ease-in-out 0s;
}

.ban-search.act {
  z-index: 4;
}

.ban-search ul {
  margin: 0px;
  padding: 0px;
}

.ban-search ul li {
  float: left;
  width: 20%;
  padding: 0px 5px;
  list-style-type: none;
  text-align: left;
  font-family: "Noto Sans";
}

.ban-search ul li.sr-look {
  width: 25%;
}

.ban-search ul li.sr-date {
  width: 15%;
}

.ban-search ul li.sr-gue {
  width: 25%;
}

.ban-search ul li.sr-btn {
}

.ban-search ul li.sr-look .chosen-container-single,
.ban-search ul li.sr-look .chosen-container-single .chosen-single {
  border-radius: 30px 0px 0px 30px;
  border: 0px;
}

.ban-search ul li input,
.ban-search ul li .chosen-select {
  height: 52px;
  border: 0px solid rgb(56, 73, 106);
}

.ban-search .chosen-container .chosen-single {
  border: 0px;
  height: 50px;
  line-height: 40px;
  padding: 15px 5px 0px 15px;
}

.ban-search ul li.sr-look .chosen-container-single .chosen-single span {
}

.ban-search
  ul
  li.sr-look
  .chosen-container-single
  .chosen-single
  div
  b::before {
  top: 7px;
}

.ban-search ul li.sr-sea {
  width: 53%;
  margin: 0px 1%;
}

.ban-search ul li input {
  width: 100%;
  display: block;
  line-height: 38px;
  outline: none;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  color: rgb(26, 42, 56);
  font-weight: 600;
  opacity: 1;
  font-family: "Noto Sans";
  box-shadow: none;
  font-size: 16px !important;
}

.ban-search ul li input[type="submit"] {
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%);
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120);
  padding: 5px 15px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Noto Sans";
  text-align: center;
}

.ban-search ul li input[type="submit"]:hover {
  transition: all 0.5s ease 0s;
}

.ban-search ul li .form-group {
  margin: 0px;
}

.ban-search ul li .form-group label {
  font-size: 13px;
  font-weight: 500;
  display: none;
}

.ban-search .chosen-container {
  border: 0px solid rgb(56, 73, 106);
}

.ban-search .chosen-container-single .chosen-single span {
  font-size: 16px;
  font-weight: 600;
}

.ban-search .chosen-container .chosen-results li.active-result {
  font-size: 15px;
  font-weight: 600;
  color: rgb(68, 86, 103);
  border-bottom: 1px solid rgb(246, 243, 255);
  border-radius: 0px;
  padding: 10px 5px 10px 10px;
  transition: all 0.3s ease 0s;
  background-color: rgb(255, 255, 255);
  background-image: none;
  font-family: "Noto Sans";
}

.ban-search .chosen-container .chosen-results li.highlighted {
  background-color: rgb(236, 244, 255);
  background-image: none;
  color: rgb(71, 131, 221);
  padding-left: 10px;
}

.chosen-container .chosen-results li:last-child {
  border-bottom: 0px;
}

.form-group select {
  border: 1px solid rgb(96, 30, 79);
  height: 45px;
  font-size: 15px;
  border-radius: 5px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 0px solid rgb(180, 180, 180);
  background: rgb(241, 244, 245);
  font-weight: 500;
  color: rgb(110, 125, 147);
  padding: 4px 2px 3px;
  border-radius: 2px;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: rgb(69, 69, 69);
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  font-weight: normal;
  border: 0px solid rgb(218, 213, 94);
  background: rgb(19, 175, 17);
  color: rgb(255, 255, 255);
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: rgb(43, 43, 43);
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: rgb(94, 158, 214) 0px 0px 3px 1px;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  font-weight: normal;
  border: 0px solid rgb(218, 213, 94);
  background: rgb(4, 69, 250);
  color: rgb(255, 255, 255);
}

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: rgb(0, 62, 255);
  background-color: rgb(255, 255, 255);
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.chosen-select {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  height: 40px;
}

.chosen-select-deselect {
  width: 100%;
}

.chosen-container {
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
  height: 52px;
  border-radius: 4px;
  width: 100% !important;
}

.chosen-container .chosen-drop {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(250, 251, 251);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 16px -6px;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: -9000px;
  z-index: 1060;
  padding: 6px;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0px;
  right: 0px;
}

.chosen-container .chosen-results {
  color: rgb(85, 85, 85);
  margin: 0px 4px 4px 0px;
  max-height: 240px;
  padding: 0px 0px 0px 4px;
  position: relative;
  overflow: hidden auto;
}

.chosen-container .chosen-results li {
  display: none;
  line-height: 1.42857;
  list-style: none;
  margin: 0px;
  padding: 8px 6px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(23, 27, 30);
  border-radius: 0px;
  width: 100%;
  border-bottom: 1px solid rgb(249, 243, 243);
}

.chosen-container .chosen-results li em {
  background: rgb(254, 255, 222);
  font-style: normal;
}

.chosen-container .chosen-results li.group-result {
  display: list-item;
  cursor: default;
  color: rgb(153, 153, 153);
  font-weight: 700;
}

.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item;
}

.chosen-container .chosen-results li.highlighted {
  background-color: rgb(236, 244, 255);
  background-image: none;
  color: rgb(71, 131, 221);
}

.chosen-container .chosen-results li.highlighted em {
  background: transparent;
}

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: rgb(119, 119, 119);
}

.chosen-container .chosen-results .no-results {
  background: rgb(238, 238, 238);
  display: list-item;
}

.chosen-container .chosen-results-scroll {
  background: rgb(255, 255, 255);
  margin: 0px 4px;
  position: absolute;
  text-align: center;
  width: 321px;
  z-index: 1;
}

.chosen-container .chosen-results-scroll span {
  display: inline-block;
  height: 1.42857px;
  text-indent: -5000px;
  width: 9px;
}

.chosen-container .chosen-results-scroll-down {
  bottom: 0px;
}

.chosen-container .chosen-results-scroll-down span {
  /* background: url("../public/images/icon/chosen-sprite.html") -4px -3px no-repeat; */
}

/* .chosen-container .chosen-results-scroll-up span {
  background: url("../public/images/icon/chosen-sprite.html") -22px -3px no-repeat;
} */

.chosen-container-single .chosen-single {
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  display: block;
  height: 42px;
  overflow: hidden;
  line-height: 28px;
  padding: 8px 5px 0px 15px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
}

.chosen-container-single .chosen-single span {
  display: block;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
}

.chosen-container-single .chosen-single abbr {
  /* background: url("../public/images/icon/chosen-sprite.html") right top no-repeat; */
  display: block;
  font-size: 1px;
  height: 10px;
  position: absolute;
  right: 26px;
  top: 12px;
  width: 12px;
}

.chosen-container-single .chosen-single abbr:hover {
  background-position: right -11px;
}

.chosen-container-single
  .chosen-single.chosen-disabled
  .chosen-single
  abbr:hover {
  background-position: right 2px;
}

.chosen-container-single .chosen-single div {
  display: block;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 18px;
}

.chosen-container-single .chosen-single div b {
  display: block;
  height: 100%;
  width: 100%;
}

.chosen-container-single .chosen-default {
  color: rgb(119, 119, 119);
}

.chosen-container-single .chosen-search {
  margin: 0px;
  padding: 3px 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1000;
}

.chosen-container-single .chosen-search input[type="text"] {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-bottom-color: rgb(222, 234, 252);
  border-radius: 0px;
  margin: 4px 0px;
  padding: 4px 20px 4px 4px;
  width: 100%;
  height: 35px;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
}

.chosen-container-single .chosen-drop {
  margin-top: -8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-clip: padding-box;
}

.chosen-container-single-nosearch .chosen-search input {
  position: absolute;
  left: -9000px;
}

.chosen-container-multi .chosen-choices {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  cursor: text;
  margin: 0px;
  overflow: hidden;
  padding: 3px 5px 0px;
  position: relative;
  height: 40px;
  font-size: 15px;
  font-weight: 500;
}

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}

.chosen-container-multi .chosen-choices .search-field {
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
}

.chosen-container-multi .chosen-choices .search-field input[type="text"] {
  box-shadow: none;
  color: rgb(85, 85, 85);
  height: 32px;
  margin: 0px;
  padding: 4px;
  outline: 0px;
  font-size: 12px;
  background: transparent !important;
  border: 0px !important;
}

.chosen-container-multi .chosen-choices .search-field .default {
  color: rgb(153, 153, 153);
}

.chosen-container-multi .chosen-choices .search-choice {
  background-clip: padding-box;
  border: 1px solid rgb(233, 239, 244);
  border-radius: 4px;
  cursor: default;
  line-height: 13px;
  margin: 6px 0px 3px 5px;
  padding: 4px 20px 3px 5px;
  position: relative;
  font-size: 11.5px;
  background-color: rgb(231, 251, 232);
  color: rgb(45, 134, 50);
}

.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
  /* background: url("../public/images/icon/chosen-sprite.html") right top no-repeat; */
  display: block;
  font-size: 1px;
  height: 10px;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 12px;
  cursor: pointer;
}

.chosen-container-multi
  .chosen-choices
  .search-choice
  .search-choice-close:hover {
  background-position: right -11px;
}

.chosen-container-multi .chosen-choices .search-choice-focus {
  background: rgb(212, 212, 212);
}

.chosen-container-multi
  .chosen-choices
  .search-choice-focus
  .search-choice-close {
  background-position: right -11px;
}

.search-choice-close::after {
  content: "close";
  position: absolute;
  color: rgb(69, 185, 94);
  font-size: 12px;
  font-weight: 600;
}

.chosen-container-multi .chosen-results {
  margin: 0px;
  padding: 0px;
}

.chosen-container-multi .chosen-drop .result-selected {
  display: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: rgb(255, 255, 255);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
  background: transparent;
  border-left: none;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 7px;
}

.chosen-container.chosen-container-single .chosen-single div b::before {
  font-family: FontAwesome;
  content: "\f107";
}

.chosen-container.chosen-container-single .chosen-single div b::before {
  top: 7px;
  position: relative;
}

.job-sear
  .chosen-container.chosen-container-single
  .chosen-single
  div
  b::before {
  margin-top: 8px;
}

.chosen-container-active .chosen-choices .search-field input[type="text"] {
  color: rgb(17, 17, 17) !important;
}

.chosen-container-active.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}

.chosen-disabled .chosen-single {
  cursor: default;
}

.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

.chosen-rtl {
  text-align: right;
}

.chosen-rtl .chosen-single {
  padding: 0px 8px 0px 0px;
  overflow: visible;
}

.chosen-rtl .chosen-single span {
  margin-left: 26px;
  margin-right: 0px;
  direction: rtl;
}

.chosen-rtl .chosen-single div {
  left: 7px;
  right: auto;
}

.chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}

.chosen-rtl .chosen-choices .search-field input[type="text"] {
  direction: rtl;
}

.chosen-rtl .chosen-choices li {
  float: right;
}

.chosen-rtl .chosen-choices .search-choice {
  margin: 6px 5px 3px 0px;
  padding: 3px 5px 3px 19px;
}

.chosen-rtl .chosen-choices .search-choice .search-choice-close {
  background-position: right top;
  left: 4px;
  right: auto;
}

.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0px 0px 4px 4px;
  padding: 0px 4px 0px 0px;
}

.chosen-rtl .chosen-results .group-option {
  padding-left: 0px;
  padding-right: 15px;
}

.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: none;
}

.chosen-rtl .chosen-search input[type="text"] {
  /* background: url("../public/images/icon/chosen-sprite.html") -28px -20px no-repeat, rgb(255, 255, 255); */
  direction: rtl;
  padding: 4px 5px 4px 20px;
}

.pop-bg {
  position: fixed;
  background: rgba(4, 6, 10, 0.6);
  width: 100%;
  height: 100%;
  z-index: 4;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(0);
  opacity: 0;
  right: -150%;
}

.pop-bg.act {
  transform: scale(1);
  opacity: 1;
  right: 0px;
}

.menu-pop {
  position: fixed;
  top: 0px;
  bottom: 0px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgb(255, 255, 255);
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.28) 6px 0px 26px -9px;
  overflow: hidden auto;
  transition: all 0.5s ease-in-out 0s;
}

.menu-pop2 {
  right: -500px;
}

.menu-pop-clo {
  right: 20px;
  top: 20px;
  position: absolute;
}

.menu-pop-clo i {
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
  color: rgb(203, 43, 94);
  transition: all 0.4s ease-in-out 0s;
}

.menu-pop-clo i:hover {
  transform: rotate(90deg);
}

.search-top .menu-pop-clo i {
  color: rgb(255, 255, 255);
}

.search-top .menu-pop-clo {
  right: 25px;
  top: 25px;
}

.menu-pop .inn {
  float: left;
  width: 100%;
}

.menu-pop p {
  font-size: 15px;
  padding-bottom: 15px;
}

.menu-pop-info {
  float: left;
  width: 100%;
  border-top: 1px solid rgb(231, 231, 231);
  margin: 25px 0px 0px;
  padding: 35px 0px 0px;
}

.menu-pop-help {
  border-top: 1px solid rgb(231, 231, 231);
  margin-top: 15px;
  padding-top: 35px;
  float: left;
  width: 100%;
}

.menu-pop-help h4 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 15px;
}

.menu-pop-help .user-pro {
  float: left;
  width: 72px;
}

.menu-pop-help .user-pro img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  float: left;
}

.menu-pop-help .user-bio {
  float: left;
  width: calc(100% - 72px);
  padding: 0px 0px 0px 20px;
}

.menu-pop-help .user-bio h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1px;
}

.menu-pop-help .user-bio span {
  font-size: 13px;
  padding-bottom: 10px;
  display: inline-block;
}

.menu-pop-help .user-bio a {
  font-size: 16px;
  color: rgb(255, 255, 255);
  background: rgb(96, 125, 139);
  border: 0px;
  font-family: "Josefin Sans", sans-serif;
  padding: 7px 12px 5px;
}

.menu-pop2 .menu-pop-help {
  border: 0px;
  padding-top: 0px;
}

.menu-pop2 .menu-pop-help h4 {
  text-align: center;
}

.menu-pop2 .menu-pop-help .user-pro {
  width: 100%;
  position: relative;
}

.menu-pop2 .menu-pop-help .user-pro::before,
.menu-pop2 .menu-pop-help .user-pro::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  clip-path: polygon(78% 38%, 8% 100%, 100% 100%);
  z-index: -1;
  transform-origin: center center;
}

.menu-pop2 .menu-pop-help .user-pro::before {
  background: rgb(0, 255, 125);
  transform: rotate(38deg);
  left: 0px;
  top: -15px;
  animation: 20s ease 0s infinite normal both running rotat-light;
}

.menu-pop2 .menu-pop-help .user-pro::after {
  background: rgb(236, 255, 0);
  transform: rotate(353deg);
  right: 18px;
  bottom: -25px;
  animation: 20s ease 5s infinite normal both running rotat-light;
  clip-path: circle(50% at 50% 50%);
}

@keyframes rotat-light {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu-pop2 .menu-pop-help .user-pro img {
  width: 225px;
  border-radius: 5px;
  height: 225px;
  margin: 0px auto;
  display: table;
  float: initial;
  position: relative;
}

.menu-pop2 .menu-pop-help .user-bio {
  width: 100%;
  text-align: center;
  padding: 25px 25px 5px;
}

.menu-pop2 .menu-pop-help .user-bio h5 {
  font-size: 20px;
}

.menu-pop2 .menu-pop-soci ul {
  margin: 0px auto;
  display: table;
}

.menu-pop2 .menu-pop-soci ul li {
  margin: 0px 2px;
}

.menu-pop2 .menu-pop-soci {
}

.menu-pop-soci {
  float: left;
  width: 100%;
  padding-top: 30px;
}

.menu-pop-soci ul {
}

.menu-pop-soci ul li {
  display: inline-block;
}

.menu-pop-soci ul li a {
  display: inline-block;
  padding: 0px !important;
}

.menu-pop-info li {
  margin-bottom: 23px;
  position: relative;
}

.menu-pop-info li a {
  font-size: 16px;
  color: rgb(32, 43, 58);
  padding: 4px 0px 0px 55px;
  display: inline-block;
  font-weight: 500;
}

.menu-pop-info li a i {
  width: 38px;
  height: 38px;
  background: rgb(75, 62, 126);
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  padding: 9px;
  margin-right: 10px;
  position: absolute;
  left: 0px;
  top: -2px;
}

.menu-pop-soci ul li a i {
  width: 38px;
  height: 38px;
  background: rgb(247, 247, 247);
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  font-size: 18px;
  padding: 11px 8px;
  transition: all 0.5s ease 0s;
}

.menu-pop-soci i:hover,
.menu-pop-soci i.fa:hover {
  color: rgb(255, 255, 255);
}

/* i.fa-facebook:hover {
  background: rgb(0, 90, 255);
} */

.ed-com-t1-social ul li a i:hover,
.foot-social ul li i:hover {
  color: rgb(255, 255, 255);
}
/* 
i.fa-twitter:hover {
  background: rgb(0, 172, 237);
}

i.fa-linkedin:hover {
  background: rgb(14, 118, 168);
}

i.fa-youtube-play:hover {
  background: rgb(187, 0, 0);
}

i.fa-whatsapp:hover {
  background: rgb(52, 191, 73);
}

i.fa-instagram:hover {
  background: rgb(188, 42, 141);
} */ 

.late-news {
  float: left;
  width: 100%;
  border-top: 1px solid rgb(231, 231, 231);
  margin: 25px 0px 40px;
  padding: 35px 0px;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.late-news h4 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 15px;
}

.late-news ul li {
  float: left;
  width: 100%;
  padding-bottom: 15px;
  position: relative;
}

.late-news ul li:last-child {
  padding-bottom: 0px;
}

.late-news ul li .rel-pro-img {
  width: 80px;
}

.late-news ul li .rel-pro-img img {
  border-radius: 5px;
  width: 75px;
  height: 75px;
}

.late-news ul li .rel-pro-con {
  width: calc(100% - 80px);
  padding: 0px 0px 0px 10px;
}

.late-news ul li .rel-pro-con h5 {
  font-size: 15px;
  white-space: break-spaces;
  line-height: 22px;
  padding-bottom: 4px;
}

.late-news ul li .rel-pro-con span {
  position: relative;
  padding-left: 12px;
}

.late-news ul li .rel-pro-con span::before {
  content: "\f107";
  position: absolute;
  color: rgb(51, 51, 51);
  font: 200 16px / 1 FontAwesome;
  top: -1px;
  left: 0px;
}

.prof-rhs-help {
  /* background: url("../public/images/home_left.jpg") 0% 0% / cover no-repeat; */
  padding: 35px 25px;
  border-radius: 10px;
  margin: 0px 0px 25px;
  position: relative;
  color: rgb(255, 255, 255);
  float: left;
  width: 100%;
}

.prof-rhs-help .inn {
  position: relative;
  text-align: center;
}

.prof-rhs-help::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.11);
  inset: 0px;
  border-radius: 10px;
}

.prof-rhs-help:hover a {
  color: rgb(255, 255, 255);
}

.prof-rhs-help h3 {
  font-size: 23px;
  color: rgb(255, 255, 255);
  margin-top: 0px;
}

.prof-rhs-help p {
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.prof-rhs-help a {
  color: rgb(255, 255, 255);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px 22px;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  background: rgb(11, 41, 203);
}

.rel-pro-img {
  width: 50px;
  float: left;
}

.rel-pro-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.rel-pro-con {
  float: left;
  width: calc(100% - 50px);
  padding: 5px 0px 0px 8px;
}

.rel-pro-con h5 {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  transition: all 0.5s ease 0s;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  position: relative;
  margin: 0px;
}

.rel-pro-con span {
  font-weight: 500;
  font-size: 11px;
  color: rgb(89, 89, 93);
}

.menu-pop2.act {
  right: -18px;
}

.pop-bg {
  position: fixed;
  background: rgba(4, 6, 10, 0.6);
  width: 100%;
  height: 100%;
  z-index: 4;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(0);
  opacity: 0;
  right: -150%;
}

.pop-bg.act {
  transform: scale(1);
  opacity: 1;
  right: 0px;
}

section.c3 {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* background: url("../public/images/bannerbg.jpg") center center / cover no-repeat; */
}

.item {
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  float: right;
}

.slider-cap {
}

.slider-cap h1 {
  font-size: 40px;
  font-weight: 700;
}

.slider-cap h5 {
}

.slider-cap p {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 30px;
  padding-top: 15px;
  line-height: 32px;
  color: rgb(255, 255, 255);
}

.slider-cap a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 8px 15px 10px;
  background: rgb(26, 165, 216);
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
}

.slider-cap a:hover {
  transition: all 0.5s ease 0s;
  background: rgb(26, 165, 216);
  color: rgb(0, 0, 0);
  text-shadow: rgba(0, 0, 0, 0) 0px 1px 3px;
}

.banner {
  /* background: url("../public/images/banner.jpg") 0% 0% / cover no-repeat; */
  padding: 100px 0px;
  position: relative;
}

.banner::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(76, 97, 175, 0.34);
}

.banner h1 {
}

.banner p {
}

.home_enq {
  background: rgb(37, 61, 82);
}

.tp-simpleresponsive a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  background: rgb(3, 169, 244);
  border-radius: 50px;
  margin-left: 25px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px;
}

.tp-simpleresponsive a:hover {
  text-decoration: none;
  background: rgb(231, 175, 6);
  transition: all 0.5s ease 0s;
  color: rgb(243, 243, 243);
}

.tb-space {
  padding: 60px 15px 50px 15px;
}

.tb-space-inn {
  padding: 40px 0px;
}

.home_form {
}

.home_form form {
}

.home_form form ul {
  padding: 8px 8px 4px;
  margin-bottom: 0px;
  position: relative;
  overflow: hidden;
}

.home_form form ul li {
  display: inline-block;
  line-height: 10px;
  padding: 5px 0px;
}

.home_form form ul li:nth-child(2) input {
  position: relative;
}

.home_form form ul li:nth-child(2)::before {
}

.home_form form ul li h4 {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  margin-top: 8px;
}

.home_form form ul li input {
  border: 0px;
  padding: 8px;
  border-radius: 2px;
  width: 95%;
}

.home_form form ul li textarea {
  border: 0px;
  padding: 7px;
  border-radius: 2px;
  resize: none;
}

.home_form form ul li input[type="submit"] {
  background: rgb(26, 165, 216);
  line-height: 13px;
  font-weight: 600;
  padding-top: 11px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.b_pack {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 5px -1px;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 30px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.b_pack h4 {
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px 0px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.b_pack h4 a {
  color: rgb(0, 0, 0);
  text-decoration: none;

  font-weight: 600;
}

.v_pl_name {
  text-transform: capitalize;
  padding: 5px;
  color: rgb(108, 108, 108);
  font-weight: 500;
}

.v_place_img {
  position: relative;
  overflow: hidden;
}

.v_place_img img {
  width: 100% !important;
}

.b_packages img {
  width: 100%;
  transition: all 0.4s ease 0s;
}

.b_packages img:hover {
  transform: scale(1.08);
}

.band {
  width: 50px;
  height: auto;
  position: absolute;
  z-index: 9;
  left: 29px;
  top: -6px;
}

.band img:hover {
  transform: scale(1);
}

.home_title {
}

.home_title h1 {
  text-align: center;
  color: rgb(66, 78, 87);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  margin-top: 0px;
}

.home_title h1 span {
  color: rgb(0, 188, 212);
}

.home_title p {
  color: rgb(112, 114, 117);
  text-align: center;
}

.pack_icon {
}

.pack_icon ul {
  padding: 15px 0px 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}

.pack_icon ul li {
  display: inline-block;
  width: 25%;
}

.pack_icon ul li a {
}

.b_pack div {
}

.pack_icon_2 img {
  width: 22px;
}

.home_all {
  background: rgb(26, 165, 216);
  border-bottom: 2px solid rgb(227, 170, 0);
  border-radius: 3px;
  margin: 0px auto;
  display: table;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
}

.h_con {
  background: rgb(26, 165, 216);
  position: relative;
  overflow: hidden;
}

.home_consul_1 {
  padding-right: 0px;
  padding-left: 0px;
}

.home_consul_1 img {
  width: 100%;
}

.home_consul {
  overflow: hidden;
  padding: 0px 50px;
}

.home_consul h1 {
  font-size: 42px;
  padding: 5px 0px 25px;
}

.home_consul h4 {
  font-size: 34px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 10px 0px 25px;
}

.home_consul p {
  line-height: 35px;
}

.home_consul a {
  background: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(214, 162, 4);
  border-radius: 3px;
  display: table;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  margin-top: 40px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.tout-map {
}

.tout-map iframe {
  height: 300px;
  width: 100%;
  border: 0px;
}

.to-ho-hotel {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
}
.book-slie-pg {
  margin-top: var(--topspac);
  padding: 0 0 50px 0;
  background-image: linear-gradient(to top, #f4f4f4 0%, #e5eeff 100%);
}
.book-slie-pg .container {
  width: 100%;
  padding: 0;
}
.book-slie-pg .to-ho-hotel ul li {
  padding: 0;
  float: left;
}
.book-slie-pg .to-ho-hotel-con {
  border: 0;
  border-radius: 0;
}
.book-slie-pg .pack-new-box .to-ho-hotel-con-1 img {
  height: calc(100vh - 80px);
}
.book-slie-pg .hom-pack-deta {
  padding: 0px 50px 70px;
}
.to-ho-hotel-con {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 7px;
  margin-bottom: 30px;
  border-radius: 20px;
}

.to-ho-hotel-con .fclick {
  z-index: 3;
}

.to-ho-hotel-con-1 {
  position: relative;
}

.to-ho-hotel-con-1::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.72) 14%,
    rgba(0, 0, 0, 0.08) 35%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.pack-new-box .to-ho-hotel-con-1::before {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.68) 15%,
    rgba(0, 0, 0, 0) 87%
  );
}

.pack-new-box * {
  outline: none;
}

.to-ho-hotel-con-1 img {
  width: 100%;
  height: 325px;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
  transition: all 0.5s ease 0s;
}

.pack-new-box .to-ho-hotel-con-1 img {
  height: 450px;
}

.inn-page-plac-2 .pack-new-box .to-ho-hotel-con-1 img {
  height: 300px;
}

.to-ho-hotel-con-23 {
  position: relative;
  overflow: hidden;
  padding: 25px;
}

.to-ho-hotel-con-2 {
}

.to-ho-hotel-con-2 h4 {
  margin-top: 0px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.to-ho-hotel-con-2 a {
  color: rgb(51, 51, 51);
}

.to-ho-hotel-con-3 {
}

.to-ho-hotel-con-3 ul {
  padding: 0px;
  margin-bottom: 0px;
}

.to-ho-hotel-con-3 ul li {
  list-style-type: none;
  float: left;
}

.to-ho-hotel-con-3 ul li:last-child {
  float: right;
  padding-top: 10px;
}

.hom-pack-deta {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;
  padding: 0px 50px 30px;
  z-index: 2;
}

.hom-pack-deta h2 {
  margin: 0px 0px 20px;
  font-size: 28px;
}

.hom-pack-deta h4 {
  font-weight: 500;
  padding-bottom: 5px;
}

.hom-pack-deta h4 span {
  font-size: 18px;
  font-weight: 600;
  background: rgb(239, 149, 15);
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.ho-hot-rat-star {
  padding-bottom: 0px !important;
}

.ho-hot-rat-star i {
  padding-left: 3px;
  font-size: 14px !important;
}

.ho-hot-rat-star-list {
}

.ho-hot-rat-star-list i {
  color: rgb(4, 171, 192);
}

.hot-list-left-part-rat {
  width: 25px;
  height: 25px;
  background-color: rgb(4, 171, 192);
  padding: 4px;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
  margin-right: 10px;
}

.ho-hot-pri {
  font-size: 38px;
  font-weight: 600;
  color: rgb(244, 54, 79);
  float: right;
}

.ho-hot-pri-dis {
  font-weight: 400;
  color: rgb(136, 136, 136);
  float: right;
  padding: 0px 5px;
}

.hot-page2-hli-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: rgba(255, 197, 37, 0.79);
  padding: 4px;
  margin: 9px 5px;
  right: 5px;
  border-radius: 5px;
}

.to-ho-hotel-con-4 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 14px;
}

.to-ho-hotel-con-4 a {
  float: left;
  width: 47%;
  text-align: center;
}

.to-ho-hotel-con-5 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 12px;
}

.to-ho-hotel-con-5 ul {
  margin-bottom: 0px;
  padding: 0px;
}

.to-ho-hotel-con-5 ul li {
  float: left;
  list-style-type: none;
  display: inline-block;
  width: 25%;
}

.to-ho-hotel-con-5 ul li a {
  display: block;
  border: 1px solid rgb(236, 236, 236);
  margin: 2px;
  padding: 0px 5px;
  font-size: 12px;
  color: rgb(138, 138, 138);
  text-align: center;
}

.to-ho-hotel-con-5 ul li a i {
  padding-right: 5px;
}

.hom-hot-book-btn {
  margin-right: 3%;
}

.hom-hot-view-btn {
  margin-left: 3%;
}

.hom-hot-av-tic {
  position: absolute;
  background-color: rgba(137, 255, 0, 0.62);
  bottom: 15px;
  padding: 3px 10px;
  margin: 0px;
  right: 15px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  border-radius: 25px;
  border: 1px solid rgb(37, 55, 71);
}

.hom-hot-av-tic-list {
  left: 10px;
  right: inherit;
  bottom: 10px;
  border-radius: 2px;
  background: #4caf50;
  color: #fff;
  font-weight: 600;
  padding: 1px 8px;
  font-size: 13px;
  border: 0;
}

.plac-hom-box {
  border: 1px solid rgb(207, 215, 229);
  border-radius: 10px;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.plac-hom-box .fclick {
  z-index: 3;
}

.plac-hom-box-im::before,
.plac-det-ban-im::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.61));
  z-index: 1;
}

.plac-hom-box:hover {
}

.plac-hom-box:hover .plac-hom-box-im img,
.pack-new-box:hover .to-ho-hotel-con-1 img {
  transform: scale(1.1);
}

.plac-det-ban-im .share-new-top {
  z-index: 1;
}

.plac-hom-box-im {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.plac-hom-box-im img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  transition: all 0.4s ease-in-out 0s;
}

.image-size-250 .plac-hom-box-im img {
  height: 270px;
}

.plac-det-eve img,
.plac-det-eve .plac-hom-box-im::before {
  border-radius: 10px;
}

.plac-hom-box-im h4 {
  position: absolute;
  bottom: 18px;
  left: 18px;
  color: rgb(255, 255, 255);
  font-size: 22px;
  margin-bottom: 0px;
  z-index: 2;
  white-space: break-spaces;
}

.plac-hom-box-txt {
  padding: 15px;
  float: left;
  width: 100%;
}

.plac-hom-box-txt span {
  float: left;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.plac-hom-box-txt span:nth-child(1) {
  width: 205px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  padding-right: 15px;
  color: rgb(48, 60, 70);
}

.plac-hom-box-txt span:nth-child(2) {
  padding: 3px 0px 0px 15px;
  font-size: 13px;
}

.plac-hom-box-txt span:nth-child(2)::after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  line-height: 26px;
  padding-left: 5px;
  transition: all 0.4s ease-in-out 0s;
}

.plac-hom-box:hover .plac-hom-box-txt span:nth-child(2)::after {
  padding-left: 8px;
}

.hotel-book-room {
  margin-bottom: 105px;
}

.carousel-inner1 > .item > img,
.carousel-inner1 > .item > a > img {
  width: 100%;
  margin: auto;
}

.carousel-indicators-1:hover {
  overflow-y: auto;
}

.tr-room-type {
}

.tr-room-type ul {
  padding: 0px;
  margin-bottom: 0px;
}

.tr-room-type ul li {
  list-style-type: none;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(206, 206, 206);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tr-room-type-list {
}

.tr-room-type-list p b {
  color: rgb(33, 61, 68);
}

.tr-room-type-list span b {
  color: rgb(33, 61, 68);
}

.tr-room-type-list-1 {
  padding-left: 0px !important;
}

.tr-room-type-list-1 img {
  width: 100%;
}

.tr-room-type-list-2 h4 {
  margin-top: 0px;
  color: rgb(33, 61, 68);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}

.tr-room-type-list-2 span {
  display: block;
}

.tr-room-type-list-3 {
}

.tr-room-type-list-3 span {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.tour-consul {
  /* background: url("../public/images/consul-bg.jpg") 0% 0% / cover no-repeat fixed rgb(0, 188, 212); */
  position: relative;
  overflow: hidden;
}

.tour-consul::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  background: rgba(19, 40, 58, 0.87);
}

.tour-consul {
}

.tour-consul h2 {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 42px;
  margin-top: 25px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.tour-consul p {
  color: rgb(212, 216, 216);
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  border-top: 1px solid rgb(76, 84, 90);
  border-bottom: 1px solid rgb(76, 84, 90);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.to-con-1 {
  background: rgb(26, 165, 216);
  color: rgb(0, 0, 0);
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 8px;
}

.to-con-2 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: 25px;
  font-weight: 700;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(46, 157, 162), rgb(19, 177, 148));
}

.to-con-3 {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(26, 165, 216), rgb(19, 134, 177));
}

.to-con-4 {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(26, 165, 216), rgb(19, 134, 177));
}

.ho-con-cont {
}

.ho-vid {
}

.ho-vid img {
  width: 100%;
}

.events table {
  width: 100%;
}

.events table tr {
  line-height: 50px;
  border-bottom: 1px solid rgb(233, 233, 233);
  transition: all 0.5s ease 0s;
}

.events table tr:hover {
  background: rgb(233, 242, 243);
  transition: all 0.5s ease 0s;
}

.events table tr th {
  color: rgb(74, 80, 93);
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  padding: 10px 12px !important;
}

.events table tr td {
  color: rgb(114, 112, 112);
  font-size: 15px;
}

.events table tr td:first-child {
  padding-right: 5px;
  text-align: center !important;
}

.events table tr td a:hover {
  background: linear-gradient(rgb(222, 37, 63), rgb(223, 39, 90));
}

.events table tr th:first-child {
  padding-right: 5px;
  text-align: center !important;
}

.events-1 {
  /* */
}

.events-1 h1 {
  margin-top: 0px;
}

.events img {
  width: 110px;
  padding: 10px 15px 10px 0px;
}

.events-title {
  font-size: 15px;
  margin-bottom: 30px;
  background: none !important;
}

#myInput {
  /* background-image: url("../public/images/searchicon.png"); */
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid rgb(221, 221, 221);
  margin-bottom: 12px;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  font-size: 18px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 0px 12px;
}

#myTable tr {
  border-bottom: 1px solid rgb(221, 221, 221);
}

#myTable tr.header,
#myTable tr:hover {
  background-color: rgb(241, 241, 241);
}

.events table tr:first-child {
}

.events table tr:first-child:hover {
  background: none;
}

.place {
  margin-bottom: 30px;
}

.popu-places-home {
  position: relative;
  overflow: hidden;
}

.place img {
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.29) 1px 3px 10px;
}

.place img:hover {
}

.pla {
  background: 0% 0% / cover fixed rgb(239, 245, 251);
  position: relative;
  overflow: hidden;
}

.pla::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0px;
  top: 0px;
  background: linear-gradient(rgb(242, 249, 255), rgb(225, 228, 232));
}

.pla1 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.place h3 {
  color: rgb(37, 61, 82);
  font-size: 18px;
  text-transform: uppercase;
  line-height: 34px;
  margin-top: 0px;
  margin-bottom: 0px;

  font-weight: 600;
}

.place h3 span {
  color: rgb(37, 61, 82);
  display: block;
  font-weight: 600;

  text-transform: capitalize;
}

.place p {
  color: rgb(37, 61, 82);
  padding: 10px 0px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
}

.place a {
}

.place a:hover {
}

.pla1 h1 {
  text-align: center;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  margin-top: 0px;
  padding-bottom: 40px;
  position: relative;
}

.pla1 h1 span {
  color: rgb(0, 188, 212);
}

.form_1 ul {
  padding: 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}

.form_1 ul li {
  list-style-type: none;
  width: 50%;
  float: left;
  padding: 0px 8px 16px;
  box-sizing: border-box;
}

.form_1 ul li:last-child {
  padding-bottom: 0px;
}

.form_1 ul li input {
  border: 1px solid rgb(187, 187, 187);
  width: 100%;
  padding: 10px;
  border-radius: 3px;
}

.form_1 ul li input[placeholder] {
  color: rgb(107, 122, 136);
}

.form_1 ul li select {
  color: rgb(107, 122, 136);
}

.form_1 ul li textarea {
  border: 1px solid rgb(187, 187, 187);
  resize: none;
  border-radius: 3px;
  width: 100%;
}

.form_1 ul li select {
  border: 1px solid rgb(187, 187, 187);
  width: 100%;
  padding: 14px 10px;
  border-radius: 3px;
}

.form_book ul li:nth-child(13) {
  width: 100%;
}

.form_book ul li:nth-child(7) {
  width: 50% !important;
}

.form_1 ul li:nth-child(7) textarea {
  height: 120px;
  padding: 10px;
  background: rgb(255, 255, 255);
}

.form_book select {
}

.form_1 ul li:nth-child(7) {
  width: 100%;
}

.form_1 ul li input[type="submit"] {
  font-weight: 700;
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 12px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(26, 165, 216), rgb(19, 134, 177));

  border: 0px;
  text-transform: uppercase;
}

.form_1 ul li input[type="submit"]:hover {
}

.form {
  /* background: url("../public/images/cloud.png") center bottom / contain no-repeat rgb(255, 255, 255); */
  padding: 100px 0px 45px;
}

.form_booking {
  /* background: url("../public/images/cloud.png") center bottom / contain no-repeat rgb(242, 248, 249); */
  padding: 100px 0px 45px;
}

.form-spac {
  padding: 100px 0px;
}

.form_book_1 ul li:nth-child(11) {
  width: 100%;
}

.family {
}

.family img {
  width: 95%;
  margin-bottom: -45px;
}

.tips {
  /* background: url("../public/images/tipsbg.jpg") center center / cover no-repeat rgb(240, 242, 242); */
  position: relative;
  overflow: hidden;
}

.tips-home tb-space {
}

.home_tip {
  margin-top: 0px;
}

.tips h3 {
  background: rgb(46, 165, 211);
  display: inline-block;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 5px 8px 3px;
  font-weight: 500;
  margin-top: 0px;
  font-size: 20px;
}

.tips_left {
  padding-left: 100px;
  padding-bottom: 25px;
}

.tips_left h5 {
  color: #000;
  font-size: 18px;
}

.tips_left p {
  color: rgb(30, 32, 33);
  text-align: left;
}
/* 
.tips_left_1 {
  background: url("../public/images/tips1.png") left top no-repeat;
  margin-top: 25px;
}

.tips_left_2 {
  background: url("../public/images/tips2.png") left top no-repeat;
}

.tips_left_3 {
  background: url("../public/images/tips3.png") left top no-repeat;
  padding-bottom: 5px;
} */

.tips p {
  line-height: 25px;
  text-align: left;
  margin-bottom: 12px;
}

.testi {
  /* background: url("../public/images/testi_img.png") left top no-repeat; */
  padding-left: 116px;
  margin-top: 20px;
  padding-bottom: 15px;
}

.testi h4 {
  color: #000;
}

.testi p {
  color: rgb(30, 32, 33);
}

.testi address {
  color: rgb(30, 32, 33);
}

.testi a {
  background: rgb(20, 160, 214);
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  border-radius: 50px;
}

.testi a:hover {
  background: rgb(26, 165, 216);
  color: rgb(0, 0, 0);
}

.p-tesi {
  border: 1px dashed rgb(224, 224, 224);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 20px 15px;
}

.p-tesi h4 {
  color: rgb(37, 61, 82);
  margin-top: 0px;
}

.p-tesi p {
}

.p-tesi address {
  margin-bottom: 0px;
  color: rgb(37, 61, 82);
  font-weight: 600;
}

.arrange {
  padding-top: 12px;
}

.arrange ul {
  padding: 0px;
  margin-bottom: 0px;
}

.arrange ul li {
  width: 45%;
  list-style-type: none;
  padding: 5px;
  display: inline-block;
}

.arrange ul li a {
}

.arrange ul li a img {
  width: 100%;
  border-radius: 10px;
}

.footer {
  background: rgb(0, 0, 0);
  position: relative;
  overflow: hidden;
  padding-bottom: 85px;
}

.footer h4 {
  color: rgb(172, 173, 175);
  text-transform: uppercase;
  padding-bottom: 15px;
}

.foot-social {
}

.foot-social ul {
  padding: 0px;
}

.foot-social ul li {
  list-style-type: none;
  float: left;
  padding-right: 10px;
}

.foot-social ul li i {
  font-size: 16px;
  width: 32px;
  height: 32px;
  border: 1px solid rgb(45, 45, 45);
  border-radius: 50px;
  padding: 7px;
  text-align: center;
  color: rgb(105, 105, 105);
}

footer ul li a {
  color: rgb(110, 113, 115);
  transition: all 0.5s ease 0s;
}

.foot-social ul li a:hover {
  padding-left: 0px;
}

.foot-logo {
}

.foot-logo img {
  padding-bottom: 20px;
}

.foot-spec {
}

.foot-spec span {
  color: rgb(172, 173, 175);
  text-transform: uppercase;

  font-weight: 700;
  font-size: 18px;
}

.foot-sec2 p {
  color: rgb(97, 97, 97);
}

.two-columns {
  padding: 0px;
}

.two-columns li {
  width: 50%;
  float: left;
  list-style-type: none;
}

.two-columns::after {
  content: "";
  display: table;
  clear: both;
}

.two-columns li a {
  color: rgb(97, 97, 97);
}

.footer_social {
}

.footer_social ul {
  padding: 0px;
}

.footer_social ul li {
  display: inline-block;
}

.footer_social ul li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.footer_social ul li a i {
}

.footer_social ul li a .fa-facebook {
  background: rgb(59, 89, 152);
  padding: 10px 15px;
}

.footer_social ul li a .fa-google-plus {
  background: rgb(221, 75, 57);
  padding: 10px;
}

.footer_social ul li a .fa-twitter {
  background: rgb(85, 172, 238);
  padding: 10px 12px;
}

.footer_social ul li a .fa-linkedin {
  background: rgb(0, 119, 181);
  padding: 11px 12px;
}

.footer_social iframe {
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative;
}

.footer_social iframe.clicked {
  pointer-events: auto;
}

.footer_sub {
}

.footer_sub input {
  width: 100%;
  border: 0px;
  padding: 7px 5px;
  margin-bottom: 12px;
  border-radius: 1px;
}

.footer_sub input[type="submit"] {
  background: rgb(255, 87, 34);
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  border-radius: 1px;
  width: 50%;
  float: right;
  margin-bottom: 45px;
}

.footer_faq {
}

.footer_faq ul {
  padding: 0px;
}

.footer_faq ul li {
  list-style-type: none;
}

.footer_faq ul li a {
  display: block;
  color: rgb(255, 255, 255);
  text-decoration: none;
  line-height: 30px;
  text-transform: uppercase;
}

.footer_faq p {
  color: rgb(255, 255, 255);
}

.mob_logo {
  display: none;
}

.disco {
  /* background: url("../public/images/f1.png") center center / cover no-repeat; */
  padding: 20px 15px 22px;
  text-align: center;
}

/* .disco1 {
  background: url("../public/images/f2.png") center center / cover no-repeat;
} */

.disco h3 {
  color: rgb(255, 235, 59);
  font-size: 38px;
  margin-top: 0px;
  font-weight: 700;
}

.disco h3 span {
  color: rgb(255, 255, 255);
}

.disco h4 {
  color: rgb(255, 255, 255);
}

.disco p {
  color: rgb(255, 255, 255);
  margin-top: 0px;
}

.disco a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 6px 15px;
  border-radius: 2px;
  display: inline-block;

  transition: all 0.5s ease 0s;
  font-weight: 700;
  background: linear-gradient(rgb(244, 54, 79), rgb(220, 32, 57));
}

.disco a:hover {
  background: rgb(212, 102, 0);
  color: rgb(255, 255, 255);
}

.footer1 {
  background: rgb(0, 0, 0);
  padding: 100px 0px 50px;
  border-top: 1px solid;
}

.footer_places {
}

.footer_places h4 {
  color: rgb(172, 173, 175);
  margin-top: 0px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.footer_places ul {
  padding: 0px;
}

.footer_places ul li {
  display: inline-block;
  padding-right: 3px;
  line-height: 35px;
}

.footer_places ul li a {
  color: rgb(152, 152, 152);
  text-decoration: none;
  background: rgb(45, 45, 45);
  padding: 5px;
}

.footer_places ul li a:hover {
  transition: all 0.5s ease 0s;
  background: rgb(18, 97, 100);
}

.copy {
  background: rgb(255, 255, 255);
}

.copy p {
  text-align: center;
  margin-bottom: 0px;
  padding: 5px;
  font-size: 13px;
  color: rgb(0, 0, 0);
}

.open > .dropdown-menu {
  transition:
    background-color 0.3s ease 0s,
    border 0s ease 0s,
    color 0.3s ease 0s,
    opacity 0.3s ease-in-out 0s;
}

.inner_banner {
  /*background: url("../public/images/list-p-bg.jpg") center center / cover no-repeat;*/
  padding: 55px 0px 10px;
  position: relative;
  margin-top: var(--topspac);
}
.mt-top {
  margin-top: var(--topspac);
}
.inner_banner::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgb(0 0 0 / 70%) 15%,
    rgb(0 0 0 / 28%) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

/* .inner_banner_1 {
  background: url("../public/images/banner/1.jpg") center center / cover no-repeat;
}

.inner_banner_2 {
  background: url("../public/images/banner/2.jpg") center center / cover no-repeat;
}

.inner_banner_3 {
  background: url("../public/images/banner/3.jpg") center center / cover no-repeat;
} */

.inner_banner_4 {
  /* background: url("../public/images/banner/4.jpg") center center / cover no-repeat; */
  padding: 130px 0px 55px 0;
}

/* .inner_banner_5 {
  background: url("../public/images/banner/5.jpg") center center / cover no-repeat;
} */

.inner_banner div.spe-title,
.inner_banner div ul {
  position: relative;
  margin-bottom: 20px;
}

.inner_banner h1 {
  color: rgb(255, 46, 74);
  font-size: 36px;
  /* display: inline-block; */
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 0;
}

.inner_banner h1 span {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}

.inner_banner p {
  color: rgb(214, 214, 214);
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  padding: 15px 0 20px 0;
}

.inner_banner ul {
  margin: 0px auto;
  display: table;
}

.inner_banner ul li {
  float: left;
  display: inline-block;
  color: rgb(255, 255, 255);
  padding: 0px 5px;
}

.inner_banner a {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.inner_banner a i {
  color: rgb(255, 255, 255);
}

.inner_banner a:hover {
}

.bread-acti {
  color: rgb(214, 214, 214) !important;
}

.bg-none {
  background: none;
}

.p2_2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  width: 100%;
}

.p2_1 {
}

.p2_1 img {
  width: 100%;
}

.p2_2 .p2 {
  padding-left: 40px;
}

.p2 h3 {
  color: #000;
  margin-top: 0px;
}

.p2 h3 span {
  display: block;
  margin-top: 12px;
}

.p2 p {
  text-align: left;
  color: #373748;
  line-height: 25px;
  font-size: 16px;
  margin-bottom: 25px;
}

.p2 .fa-star {
  color: rgb(255, 152, 0);
  font-size: 20px;
  margin-right: 3px;
}

.p2 .fa-star-half-o {
  color: rgb(158, 158, 158);
  font-size: 20px;
}

.ticket {
  margin-bottom: 10px;
}

.ticket ul {
  padding: 0px;
  margin-bottom: 5px;
}

.ticket ul li {
  display: inline-block;
  background: rgb(239, 239, 239);
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  margin-right: 1px;
  border-radius: 15px;
}

.ticket ul li:nth-child(1) {
  background: rgb(1 244 10);
}

.featur {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}

.featur h4 {
  /* background: url("../public/images/flag.png") 0px 0px / contain no-repeat; */
  padding-left: 25px;
  margin-top: 15px;
  color: rgb(37, 61, 82);
  text-transform: uppercase;
  font-size: 14px;
}

.featur ul {
  padding: 0px;
  margin-bottom: 0px;
}

.featur ul li {
  /* background: url("../public/images/tick.png") left center no-repeat; */
  display: inline-block;
  padding: 3px 3px 3px 24px;
  color: rgb(37, 61, 82);
  font-size: 13px;
  font-weight: 600;
}

.p2_book {
}

.p2_book ul {
  padding: 0px;
}

.p2_book ul li {
  display: inline-block;
}

.p2_book ul li a {
  display: table;
}

.p2_book ul li a:hover {
  transition: all 0.5s ease 0s;
  background: rgb(21 133 232);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(21 133 232);
}

.p2_book ul li:nth-child(2) a {
  background: #fff;
  border: 2px solid #000;
  color: #000;
  margin-left: 5px;
}

.p2_book ul li:nth-child(2) a:hover {
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  background: #000;
}

.banner_book {
  background: rgb(37, 61, 82);
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.37) 1px 3px 5px;
}

.banner_book_1 {
}

.banner_book_1 ul {
  padding: 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}

.banner_book_1 ul li {
  display: inline;
  line-height: 55px;
  font-size: 18px;
  border-right: 1px solid rgb(49, 76, 100);
  color: rgb(255, 255, 255);
  float: left;
  width: 24%;
  text-align: center;
}

.banner_book_1 ul li:last-child {
  border-right: 0px solid rgba(3, 3, 3, 0.12);
  padding-left: 0px;
}

.banner_book_1 ul li a {
  background: linear-gradient(rgb(244, 54, 79), rgb(220, 32, 57));
  display: block;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  line-height: inherit;
  transition: all 0.5s ease 0s;
}

.banner_book_1 ul li a:hover {
  text-decoration: none;
  transition: all 0.5s ease 0s;
  background: rgb(63, 81, 181);
}

.dl1 {
}

.dl2 {
}

.dl3 {
}

.dl4 {
}

.posts {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-bottom: 50px; */
}

.posts2 img {
  margin-top: 25px;
}

.posts img {
  width: 100%;
}

.posts h3 {
  color: rgb(33, 38, 39);
  margin-top: 0px;
  padding-bottom: 5px;
  text-transform: capitalize;
}

.posts h5 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.posts p {
  text-align: left;
  color: rgb(134, 134, 134);
  line-height: 25px;
  margin-bottom: 25px;
  font-size: 13px;
}

.posts a {
}

.post_author {
  font-weight: 700;
}

.post_date {
  color: rgb(0, 0, 0);
  padding-left: 8px;
  font-weight: 500;
  font-size: 13px;
}

.post_city {
  color: rgb(0, 0, 0);
  padding-left: 8px;
  font-weight: 500;
  font-size: 13px;
}

.tour_font {
  margin-bottom: 50px;
}

.tour_font p {
  text-align: left;
  color: rgb(134, 134, 134);
  font-size: 15px;
  line-height: 25px;
}

.tour_head {
}
.tour_rhs {
  padding-left: 25px;
}
.tour_lhs {
  padding: 0 25px;
}
.tour_head h2 {
  color: rgb(33, 61, 68);
  font-weight: 600;
  margin-top: 0px;
  font-size: 36px;
}

.tour_head span {
  font-family: Poppins, sans-serif;
}

.tour_star {
}

.tour_star i {
  font-size: 18px;
  color: rgb(255, 152, 0);
}

.tour_rat {
  background: rgb(255, 87, 34);
  color: rgb(255, 255, 255);
  font-size: 16px;
  padding: 0px 5px;
  border-radius: 2px;
  margin-left: 8px;
}

.tour_head1 {
}

.tour_head1 h3 {
  /* background: url("../public/images/umbrella.png") left center / 16px no-repeat; */
  padding-left: 35px;
  color: rgb(33, 61, 68);
  margin-bottom: 20px;
  margin-top: 50px;
  font-weight: 600;
  font-size: 22px;
}

.tour_head1 p {
  color: rgb(127, 127, 127);
  font-weight: 500;
}

.tour_head1 table {
  width: 100%;
}

.tour_head1 table tr {
  border-bottom: 1px solid rgb(229, 229, 229);
}

.tour_head1 table tr th {
  color: rgb(46, 46, 46);
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.tour_head1 table tr td {
  line-height: 40px;
  color: rgb(127, 127, 127);
  font-weight: 500;
}

.days {
}

.days h4 {
  margin: 25px 0px 15px;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  color: rgb(33, 61, 68);
}

.days h4 span {
  color: rgb(146, 149, 150);
}

.l-info-pack-days ul {
  padding: 5px 0px 0px;
}

.l-info-pack-days ul li {
  position: relative;
  overflow: hidden;
  padding-left: 70px;
}

.l-info-pack-days ul li i {
  width: 35px;
  height: 35px;
  background: rgb(223, 59, 119);
  border-radius: 50%;
  text-align: center;
  padding: 9px 10px;
  margin-left: -68px;
  position: absolute;
  z-index: 99;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.l-info-pack-days ul li h4 {
  margin-top: 7px;
}

.l-info-pack-days ul li p {
  padding-top: 0px;
  font-size: 13px;
}

.l-info-pack-plac::after {
  content: "";
  background: rgb(206, 206, 206);
  position: absolute;
  width: 1px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: 19px;
}

.tour_right h3 {
  background: 0% 0% / 22px rgb(37, 61, 82);
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-top: 0px;
  font-weight: 600;
  /* text-transform: uppercase; */
  padding: 15px;
  margin-bottom: 0px;
  border-radius: 7px 7px 0 0;
}

.tour_incl {
}

.tour_incl ul {
}

.tour_incl ul li {
  list-style-type: none;
  /* background: url("../public/images/tick.png") left center no-repeat; */
  padding-left: 30px;
  padding-bottom: 8px;
}

.tour_right ul {
  padding: 15px;
  margin-bottom: 0px;
}

.tour_r {
}

.tour_offer {
  /* background: url("../public/images/book-bg.png") center center / cover no-repeat; */
  padding: 50px 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.tour_offer p {
  text-align: center;
  color: rgb(26, 165, 216);
}

.tour_offer h4 {
  color: rgb(255, 255, 255);
  font-size: 50px;
  text-align: center;
}

.tour_offer h4 span {
  color: rgb(241, 40, 96);
  font-size: 25px;
}

.tour_offer ul {
  list-style-type: none;
  margin-bottom: 0px;
}

.tour_offer ul li {
  display: inline-block;
  width: 49%;
}

.tour_offer img {
}

.tour_offer a {
  background: rgb(26, 165, 216);
  display: block;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px;
  font-weight: 600;
  border-radius: 45px;
  font-size: 15px;
}

.tour_right ul li {
}

.band1 {
  width: 50px;
  height: auto;
  position: absolute;
  z-index: 99;
  top: -4px;
  right: 61px;
}

.tour-ri-com {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 30px;
  border-radius: 7px;
}

.hotel-com-color {
}

.hotel-com-color p {
  color: rgb(134, 134, 134);
}

.hotel-gal-arr {
  margin-top: 175%;
  position: absolute;
  background: rgb(0, 193, 255);
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  left: 24px;
  font-size: 24px;
}

.hotel-gal-arr1 {
  right: 10px;
  position: relative;
}

.tour_social {
}

.tour_social ul {
  float: left;
  padding: 15px 15px 10px;
}

.tour_social ul li {
  margin-bottom: 5px;
}

.tour_social ul li:nth-child(2) {
  padding-right: 0px;
}

.tour_social ul li a {
}

.tour_social ul li a i {
  width: 32px;
  height: 32px;
  color: rgb(255, 255, 255);
  padding: 8px;
  text-align: center;
  border-radius: 50%;
}

.tour_incl {
}

.tour_social {
}

.tour_social ul li:nth-child(3) {
  padding-right: 7px;
}

.tour_social ul li:nth-child(4) {
  padding-right: 7px;
}

.tour_help {
}

.tour_help_1 {
  padding: 15px;
  text-align: center;
}

.tour_help_1 i {
}

.tour_help_1 h4 {
  margin-bottom: 0px;
  font-size: 24px;
}

.tour_help_1_call {
  color: rgb(221, 75, 57);
  margin-top: 0px;
}

.tour_rela_1 {
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  padding: 20px 25px 20px 25px;
}

.tour_rela_1 img {
  width: 100%;
}

.tour_rela_1 h4 {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 600;
}

.tour_rela_1 p {
  color: rgb(134, 134, 134);
}

.tour_rela_1 a {
}

.tour_rela_1 a:hover {
}

.tour_booking {
  position: relative;
  overflow: hidden;
}

.book_poly {
}

.book_poly h3 {
  text-transform: uppercase;
  font-size: 28px;
  color: rgb(37, 61, 82);
  margin-top: 0px;
  padding-bottom: 12px;
  font-weight: 600;
}

.book_poly ul {
}

.book_poly ul li {
  line-height: 35px;
  color: rgb(70, 70, 70);
  font-size: 16px;
}

.book_poly p {
  color: rgb(70, 70, 70);
}

.tourb2-ab-p-2 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.tourb2-ab-p1 {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.tourb2-ab-p1-left {
}

.tourb2-ab-p1-left h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(37, 61, 82);
}

.tourb2-ab-p1-left span {
  display: inline-block;
  padding: 20px 0px;
  font-size: 20px;
  font-style: italic;
  font-weight: 200;
  color: rgb(162, 162, 162);
}

.tourb2-ab-p1-left p {
  line-height: 24px;
}

.tourb2-ab-p1-left a {
  padding: 14px 35px;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 2px;
  margin-top: 12px;
}

.tourb2-ab-p1-right {
}

.tourb2-ab-p1-right img {
  width: 100%;
}

.tourb2-ab-p-3 {
  background: #00afeb;
  padding: 70px 0px 20px;
  background-image: linear-gradient(to top, #4481eb 0%, #00afeb 100%);
}

.tourb2-ab-p3 {
}

.tourb2-ab-p3-com {
  padding: 15px 15px 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 2px;
}

.tourb2-ab-p3-com span {
  font-size: 42px;
  font-weight: 600;
  float: left;
  padding-right: 15px;
  color: rgb(255, 194, 16);
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
}

.tourb2-ab-p3-com h4 {
  font-size: 24px;
  font-weight: 600;
  padding-top: 2px;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
  color: rgb(255, 255, 255);
  margin: 0 0 25px 0;
}

.tourb2-ab-p3-com p {
  display: block;
  float: left;
  width: 100%;
}

.tourb2-ab-p3-1 {
}

.tourb2-ab-p3-1 p {
  font-size: 16px;
  font-weight: 300;
  color: rgb(237 247 255);
}

.tourb2-ab-p-4 {
  background: rgb(255, 255, 255);
  padding: 100px 0px 70px;
}

.tourb2-ab-p4 {
}

.tourb2-ab-p4-com {
  background: rgb(255, 255, 255);
  padding: 25px 25px 16px;
  position: relative;
  overflow: hidden;
  border: 1px dashed rgb(224, 224, 224);
  margin-bottom: 30px;
}

.tourb2-ab-p4-com i {
  font-size: 42px;
  float: left;
  padding-right: 25px;
  color: #4373eb;
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
}

.tourb2-ab-p4-com h4 {
  padding-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(37, 61, 82);
}

.tourb2-ab-p4-com h4 span {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4373eb;
}

.tourb2-ab-p4-com p {
  display: block;
  float: left;
  width: 100%;
}

.tourb2-ab-p4-text {
  position: relative;
  overflow: hidden;
}

.about {
}

.about h1 {
  padding-bottom: 15px;
}

.about h2 {
}

.about p {
  text-align: left;
  margin-bottom: 15px;
}

.myfont {
}

.about_ser {
  position: relative;
  overflow: hidden;
}

.about_ser ul {
  padding: 0px;
}

.about_ser ul li {
  display: inline-block;
  text-align: center;
  border: 1px solid rgb(255, 255, 255);
  font-size: 18px;
  padding: 20px;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  background: rgb(85, 192, 234);
  margin-bottom: 4px;
}

.about_ser ul li i {
  font-size: 34px;
  color: rgb(241, 241, 241);
  text-shadow: rgba(0, 0, 0, 0.08) 1px 3px 5px;
}

.about_ser ul li:hover {
  background: rgb(58, 62, 68);
  transition: all 0.5s ease 0s;
  color: rgb(181, 181, 181);
}

.p_testi {
}

.p_testi h4 {
  font-weight: 600;
  color: rgb(58, 58, 58);
}

.p_testi p {
  color: rgb(132, 132, 132);
}

.p_title h2 {
  /* background: url("../public/images/umbrella.png") left center / 25px no-repeat; */
  padding-left: 50px;
  color: rgb(33, 38, 39);
  margin-bottom: 20px;
  margin-top: 30px;
}

.p_title h2 span {
  color: rgb(46, 165, 211);
}

.p_testi address {
  color: rgb(162, 162, 162);
  margin-bottom: 0px;
}

.p_testimonial {
  position: relative;
  overflow: hidden;
}

.p_testimonial p {
  text-align: left;
  margin-bottom: 10px;
  line-height: 24px;
  color: rgb(136, 136, 136);
  padding-top: 10px;
}

.p_testimonial h1 {
  padding-bottom: 5px;
}

.tips_travel {
}

.tips_travel h3 {
  /* background: url("../public/images/umbrella.png") left center / 25px no-repeat; */
  padding-left: 50px;
  color: rgb(33, 38, 39);
  margin-bottom: 20px;
  margin-top: 30px;
}

.tips_travel p {
  text-align: left;
  margin-left: 50px;
  margin-bottom: 0px;
}

.tips_travel_1 {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 50px;
}

.tips_travel_1 ul {
  padding: 0px;
}

.tips_travel_1 ul li {
  float: left;
  list-style-type: none;
}

.tips_travel_2 {
  text-align: center;
  background: rgb(255, 255, 255);
  border: 1px dashed rgb(224, 224, 224);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 25px 15px 15px;
  transition: all 0.5s ease 0s;
}

.tips_travel_2:hover {
  box-shadow: rgba(150, 150, 150, 0.4) 0px 15px 32px -5px;
  transition: all 0.5s ease 0s;
}

.tips_travel_2:hover i {
  color: rgb(255, 255, 255);
  background: rgb(37, 61, 82);
  transition: all 0.5s ease 0s;
  border: 0px;
}

.tips_travel_2 i {
  width: 65px;
  height: 65px;
  background: rgb(255, 194, 16);
  border-radius: 50px;
  color: rgb(37, 61, 82);
  padding: 21px 18px;
  font-size: 24px;
  text-align: center;
  transition: all 0.5s ease 0s;
  border: 1px dashed rgb(224, 224, 224);
  text-shadow:
    rgb(255, 255, 255) 0px 1px,
    rgb(38, 47, 51) 0px -1px;
}

.tips_travel_2 h4 {
  color: rgb(37, 61, 82);
  margin-top: 25px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.tips_travel_2 p {
  font-size: 15px;
  font-weight: 400;
}

.pack_title {
  /* background: url("../public/images/places/12.jpg") center center / cover no-repeat; */
  padding: 20px;
  text-align: center;
  border-radius: 20px 20px 0 0;
  position: relative;
}
.pack_title * {
  position: relative;
  z-index: 1;
}
.pack_title:before {
  content: "";
  position: absolute;
  background: #00000063;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; /* z-index: -1; */
}
.pack_title h3 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-shadow: rgba(0, 0, 0, 0.8) 0px 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.pack_title h2 {
  color: #ffeb3b;
  font-weight: 600;
  font-size: 50px;
  text-shadow: rgba(0, 0, 0, 0.8) 0px 2px 5px;
}

.pack_title h2 span {
  color: rgb(255, 255, 255);
  font-size: 29px;
}

.pack_price {
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 13px 13px -12px;
  transition: all 0.5s ease 0s;
  border-radius: 20px;
  border: 1px solid #eceaea;
}

.pack_price:hover .pack_price ul li a {
  background: rgb(255, 214, 0);
  transition: all 0.5s ease 0s;
  color: rgb(0, 0, 0);
}

.pack_price ul {
  padding: 15px 0 0 0;
}

.pack_price ul li {
  text-align: center;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 42px;
  color: rgb(0, 0, 0);
  letter-spacing: 0.3px;
  border-bottom: 1px solid #eceaea;
}

.pack_price ul li a {
  display: block;
  padding: 15px 8px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  background: #197ee3;
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  color: rgb(255, 255, 255);
  border-radius: 0 0 20px 20px;
}

.pack_price ul li a:hover {
  background: rgb(255, 214, 0);
  transition: all 0.5s ease 0s;
  color: rgb(0, 0, 0);
}

.pack_price ul li:last-child {
  border-bottom: 0px;
}

.disc-bot {
  padding-bottom: 35px;
}

.site_map {
  padding: 0px 15px;
}

.site_map ul {
  padding: 0px;
}

.site_map ul li {
  list-style-type: circle;
}

.site_map ul li a {
  font-weight: 600;
  color: rgb(37, 61, 82);
  line-height: 32px;
  font-size: 15px;
}

.site_map ul li ul {
  padding-left: 50px;
}

.site_map ul li ul li {
}

.site_map ul li ul li a {
}

.nf {
  text-align: center;
  font-size: 34px;
  color: rgb(224, 97, 37);
}

.site-map-2 {
}

.site-map-2 h1 {
  padding-bottom: 30px;
}

.nf1 {
  text-align: center;
  font-size: 120px;
  color: rgb(19, 19, 19);
  text-shadow: rgba(22, 111, 182, 0.74) 0px 3px 5px;
}

.links {
  margin: 0px auto;
  display: table;
  text-align: center;
}

.links h4 {
  padding-bottom: 15px;
}

.links ul {
  padding: 0px;
}

.links ul li {
  display: inline-block;
  margin-top: 10px;
}

.links ul li a {
  text-decoration: none;
  border: 1px solid rgb(19, 19, 19);
  padding: 5px;
  color: rgb(19, 19, 19);
  border-radius: 4px;
  font-weight: 500;
}

element.style {
}

.rnslider .button {
  position: absolute;
}

.footer_social_1 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 25px;
}

.footer_social_1 ul {
  display: table;
  margin: 0px auto;
}

.footer_social_1 ul li {
  margin: 0px 3px;
}

.footer_social_1 ul li a {
}

.footer_social_1 ul li a i {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.contact-map {
  margin-bottom: -10px;
}

.contact-map iframe {
  width: 100%;
  height: 320px;
}

.map-container iframe {
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative;
  margin-top: 1px;
  border: 0px;
  border-radius: 10px;
}

.map-container iframe.clicked {
  pointer-events: auto;
}

.succ_mess {
  background: rgb(88, 181, 51);
  color: rgb(255, 255, 255);
  display: none;
  text-align: center;
  padding: 5px;
  margin-bottom: 20px;
  transition: all 0.5s ease 0s;
}

.succ_mess_qe {
  background: rgb(88, 181, 51);
  color: rgb(255, 255, 255);
  display: none;
  text-align: center;
  padding: 5px;
  transition: all 0.5s ease 0s;
}

.form_1 h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  color: rgb(27, 48, 51);
  padding: 0px 8px 10px;
  margin-top: 0px;
}

.post-grid {
  margin-bottom: 45px;
  margin-top: 25px;
}

.n-td {
  color: red;
  text-decoration: line-through;
}

.n-td-1 {
  color: rgb(190, 190, 190);
}

.n-dis-pack {
}

.p-home {
}

#preloader {
  position: fixed;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 99999;
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  /* background-image: url("../public/images/preloader.gif"); */
  background-repeat: no-repeat;
  background-position: center center;
  margin: -100px 0px 0px -100px;
}

.dir-rat {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(222, 222, 222);
  padding: 25px 25px 0px;
  background: rgb(255, 255, 255);
  margin-top: 50px;
}

fieldset,
label {
  margin: 0px;
  padding: 0px;
}

.dir-rat h3 {
  font-size: 18px;
}

.dir-rat-inn {
  position: relative;
  overflow: hidden;
}

.dir-rat h3 {
  font-size: 18px;
}

.dir-rat-form {
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
}

.dir-rat-form label {
  margin-bottom: 10px;
  margin-top: 5px;
  color: rgb(33, 61, 68);
}

.dir-rat-form input,
textarea {
  width: 100%;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  resize: none;
  padding: 18px;
  box-shadow: none;
}

.dir-rat-form textarea {
  height: 120px;
}

.dir-rat-form input[type="submit"] {
  border: 0px;
  padding: 11px;
  /* width: 250px; */
}

.rating {
  border: none;
  float: left;
  height: 48px;
  overflow: hidden;
}

.rating > input {
  display: none;
}

.rating > label::before {
  margin: 5px;
  font-size: 22px;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
  border: 0px !important;
}

.rating > label::after {
  background: none !important;
  border: 0px !important;
}

.rating > .half::before {
  content: "\f005 ";
  position: absolute;
  border: 0px;
}

.rating > label {
  color: rgb(221, 221, 221);
  float: right;
}

.half {
  display: none !important;
}

.dir-rat-review {
  border: 1px solid rgb(222, 222, 222);
  padding: 25px;
  background: rgb(255, 255, 255);
  margin-bottom: 30px;
}

.dir-rat-left {
  text-align: center;
}

.dir-rat-left img {
  border-radius: 50px;
  margin-bottom: 20px;
}

.dir-rat-left p {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 600;
  color: rgb(33, 61, 68);
}

.dir-rat-left p span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: rgb(136, 136, 136);
}

.dir-rat-right {
}

.dir-rat-right p {
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.dir-rat-right ul {
  padding: 0px;
}

.dir-rat-right ul li {
  float: left;
  list-style-type: none;
  display: inline-block;
  padding-right: 15px;
}

.dir-rat-right ul li a i {
}

.dir-rat-right ul li a {
  color: rgb(51, 51, 51);
  font-size: 14px;
}

.dir-rat-star {
  padding-bottom: 20px;
}

.dir-rat-star i {
  font-size: 16px;
  color: rgb(255, 152, 0);
  margin-right: 2px;
}

.dir-rat-right ul li span {
  padding-right: 7px;
  color: rgb(136, 136, 136);
  font-size: 12px;
}

.dir-rat-right ul li:nth-child(2) {
}

.dir-rat-right ul li:nth-child(4) {
}

.dir-rat-title {
}

.dir-rat-title h3 {
  margin-top: 0px;
  color: rgb(33, 61, 68);
  background-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.dir-rat-title p {
}

.hot-page2-hom-pre {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  padding: 15px;
  border: 1px solid rgb(231, 231, 231);
  margin-bottom: 30px;
  border-radius: 0px 0px 10px 10px;
}

.hot-page2-hom-pre ul {
  padding: 0px;
}

.hot-page2-hom-pre ul li {
  list-style-type: none;
  border-bottom: 1px solid rgb(231, 231, 231);
  position: relative;
  overflow: hidden;
  padding: 15px 0px;
}

.hot-page2-hom-pre ul li:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.hot-page2-hom-pre div {
  float: left;
}

.hot-page2-hom-pre ul li a {
}

.hot-page2-hom-pre-1 {
  width: 40px;
}

.hot-page2-hom-pre-1 img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.hot-page2-hom-pre-2 {
  width: calc(100% - 70px);
  padding: 0px 25px;
}

.hot-page2-hom-pre-2 h5 {
  margin-top: 0px;
  margin-bottom: 2px;
  color: rgb(52, 60, 66);
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  transition: all 0.5s ease 0s;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.hot-page2-hom-pre-2 span {
  font-size: 13px;
  color: rgb(153, 153, 153);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.hot-page2-hom-pre-3 {
  width: 30px;
}

.hot-page2-hom-pre-3 span {
  position: absolute;
  border: 1px solid rgb(139, 155, 169);
  padding: 0px 4px;
  border-radius: 2px;
  font-size: 12px;
  z-index: 9;
  line-height: 20px;
  color: rgb(37, 61, 82);
  font-weight: 600;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
  right: 0px;
}

.hot-page2-hom-pre-3 i {
  width: 22px;
  float: right;
  border: 1px solid rgb(169, 169, 169);
  padding: 4px;
  font-size: 12px;
  border-radius: 50%;
  color: rgb(169, 169, 169);
  margin-top: 10px;
}

.hot-page2-hom-pre-tit {
}

.hot-page2-hom-pre-tit h3 {
  color: rgb(52, 60, 66);
  font-size: 28px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.hot-page2-hom-pre-tit h3 span {
  color: rgb(111, 110, 107);
  font-size: 28px;
}

.hot-page2-hom-pre ul li:hover .hot-page2-hom-pre-2 h5 {
  padding-left: 15px;
  transition: all 0.5s ease 0s;
}

.hot-page2-hom-pre-head {
  background: linear-gradient(39deg, rgb(0, 184, 255), rgb(1, 161, 255) 80%);
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.hot-page2-hom-pre-head h4 {
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.hot-page2-hom-pre-head h4 span {
  color: rgb(255, 193, 7);
  font-weight: 600;
  font-size: 18px;
}

.hot-page2-hom-pre-head::after,
.hot-page2-hom-pre-head::before {
  top: 59px;
  left: 48%;
  border: solid transparent;
  content: " ";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
}

.hot-page2-hom-pre-head::after {
  border-color: rgb(1, 173, 255) rgba(136, 183, 213, 0) rgba(136, 183, 213, 0);
  border-width: 9px;
  margin-left: -9px;
  z-index: 9;
}

.hot-page2-il-top-fix {
  display: block;
  opacity: 1 !important;
}

.hot-page2-pa-sp-top {
}
/* 
.hot-page2-alp {
  background: url("../public/images/consul-bg-1.jpg") center top / cover no-repeat fixed rgb(230, 230, 230);
} */

/* .all-hot-bg {
  background: url("../public/images/consul-bg-2.jpg") center top / cover no-repeat fixed rgb(230, 230, 230);
} */

.hot-page2-alp::before {
  content: "";
  position: fixed;
  background: linear-gradient(
    rgba(29, 36, 42, 0.75) 15%,
    rgba(0, 0, 0, 0) 100%
  );
  inset: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hot-page2-alp-tit {
  text-align: center;
}

.hot-page2-alp-tit h1 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 40px;
}

.hot-page2-alp-tit ol {
  background: none;
}

.hot-page2-alp-tit ol li {
}

.hot-page2-alp-tit ol li a {
  color: rgb(138, 138, 138);
}

.breadcrumb > .active {
  color: rgb(255, 199, 47) !important;
}

.breadcrumb > li + li::before {
  color: rgb(138, 138, 138);
}

.hot-page2-alp-con {
  position: relative;
  overflow: hidden;
  background: rgb(242, 241, 241);
  box-shadow: rgba(0, 0, 0, 0.36) 0px 4px 30px;
}

.hot-page2-alp-con-left {
  padding-left: 0px !important;
}

.hot-page2-alp-con-right {
  padding-bottom: 70px;
}

.hot-page2-alp-con-left-1 {
  background: rgb(37, 61, 82);
  color: rgb(255, 255, 255);
  padding: 25px;
}

.hot-page2-alp-con-left-1::after,
.hot-page2-alp-con-left-1::before {
  top: 72px;
  left: 48%;
  border: solid transparent;
  content: " ";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
}

.hot-page2-alp-con-left-1::after {
  border-color: rgb(31, 67, 99) rgba(136, 183, 213, 0) rgba(136, 183, 213, 0);
  border-width: 9px;
  margin-left: -9px;
}

.hot-page2-alp-con-left-1 h3 {
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins;
  text-transform: uppercase;
}

.hot-page2-alp-cl-1-1 {
}

.hot-page2-alp-cl-1-1 img {
}

.hot-page2-alp-cl-1-2 {
  padding-left: 15px;
}

.hot-page2-alp-cl-1-2 h5 {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.hot-page2-hom-pre ul li:hover .hot-page2-hom-pre-2 h5 {
  padding-left: 5px;
  transition: all 0.5s ease 0s;
}

.hot-page2-alp-cl-1-2 span {
  line-height: 12px;
  font-size: 12px;
}

.hot-page2-alp-cl-1-3 {
}

.hot-page2-alp-cl-1-3 img {
  width: 18px;
  float: right;
}

.hot-page2-alp-left-ner-notb {
  border-top: 0px;
  margin-bottom: 0px;
}

.hot-page2-alp-left-ner-notb ul li {
  padding: 10px 0px;
}

.hot-page2-alp-l3 {
}

.hot-page2-alp-l3 ul {
  padding: 0px;
  float: left;
}

.hot-page2-alp-l3 ul li {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.hot-page2-alp-l3 ul li:nth-child(5) {
}

.hot-page2-alp-l3 ul li:last-child {
  border-bottom: 0px solid rgb(212, 212, 212);
}

.hot-page2-alp-l3 ul li label {
}

.hot-page2-alp-l-com {
  padding: 40px 0px 10px 0;
  float: left;
  width: 100%;
}

.hot-page2-alp-l-com h4 {
  color: rgb(255, 255, 255);
  padding: 15px 15px 15px 25px;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  background: rgb(3, 158, 249);
  border-radius: 0px 50px 50px 0px;
  font-weight: 500;
}

.hot-page2-alp-l-com h4 i {
  padding-right: 7px;
}

.hot-page2-alp-l-com1 form {
  padding-bottom: 8px;
}

.hot-page2-alp-l-com1 {
  padding: 15px 15px 0px 25px;
  position: relative;
  float: left;
}

.hot-page2-alp-l-com1 a {
  margin-top: 5px;
  border: 1px solid rgb(37, 65, 88);
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 5px 10px;
  text-decoration: none;
  text-shadow: rgba(177, 177, 177, 0.3) -1px -1px 0px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background-color: rgb(48, 75, 98);
  background-image: linear-gradient(rgb(59, 88, 113), rgb(37, 61, 82));
  margin-right: 5px;
}

.hot-page2-alp-l-com1 a:hover {
  color: rgb(255, 255, 255);
}

.hot-page2-alp-l-com1 a i {
  font-size: 10px;
}

.hot-page2-alp-p3 {
}

.hot-page2-alp-p3 ul {
}

.hot-page2-alp-p3 ul li:nth-child(n + 6) {
  display: none;
}

.hot-page2-alp-p3-btn-h {
  display: none;
}

.hot-page2-alp-p4-btn-h {
  display: none;
}

.hot-page2-alp-p4 ul li:nth-child(n + 6) {
  display: none;
}

.hot-page2-alp-p5-btn-h {
  display: none;
}

.hot-page2-lp-rat {
  padding: 6px 6px 15px 8px;
}

.hot-page2-alp-ri-p2 {
  padding: 18px 15px;
}

.hot-page2-alp-ri-p2 h3 {
  color: rgb(52, 60, 66);
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.hot-page2-alp-ri-p2 ul {
  padding: 12px 0px 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 4px;
}

.hot-page2-alp-ri-p2 ul li {
  list-style-type: none;
  float: left;
  margin-bottom: 8px;
  font-size: 14px;
  padding-left: 30px;
}

.hot-page2-alp-ri-p2 ul li:nth-child(1) {
  color: rgb(52, 60, 66);
  /* background: url("../public/images/1.png") left center / 18px no-repeat; */
  font-size: 15px;
  font-weight: 600;
}

.hot-page2-alp-ri-p2 ul li:nth-child(2) {
  color: rgb(151, 151, 151);
  /* background: url("../public/images/2.png") left center / 18px no-repeat; */
}

.hot-page2-alp-ri-p3 {
  padding: 52px 0px 10px;
}

.hot-page2-alp-ri-p3 span {
  display: block;
  text-align: center;
}

.hot-list-p3-1 {
  color: rgb(151, 151, 151);
  font-weight: 400;
  font-size: 14px;
}

.hot-list-p3-2 {
  font-size: 32px;
  font-weight: 600;
  color: rgb(244, 54, 79);
  padding: 10px 0px;
}

.hot-list-p3-3 {
  font-size: 24px;
  color: rgb(151, 151, 151);
  text-decoration: line-through;
  padding: 10px 0px;
}

.hot-list-p3-4 {
}

.hot-list-p3-4 a {
  transition: all 0.5s ease 0s;
}

.hot-page2-alp-ri-p2 p {
  color: rgb(151, 151, 151);
  margin-bottom: 0px;
  text-transform: lowercase;
  font-size: 14px;
}

.hot-page2-alp-ri-p2 p b {
  padding-right: 5px;
  color: rgb(52, 60, 66);
  text-transform: uppercase;
  font-size: 12px;
}

.hot-page2-alp-ri-p2 a {
}

.hot-page2-alp-ri-p2 a:hover {
  color: rgb(255, 255, 255);
  transition: all 0.5s ease 0s;
}

.hot-page2-alp-quot-btn {
  transition: all 0.5s ease 0s;
  margin-top: 5px;
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 10px;
  text-decoration: none;
  text-shadow: rgba(177, 177, 177, 0.3) -1px -1px 0px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  display: block;
  text-align: center;
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  border: 0px;
}

.btn {
  background: rgb(51, 51, 51);
  color: rgb(255, 255, 255);
  padding: 5px 50px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  line-height: 34px;
  display: block;
  border: 1px solid rgb(51, 51, 51);
  transition: all 0.3s ease 0s;
  width: 100%;
  margin-top: 20px;
}

.hot-page2-alp-quot-free-btn {
  margin-top: 5px;
  border: 1px solid rgb(35, 71, 103);
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 5px 10px;
  text-decoration: none;
  text-shadow: rgba(177, 177, 177, 0.3) -1px -1px 0px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background-color: rgb(31, 67, 99);
  background-image: linear-gradient(rgb(50, 99, 142), rgb(31, 67, 99));
  margin-right: 5px;
  transition: all 0.5s ease 0s;
}

.hot-ameni {
  margin-bottom: -15px;
}

.hot-ameni ul {
  padding: 0px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}

.hot-ameni ul li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  padding-bottom: 15px;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.hot-ameni ul li i {
  font-size: 12px;
  width: 22px;
  height: 22px;
  background: rgb(255, 205, 51);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  margin-right: 6px;
  text-shadow: rgba(255, 255, 255, 0.62) 0px 1px 0px;
}

.hot-page2-alp-r-list-re-sp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hot-page2-alp-r-list {
  border: 1px solid rgb(222, 222, 222);
  background: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  margin: 20px 25px 20px 15px;
  transition: all 0.5s ease 0s;
}

.hot-page2-alp-r-list {
  transition: all 0.5s ease 0s;
}

.hot-page2-alp-r-list:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 19px -3px;
  transform: scale(1.02);
}

.hot-page2-alp-r-list:hover .hot-page2-alp-quot-btn {
  color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
}

.hot-page2-alp-r-hot-page-rat {
  background: rgb(255, 152, 0);
  position: absolute;
  padding: 4px 8px;
  right: -1px;
  border-radius: 1px;
  color: rgb(255, 255, 255);
  top: 0px;
  font-weight: 600;
  font-size: 11px;
}

.hotel-list-score {
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: rgb(255, 152, 0);
  padding: 4px;
  margin: 10px 5px;
  right: 5px;
  border-radius: 2px;
  z-index: 1;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
}

.hot-page2-hr1 {
  text-align: center;
  width: 70%;
  margin: 0px auto;
  position: relative;
}

.hot-page2-hli {
  position: relative;
  overflow: hidden;
}

.hot-page2-hli a {
  text-decoration: none;
}

.hot-page2-hli ul {
  padding: 0px;
}

.hot-page2-hli ul li {
  list-style-type: none;
}

.hot-page2-hli-1 {
  position: relative;
}

.hot-page2-hli-1 img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.hot-page2-hli-3 {
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: rgba(255, 111, 111, 0.68);
  padding: 4px;
  margin: auto;
  right: 15px;
  top: 15px;
  border-radius: 2px;
  display: none;
}

.hot-page2-hli-3 img {
}

.hot-page2-hli-4 {
}

.hot-page2-hli-5 {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin-bottom: 10px;
}

.hot-page2-hli-2 {
  padding: 10px 12px;
  background: rgb(255, 255, 255);
}

.hot-page2-hli-2 h4 {
  color: rgb(39, 52, 64);
  font-size: 16px;
}

.hot-page2-hli-2 h4 span {
  float: right;
  font-size: 12px;
  line-height: 20px;
}

.hot-page2-hpb {
  background: rgb(27, 38, 41);
}

.hot-page2-hpb h2 {
  color: rgb(255, 255, 255);
}

.hot-page2-hpb h5 {
  color: rgb(255, 197, 32);
  padding-top: 14px;
  font-weight: 400;
}

.hot-page2-hpb p {
  color: rgb(152, 152, 152);
  padding-top: 15px;
  margin-bottom: 0px;
}

.hot-page2-hpb ul {
  margin-bottom: 0px;
  padding: 42px 0px 0px;
}

.hot-page2-hpb ul li {
  list-style-type: none;
  display: inline-block;
  width: 32%;
  float: left;
  margin: 0px 3px 5px;
}

.hot-page2-hpb ul li a {
  display: block;
  background: rgb(255, 197, 32);
  color: rgb(0, 0, 0);
  padding: 14px 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
}

.hot-room-ava-check {
  padding: 20px 10px 0px 25px;
}

.hot-room-ava-check form {
  padding-bottom: 0px;
}

.hot-room-ava-check ul {
  margin-bottom: 0px;
}

.hot-room-ava-check ul li {
  border-bottom: 0px;
}

.hot-room-ava-check ul li label {
  padding-left: 0px;
}

.hot-room-ava-check ul li input {
  width: 100%;
  font-size: 13px;
  padding: 4px 10px;
  border: 1px solid rgb(212, 212, 212);
  margin-bottom: 12px;
}

.hot-room-ava-check ul li input[type="submit"] {
  margin-bottom: 0px;
  border: 1px solid rgb(37, 65, 88);
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 5px 10px;
  text-decoration: none;
  text-shadow: rgba(177, 177, 177, 0.3) -1px -1px 0px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(rgb(59, 88, 113), rgb(37, 61, 82));
  background-color: rgb(48, 75, 98) !important;
}

.tourb2-hli-3 {
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: rgba(255, 193, 7, 0.84);
  padding: 4px;
  right: 15px;
  border-radius: 2px;
  z-index: 9;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
  top: 15px;
}

.icon-float {
  position: fixed;
  left: 0px;
  top: 35%;
  z-index: 999;
}

.icon-float ul {
  padding: 0px;
}

.icon-float ul li {
  list-style-type: none;
}

.icon-float ul li a {
  display: block;
  transition: all 0.5s ease 0s;
  padding: 0px;
}

.icon-float ul li a i {
  padding: 8px;
  width: 40px;
  height: 32px;
  text-align: center;
  color: rgb(255, 255, 255);
}

.icon-float ul li a:hover {
  transform: rotateY(-180deg);
}

.icon-float ul li a:hover i {
  transform: rotateY(180deg);
}

.fb1 {
  background: rgb(59, 89, 152);
}

.gp1 {
  background: rgb(221, 75, 57);
}

.tw1 {
  background: rgb(29, 161, 242);
}

.li1 {
  background: rgb(0, 119, 181);
}

.yt1 {
  background: rgb(205, 32, 31);
}

.wa1 {
  background: rgb(52, 175, 35);
}

.sh {
  background: rgb(255, 255, 255);
  padding: 10px 0px;
  font-size: 11px;
  text-align: center;
  color: rgb(51, 51, 51);
  border-top-right-radius: 5px;
}

.sh1 {
  background: rgb(51, 51, 51);
  border-bottom-right-radius: 5px;
}

.top-menu {
  min-width: 200px;
}

.pad-bot-redu {
  padding-bottom: 70px;
}

.pad-bot-redu-5 {
  padding-bottom: 50px;
}

.pad-bot-incre {
  padding-bottom: 100px;
}

.pad-left-o {
  padding-left: 0px !important;
}

.pad-top-o {
  padding-top: 0px;
}

.hom-hotels {
}

.hom-hotels::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(22, 40, 105, 0.91) 46%,
    rgba(0, 0, 0, 0.41) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.tour-mig-like {
}

.tour-mig-like-com {
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;
  background: rgb(20, 173, 219);
  transition: all 0.5s ease 0s;
}

.tour-mig-lc-img {
}

.tour-mig-like-com:hover img {
  transition: all 0.5s ease 0s;
  opacity: 0.5;
}

.tour-mig-lc-img img {
  width: 100%;
  border-radius: 5px;
}

.tour-mig-like-com::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.82) 10%,
    rgba(84, 84, 84, 0.35) 80%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 5px;
}

.tour-mig-lc-con {
  position: absolute;
  width: 100%;
  margin-top: -128px;
  padding: 20px 20px 0px;
  display: block;
  text-align: right;
}

.tour-mig-lc-con2 {
  margin-top: -115px;
}

.tour-mig-lc-con h5 {
  font-size: 28px;
  color: rgb(255, 255, 255);
  padding-top: 10px;
  padding-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 2px;
  font-weight: 500;
}

.tour-mig-lc-con h6 {
  font-size: 14px;
  color: rgb(255, 255, 255);
  position: absolute;
  top: -20px;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px 10px 10px;
  border-radius: 18px;
  margin-bottom: 5px;
}

.tour-mig-lc-con p {
  color: rgb(193, 193, 193);
}

.tour-mig-lc-con p span {
  text-align: left;
  float: left;
  font-size: 20px;
  color: rgb(238, 238, 238);
}

.foot-mob-app {
}

.foot-mob-app h2 {
  margin-top: 0px;
  font-size: 48px;
}

.foot-mob-app p {
  font-size: 15px;
}

.foot-mob-app ul {
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 24px;
}

.foot-mob-app ul li {
  list-style-type: none;
  color: rgb(51, 51, 51);
  line-height: 38px;
  font-size: 18px;
}

.foot-mob-app ul li i {
  color: rgb(255, 255, 255);
  width: 18px;
  height: 18px;
  background: rgb(141, 206, 212);
  border-radius: 3px;
  text-align: center;
  padding: 3px;
  margin-right: 10px;
  font-size: 12px;
  vertical-align: text-bottom;
}

.foot-mob-app a {
}

.foot-mob-sec {
  /* background: url("../public/images/cloud.png") center bottom / contain no-repeat rgb(255, 255, 255); */
  padding: 100px 0px 60px;
  position: relative;
}

.foot-mob-sec::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.55) 0%,
    rgba(240, 240, 240, 0.8) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.mob-menu-icon {
  display: inline-block;
  color: rgb(255, 255, 255);
  background: rgb(246, 99, 101);
  padding: 5px 8px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 2px;
}

.mob-menu-slide {
  background: rgb(255, 255, 255);
  right: -240px;
  padding: 0px;
  border-radius: 2px;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.8) 10px 50px 100px;
  z-index: 9999;
  overflow-y: scroll;
}

.mob-menu-slide h4 {
  padding: 0px 15px 10px;
  border-bottom: 0px solid rgb(48, 59, 64);
  margin-bottom: 0px;
  color: rgb(247, 100, 102);
  margin-top: 30px;
}

.mob-menu-slide ul {
  padding: 0px;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.mob-menu-slide ul li {
  list-style-type: none;
  text-align: left;
  display: block;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(236, 236, 236);
}

.mob-menu-slide ul li a {
  color: rgb(0, 0, 0);
  float: inherit;
  display: block;
  font-size: 13px;
  padding: 12px 20px;
  font-weight: 500;
  cursor: pointer;
}

.mob-top {
}

.mob-menu {
  background: rgb(37, 61, 82);
  position: fixed;
  z-index: 99;
  width: 100%;
  display: none;
  padding: 10px 30px;
  box-shadow: rgba(0, 0, 0, 0.63) 0px 3px 15px;
}

.mob-head-left {
  width: 50%;
  float: left;
  display: block;
}

.mob-head-left img {
}

.mob-head-right {
  width: 50%;
  float: left;
  display: block;
}

.mob-head-right a {
  float: right;
}

.top-menu a i {
  padding-right: 10px;
}

.btn-close-menu {
  display: none;
  background: rgb(246, 99, 101);
  color: rgb(255, 255, 255);
  position: absolute;
  right: 185px;
  padding: 4px;
  border-radius: 42px;
  top: 5px;
  z-index: 99999;
  width: 32px;
  height: 32px;
  text-align: center;
}

.db {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: rgb(234, 237, 239);
  padding: 50px;
  margin-top: var(--topspac);
}

.db-l {
  float: left;
  width: 20%;
  padding-right: 40px;
}

.db-2 {
  float: left;
  width: 58%;
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 218, 218);
  box-shadow: rgba(224, 224, 224, 0.8) 0px 2px 4px;
  border-radius: 4px;
  margin-left: 12px;
  margin-right: 12px;
}

.db-3 {
  float: left;
  width: 18%;
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 218, 218);
  box-shadow: rgba(224, 224, 224, 0.8) 0px 2px 4px;
  border-radius: 4px;
  margin-left: 2%;
}

.db-l-1 {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 218, 218);
  box-shadow: rgba(224, 224, 224, 0.8) 0px 2px 4px;
  border-radius: 4px;
}

.db-l-1 ul {
  padding: 0px;
}

.db-l-1 ul li {
  color: rgb(173, 173, 173);
  list-style-type: none;
  text-align: center;
}

.db-l-1 ul li span {
  display: block;
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  color: rgb(33, 37, 43);
}

.db-l-1 ul li:nth-child(1) {
  width: 100%;
  float: left;
}

.db-l-1 ul li:nth-child(1) img {
  width: 100%;
}

.db-l-1 ul li:nth-child(2) {
  width: 50%;
  float: left;
  padding: 20px 10px;
}

.db-l-1 ul li:nth-child(3) {
  width: 50%;
  float: left;
  padding: 20px 10px;
}

.db-l-2 {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 218, 218);
  padding: 20px;
  margin-top: 12px;
  box-shadow: rgba(224, 224, 224, 0.8) 0px 2px 4px;
  border-radius: 4px;
}

.db-l-2 ul {
  padding: 0px;
}

.db-l-2 ul li {
  border-bottom: 1px solid rgb(234, 237, 239);
  padding: 12px 0px;
  list-style-type: none;
}

.db-l-2 ul li:last-child {
  border-bottom: 0px solid rgb(220, 220, 220);
  padding: 12px 0px 0px;
}

.db-l-2 ul li a {
  color: rgb(51, 51, 51);
}

.db-l-2 ul li a:hover {
  color: rgb(51, 51, 51);
  padding-left: 8px;
}

.db-l-2 ul li a img {
  width: 20px;
  margin-right: 8px;
}

.db-3 h4 {
  margin: 0px;
  background: rgb(37, 61, 82);
  padding: 15px;
  color: rgb(255, 255, 255);
}

.db-3 ul {
  margin-bottom: 0px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px !important;
}

.db-3 ul li {
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 12px 0px;
  list-style-type: none;
}

.db-3 ul li a {
}

.db-3 ul li a img {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  margin-right: 10px;
}

.db-3 ul li a h5 {
  color: rgb(51, 51, 51);
  font-size: 15px;
  margin-top: 0px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.db-3 ul li a p {
  padding-left: 40px;
  margin-bottom: 0px;
  color: rgb(150, 149, 149);
  font-size: 12px;
  line-height: 18px;
}

.db-3 ul li:last-child {
  border-bottom: 0px solid rgb(220, 220, 220);
  padding: 12px 0px 0px;
}

.db-2-com {
}

.db-2-com h4 {
  margin: 0px;
  background: rgb(37, 61, 82);
  padding: 15px;
  color: rgb(255, 255, 255);
}

.db-2-main {
}

.db-2-main-com {
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.db-2-main-com img {
  display: block;
  margin: 0px auto 20px;
  border: 1px solid rgb(234, 237, 239);
  padding: 12px;
  border-radius: 5px;
}

.db-2-main-com span {
  font-size: 24px;
  font-weight: 600;
}

.db-2-main-com ul {
  margin-bottom: 0px;
  margin-top: 10px;
  padding: 0px;
}

.db-2-main-com ul li {
  text-align: left;
  border-bottom: 1px solid rgb(234, 237, 239);
  padding: 12px 0px;
  list-style-type: none;
  /* background: url("../public/images/icon/db-arrow.png") left center / 10px no-repeat; */
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
}

.db-2-main-com ul li a {
  display: block;
  color: rgb(51, 51, 51);
  font-size: 13.5px;
  padding-left: 14px;
}

.db-2-main-com ul li a:hover {
  padding-left: 10px;
}

.db-done {
  background: rgb(62, 142, 65);
  color: rgb(255, 255, 255);
  padding: 2px 4px;
  border-radius: 3px;
  text-transform: lowercase;
  margin-left: 5px;
  font-size: 12px !important;
}

.db-done:hover {
  color: rgb(255, 255, 255);
}

.db-not-done {
  background: rgb(244, 163, 33);
  color: rgb(255, 255, 255);
  padding: 2px 4px;
  border-radius: 3px;
  text-transform: lowercase;
  margin-left: 5px;
  font-size: 12px !important;
}

.db-2-main-1 {
  position: relative;
  overflow: hidden;
  float: left;
  width: 33.3%;
  text-align: center;
}

.db-2-main-2 {
  border-right: 1px solid rgb(234, 237, 239);
  padding: 20px;
}

.db-2-main-com-table table {
  width: 100%;
}

.db-2-main-com-table th {
  font-weight: 600;
  padding: 10px 4px;
}

.db-2-main-com-table tr {
  border-bottom: 1px solid rgb(234, 237, 239);
  line-height: 37px;
  padding: 10px;
}

.db-2-main-com-table tr td {
  padding: 16px 4px 13px;
}

.db2-form-com {
}

.db2-form-com form {
  padding: 35px 50px;
  background: rgb(234, 237, 239);
  margin-top: 15px;
}

.db2-form-com form input {
  border: 1px solid rgb(228, 228, 228);
  line-height: 44px;
  padding: 0px 25px;
  background: rgb(255, 255, 255);
}

.db2-form-com form input[type="submit"] {
  padding: 0px;
  background: #197ee3;
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  color: rgb(255, 255, 255);
}

.db2-form-com form label {
  font-size: 14px;
  color: rgb(100, 100, 100);
}

.db2-form-pay {
}

.full-btn {
  height: 45px;
  line-height: 45px;
  background: linear-gradient(rgb(250, 109, 114), rgb(244, 93, 93));
  outline: none;
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: 600;

  text-transform: uppercase;
  text-align: center;
  display: block !important;
}

.db-pay-card {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(234, 237, 239);
  padding: 1px 10px;
}

.db-pay-card h5 {
  display: inline-block;
}

.db-pay-card img {
  width: 175px;
  border: 0px;
  display: inline-block;
  margin-bottom: 0px;
  float: right;
  padding: 0px;
}

.db2-form-pay ul li {
  background: none;
  padding: 5px 0px 12px;
}

.db-pay-amount {
  float: right;
  color: rgb(255, 193, 7);
  font-size: 22px;
}

.db-down-pdf {
  border: 1px solid rgb(199, 200, 201);
  border-radius: 3px;
  padding: 4px 8px;
  color: rgb(51, 51, 51);
}

.db-make-pay {
  border: 1px solid rgb(199, 200, 201);
  border-radius: 3px;
  padding: 4px 8px;
  color: rgb(51, 51, 51);
}

.db-make-pay:hover {
  background: rgb(62, 142, 65);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(49, 125, 52);
}

.db-mak-pay-bot {
  margin-top: 25px;
}

.db-mak-pay-bot p {
  color: rgb(150, 149, 149);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.db-mak-pay-bot a {
  font-size: 16px;
  text-transform: capitalize;
}

.db-mak-pay-bot a:hover {
  color: rgb(255, 255, 255);
}

.db-up-btn {
  display: inline-block;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  float: left;
  line-height: 3rem;
  height: 45px;
  padding: 10px 25px;
  background: rgb(20, 173, 219);
  color: rgb(255, 255, 255);
}

.db-file-upload {
  padding: 0px 0.75rem;
}

.db-com-p {
}

.db-com-p h5 {
  font-size: 20px;
}

.db-com-p p {
  color: rgb(150, 149, 149);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

#dropdown1 a {
  color: rgb(51, 51, 51);
}

.tr-register {
  /* background: url("../public/images/face.jpg") rgb(230, 230, 230); */
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: var(--topspac);
}

.tr-register::before {
  content: "";
  position: absolute;
  background: rgba(33, 48, 59, 0.52);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 5px;
}

.tr-regi-form {
  margin: 70px auto;
  width: 50%;
  background: rgb(255, 255, 255);
  padding: 40px 50px 50px;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 20px;
  margin-top: var(--topspac);
  max-width: 900px;
}

.tr-regi-form input {
  padding: 10px;
  border: 1px solid rgb(201, 201, 201);
}

.tr-regi-form textarea {
  padding: 10px 10px 10px 22px;
  border: 1px solid rgb(201, 201, 201);
  height: 100px;
}

.tr-regi-form input[type="submit"] {
  width: 100%;
  font-size: 18px !important;
  background: #1b7de3;
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  border: 0;
  font-weight: 600;
  padding: 5px;
  height: auto;
}

.tr-regi-form label {
}

.tr-regi-form h4 {
  margin-top: 0px;
  font-size: 42px;
  margin-bottom: 8px;
  text-align: center;
}

.tr-regi-form h4 span {
  color: rgb(233, 43, 68);
  font-size: 42px;
  font-weight: 600;
}

.tr-regi-form form {
  position: relative;
  padding: 20px;
}

.tr-regi-form p {
  margin-bottom: 10px;
  text-align: center;
}
.tr-regi-form p *,
.tr-regi-form p {
  font-size: 16px;
}
.tr-regi-form p a {
}

.full-btn {
  width: 100%;
  display: block;
}

.soc-login {
  position: relative;
  overflow: hidden;
  border-top: 1px solid rgb(220, 220, 220);
  margin-top: 15px;
  padding-top: 15px;
}

.soc-login h4 {
  text-align: center;
  padding-bottom: 15px;
}

.soc-login ul {
  padding: 0px;
  margin: 0px;
}

.soc-login ul li {
  list-style-type: none;
  float: left;
  width: 33.333%;
  padding: 5px;
}

.soc-login ul li a {
  display: block;
  color: rgb(255, 255, 255);
  padding: 7px 18px;
  text-align: center;
  border-radius: 2px;
}

.soc-login ul li:nth-child(1) a {
  background: rgb(59, 89, 152);
}

.soc-login ul li:nth-child(2) a {
  background: rgb(0, 172, 237);
}

.soc-login ul li:nth-child(3) a {
  background: rgb(211, 72, 54);
}

.soc-login ul li a i {
  padding-right: 5px;
}

.v2-ho-se-ri {
  padding-top: 100px;
}

.v2-ho-se-ri h5 {
  color: rgb(255, 255, 255);
  text-transform: uppercase;

  font-size: 10px;
  letter-spacing: 4px;
  font-weight: 100;
}

.v2-ho-se-ri h1 {
  margin: 0px 0px 20px;
  color: rgb(255, 255, 255);
  font-size: 48px;
}

.v2-ho-se-ri p {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 100;

  line-height: 30px;
}

.v2-search-form {
  position: relative;
  background: rgb(255, 255, 255);
  padding: 50px;
  border-radius: 5px;
  border-bottom: 2px solid rgb(204, 204, 204);
  box-sizing: border-box;
}

.v2-search-form label {
  color: rgb(114, 112, 112);
  font-weight: 600;
  top: 14px;
  font-size: 14px;
}

.v2-search-form input {
  background: rgb(255, 255, 255);
  height: 45px;
  border-radius: 2px;
  padding: 0px 10px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid rgb(239, 239, 239);
}

.v2-hom-search {
  /* background: url("../public/images/slider/1.jpg") 0% 0% / cover no-repeat; */
  position: relative;
  padding: 100px 0px 170px 0px;
  margin-top: var(--topspac);
}

.v2-hom-search::before {
  content: "";
  background: linear-gradient(
    to right,
    rgba(25, 42, 103, 0.8),
    rgba(20, 30, 48, 0)
  );
  position: absolute;
  inset: 0px;
}

.v2-ser-btn {
  text-align: center;
}

.v2-hom-ser {
  padding-top: 28px;
}

.v2-hom-ser ul {
  margin: inherit;
}

.ui-state-disabled {
  pointer-events: none;
  cursor: default !important;
}

.ui-datepicker {
  width: 300px !important;
}
/* 
.ui-datepicker-prev {
  background: url("../public/images/left-arrow.png") center center / 12px no-repeat;
}

.ui-datepicker-next {
  background: url("../public/images/right-arrow.png") center center / 12px no-repeat;
} */

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-datepicker {
  padding: 0.2em 0.2em 0px;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: -4px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0px 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0px;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0px 0px 0.4em;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: 500;
  color: rgb(170, 170, 170);
  border: 0px;
}

.ui-datepicker td {
  border: 0px;
  padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  width: 35px;
  height: 35px;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0px 0px;
  padding: 0px 0.2em;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0px auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0px;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0px;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0px;
  border-left-width: 1px;
}

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: 0.5em;
  top: 0.3em;
}

.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-widget.ui-widget-content {
  border: 0px solid rgb(197, 197, 197);
  box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 54px;
  padding: 20px;
}

.ui-widget-content {
  border: 1px solid rgb(221, 221, 221);
  background: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
}

.ui-widget-content a {
  color: rgb(51, 51, 51);
}

.ui-widget-header {
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.ui-widget-header a {
  color: rgb(51, 51, 51);
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 0px solid rgb(180, 180, 180);
  background: rgb(246, 246, 246);
  font-weight: normal;
  color: rgb(79, 79, 79);
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: rgb(69, 69, 69);
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  font-weight: normal;
  border: 0px solid rgb(218, 213, 94);
  background: rgb(246, 89, 142);
  color: rgb(255, 255, 255);
  border-radius: 27px;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: rgb(43, 43, 43);
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: rgb(94, 158, 214) 0px 0px 3px 1px;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  font-weight: normal;
  border: 0px solid rgb(218, 213, 94);
  background: rgb(22, 146, 192);
  color: rgb(255, 255, 255);
  border-radius: 27px;
}

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: rgb(0, 62, 255);
  background-color: rgb(255, 255, 255);
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-state-disabled span {
  color: rgb(170, 170, 170) !important;
}

.main-menu-v2 {
  padding: 8px 0px;
  background: rgb(37, 61, 82);
}

.main-menu-v2 ul {
  position: relative;
  padding: 0px;
  margin-bottom: 0px;
}

.main-menu-v2 ul li {
  float: left;
  list-style-type: none;
  display: inline-block;
  transition: all 0.5s ease 0s;
}

.main-menu-v2 ul li a {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 10px 14px;
  line-height: 35px;
  font-weight: 600;
  font-size: 14px;
}

.v2-sub-sinl-men {
}

.v2-sub-sinl-men li {
  border-bottom: 1px solid rgb(243, 243, 243);
  min-height: 34px !important;
  line-height: 34px !important;
}

.v2-sub-sinl-men li a {
  padding: 7px 12px !important;
}

#main-menu-v2-book {
  float: right;
}

#main-menu-v2-book a {
  font-weight: 700;
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 0px 15px;
  border-radius: 2px;
  display: inline-block;
  background: linear-gradient(rgb(26, 165, 216), rgb(19, 134, 177));

  font-size: 13px;
}

.menu-arr::after {
  content: "\f107";
  font-family: FontAwesome;
  transform: rotate(45deg);
  top: 0px;
  right: 10px;
  font-weight: 500;
  padding-left: 4px;
}

.drop-v2-all {
  width: 100%;
  left: 0px !important;
}

.drop-v2-all {
}

.drop-v2-all-inn {
  padding: 20px;
  position: relative;
  overflow: hidden;
  /* background: url("../public/images/search-bg.png") right bottom / 600px no-repeat; */
}

.menu-sub-drop {
  float: left;
  width: 20%;
}

.menu-sub-drop h4 {
  margin-top: 10px;
  text-transform: uppercase;
  color: rgb(51, 51, 51);
  font-size: 17px;
  border-bottom: 1px solid rgb(228, 228, 228);
  margin-right: 15px;
  padding-bottom: 15px;
}

.menu-sub-drop ul {
}

.menu-sub-drop ul li {
  min-height: 42px;
  transition: all 0.5s ease 0s;
}

.menu-sub-drop ul li a {
  padding: 0px;
  line-height: 26px;
  font-size: 14px;
}

.v2_head_right {
  width: 190px;
}

.v2_head_right li {
  display: block !important;
}

.v2_head_right li a {
}

.post-btn {
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.post-btn ul {
  padding: 0px;
  margin-bottom: 0px;
}

.post-btn ul li {
  display: inline-block;
  margin-right: 4px;
  line-height: 40px;
}

.post-btn ul li a {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 11.5px;

  padding: 10px;
  border-radius: 3px;
}

.post-btn ul li:nth-child(1) a {
  background: rgb(59, 89, 152);
}

.post-btn ul li:nth-child(2) a {
  background: rgb(0, 172, 237);
}

.post-btn ul li:nth-child(3) a {
  background: rgb(211, 72, 54);
}

.post-btn ul li i {
  padding-right: 5px;
}

.contact__form {
}

.contact__form input[type="submit"] {
  border: 0;
  width: 100%;
  background: #1b7de3;
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%) 0%
    0% / 100% 100% rgb(37, 111, 218);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  height: auto;
}

.faq-form {
}

.faq-form ul li:nth-child(5) {
  width: 100%;
}

.faq-form ul li:nth-child(6) {
  width: 100%;
}

.faq-form ul li:nth-child(6) textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  background: rgb(255, 255, 255);
  resize: none;
  border-radius: 3px;
}

.alert-success {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.alert {
  display: none;
}
.wed-logo {
  float: left;
  width: 20%;
}

.wed-logo a {
}

.wed-logo a img {
  width: 250px;
  padding: 6px 0px;
}

.wed-menu {
  float: right;
  padding-top: 14px;
}

.wed-menu ul {
  margin-bottom: 0px;
}

.wed-menu ul li {
  float: left;
  display: inline-block;
}

.wed-menu ul li:nth-child(2) {
  padding-right: 10px;
}

.wed-menu ul li a {
  color: rgb(54, 58, 60);
  padding: 8px 10px 5px;
}

.menu-drop-menu {
}

.menu-drop-menu li {
}

.menu-drop-menu li a {
}

.wed-search {
  /* background: url("../public/images/banner.jpg") 0% 0% / cover no-repeat; */
  position: relative;
}

.wed-search {
}

.web-search-form {
}

.web-search-form input {
  background: rgb(255, 255, 255) !important;
  border: 0px !important;
  height: 45px !important;
  border-radius: 2px !important;
  padding: 0px 10px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
}

.web-search-form input[type="submit"] {
  background: none !important;
}

.wed-search-1 {
  padding: 250px 0px 100px;
  width: 70%;
  margin: 0px auto;
  text-align: center;
  position: relative;
}

.wed-search-1 h1 {
  color: rgb(255, 255, 255);
  font-size: 40px;
}

.wed-search-1 p {
  font-size: 18px;
  color: rgb(255, 255, 255);
  padding-bottom: 15px;
}

.wed-search-1 form {
}

.web-search-form label {
  top: 16px;
  left: 18px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

.wed-search::before {
  content: "";
  background: linear-gradient(
    to right,
    rgba(36, 59, 85, 0.55),
    rgba(20, 30, 48, 0.82)
  );
  position: absolute;
  inset: 0px;
}

.web-search-form div {
  padding-left: 5px;
  padding-right: 5px;
}

.wed-sear-btn {
  height: 45px;
  line-height: 45px;
  background: linear-gradient(rgb(154, 76, 152), rgb(106, 49, 121));
  outline: none;
}

.wed-pop-ser-btn {
}

.wed-pop-ser-btn img {
}

.wed-top-phone {
  border: 1px solid rgb(107, 106, 106);
  border-radius: 50px;
}

/* .main-menu {
  float: left;
  width: 65%;
} */

.main-menu ul {
  margin: 0px auto;
  display: table;
  padding: 0px;
}

.main-menu ul li {
  float: left;
  display: inline-block;
}

/* .main-menu ul li a {
  color: rgb(0, 0, 0);
  padding: 20px 10px;
  line-height: 58px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
} */

.main-menu ul li a:hover {
  color: rgb(226, 52, 100);
}

.main-menu ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 12px;
  left: 0px;
  color: rgb(226, 52, 100);
  background-color: rgb(226, 52, 100);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.15s ease-in 0s;
  z-index: 1;
}

.main-menu ul li a:hover::after {
  /* visibility: visible; */
  transform: scaleX(1);
  transition: all 0.25s ease-out 0s;
}

.main-menu ul li:last-child a {
  padding-right: 0px;
}

.ed-sub-menu::after {
  content: "\f107";
  font-family: FontAwesome;
  padding: 5px;
  position: relative;
  top: 0px;
  font-size: 14px;
  font-weight: 900;
}

.ed-sub-drop-menu::after {
  content: "\f105";
  font-family: FontAwesome;
  padding: 5px;
  position: relative;
  top: -5px;
  font-size: 14px;
  font-weight: 900;
  float: right;
}

.menu-about::after {
  display: none;
}

.mm1-s2 a::after {
  display: none;
}

.mm2-com a::after {
  display: none;
}

.slider {
  height: 500px !important;
}

.slider::after {
  position: absolute;
  content: "";
  background: rgb(51, 51, 51);
  inset: 0px;
}

.caption h3 {
  font-size: 60px;
}

.caption h5 {
  font-size: 32px;
}

.caption p {
}

.caption a {
}

.sear-pop {
  cursor: pointer;
  display: inline-block;
}

.sear-pop i {
  background: rgb(62, 61, 61);
  padding: 5px 15px 7px 20px;
  border-radius: 0px;
  font-size: 18px;
  color: rgb(138, 138, 141);
  margin: 0px 10px 0px 0px;
  display: block;
  transition: all 0.5s ease 0s;
}

.sear-pop i:hover {
  background: rgb(10, 144, 223);
  color: rgb(232, 246, 255);
}

.search-top {
  background: rgb(0, 33, 71);
  padding: 12px 0px;
  box-shadow: rgba(0, 0, 0, 0.32) 0px 4px 15px 0px;
  z-index: 9;
  position: fixed;
  width: 100%;
  top: -250px;
  transition: all 0.5s ease-in-out 0s;
}

.search-top .ban-search {
  margin: 0px;
  padding: 5px 0px;
  border-radius: 5px;
}

.search-top.act {
  top: 0px;
}

.search-form {
  width: 60%;
  margin: 0px auto;
}

.search-form form {
}

.sf-type {
  float: left;
  width: 75%;
}

.sf-input {
}

.sf-input input {
  width: 100%;
  /* background: url("../public/images/search.html") 12px center / 17px no-repeat rgb(255, 255, 255); */
  color: rgb(70, 70, 70);
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px 45px;
  margin: 0px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  font-size: 14px;
}

.sf-list {
  display: none;
  position: absolute;
  width: 43.8%;
  background: rgb(255, 255, 255);
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 26px 64px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 300px;
  overflow-y: hidden;
}

.sf-list:hover {
  overflow-y: auto;
}

.sf-list ul {
}

.sf-list ul li {
}

.sf-list ul li a {
  display: block;
  color: rgb(43, 51, 58);
  padding: 10px;
  border-bottom: 1px solid rgb(239, 239, 239);
  font-size: 14px;
  font-weight: 600;
}

.sf-list ul li a:hover {
  background: rgb(238, 238, 238);
}

.sf-list ul li:last-child a {
  border-bottom: 0px solid rgb(239, 239, 239);
}

.sf-submit {
  float: left;
  width: 25%;
}

.sf-submit input {
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  padding: 13px;
  border: 1px solid rgb(226, 95, 48);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: linear-gradient(to top, rgb(222, 89, 42), rgb(243, 107, 59));
}

.slider-arr {
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 18px;
  top: 50%;
  width: 50px;
  height: 50px;
  background: rgb(0, 0, 0);
  padding: 17px;
  border-radius: 50px;
}

.slider-con {
}

.slider-con h2 {
  font-size: 50px;
  text-transform: uppercase;
  line-height: 50px;
}

.slider-con h2 span {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 28px;
  color: rgb(230, 96, 48);
}

.slider-con p {
  font-size: 20px;
  color: rgb(255, 255, 255);
  margin-bottom: 40px;
  margin-top: 30px;
  line-height: 30px;
  font-weight: 300;
}

.slider-con a {
  padding: 14px 45px;
  line-height: 45px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  height: 45px;
  text-align: center;
  margin: 0px 10px;
}

.bann-btn-1 {
  background: linear-gradient(to top, rgb(239, 97, 47), rgb(243, 107, 59));
  color: rgb(255, 255, 255);
  border: 1px solid rgb(243, 107, 59);
}

.bann-btn-1:hover {
  border: 1px solid rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
}

.bann-btn-2 {
  border: 1px solid rgb(224, 224, 224);
  color: rgb(224, 224, 224);
}

.bann-btn-2:hover {
  border: 1px solid rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
}

.item {
}

.item img {
  width: 100%;
}

.top-revi {
  float: left;
  position: relative;
  overflow: hidden;
  padding: 5px;
  width: 20%;
}

.top-revi img {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 10px;
  border-radius: 5px;
}

.top-revi h4 {
  float: left;
  display: block;
}

.top-revi p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  margin-bottom: 0px;
  font-size: 12px;
  width: 70%;
}

.top-star-rat {
  margin-bottom: 0px;
  line-height: 10px;
  float: left;
  display: block;
}

.top-star-rat i {
  font-size: 10px;
}

.top-logo {
  transition: all 0.5s ease 0s;
  padding: 10px 0px;
  position: absolute;
  z-index: 1;
  background: rgb(255, 255, 255);
  left: 0px;
  right: 0px;
  width: 100%;
}

.pop-form {
}

.pop-form input {
  font-size: 16px;
}

.ff3 {
  border-width: 0px 1px 1px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-color: rgb(195, 195, 195);
  border-bottom-color: rgb(195, 195, 195);
  border-left-color: rgb(195, 195, 195);
  border-image: initial;
  padding: 25px;
  border-top-style: initial;
  border-top-color: initial;
}

.ff3 h3 {
}

.ff3 p {
}

.pop-close {
}

.wed-hom-ser {
  margin-top: -140px;
}

.wed-hom-ser ul {
  margin: 0px auto;
  display: table;
  position: relative;
}

.wed-hom-ser ul li {
  float: left;
  display: inline;
  text-align: center;
  margin: 5px;
}

.wed-hom-ser ul li a {
  background: rgb(32, 47, 64);
  width: 90px;
  height: 90px;
  display: block;
  padding: 18px 2px 10px;
  text-align: center;
  color: rgb(255, 255, 255);
  border-radius: 70px;
  font-family: Arimo, sans-serif;
  position: relative;
  overflow: hidden;
  line-height: 24px;
  font-size: 11px;
}

.wed-hom-ser ul li a:hover {
  transform: scale(1.1);
  background: rgb(239, 99, 49);
  color: rgb(255, 255, 255);
}

.wed-hom-ser ul li a img {
  display: table;
  text-align: center;
  margin: 0px auto;
  padding-bottom: 5px;
}

.wed-hom-ser ul li a h4 {
  font-size: 32px;
  color: rgb(0, 33, 71);
  font-weight: 600;
  margin-bottom: 5px;
}

.wed-hom-ser ul li a span {
  color: rgb(137, 141, 146);
  font-size: 12px;
  letter-spacing: 2px;
}

.log-in-pop {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  width: 60%;
  margin: 5% auto 0px;
}

.log-in-pop-left {
  float: left;
  width: 40%;
  /* background: url("../public/images/login.html") center center no-repeat rgb(0, 33, 71); */
  padding: 12.5% 6%;
  color: rgb(255, 255, 255);
  height: 100%;
  bottom: 0px;
}

.log-in-pop-left::before {
}

.log-in-pop-left h1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
}

.log-in-pop-left h1 span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-size: 24px;
}

.log-in-pop-left p {
  color: rgb(255, 255, 255);
}

.log-in-pop-left h4 {
  color: rgb(255, 255, 255);
  margin-bottom: 15px;
  margin-top: 15px;
  border-top: 1px solid rgb(0, 46, 99);
  padding-top: 15px;
}

.log-in-pop-left ul {
  margin-bottom: 0px;
}

.log-in-pop-left ul li {
  margin-bottom: 5px;
}

.log-in-pop-left ul li a {
  display: block;
  background: rgb(63, 81, 181);
  color: rgb(255, 255, 255);
  padding: 12px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
}

.log-in-pop-left ul li:nth-child(1) a {
  background: rgb(57, 87, 154);
}

.log-in-pop-left ul li:nth-child(2) a {
  background: rgb(242, 64, 51);
}

.log-in-pop-left ul li:nth-child(3) a {
  background: rgb(36, 169, 230);
}

.log-in-pop-left ul li a i {
  padding-right: 7px;
}

.log-in-pop-right {
  float: left;
  width: 60%;
  padding: 50px;
}

.log-in-pop-right h4 {
  font-size: 24px;
  margin-bottom: 20px;
}

.log-in-pop-right p {
}

.log-in-pop-right a {
  color: rgb(51, 51, 51);
}

.log-in-pop-right form {
}

.log-in-pop-right form label {
  font-weight: 200;
  left: 15px;
  top: 14px;
  font-size: 14px !important;
}

.log-in-pop-right form input {
  border: 1px solid rgb(223, 223, 223);
  padding: 8px;
  box-sizing: border-box;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}

.log-in-pop-right form textarea {
  border: 1px solid rgb(223, 223, 223);
  padding: 8px;
  box-sizing: border-box;
  height: 70px;
  border-radius: 2px;
}

.log-in-pop-right form input[type="submit"] {
  color: rgb(255, 255, 255);
  height: 32px;
}

.padd-top {
  margin-top: 15px;
}

.log-in-btn {
  background: rgb(244, 54, 79);
  color: rgb(255, 255, 255);
  padding: 2px 10px;
  font-weight: 600;
}

.pop-close {
  color: rgb(51, 51, 51);
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
}

.pop-close:hover {
  transform: rotate(180deg);
}

.pop-close img {
  width: 24px;
}

.modal-open {
  overflow: inherit !important;
}

.log-ch-bx {
}

.log-ch-bx p {
}

.log-ch-bx p label {
  left: 1px;
  top: 10px;
  color: rgb(114, 112, 112);
}

.log-ch-bx p input {
}

.in2-top-logo {
  background: rgb(0, 33, 71);
}

.in2-search-top {
  background: rgb(1, 54, 115);
  border-top: 1px solid rgb(0, 33, 71);
}

.in2-main-menu {
}

.in2-main-menu ul li a {
  color: rgb(255, 255, 255);
}

.in2-dropdown-content {
  background-color: rgb(0, 33, 71);
  box-shadow: rgba(0, 0, 0, 0.37) 0px 4px 5px;
}

.in2-dropdown-content li {
}

.in2-dropdown-content li a {
  border-bottom: 1px solid rgb(3, 41, 84);
}

.in2-dropdown-content li a:hover {
  background: rgb(1, 54, 115);
}

.in2-ban {
  margin-top: 72px;
  /* background: url("../public/images/android.png") center center / cover no-repeat rgb(51, 51, 51); */
  padding: 80px 0px;
  position: relative;
}

.in2-ban::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(2, 35, 74, 0.95) 14%,
    rgba(0, 33, 71, 0.87) 66%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.in2-bi {
  position: relative;
  overflow: hidden;
  width: 90%;
  margin: 0px auto;
}

.in2-bi-left {
  width: 70%;
  padding-top: 35px;
}

.in2-bi-left h1 {
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  font-size: 52px;
}

.in2-bi-left p {
  color: rgb(148, 148, 148);
  font-size: 18px;
}

.in2-bi-form {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 25px;
  border-radius: 4px;
  box-shadow: rgb(0, 0, 0) 0px 16px 40px -10px;
  border-bottom: 5px solid rgb(240, 100, 51);
}

.in2-bi-form h4 {
  text-align: center;
}

.in2-bi-form input {
  border: 1px solid rgb(232, 232, 232);
  height: 45px;
  margin-bottom: 4px;
}

.in2-bi-form label {
  color: rgb(14, 14, 14);
  left: 20px;
  top: 15px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.mm-pos {
  position: absolute;
  width: 100%;
  right: 0px;
  left: 0px;
}

.about-mm,
.admi-mm,
.cour-mm {
  display: none;
}

.m-menu {
  position: absolute;
  overflow: hidden;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 7px 12px -4px;
  /* background: url("../public/images/1.jpg") right bottom no-repeat, rgb(255, 255, 255); */
  z-index: 999;
  margin-top: -7px;
  padding: 20px 10px;
}

.m-menu-inn {
}

.mm1-com {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 20px;
  border-right: 1px solid rgb(234, 234, 234);
}

.mm1-com h4 {
  border-bottom: 1px solid rgb(234, 234, 234);
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 14px;
}

.mm1-com ul {
}

.mm1-com ul li {
  display: block;
  width: 100%;
  position: relative;
  padding-left: 15px;
}

.mm1-com ul li a {
  font-size: 14px;
  color: rgb(32, 50, 69);
  line-height: 31px;
  padding: 5px;
  text-transform: capitalize;
}

.mm1-com ul li a:hover {
}

.mm1-com ul li a::after {
  bottom: 0px;
}

.mm1-cour-com {
  width: 20%;
}

.ed-dr-men-mar-top {
  padding-top: 30px;
  display: inline-block;
}

.mm1-s1 {
}

.mm1-s1 img {
  width: 100%;
}

.mm1-s2 {
}

.mm1-s2 p {
  font-size: 12px;
  color: rgb(32, 50, 69);
}

.mm1-s2 a {
}

.mm1-s3 {
}

.mm1-s3 ul {
  padding: 0px;
}

.mm1-s4 {
  border-right: 0px solid rgb(234, 234, 234);
}

.mm1-s4 img {
  width: 100%;
}

.mm-arr::after {
}

.m-menu-inn > .mm1-s2 > .mm-r-m-btn {
  background: rgb(0, 33, 71);
  padding: 4px 10px;
  color: rgb(255, 255, 255);
  font-size: 11px;
  border-radius: 3px;
  height: 30px;
  line-height: 24px;
}

.mm1-com > .ed-course-in > a {
  padding: 0px;
}

.mm2-com {
}

.mm2-com p {
  font-size: 12px;
  color: rgb(32, 50, 69);
}

.mm2-com > .ed-course-in {
  margin-bottom: 15px;
}

.m-menu-inn > .mm2-com > a {
  background: rgb(0, 33, 71);
  padding: 4px 10px;
  color: rgb(255, 255, 255);
  font-size: 11px;
  border-radius: 3px;
  height: 30px;
  line-height: 24px;
}

.m-menu-inn ul li::before {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding-right: 0.5em;
  position: absolute;
  top: 5px;
  left: 0px;
}

.mm1-s4 ul {
  padding: 0px;
}

.ed-mob-menu {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 55px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.32) 0px 4px 15px 0px;
  z-index: 999;
  display: none;
}

.ed-mob-menu-con {
  width: 90%;
  margin: 0px auto;
}

.ed-mm-left {
  float: left;
  width: 50%;
  box-sizing: border-box;
}

.wed-logo {
}

.wed-logo a {
}

.wed-logo a img {
}

.ed-mm-right {
  float: left;
  width: 50%;
  box-sizing: border-box;
}

.ed-mm-menu {
}

.ed-micon {
  float: right;
}

.ed-micon i {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(10, 36, 68);
  text-align: center;
  font-size: 20px;
  padding: 5px;
  border-radius: 2px;
  margin-top: 10px;
  color: rgb(10, 36, 68);
  cursor: pointer;
}

.ed-mm-inn {
  position: fixed;
  width: 70%;
  background: rgb(255, 255, 255);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.32) 0px 4px 15px 0px;
  overflow-y: auto;
  right: -80%;
  height: 100%;
  transition: all 0.5s ease 0s;
}

.ed-mm-act {
  right: 0%;
  transition: all 0.5s ease 0s;
}

.ed-mi-close {
  position: absolute;
  z-index: 99;
  margin: -50px 8px 8px -30px;
}

.ed-mi-close i {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(10, 36, 68);
  text-align: center;
  font-size: 20px;
  padding: 5px;
  border-radius: 2px;
  margin-top: 10px;
  color: rgb(10, 36, 68);
  cursor: pointer;
}

.ed-mm-inn h4 {
}

.ed-mm-inn ul {
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 0px;
}

.ed-mm-inn ul li {
  list-style-type: none;
}

.ed-mm-inn ul li a {
  display: block;
  border-bottom: 1px solid rgb(236, 236, 236);
  padding: 6px 0px 6px 25px;
  font-size: 14px;
  color: rgb(66, 73, 78);
}

.ed-mm-inn ul li a::before {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  font-size: 14px;
  color: black;
  margin-left: -25px;
}

.affix {
  top: 0px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px;
  z-index: 999;
  padding: 1px;
  transition: all 0.5s ease 0s;
}

.sb1 {
  background: rgb(0, 33, 71);
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.48) 0px 2px 5px;
}

.sb1 h3 {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.sb1-1 {
}

.sb1-2 {
  float: right;
}

.sb1-2 button {
  background: rgb(45, 58, 64);
  border: 0px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px;
  border-radius: 0px;
  color: rgb(255, 255, 255) !important;
}

.sb2 {
  background: rgb(0, 33, 71);
  margin-top: 59px;
}

.sb2-1 {
  float: left;
  width: 20%;
  background: rgb(38, 50, 56);
  color: rgb(255, 255, 255);
  height: 100%;
  position: absolute;
  transition: all 0.5s ease 0s;
}

.sb2-1 a {
  color: rgb(206, 206, 206);
  transition: all 0.5s ease 0s;
}

.sb2-1 a:hover {
  color: rgb(255, 255, 255);
  transition: all 0.5s ease 0s;
  text-decoration: none;
}

.sb2-12 {
  position: relative;
  overflow: hidden;
  padding: 15px;
  background: rgb(230, 96, 48);
}

.sb2-12 ul {
  padding: 0px;
}

.sb2-12 ul li {
  float: left;
  display: inline-block;
}

.sb2-12 ul li:nth-child(1) {
  width: 15%;
}

.sb2-12 ul li:nth-child(2) {
  width: 70%;
  padding: 0px 7px;
}

.sb2-12 ul li:nth-child(3) {
  width: 10%;
}

.sb2-12 ul li img {
  width: 30px;
  border-radius: 50%;
}

.sb2-12 ul li h5 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.sb2-12 ul li h5 span {
  display: block;
  font-size: 12px;
  color: rgb(255, 207, 190);
  margin-top: -4px;
}

.sb2-13 {
  background: rgb(0, 33, 71);
}

.sb2-13 ul {
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
}

.sb2-13 ul li {
  list-style-type: none;
}

.sb2-13 ul li a {
  display: block;
  font-size: 13.5px;
  border-bottom: 1px solid rgb(0, 41, 88);
  padding: 12px 20px;
  background: rgb(0, 33, 71);
}

.sb2-13 ul li a::after {
  font-family: FontAwesome;
  content: "\f105";
  display: block;
  position: absolute;
  margin-top: -15px;
  right: 20px;
  font-size: 12px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  transform: rotate(0deg);
  transition: -webkit-transform 0.2s ease-in-out 0s;
}

.sb2-13 ul li a:hover::after {
  transform: rotate(90deg);
}

.sb2-13 ul li a:focus::after {
  transform: rotate(90deg);
}

.sb2-13 ul li a:hover {
  background: rgb(2, 44, 93);
}

.sb2-13 ul li a:focus {
  background: rgb(2, 44, 93);
}

.menu-act {
  background: rgb(87, 102, 109);
}

.sb2-13 ul li a i {
  margin-right: 8px;
  width: 16px;
}

.sb2-2 {
  float: left;
  width: 80%;
  padding: 40px;
  margin-left: 20%;
  background: rgb(227, 232, 234);
}

.sb2-2-1 {
  background: rgb(255, 255, 255);
  padding: 25px;
}

.sb2-2-1 h2 {
  margin-top: 0px;
}

.sb2-2-1 p {
}

.sb2-2-1 table {
  width: 100%;
}

.sb2-2-1 table tr {
}

.sb2-2-1 table tr th {
}

.sb2-2-1 table tr td {
}

.sb2-2-1 table tr td a {
}

.sb2-2-1-edit {
}

.sb2-2-1-edit i {
  color: rgb(255, 87, 34);
}

.sb2-2-add-blog textarea {
  width: 100%;
  display: block;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  height: 200px;
}

.sa-blog {
}

.sa-blog-post {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(210, 210, 210);
  padding: 25px 0px;
}

.sa-blog-post h2 {
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.sa-blog-post span {
  font-style: italic;
  color: rgb(136, 136, 136);
}

.sa-blog-post span b {
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.sa-blog-post img {
  width: 100%;
  padding-bottom: 25px;
  padding-top: 20px;
}

.sa-blog-post p {
  color: rgb(136, 136, 136);
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 15px;
}

.sa-blog-post-btn {
  background: rgb(247, 148, 30);
  color: rgb(255, 255, 255);
  padding: 5px;
}

.sa-blog-com-1 {
  padding: 50px 0px;
}

.sa-blog-com {
}

.sa-blog-ads {
}

.sa-blog-ads img {
}

.sa-blog-rece {
}

.sa-blog-rece h3 {
  text-transform: uppercase;
  font-size: 18px;
}

.sa-blog-rece ul {
  padding: 0px;
}

.sa-blog-rece ul li {
  list-style-type: none;
}

.sa-blog-rece ul li a {
  border-bottom: 1px solid rgb(225, 230, 236);
  line-height: 40px;
  padding-bottom: 7px;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(162, 162, 162) !important;
  font-weight: 200 !important;
}

.sa-blog-rece ul li a:hover {
  padding-left: 8px;
  transition: all 0.5s ease 0s;
}

.sa-blog-rece ul li a i {
  padding-right: 8px;
}

.sa-blog-g {
  padding-top: 50px;
}

.sa-blog-g img {
}

.n-blog-top {
  background: rgb(0, 0, 0);
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 5px;
}

.blog-logo {
}

.blog-logo a {
}

.blog-logo a img {
  width: 175px;
}

.blog-soc {
}

.blog-soc ul {
  padding: 25px 0px 0px;
  float: right;
  margin-bottom: 0px;
}

.blog-soc ul li {
  float: left;
  display: inline-block;
  padding-right: 6px;
}

.blog-soc ul li a {
}

.blog-soc ul li a i {
  color: rgb(255, 255, 255);
  width: 24px;
  height: 24px;
  padding: 5px;
  text-align: center;
  border-radius: 20px;
  background: rgb(247, 148, 30);
}

.blog-copy {
  padding: 50px;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.blog-copy p {
  text-align: center;
  margin-bottom: 0px;
}

.blog-copy p a {
  color: rgb(255, 255, 255);
}

.blog-login {
  /* background: url("../src/img/bg/contact_bg_1.jpg") 0% 0% / cover no-repeat; */
  width: 100%;
  height: 100%;
  position: absolute;
}

.blog-login::before {
}

.blog-login-in {
  position: relative;
  width: 30%;
  margin: 7% auto 0px;
  background: rgb(255, 255, 255);
  padding: 40px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.51) 0px 0px 50px 2px;
}

.blog-login-in form {
  position: relative;
}

.blog-login-in form img {
  margin: 0px auto;
  display: table;
  padding-bottom: 25px;
}

.blog-login-in input {
}

.blog-login-in button {
  color: rgb(255, 255, 255);
  border-color: rgb(66, 132, 51);
  background: rgb(78, 146, 63);
  font-weight: 700;
}

.blog-login-in a {
  display: block;
}

.left-sub-menu {
  padding: 0px;
}

.left-sub-menu ul {
}

.left-sub-menu ul li {
  list-style-type: none;
  border-bottom: 1px solid rgb(0, 40, 86);
}

.left-sub-menu ul li:last-child {
  border-bottom: 0px solid rgb(48, 59, 64);
}

.left-sub-menu ul li a {
  display: block;
  font-size: 13.5px;
  padding: 10px 24px 10px 45px;
}

.left-sub-menu ul li a:hover {
  background: none;
  padding-left: 55px;
}

.left-sub-menu ul li a:focus {
  background: none;
}

.left-sub-menu ul li a::after {
  display: none;
}

.sb2-2-2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.sb2-2-2 ul {
  padding: 0px;
  margin-bottom: 0px;
}

.sb2-2-2 ul li {
  list-style-type: none;
  float: left;
  padding-right: 5px;
}

.sb2-2-2 ul li a {
  color: rgb(73, 93, 101);
  vertical-align: middle;
}

.sb2-2-2 ul li a i {
  padding-right: 2px;
}

.active-bre::before {
  content: "/";
  padding: 0px 5px;
  color: rgb(51, 51, 51);
}

.my-acc {
}

.my-acc:hover {
  background: rgb(75, 90, 97) !important;
}

.my-acc:focus {
  background: rgb(75, 90, 97) !important;
}

.drop-down-meta {
  position: absolute;
  right: 30px;
  padding: 3px;
  color: black;
  z-index: 999;
  top: 15px;
  background: rgb(227, 232, 234);
  width: 30px;
  height: 30px;
  border-radius: 25px;
}

.drop-down-meta:hover {
  background: rgb(41, 160, 218);
  color: rgb(255, 255, 255);
}

.box-inn-sp {
  background: rgb(255, 255, 255);
}

.tab-inn {
  padding: 25px;
}

.tab-posi {
  position: relative;
  overflow: hidden;
}

.top-menu-sty {
  width: 250px !important;
}

.my-btn {
  color: rgb(255, 255, 255);
  background: rgb(87, 102, 109);
  padding: 21px;
  line-height: 18px;
  font-weight: 700;
}

.app-search {
  position: relative;
  margin: 12px 15px 8px 5px;
  font-size: 13px;
  color: rgb(98, 103, 115);
  padding-left: 20px;
  padding-right: 40px;
  background: transparent;
  border: 1px solid rgba(122, 125, 132, 0.15);
  box-shadow: none;
  border-radius: 30px;
  height: 36px;
  font-weight: 600;
  width: 100%;
}

.app-search input {
  color: rgb(206, 206, 206);
  border-bottom: 0px !important;
  height: 35px !important;
}

.app-search a {
  position: absolute;
  top: 8px;
  right: 16px;
  color: rgb(122, 125, 132);
}

.box-second-inn {
  margin-top: 30px;
}

.mar-bot-20 {
  margin-bottom: 20px;
}

.icon-container .icon-preview {
  height: 90px;
  text-align: center;
}

.icon-container span {
  display: block;
}

.icon-container i {
  font-size: 3em;
  margin-bottom: 10px;
}

.tab-pad {
  padding-top: 20px !important;
}

.tab-col {
  color: rgb(0, 0, 0);
  padding: 20px !important;
}

.btn-noti span {
  position: absolute;
  transform: translateX(-50%);
  top: -8px;
  height: 14px;
  border-radius: 10px;
  min-width: 15px;
  background: rgb(76, 175, 80);
  color: rgb(255, 255, 255);
  line-height: 14px;
  padding: 0px 2px;
  font-size: 11px;
  white-space: nowrap;
  transform-origin: -10% center;
  font-family: tahoma;
  box-shadow: rgb(61, 121, 63) 0px 0px 0px 1px;
  text-align: center;
}

.top-user-pro {
  padding: 18px 20px;
  color: rgb(255, 255, 255);
  float: right;
}

.top-user-pro img {
  width: 25px;
  margin-right: 10px;
  border-radius: 35px;
  border: 2px solid rgb(161, 173, 179);
}

.top-user-pro i {
  margin-left: 5px;
}

.top-not-cen {
  margin: 0px auto;
  display: table;
  padding-top: 5px;
}

.tab-menu {
  display: none;
}

.logo {
}

.logo img {
  width: 100%;
}

.atab-menu {
  display: inline-block;
  cursor: pointer;
}

.btn-close-menu {
  display: none;
  z-index: 999;
  background: rgb(14, 118, 168);
  color: rgb(255, 255, 255);
  position: absolute;
  left: 240px;
  padding: 5px 8px;
  border-radius: 2px;
  top: 0px;
}

.page-back {
  float: right !important;
}

.page-back a {
  background: rgb(244, 67, 54);
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 25px;
  font-weight: 600;
  color: rgb(255, 255, 255) !important;
}

.page-back a i {
}

.nav-tabs > li > a {
  color: rgb(14, 118, 168);
  font-weight: 700;
  font-size: 14px;
}

.nav-tabs > li > a i {
  width: 16px;
  color: rgb(14, 118, 168);
  margin-right: 5px;
}

.coll-head {
  display: block;
  cursor: pointer;
  min-height: 3rem;
  line-height: 3rem;
  padding: 0px 1rem;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(221, 221, 221);
}

.coll-head i {
  width: 2rem;
  font-size: 1.6rem;
  line-height: 3rem;
  display: block;
  float: left;
  text-align: center;
  margin-right: 1rem;
}

.coll-body {
  border-bottom: 1px solid rgb(221, 221, 221);
  box-sizing: border-box;
  padding: 2rem;
}

.btn-log-in {
  text-align: center;
}

.for-pass {
  text-align: right;
  padding-top: 15px;
  font-size: 15px;
  font-weight: 700;
  color: rgb(14, 118, 168);
}

.select-pos {
  position: relative;
  display: initial;
}

.menu-active {
  background: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
}

.tab-map {
}

.tab-map iframe {
  width: 100%;
  height: 350px;
  border: 0px;
  display: block;
  pointer-events: none;
  position: relative;
}

.ad-st-view {
  background: rgb(226, 94, 48);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 5px;
  border-radius: 3px;
  color: rgb(255, 255, 255) !important;
}

.admin-form {
}

.admin-form form {
}

.admin-form form input {
  border: 1px solid rgb(226, 226, 226);
  height: 40px;
  border-radius: 2px;
  font-size: 14px;
  padding-left: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.admin-form form textarea {
  border: 1px solid rgb(226, 226, 226);
  height: 100px;
  border-radius: 2px;
  font-size: 14px;
  padding-left: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding-top: 15px;
}

.admin-form form label {
  left: 20px;
  font-size: 14px;
  top: 8px;
}

.admin-form form i {
  text-align: center;
  padding: 0px;
}

.admin-form form [type="submit"] {
  width: 100%;
  display: block;
  padding: 1px 30px;
  font-size: 14px;
  font-weight: 700;
}

.ad-cou-deta-h4 h4 {
  margin-top: 15px;
  padding-bottom: 10px;
}

.admin-upload-btn {
  height: 40px !important;
}

.admin-upload-btn span {
  color: rgb(255, 255, 255);
}

.ad-page-pre-btn {
  background: rgb(3, 169, 244) !important;
}

.ad-page-pre-btn a {
  color: rgb(255, 255, 255);
  padding: 0px 10px;
  font-weight: 700;
  font-style: initial;
}

.ad-p-lr {
  padding: 0px 12px;
}

.ad-mar-bot-20 {
  margin-bottom: 20px;
}

.ed-home {
}

.com-fir-sp-top {
}

.ed-top {
  background: rgb(0, 0, 0);
  z-index: 1;
  position: relative;
}

.ed-com-t1-left {
}

.ed-com-t1-left ul {
  margin-bottom: 0px;
}

.ed-com-t1-left ul li {
  float: left;
  display: inline-block;
}

.ed-com-t1-left ul li a {
  color: rgb(195, 195, 195);
  padding: 5px 14px;
  line-height: 30px;
  border-right: 1px solid rgb(66, 66, 66);
  font-size: 12px;
}

.ed-com-t1-right {
  float: right;
}

.ed-com-t1-right ul {
}

.ed-com-t1-right ul li {
  float: left;
  display: inline-block;
}

.ed-com-t1-right ul li a {
  color: rgb(255, 255, 255);
  padding: 7px 12px;
  line-height: 30px;
  font-size: 12px;
}

.ed-com-t1-right ul li:nth-child(1) a {
  background: rgb(56, 85, 200);
}

.ed-com-t1-right ul li:nth-child(2) a {
}

.top-sign {
  background: rgb(70, 87, 114);
}

.top-regi {
  background: rgb(3, 158, 249);
}

.top-prof {
  background: rgb(79, 102, 221);
}

.ed-com-t1-social {
  position: relative;
  overflow: hidden;
  margin-top: -5px;
}

.ed-com-t1-social ul li {
  float: left;
  list-style-type: none;
}

.ed-com-t1-social ul li a {
  line-height: 26px;
}

.ed-com-t1-social ul li a i {
  width: 30px;
  height: 100%;
  color: rgb(195, 195, 195);
  padding: 14px 4px 6px;
  text-align: center;
  display: inline-block;
}

.offer {
  /* background: url("../public/images/placebg.jpg") 0% 0% / cover no-repeat fixed; */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.offer::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(22, 40, 105, 0.91) 46%,
    rgba(0, 0, 0, 0.41) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.offer-l {
  padding: 110px 0px 100px;
  text-align: center;
}

.ol-1 {
  width: 250px;
  height: 1px;
  background: rgb(81, 87, 113);
  margin: 0px auto;
  display: table;
}

.ol-2 {
  background: rgb(244, 54, 79);
  padding: 5px 3px 3px;
  position: relative;
  margin: -15px auto 0px;
  display: table;
  border-radius: 2px;
}

.ol-2 i {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin: 0px 5px;
}

.ol-3 {
  width: 250px;
  height: 1px;
  background: rgb(81, 87, 113);
  margin: 30px auto;
  display: table;
}

.ol-4 {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 58px;
  padding-top: 30px;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
}

.ol-5 {
  font-size: 66px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 46px;
  margin-bottom: 30px;
}

.offer-l ul {
  margin-bottom: 0px;
}

.offer-l ul li {
  display: inline-block;
  margin: 0px 10px;
}

.offer-l ul li span {
  display: block;
  color: rgb(236, 222, 224);
}

.offer-l ul li a {
}

.offer-l ul li a img {
  padding: 12px;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  background: rgb(244, 54, 79);
  display: inline-block;
}

.offer-r {
  padding: 50px;
  margin: 0px auto;
  display: table;
}

.or-1 {
  color: rgb(255, 255, 255);
  font-weight: 600;

  display: inline-block;
  line-height: 46px;
  text-transform: uppercase;
  width: 150px;
  height: 150px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 40px;
  text-align: center;
  margin-top: 67px;
  position: absolute;
  margin-left: -100px;
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;
}

.or-11 {
  font-size: 45px;
  display: block;
  text-transform: capitalize;
  color: rgb(244, 54, 79);
  font-weight: 600;
}

.or-12 {
  display: block;
  line-height: 20px;
  letter-spacing: 5px;
  border-bottom: 1px solid;
  color: rgb(51, 51, 51);
  margin-top: 25px;
}

.or-2 {
  color: rgb(255, 255, 255);
  font-weight: 600;

  display: inline-block;
  line-height: 46px;
  text-transform: uppercase;
  width: 300px;
  height: 300px;
  background: rgb(244, 54, 79);
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  margin-top: 100px;
  position: absolute;
  border: 7px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;
}

.or-1:hover {
  transform: rotate(360deg);
  transition: all 0.5s ease 0s;
}

.or-2:hover {
  background: rgb(244, 186, 54);
  transition: all 0.5s ease 0s;
}

.or-2 span {
  display: inline-block;
}

.or-21 {
  font-size: 34px;
  display: block;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-22 {
  font-size: 75px;
  display: block;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-23 {
  font-size: 34px;
  display: block;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-24 {
  font-size: 14px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;

  border: 1px solid rgb(251, 175, 185);
  padding: 6px;
  border-radius: 25px;
}

.or-25 {
  font-size: 16px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.slider-wid {
  height: 600px;
}

.slides li img::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(88, 25, 39, 0.91) 46%,
    rgba(0, 0, 0, 0.41) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.slid-cap {
}

.slid-cap h2 {
  font-size: 62px;
  color: rgb(255, 255, 255);
  margin-bottom: 15px;
  margin-top: 0px;
  text-transform: uppercase;
}

.slid-cap h5 {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
  letter-spacing: 5px;
}

.slid-cap p {
  margin-bottom: 20px;
  font-size: 18px;
}

.slid-cap a {
  background: rgb(244, 54, 79);
  padding: 7px 25px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 600;

  border-radius: 2px;
  font-size: 14px;
  margin-right: 10px;
}

.hom2-ban-pack {
  padding-top: 0px;
  margin-top: -80px;
  z-index: 9;
  position: relative;
}

.cus-book-form {
  width: 50%;
  margin: 0px auto;
}

.cus-pack-form {
  /* background: url("../public/images/placebg.jpg") 0% 0% / cover no-repeat fixed rgb(255, 255, 255); */
  padding: 60px 0px 75px;
  position: relative;
  margin-top: var(--topspac);
}

.cus-pack-form::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    rgba(22, 40, 105, 0.91) 46%,
    rgba(0, 0, 0, 0.41) 100%
  );
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.cus-title {
}

.cus-title h2 {
  color: rgb(255, 255, 255);
}

.book-tab {
  /* background: url("../public/images/slider/1.jpg") 0% 0% / cover no-repeat fixed rgb(255, 255, 255); */
  padding: 55px 0px 160px;
  position: relative;
  margin-top: var(--topspac);
}

.book-tit {
  text-align: center;
  padding-bottom: 30px;
}

.book-tit h2 {
  font-size: 42px;
  margin-bottom: 20px;
}

.book-tit h5 {
  font-size: 15px;
  letter-spacing: 4px;
  color: #fff;
  font-weight: 300;
}

.book-tab-inn {
  width: 70%;
  margin: 0px auto;
}

.book-tab-inn h3 {
  margin-top: 0px;
  color: #000;
  font-size: 32px;
}

.book-tab-inn p {
  color: #333;
  font-size: 16px;
}

.book-tab-body {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 10px 10px;
  float: left;
  width: 100%;
}

.book-tab-tit {
  padding: 50px 50px 0px;
  text-align: center;
}
.book-tab-tit + .book-tab-form {
  padding-top: 30px;
}
.book-tab-form {
  box-shadow: none;
  padding-top: 0px;
  border-bottom: 0px solid rgb(204, 204, 204);
  padding: 55px 70px 40px 70px;
  float: left;
  width: 100%;
}

.book-tab-inn ul li a {
  color: rgb(85, 85, 85);
  font-weight: 700;
  font-size: 14px;
  background: rgb(255, 255, 255);
}

.book-tab-inn .nav-tabs > li.active > a,
.book-tab-inn .nav-tabs > li.active > a:hover,
.book-tab-inn .nav-tabs > li.active > a:focus {
  background: rgb(241, 51, 76);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(241, 51, 76);
}

.course-overlay span {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  text-align: center;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  z-index: 1;
}

.con-page {
  padding-bottom: 170px;
}

.new-con h2 {
  color: rgb(242, 104, 56);
  font-size: 24px;
  font-weight: 700;
}

.new-con h2 span {
  color: rgb(19, 41, 61);
  font-size: 24px;
  font-weight: 700;
}

.new-con p a {
  color: rgb(51, 51, 51);
}

.package-form {
  box-shadow: none;
  padding: 0px;
  background: none;
}
.package-form label {
  font-size: 14px;
  color: #000;
}
select,
input {
  font-size: 14px;
  color: #000;
  /* font-family: 'Poppins'; */
  font-weight: 500;
}

.tour-alp-ri-p3 {
  padding-top: 20px;
}

.trav-ami {
  display: inline-block;
  padding: 15px 15px 5px;
  border-top: 1px solid rgb(223, 210, 191);
  width: 100%;
  margin-top: 10px;
}

.trav-ami h4 {
  margin: 0px;
  padding-bottom: 15px;
}

.trav-ami ul {
  display: inline;
}

.trav-ami ul li {
  color: rgb(51, 51, 51);
  display: block;
  position: relative;
  padding: 11px 10px 1px;
  float: left;
  text-align: center;
  border: 1px solid rgb(223, 210, 191);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.trav-ami ul li img {
  margin: 0px auto 1px;
  display: table;
  width: 20px;
}

.trav-ami ul li span {
  font-size: 12px;
}

.trav-list-bod {
}

.trav-list-bod a h3 {
  color: rgb(244, 54, 79);
}

.trav-list-bod p {
  font-size: 13px;
  line-height: 20px;
}

.slick-arrow {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(237, 237, 237);
  color: rgba(255, 255, 255, 0.01);
  position: absolute;
  z-index: 1;
  top: 38%;
  text-align: center;
  padding: 14px 17px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 14px -3px;
  transition: all 0.5s ease 0s;
}

.slick-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.slick-arrow::before {
  font: 45px / 1 FontAwesome;
  content: "\f104";
  top: -9px;
  left: 3px;
  color: rgb(0, 0, 0);
  position: relative;
}

.slick-prev {
  left: 24px;
}

.slick-next {
  right: 25px;
}

.slick-next::before {
  content: "\f105";
}

.slick-dots {
  margin: 0px auto;
  display: table;
}

.slick-dots li {
  display: inline-block;
  width: auto !important;
  padding: 0px 3px !important;
}

.slick-dots li button {
  color: rgba(0, 0, 0, 0);
  width: 60px;
  height: 14px;
  border-radius: 50px;
  outline: none;
  transition: all 0.5s ease-in-out 0s;
  display: block;
}

.slick-dots li button {
  background: rgb(218, 223, 231);
  border: 2px solid rgb(255, 255, 255);
  box-shadow: rgba(255, 255, 255, 0.18) 0px 0px 0px 5px;
}

.slick-dots li.slick-active button {
  background: rgb(239, 1, 70);
  border: 2px solid rgb(0, 0, 0);
  box-shadow: rgb(239, 1, 70) 0px 0px 0px 5px;
}

.slick-dots {
  padding-top: 25px;
}

.slick-dots li.slick-active button {
  background: rgb(246, 175, 4);
  border: 2px solid rgb(251, 248, 237);
  box-shadow: rgb(246, 213, 135) 0px 0px 0px 3px;
}

.chbox {
  position: relative;
}

.chbox label {
  padding-left: 25px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  /* text-overflow: ellipsis; */
  /* white-space: pre; */
  /* overflow: hidden; */
  position: relative;
  width: 100%;
  line-height: 26px;
  color: rgb(88, 94, 107);
  float: left;
}

.chbox label::before {
  content: "";
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  cursor: pointer;
  left: 0px;
  position: absolute;
  top: 3px;
  width: 18px;
  height: 18px;
}

.chbox label::after {
  border-bottom: 3px solid rgb(255, 255, 255);
  border-left: 3px solid rgb(255, 255, 255);
  border-image: initial;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 11px;
}

.chbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.chbox input[type="checkbox"]:checked + label::before {
  background-color: rgb(33, 158, 62);
  border-color: rgb(33, 158, 62);
}

.chbox input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.form-login .form-group input,
.form-login .form-group select,
.form-login .form-group textarea,
.form-login .form-group .chosen-container {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.form-out-box {
  padding: 25px;
}
.row .col {
  float: left;
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}
.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.input-field {
  margin-bottom: 20px;
}
.input-field select,
.input-field input {
  width: 100%;
  border: 2px solid #000;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
}
.input-field input {
}
.input-field select {
}
.input-field .chosen-container-single .chosen-single {
  height: 45px;
  padding-top: 9px;
  border: 2px solid #000;
}
.input-field .chosen-container-single .chosen-single span {
}
.row .col.s12 {
  width: 100%;
}
.file-field input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.swiper-pagination {
  display: flex;
  justify-content: center; /* Centers the bullets */
  align-items: center;
  bottom: 10px; /* You can adjust the position from the bottom */
  width: 100%; /* Make sure it spans the full width */
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #000;
  opacity: 0.5;
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: #ffae00; /* Color for active bullet */
  opacity: 1;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d1d1 !important;
  border-radius: 12px !important;
  font-size: 16px;
  transition: border 0.3s ease;
}

.inquiry-form-container {
  max-width: 600px;
  margin: auto;
  background-color: transparent !important;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}