@use "sass:math";
:root {
  --theme-color: #df1f2c;
  --primary-color: #df1f2c;
  --title-color: #f9af2a;
  --body-color: #6e7070;
  --smoke-color: #fff8ea;
  --smoke-color2: #f3f4f6;
  --black-color: #000000;
  --black-color2: #0d0d0c;
  --gray-color: #e1e4e5;
  --white-color: #ffffff;
  --light-color: #e1e4e5;
  --yellow-color: #ffb539;
  --success-color: #28a745;
  --error-color: #dc3545;
  --th-border-color: #e9edf5;
  --title-font: "Amaranth-Bold", sans-serif;
  --body-font: "Manrope-Medium", sans-serif;
  --style-font: "Amaranth-Bold";
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1320px;
  --container-gutters: 24px;
  --section-space: 50px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 2s;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montez&family=Poppins:wght@300..700&display=swap");

/* Other styles here */
@font-face {
  font-family: Sundae Ice;
  src: url(../public/fonts/SundaeIce.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Amaranth-Bold;
  src: url(../public/fonts/Amaranth-Bold.ttf);
}

@font-face {
  font-family: Manrope-Medium;
  src: url(../public/fonts/Manrope-Medium.ttf);
}

@font-face {
  font-family: Manrope-SemiBold;
  src: url(../public/fonts/Manrope-SemiBold.ttf);
}



html,
body {
  scroll-behavior: auto !important;
}
body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}
iframe {
  border: none;
  width: 100%;
}
.slick-slide:focus,
button:focus,
a:focus,
a:active,
a.nice-select.open,
.currency-menu a.nice-select.open,
input,
input:hover,
input:focus,
input:active,
input.nice-select.open,
.currency-menu input.nice-select.open,
textarea,
textarea:hover,
textarea:focus,
textarea:active,
textarea.nice-select.open,
.currency-menu textarea.nice-select.open {
  outline: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}
img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--th-border-color);
}
th {
  font-weight: 700;
  color: var(--title-color);
}
td,
th {
  border: 1px solid var(--th-border-color);
  padding: 9px 12px;
}
a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active,
a.nice-select.open,
.currency-menu a.nice-select.open,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}
button {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
img {
  border: none;
  max-width: 100%;
}
ins {
  text-decoration: none;
}
pre {
  font-family: var(--body-font);
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
span.ajax-loader:empty,
p:empty {
  display: none;
}
p {
  font-family: var(--body-font);
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.75;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}
h2.blog-title{
  font-family: "Amaranth-Bold", sans-serif;
  font-size: 26px;
  color: #f9af2a;
}
p.blog-text{
  font-family: "Manrope-Medium", sans-serif;
  font-size: 16px;
}
h1.blog-title{
  font-family: "Amaranth-Bold", sans-serif;
  font-size: 26px;
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 0;
}
.h1,
h1 {
  font-size: 80px;
  line-height: 1.18;
}
.h2,
h2 {
  font-size: 48px;
  line-height: 1.327;
}
.h3,
h3 {
  font-size: 32px;
  line-height: 1.278;
}
.h4,
h4 {
  font-size: 30px;
  line-height: 1.333;
}
.h5,
h5 {
  font-size: 24px;
  line-height: 1.417;
}
.h6,
h6 {
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 54px;
    line-height: 1.3;
  }
  .h2,
  h2 {
    font-size: 36px;
    line-height: 1.3;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 34px;
    line-height: 1.3;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .h1,
  h1 {
    font-size: 34px;
    line-height: 1.35;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .h1,
  h1 {
    font-size: 32px;
  }
}
p.has-drop-cap {
  margin-bottom: 20px;
}
.page--item p:last-child .alignright {
  clear: right;
}
.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}
.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}
.bypostauthor,
.gallery-caption {
  display: block;
}
.page-links,
.clearfix {
  clear: both;
}
.page--item {
  margin-bottom: 30px;
}
.page--item p {
  line-height: 1.8;
}
.content-none-search {
  margin-top: 30px;
}
.wp-block-button.aligncenter {
  text-align: center;
}
.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}
.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}
.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}
.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}
.wp-block-columns {
  margin-bottom: 1em;
}
figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}
figure.wp-block-gallery {
  margin-bottom: 14px;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}
.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}
.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}
.wp-block-cover {
  margin-bottom: 15px;
}
.wp-caption-text {
  text-align: center;
}
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}
.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}
.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}
.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}
.wp-block-latest-comments a {
  color: inherit;
}
.wp-block-button {
  margin-bottom: 10px;
}
.wp-block-button:last-child {
  margin-bottom: 0;
}
.wp-block-button .wp-block-button__link {
  color: #fff;
}
.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}
.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}
ol.wp-block-latest-comments li {
  margin: 15px 0;
}
ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
ul.wp-block-latest-posts a {
  color: inherit;
}
ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}
ul.wp-block-latest-posts li {
  margin: 15px 0;
}
.wp-block-search__inside-wrapper {
  position: relative;
}
.wp-block-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: 100%;
  border: 1px solid transparent;
  padding-left: 25px;
  border: 1px solid transparent;
  border-radius: 30px;
}
.wp-block-search .wp-block-search__input:focus {
  border-color: var(--theme-color);
}
.wp-block-search .wp-block-search__button {
  margin: 0;
  min-width: 110px;
  height: 100%;
  border: none;
  color: #fff;
  background-color: var(--theme-color);
  border-radius: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.wp-block-search .wp-block-search__button.has-icon {
  min-width: 56px;
}
.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
}
.wp-block-search.wp-block-search__button-inside
  .wp-block-search__inside-wrapper {
  padding: 0;
  border: none;
}
.wp-block-search.wp-block-search__button-inside
  .wp-block-search__inside-wrapper
  .wp-block-search__input {
  padding: 0 8px 0 25px;
}
ul.wp-block-rss a {
  color: inherit;
}
.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}
.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}
.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.wp-block-table.is-style-stripes {
  border: 0;
  margin-bottom: 30px;
  border-bottom: 0;
}
.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
  border-color: var(--th-border-color);
}
.logged-in .sticky-wrapper.sticky,
.logged-in .preloader .th-btn {
  top: 32px;
  z-index: 9999;
}
@media (max-width: 782px) {
  .logged-in .sticky-wrapper.sticky,
  .logged-in .preloader .th-btn {
    top: 46px;
  }
}
@media (max-width: 600px) {
  .logged-in .sticky-wrapper.sticky,
  .logged-in .preloader .th-btn {
    top: 0;
  }
}
.post-password-form {
  margin-bottom: 30px;
  margin-top: 20px;
}
.post-password-form p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  gap: 15px;
}
@media (max-width: 575px) {
  .post-password-form p {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.post-password-form label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  margin-bottom: 0;
  line-height: 1;
  margin-top: 0;
  gap: 15px;
}
@media (max-width: 575px) {
  .post-password-form label {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.post-password-form input {
  width: 100%;
  border: none;
  height: 55px;
  padding-left: 25px;
  color: var(--body-color);
  border: 1px solid var(--th-border-color);
}
.post-password-form input[type="submit"] {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  width: 140px;
  border: none;
  color: #fff;
  background-color: var(--theme-color);
  text-align: center;
}
.post-password-form input[type="submit"]:hover {
  background-color: var(--title-color);
}
.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}
.page-links > .page-links-title {
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text),
.page-links > a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links > a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text).current,
.page-links > a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}
.page-links span.screen-reader-text {
  display: none;
}
.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}
.blog-single.format-quote,
.blog-single.format-link,
.blog-single.tag-sticky-2,
.blog-single.sticky {
  border-color: transparent;
  position: relative;
}
.blog-single.format-quote .blog-content:before,
.blog-single.format-link .blog-content:before,
.blog-single.tag-sticky-2 .blog-content:before,
.blog-single.sticky .blog-content:before {
  display: none;
}
.blog-single.format-quote:before,
.blog-single.format-link:before,
.blog-single.tag-sticky-2:before,
.blog-single.sticky:before {
  content: "\f0c1";
  position: absolute;
  font-family: var(--icon-font);
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  right: 0;
  top: 0;
  color: var(--white-color);
  background-color: var(--theme-color);
  z-index: 1;
  height: 44px;
  width: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 0 20px 0 20px;
}
@media (max-width: 767px) {
  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    border-radius: 0 10px 0 10px;
  }
}
.blog-single.tag-sticky-2::before,
.blog-single.sticky::before {
  content: "\f08d";
}
.blog-single.format-quote:before {
  content: "\f10e";
}
.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}
.blog-details .blog-single:before {
  display: none;
}
.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}
.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}
.blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}
.blog-details .blog-single.tag-sticky-2,
.blog-details .blog-single.sticky,
.blog-details .blog-single.format-quote,
.blog-details .blog-single.format-link {
  background-color: transparent;
}
.blog-details .blog-single.tag-sticky-2:before,
.blog-details .blog-single.sticky:before,
.blog-details .blog-single.format-quote:before,
.blog-details .blog-single.format-link:before {
  display: none;
}
.nof-title {
  margin-top: -0.24em;
}
.th-search {
  background-color: var(--smoke-color2);
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
}
.th-search .search-grid-content {
  padding: 30px;
}
@media (max-width: 767px) {
  .th-search .search-grid-content {
    padding: 20px;
  }
}
.th-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 0;
}
.th-search .search-grid-title a {
  color: inherit;
}
.th-search .search-grid-title a:hover {
  color: var(--theme-color);
}
.th-search .search-grid-meta > * {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
.th-search .search-grid-meta > *:last-child {
  margin-right: 0;
}
.th-search .search-grid-meta a,
.th-search .search-grid-meta span {
  color: var(--body-color);
}
@media (max-width: 767px) {
  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    font-size: 14px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .wp-block-latest-comments {
    padding-left: 10px;
  }
  .page--content.clearfix + .th-comment-form {
    margin-top: 24px;
  }
}
@media (max-width: 1399px) {
  :root {
    --main-container: 1250px;
  }
}
@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
  .container-xxl.px-0,
  .container-xl.px-0,
  .container-lg.px-0,
  .container-md.px-0,
  .container-sm.px-0,
  .container.px-0 {
    max-width: var(--main-container);
  }
}
@media only screen and (min-width: 1300px) {
  .th-container {
    --main-container: 1776px;
  }
}
@media only screen and (min-width: 1300px) {
  .th-container2 {
    --main-container: 1544px;
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.container-gallery {
  max-width: 1840px;
}
.slick-track > [class*="col"] {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 1300px) {
  .row {
    --bs-gutter-x: 24px;
  }
}
.gy-30 {
  --bs-gutter-y: 30px;
}
.g-0 {
  --bs-gutter-y: 0;
  --bs-gutter-x: 0;
}
.gx-0 {
  --bs-gutter-x: 0;
}
.gx-24 {
  --bs-gutter-x: 24px;
}
.gy-40 {
  --bs-gutter-y: 40px;
}
.gy-24 {
  --bs-gutter-y: 24px;
}
.gy-50 {
  --bs-gutter-y: 50px;
}
.gx-10 {
  --bs-gutter-x: 10px;
}
.gy-10 {
  --bs-gutter-y: 10px;
}
@media (min-width: 1299px) {
  .gx-60 {
    --bs-gutter-x: 60px;
  }
}
@media (min-width: 1399px) {
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-25 {
    --bs-gutter-x: 25px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}
@media (max-width: 991px) {
  .gy-50 {
    --bs-gutter-y: 40px;
  }
}
select,
.form-control,
.form-select,
textarea,
input {
  height: 50px;
  padding: 0 25px 0 25px;
  padding-right: 50px;
  border: 1px solid var(--gray-color);
  color: var(--body-color);
  background-color: var(--white-color);
  font-size: 14px;
  width: 100%;
  font-family: var(--body-font);
  font-weight: 400;
  border-radius: 8px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  border-color: var(--theme-color);
  background-color: var(--white-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
  color: var(--body-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}
.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.form-select.style2,
select.style2 {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87109 1.71094L5.71484 5.62109C5.56901 5.7487 5.41406 5.8125 5.25 5.8125C5.08594 5.8125 4.9401 5.7487 4.8125 5.62109L0.65625 1.71094C0.382812 1.40104 0.373698 1.09115 0.628906 0.78125C0.920573 0.507812 1.23047 0.498698 1.55859 0.753906L5.25 4.25391L8.96875 0.753906C9.27865 0.498698 9.57943 0.498698 9.87109 0.753906C10.1263 1.08203 10.1263 1.40104 9.87109 1.71094Z' fill='%238B929C'/%3E%3C/svg%3E");
}
textarea.form-control,
textarea {
  min-height: 154px;
  padding-top: 16px;
  padding-bottom: 17px;
}
.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}
.form-group > i,
.form-group > img {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 17px;
  font-size: 14px;
  color: var(--body-color);
}
.form-group > i.fa-envelope,
.form-group > img.fa-envelope {
  padding-top: 1px;
}
.form-group > i.fa-comment,
.form-group > img.fa-comment {
  margin-top: -2px;
}
.form-group > i.fa-chevron-down,
.form-group > img.fa-chevron-down {
  width: 17px;
  background-color: var(--smoke-color2);
}
.form-group.has-label > i {
  top: 50px;
}
[class*="col-"].form-group > i {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}
.form-rounded-10 .form-control,
.form-rounded-10 .form-select {
  border-radius: 10px !important;
}
.form-rounded-10 .th-btn {
  border-radius: 10px;
}
option:checked,
option:focus,
option:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type="checkbox"]:checked ~ label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
input[type="checkbox"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type="checkbox"] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--th-border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}
input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type="radio"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type="radio"] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
input[type="radio"]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}
label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}
textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}
textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right
    calc(0.375em + 0.8875rem);
}
.row.no-gutters > .form-group {
  margin-bottom: 0;
}
.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}
.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}
input[type="date"] {
  padding: 0 25px 0 25px;
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
[class*="col-"].form-group > i {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}
[class*="col-"].form-group .form-icon-left {
  left: calc((var(--bs-gutter-x) / 2) + 30px);
  right: auto;
}
input[type="time"] {
  padding: 0 30px;
  position: relative;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
:root {
  --input-height: 56px;
}
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-image: none;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  position: relative;
  line-height: calc(var(--input-height) - 2px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.nice-select:after {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 16px;
  pointer-events: none;
  border-bottom: 1px solid var(--body-color);
  border-right: 1px solid var(--body-color);
  position: absolute;
  right: 20px;
  line-height: calc(var(--input-height) - 2px);
  top: 21px;
  height: 8px;
  width: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  border-radius: 0;
  pointer-events: auto;
  display: block;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  border-bottom: 1px solid var(--theme-color);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #ccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 250px;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scale(0.75) translateY(-28px);
  -ms-transform: scale(0.75) translateY(-28px);
  transform: scale(0.75) translateY(-28px);
  -webkit-transition:
    all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition:
    all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: var(--smoke-color);
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}
.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}
.currency-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
.currency-menu img {
  min-width: 16px;
  min-height: 16px;
  position: relative;
}
.currency-menu .nice-select {
  font-family: var(--body-font);
  background: none;
  border: none;
  width: 100%;
  color: var(--black-color2);
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
  line-height: 16px;
  padding: 0;
  border-radius: 0;
  padding-right: 50px;
  margin-right: 20px;
  border-right: 1px solid var(--body-color);
  height: 16px;
}
.currency-menu .nice-select .list {
  left: -20px;
}
.currency-menu .nice-select:after {
  content: "\f107";
  right: 35px;
  top: -19px;
  font-size: 14px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border-bottom: none;
  border-right: none;
}
.currency-menu .nice-select.open:after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.currency-menu .nice-select.open .list {
  left: -20px;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.currency-menu .nice-select .option {
  color: var(--title-color);
  min-height: 30px;
}
.currency-menu .nice-select .option.selected {
  color: var(--theme-color) !important;
  background: var(--theme-color) !important;
}
.currency-menu .nice-select .option.selected.focus {
  color: #fff !important;
  background: var(--theme-color) !important;
}
.currency-menu .nice-select .option:hover,
.currency-menu .nice-select .option.focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}
.swiper-wrapper.row {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.th-slider.has-shadow {
  padding-left: 12px;
  padding-right: 12px;
  margin: -25px -12px;
}
.th-slider.has-shadow .swiper-wrapper {
  padding: 25px 0;
}
.swiper-fade .swiper-slide {
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}
.swiper-fade .swiper-slide-prev {
  opacity: 0;
}
.swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: 45px 0 10px 0;
  padding-bottom: 20px;
  line-height: 20px;
  height: 16px;
}
.swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 16px;
  --swiper-pagination-bullet-horizontal-gap: 15px;
  margin: 5px 7px;
  opacity: 1;
  background-color: transparent;
  border: 1px solid #113d48;
  color: #e4e4e4;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
@media (max-width: 575px) {
  .swiper-pagination-bullets .swiper-pagination-bullet {
    --swiper-pagination-bullet-horizontal-gap: 8px;
  }
}
.swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.slider-area {
  position: relative;
}
.slider-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 48px;
  color: var(--theme-color);
  position: absolute;
  top: 50%;
  left: var(--pos-x, -120px);
  width: var(--icon-size, 88px);
  height: var(--icon-size, 56px);
  line-height: var(--icon-size, 56px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 56px) / -2);
  z-index: 3;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1500px) {
  .slider-arrow {
    display: none !important;
  }
}
.slider-arrow.style2 {
  width: var(--icon-size, 56px);
  height: var(--icon-size, 56px);
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.slider-arrow.style2:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
  border-color: var(--title-color);
}
.slider-arrow.style3 {
  background-color: var(--white-color);
  color: var(--white-color);
  --icon-size: 48px;
  line-height: 48px;
  border-radius: 50%;
  border-color: var(--white-color);
}
.slider-arrow.style3:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--white-color);
}
.slider-arrow.style4 {
  background-color: transparent;
  color: var(--title-color);
  border-color: #e4e4e4;
  box-shadow: none;
  font-size: var(--icon-font-size, 22px);
}
.slider-arrow.style4:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
  border-color: var(--title-color);
}
.slider-arrow.style5 {
  border: 1px solid var(--title-color);
}
.slider-arrow.style5:hover {
  border-color: var(--theme-color);
}
@media (max-width: 1500px) {
  .slider-arrow.style5 {
    display: inline-block !important;
  }
}
.slider-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slider-arrow.slider-next {
  right: var(--pos-x, -120px);
  left: auto;
}
.slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.slider-arrow:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.arrow-margin .slider-arrow {
  top: calc(50% - 30px);
}
.arrow-wrap {
  position: relative;
}
.arrow-wrap .slider-arrow {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
}
@media (max-width: 1299px) {
  .arrow-wrap .slider-arrow {
    display: none;
  }
}
.arrow-wrap:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
@media (max-width: 1500px) {
  .slider-arrow {
    --arrow-horizontal: -20px;
    --pos-x: -70px;
  }
}
@media (max-width: 1399px) {
  .slider-arrow {
    --arrow-horizontal: 40px;
    --pos-x: -17px;
  }
}
.icon-box .slider-arrow {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.icon-box .slider-arrow:not(:last-child) {
  margin-right: 8px;
}
@media (max-width: 991px) {
  .slider-arrow {
    --icon-size: 40px;
    line-height: 38px;
    margin-right: 40px;
    font-size: 14px;
  }
  .slider-arrow.slider-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 40px 0 0 0;
  }
  .icon-box .slider-arrow {
    margin-right: 0;
  }
}
.slick-3d-active {
  margin-left: -12%;
  margin-right: -12%;
}
.slick-3d-active .slick-list {
  padding-left: 30% !important;
  padding-right: 30% !important;
}
.slick-3d-active .slick-track {
  max-width: 100% !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  -webkit-perspective: 100px;
  perspective: 100px;
}
.slick-3d-active .slick-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  width: 100% !important;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition:
    opacity 1s,
    -webkit-transform 1s;
  transition:
    opacity 1s,
    -webkit-transform 1s;
  transition:
    transform 1s,
    opacity 1s;
  transition:
    transform 1s,
    opacity 1s,
    -webkit-transform 1s;
}
.slick-3d-active .slick-3d-next,
.slick-3d-active .slick-3d-prev,
.slick-3d-active .slick-3d-next2,
.slick-3d-active .slick-3d-prev2 {
  display: block;
}
.slick-3d-active .slick-current {
  opacity: 1;
  position: relative;
  display: block;
  z-index: 2;
}
.slick-3d-active .slick-3d-next {
  opacity: 1;
  -webkit-transform: translate3d(50%, 0, -21px);
  transform: translate3d(50%, 0, -21px);
  z-index: 1;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.slick-3d-active .slick-3d-next2 {
  opacity: 1;
  -webkit-transform: translate3d(40%, 0, -23px);
  transform: translate3d(40%, 0, -23px);
  z-index: 0;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.slick-3d-active .slick-3d-prev {
  opacity: 1;
  -webkit-transform: translate3d(-50%, 0, -21px);
  transform: translate3d(-50%, 0, -21px);
}
.slick-3d-active .slick-3d-prev .testi-card {
  box-shadow: none;
}
.slick-3d-active .slick-3d-prev2 {
  opacity: 1;
  -webkit-transform: translate3d(-40%, 0, -23px);
  transform: translate3d(-40%, 0, -23px);
}
.th-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}
.th-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: var(--smoke-color);
}
.th-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}
.th-menu-wrapper .th-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: -16.5px;
  top: 25px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 35px;
  font-size: 18px;
  z-index: 1;
  color: var(--white-color);
  background-color: var(--title-color);
  border-radius: 50%;
}
.th-menu-wrapper .th-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.th-menu-wrapper .th-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--title-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  z-index: 1;
}
.th-menu-wrapper.th-body-visible {
  opacity: 1;
  visibility: visible;
}
.th-menu-wrapper.th-body-visible .th-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.th-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}
.th-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.th-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.th-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.th-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1.4;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
  padding-left: 0px;
}
.th-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 12px;
  margin-right: 10px;
  display: inline-block;
  display: none;
}
.th-mobile-menu ul li.th-active > a {
  color: var(--theme-color);
}
.th-mobile-menu ul li.th-active > a:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.th-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.th-mobile-menu ul .menu-item-has-children > a .th-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}
.th-mobile-menu ul .menu-item-has-children > a .th-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}
.th-mobile-menu ul .menu-item-has-children > a:after {
  content: "\f067";
  font-family: var(--icon-font);
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: var(--smoke-color);
  float: right;
  margin-top: 1px;
}
.th-mobile-menu
  ul
  .menu-item-has-children.th-active
  > a
  .th-mean-expand:before {
  content: "\f068";
}
.th-mobile-menu ul .menu-item-has-children.th-active > a:after {
  content: "\f068";
}
.th-mobile-menu > ul {
  padding: 0 40px;
}
.th-mobile-menu > ul > li:last-child {
  border-bottom: none;
}
.th-menu-toggle {
  width: 56px;
  height: 56px;
  padding: 0;
  font-size: 20px;
  border: none;
  border-radius: 8px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
}
.th-menu-toggle:hover {
  background-color: var(--title-color);
}
.th-menu-toggle.style-text,
.th-menu-toggle.style-text-white {
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--title-color);
  font-size: 20px;
}
.th-menu-toggle.style-text i,
.th-menu-toggle.style-text-white i {
  margin-right: 10px;
}
.th-menu-toggle.style-text-white {
  color: var(--white-color);
}
@media (max-width: 400px) {
  .th-menu-wrapper .th-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .th-mobile-menu > ul {
    padding: 0 20px;
  }
}
.preloader {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: var(--white-color);
}
.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 30px auto;
}
.th-preloader {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 900;
}
.th-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}
.th-preloader .animation-preloader .txt-loading {
  font-family: var(--title-font);
  font-size: 72px;
  line-height: 82px;
  font-weight: 700;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.th-preloader .animation-preloader .txt-loading p {
  display: inline-block;
}
.th-preloader .animation-preloader .txt-loading .characters {
  color: rgba(0, 0, 0, 0.2);
  position: relative;
}
.th-preloader .animation-preloader .txt-loading .characters:before {
  -webkit-animation: characters 4s infinite;
  animation: characters 4s infinite;
  color: var(--theme-color);
  content: attr(preloader-text);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 10px;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.th-preloader
  .animation-preloader
  .txt-loading
  .characters:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.th-preloader
  .animation-preloader
  .txt-loading
  .characters:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.th-preloader
  .animation-preloader
  .txt-loading
  .characters:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.th-preloader
  .animation-preloader
  .txt-loading
  .characters:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.th-preloader
  .animation-preloader
  .txt-loading
  .characters:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-webkit-keyframes characters {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes characters {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
.th-btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--style-font);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  border: none;
  font-size: 20px;
  font-weight: 400;
  padding: 18.8px 35px;
  border-radius: 48px;
  -webkit-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  gap: 8px;
}
.th-btn.th-icon:after {
  content: "";
  position: relative;
  -webkit-mask-image: url(../src//img/icon/right-arrow.svg);
  mask-image: url(../src//img/icon/right-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: auto;
  mask-size: auto;
  background-color: var(--white-color);
  width: 24px;
  height: 24px;
  display: block;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.th-btn:before {
  content: "";
  width: 0;
  height: 100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: -5%;
  background-color: var(--title-color);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}
.th-btn:hover,
.th-btn.active {
  color: var(--white-color);
  box-shadow: none;
}
.th-btn:hover.th-icon i,
.th-btn.active.th-icon i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.th-btn:hover:before,
.th-btn.active:before {
  width: 110%;
}
.th-btn.style1:before,
.th-btn.style1:after {
  background-color: var(--white-color);
}
.th-btn.style1:hover {
  color: var(--title-color);
}
.th-btn.style1:hover.th-icon:after {
  background-color: var(--title-color);
}
.th-btn.style1:hover:before,
.th-btn.style1:hover:after {
  background-color: var(--white-color);
}
.th-btn.style2 {
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color);
}
.th-btn.style2:hover {
  color: var(--black-color2);
  border: 1px solid var(--white-color);
}
.th-btn.style2:hover:after {
  background-color: var(--title-color);
}
.th-btn.style2:before {
  background-color: var(--white-color);
}
.th-btn.style3 {
  background-color: var(--title-color);
  color: var(--white-color);
  box-shadow: none;
  border: none;
}
.th-btn.style3:before {
  background-color: var(--theme-color);
}
.th-btn.style4 {
  background-color: transparent;
  color: var(--title-color);
  border: 1px solid var(--title-color);
  padding: 14px 25px;
}
.th-btn.style4.th-icon:after {
  background-color: var(--title-color);
}
.th-btn.style4:hover {
  color: var(--white-color);
  border: 1px solid var(--theme-color);
}
.th-btn.style4:hover.th-icon:after {
  background-color: var(--white-color);
}
.th-btn.style4:before {
  background-color: var(--theme-color);
}
.th-btn.style5 {
  background-color: var(--title-color);
  color: var(--white-color);
  box-shadow: none;
  border: none;
}
.th-btn.style5:before {
  background-color: var(--white-color);
}
.th-btn.style5:hover {
  color: var(--title-color);
}
.th-btn.style6 {
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: none;
  border: none;
}
.th-btn.style6:before {
  background-color: var(--theme-color);
}
.th-btn.style6:after {
  background-color: var(--title-color);
}
.th-btn.style6:hover {
  color: var(--white-color);
}
.th-btn.style6:hover:after {
  background-color: var(--white-color);
}
.th-btn.style7:before,
.th-btn.style7:after {
  background-color: var(--white-color);
}
.th-btn.style7:hover {
  color: var(--title-color);
}
.th-btn.style7:hover:before,
.th-btn.style7:hover:after {
  background-color: var(--white-color);
}
.th-btn.style7:hover:after {
  background-color: var(--title-color);
}
.th-btn.white-btn {
  background-color: var(--white-color);
  color: var(--black-color2);
}
.th-btn.th-radius {
  border-radius: 0;
}
.th-btn.th-radius2 {
  border-radius: 8px;
}
.th-btn.btn-fw {
  width: 100%;
  padding: 18px 30px;
}
.icon-btn {
  display: inline-block;
  width: var(--btn-size, 46px);
  height: var(--btn-size, 46px);
  line-height: var(--btn-size, 43px);
  font-size: var(--btn-font-size, 18px);
  background-color: transparent;
  border: 2px solid #888c97;
  color: var(--title-color);
  text-align: center;
  border-radius: 99px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
}
.icon-btn.th-icon i {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.icon-btn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn:hover.th-icon i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.icon-btn.style2 {
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  border-radius: 99px;
}
.icon-btn.style2:hover {
  background-color: var(--theme-color);
}
.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 56px);
  height: var(--icon-size, 56px);
  line-height: var(--icon-size, 56px);
  text-align: center;
  background: var(--white-color);
  color: var(--title-color);
  font-size: var(--icon-font-size, 1.7em);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.play-btn:after,
.play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.play-btn:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.play-btn:hover:after,
.play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.play-btn.style2 > i {
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--white-color);
}
.play-btn.style2:before,
.play-btn.style2:after {
  background-color: transparent;
  border: 1px solid var(--white-color);
}
.play-btn.style3 > i {
  --icon-size: 40px;
  font-size: 14px;
  border: 1px solid;
  background-color: var(--theme-color);
  color: var(--white-color);
}
.play-btn.style3:before,
.play-btn.style3:after {
  background-color: var(--white-color);
}
.play-btn.style3:hover > i {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.play-btn.style3:hover:before,
.play-btn.style3:hover:after {
  background-color: var(--white-color);
}
.link-btn {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
  color: var(--theme-color);
}
.link-btn i {
  margin-left: 5px;
  font-size: 0.9rem;
}
.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.link-btn:hover {
  color: var(--theme-color);
}
.link-btn:hover::before {
  width: 100%;
}
.line-btn {
  font-family: var(--body-font);
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -1px;
  text-transform: uppercase;
  color: var(--theme-color);
}
.line-btn.th-icon i {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.line-btn i {
  margin-left: 5px;
  font-size: 0.9rem;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.line-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.line-btn:hover {
  color: var(--title-color);
}
.line-btn:hover.th-icon i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.line-btn:hover::before {
  background-color: var(--title-color);
  width: 45px;
}
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 10000;
  opacity: 1;
  visibility: hidden;
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.scroll-top:after {
  content: "\f062";
  font-family: var(--icon-font);
  position: absolute;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: var(--theme-color);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  border: 2px solid var(--theme-color);
  box-shadow: none;
  border-radius: 50%;
}
.scroll-top svg {
  color: var(--theme-color);
  border-radius: 50%;
  background: var(--white-color);
}
.scroll-top svg path {
  fill: none;
}
.scroll-top .progress-circle path {
  stroke: var(--theme-color);
  stroke-width: 20px;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.scroll-top.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.sec-title {
  margin-bottom: calc(var(--section-title-space) - 11px);
}
.sub-title {
  display: block;
  color: var(--title-color);
  font-size: 33px;
  line-height: 40px;
  font-weight: 400;
  font-family: var(--style-font) !important;
  position: relative;
  margin-bottom: -4px;
}
@media (max-width: 480px) {
  .sub-title {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 375px) {
  .sub-title {
    font-size: 25px;
    line-height: 25px;
  }
}
.box-title {
  font-size: 24px;
  line-height: 1.417;
  font-weight: 600;
  margin-top: -0.32em;
}
.box-title a {
  color: inherit;
}
.box-title a:hover {
  color: var(--theme-color);
}
/* .new-title-card a:hover {
  color: var(--title-color) !important ;
} */
.box-subtitle {
  margin-bottom: 8px;
}
.box-title2 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  margin-top: -0.35em;
}
.box-title2 a {
  color: inherit;
}
.box-title2 a:hover {
  color: var(--theme-color);
}
.sec-text {
  font-size: 18px;
  line-height: 26px;
}
.sec-text.style2 {
  max-width: 776px;
}
@media (max-width: 991px) {
  .sec-text.style2 {
    display: block;
    margin: auto auto 50px auto;
    text-align: center;
  }
}
.title-area {
  margin-bottom: calc(var(--section-title-space) - 11px);
  position: relative;
  z-index: 2;
}
.title-area .sec-title {
  margin-bottom: 22px;
  font-family: Manrope-SemiBold !important;
  margin-top: 15px !important;
  font-size: 30px;
}
.title-area.mb-0 .sec-title {
  margin-bottom: -0.24em;
}
.title-area .th-btn {
  margin-top: 20px;
  margin-bottom: 10px;
}
.mb-32 {
  margin-bottom: 32px;
}
hr.title-line {
  margin-top: 0;
  background-color: var(--th-border-color);
  opacity: 1;
}
.sec-btn {
  text-align: center;
}
.sec-btn,
.title-line {
  margin-bottom: var(--section-title-space);
}
.shadow-title {
  font-family: var(--title-font);
  font-size: 74px;
  font-weight: 900;
  line-height: 1;
  background-image: -webkit-linear-gradient(
    top,
    rgba(226, 232, 250, 0.7) 0%,
    rgba(226, 232, 250, 0) 88.54%
  );
  background-image: linear-gradient(
    180deg,
    rgba(226, 232, 250, 0.7) 0%,
    rgba(226, 232, 250, 0) 88.54%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: -0.55em 0 -0.45em -0.25em;
}
.shadow-title.color2 {
  background-image: -webkit-linear-gradient(
    top,
    #232c47 0%,
    rgba(20, 29, 56, 0) 91.15%
  );
  background-image: linear-gradient(
    180deg,
    #232c47 0%,
    rgba(20, 29, 56, 0) 91.15%
  );
}
.shadow-title.color3 {
  background-image: -webkit-linear-gradient(
    top,
    #e0e0e0 0%,
    rgba(220, 214, 214, 0) 93.75%
  );
  background-image: linear-gradient(
    180deg,
    #e0e0e0 0%,
    rgba(220, 214, 214, 0) 93.75%
  );
}
.title-area2 {
  padding: 50px 100px;
}
.title-area2 .subtitle {
  color: var(--white-color);
  text-transform: uppercase;
  margin-top: -0.4em;
  margin-bottom: 5px;
  display: block;
}
.title-area2 .title {
  color: var(--white-color);
  max-width: 430px;
  margin-bottom: -0.26em;
}
@media (max-width: 1700px) {
  .title-area2 {
    padding: 50px 50px;
  }
}
@media (max-width: 1199px) {
  .shadow-title {
    font-size: 64px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 60px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 36px;
  }
  .title-area.mb-50,
  .sec-title.mb-50 {
    margin-bottom: 40px;
  }
  .sec-btn,
  .title-line {
    --section-title-space: 55px;
  }
  .title-area2 .title {
    max-width: 300px;
  }
}
@media (max-width: 991px) {
  .shadow-title {
    font-size: 60px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 50px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 35px;
  }
  .sec-btn,
  .title-line {
    --section-title-space: 50px;
  }
  .sub-title.h4 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .sub-title {
    margin-bottom: 10px;
  }
  .title-area2 {
    text-align: center;
  }
  .title-area2 .title {
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .shadow-title {
    font-size: 52px;
  }
}
@media (max-width: 375px) {
  .shadow-title {
    font-size: 40px;
  }
  .title-area2 {
    padding: 40px 20px;
  }
}
.tx-split-text .split-line {
  overflow: hidden;
}
.title-ani,
.text-ani {
  text-align: inherit !important;
}
.title-ani > *,
.text-ani > * {
  text-align: inherit !important;
}
#smooth-wrapper {
  padding-top: var(--headerHeight);
}
.shape-mockup-wrap {
  z-index: 2;
  position: relative;
}
.shape-mockup {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .shape-mockup {
    display: none !important;
  }
}
.shape-mockup.z-index-3 {
  z-index: 3;
  pointer-events: none;
}
.shape-mockup.z-index-1 {
  z-index: 1;
  pointer-events: none;
}
.shape-mockup .svg-img {
  height: 110px;
  width: 110px;
}
.shape-mockup.hero_wrap {
  z-index: 1;
}
@media (max-width: 1599px) {
  .shape-mockup.hero_wrap {
    display: none !important;
  }
}
.shape-mockup.shape1 {
  -webkit-animation: cloudAni 10s linear infinite;
  animation: cloudAni 10s linear infinite;
}
@media (max-width: 1599px) {
  .shape-mockup.shape1 {
    display: none !important;
  }
}
.shape-mockup.shape2 {
  -webkit-animation: cloudAni 15s linear infinite;
  animation: cloudAni 15s linear infinite;
}
@media (max-width: 1599px) {
  .shape-mockup.shape2 {
    display: none !important;
  }
}
.shape-mockup.shape3 {
  -webkit-animation: cloudAni 20s linear infinite;
  animation: cloudAni 20s linear infinite;
}
@media (max-width: 1599px) {
  .shape-mockup.shape3 {
    display: none !important;
  }
}
@media (max-width: 1599px) {
  .shape-mockup.about-shape3 {
    display: block !important;
  }
}
.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}
.z-index-common {
  position: relative;
  z-index: 3;
}
.z-index-3 {
  z-index: 3;
}
.z-index-n1 {
  z-index: -1;
}
.media-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.25em 0.45em;
  font-size: 0.7em;
  border-radius: 50%;
  top: 8px;
  right: 8px;
  font-weight: 400;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.th-social a {
  display: inline-block;
  width: var(--icon-size, 32px);
  height: var(--icon-size, 32px);
  line-height: var(--icon-size, 32px);
  background-color: #e9f6f9;
  color: var(--theme-color);
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.th-social a:last-child {
  margin-right: 0;
}
.th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.th-social a:hover i {
  -webkit-animation: slideTop 0.5s;
  animation: slideTop 0.5s;
}
.th-social.color-theme a {
  color: var(--body-color);
  border-color: var(--theme-color);
}
.bg-mask {
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.box-icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.box-text {
  margin-bottom: -0.5em;
}
.btn-group {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}
.btn-group.style1 {
  gap: 40px;
}
@media (max-width: 375px) {
  .btn-group.style1 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.btn-group.style2 {
  gap: 20px 70px;
}
@media (max-width: 767px) {
  .btn-group.style2 {
    gap: 20px 30px;
  }
}
@media (max-width: 767px) {
  .btn-group {
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.th-bg-img {
  position: absolute;
  inset: 0;
}
.th-bg-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}
.mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.magic-cursor.relative {
  z-index: 999999;
}
.cursor {
  position: absolute;
  background: transparent;
  cursor: pointer;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 100%;
  z-index: 9999999;
  -webkit-transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}
.cursor:before {
  content: "DRAG" " " "";
  font-family: var(--icon-font);
  width: 100%;
  height: 100%;
  line-height: 100%;
  position: absolute;
  font-size: 2px;
  top: 6.5px;
  left: 5px;
  display: block;
  border-radius: 100%;
  opacity: 0;
  color: var(--white-color);
  letter-spacing: 0.02em;
}
.cursor:after {
  position: absolute;
  content: "\f104";
  font-family: var(--icon-font);
  font-size: 2px;
  color: var(--white-color);
  top: 6.5px;
  left: 3px;
  width: 100%;
  height: 100%;
  line-height: 100%;
  opacity: 0;
}
.cursor.active {
  opacity: 1;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  background: rgba(17, 61, 72, 0.7);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  -webkit-transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
}
.cursor.active:before,
.cursor.active:after {
  opacity: 1;
}
.cursor-follower {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  z-index: 99999999;
  background-color: var(--theme-color);
  -webkit-transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) background,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) background,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) background;
  transition:
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) background,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-transform: translate(8px, 8px);
  -ms-transform: translate(8px, 8px);
  transform: translate(8px, 8px);
}
.cursor-follower.active {
  opacity: 1;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.font-icon {
  font-family: var(--icon-font);
}
.font-title {
  font-family: var(--title-font);
}
.font-body {
  font-family: var(--body-font);
}
.fw-extralight {
  font-weight: 100;
}
.fw-light {
  font-weight: 300;
}
.fw-normal {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-extrabold {
  font-weight: 800;
}
.fs-md {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-xs {
  font-size: 14px;
}
.bg-theme {
  background-color: var(--theme-color) !important;
}
.bg-smoke {
  background-color: var(--smoke-color) !important;
}
.bg-smoke2 {
  background-color: var(--smoke-color2) !important;
}
.bg-white {
  background-color: var(--white-color) !important;
}
.bg-black {
  background-color: var(--black-color) !important;
}
.bg-black2 {
  background-color: var(--black-color2) !important;
}
.bg-title {
  background-color: var(--title-color) !important;
}
.gradient-body {
  background-image: -webkit-linear-gradient(
    top,
    rgba(237, 240, 244, 0.5) 2.66%,
    rgba(237, 240, 244, 0.36) 90.4%
  );
  background-image: linear-gradient(
    180deg,
    rgba(237, 240, 244, 0.5) 2.66%,
    rgba(237, 240, 244, 0.36) 90.4%
  );
}
.gr-bg1 {
  background: -webkit-linear-gradient(
    top,
    rgba(237, 240, 244, 0) 0%,
    rgba(237, 240, 244, 0.85) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(237, 240, 244, 0) 0%,
    rgba(237, 240, 244, 0.85) 100%
  );
}
.gr-bg2 {
  background-image: -webkit-linear-gradient(
    349.38deg,
    rgba(249, 215, 175, 0.3) 0%,
    rgba(214, 202, 245, 0.3) 24.03%,
    rgba(198, 241, 255, 0.3) 45.73%,
    rgba(248, 242, 222, 0.3) 69.05%,
    rgba(212, 179, 253, 0.3) 100.44%
  );
  background-image: linear-gradient(
    100.62deg,
    rgba(249, 215, 175, 0.3) 0%,
    rgba(214, 202, 245, 0.3) 24.03%,
    rgba(198, 241, 255, 0.3) 45.73%,
    rgba(248, 242, 222, 0.3) 69.05%,
    rgba(212, 179, 253, 0.3) 100.44%
  );
}
.gr-bg3 {
  background-image: -webkit-linear-gradient(
    339.41deg,
    rgba(236, 207, 254, 0.4) 0%,
    rgba(255, 221, 221, 0.5) 46.79%,
    rgba(247, 255, 229, 0.7) 100%
  );
  background-image: linear-gradient(
    110.59deg,
    rgba(236, 207, 254, 0.4) 0%,
    rgba(255, 221, 221, 0.5) 46.79%,
    rgba(247, 255, 229, 0.7) 100%
  );
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.gr-bg4 {
  background-image: -webkit-linear-gradient(
    344.56deg,
    rgba(255, 229, 133, 0.4) 0%,
    rgba(189, 255, 199, 0.4) 48.48%,
    rgba(223, 109, 223, 0.4) 100%
  );
  background-image: linear-gradient(
    105.44deg,
    rgba(255, 229, 133, 0.4) 0%,
    rgba(189, 255, 199, 0.4) 48.48%,
    rgba(223, 109, 223, 0.4) 100%
  );
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.gray-body {
  background-color: #f8faff;
}
.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.bg-auto {
  background-size: auto auto;
}
.bg-top-center {
  background-size: auto;
  background-position: top center;
}
.bg-bottom-center {
  background-size: auto;
  background-position: bottom center;
}
.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}
.bg-bottom-right {
  background-size: auto;
  background-position: bottom right;
}
.bg-top-right {
  background-size: auto;
  background-position: top right;
}
.bg-top-left {
  background-size: auto;
  background-position: top left;
}
.th-radius {
  border-radius: 20px;
}
.text-theme {
  color: var(--theme-color) !important;
}
.text-theme2 {
  color: var(--theme-color) !important;
}
.text-title {
  color: var(--title-color) !important;
}
.text-body {
  color: var(--body-color) !important;
}
.text-white {
  color: var(--white-color) !important;
}
.text-light {
  color: var(--light-color) !important;
}
.text-yellow {
  color: var(--yellow-color) !important;
}
.text-success {
  color: var(--success-color) !important;
}
.text-error {
  color: var(--error-color) !important;
}
.text-inherit {
  color: inherit;
}
.text-inherit:hover {
  color: var(--theme-color);
}
a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
[data-overlay] {
  position: relative;
  z-index: 2;
}
[data-overlay] [class^="col-"],
[data-overlay] [class*="col-"] {
  z-index: 1;
}
[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
[data-overlay="theme"]:before {
  background-color: var(--theme-color);
}
[data-overlay="title"]:before {
  background-color: var(--title-color);
}
[data-overlay="smoke"]:before {
  background-color: var(--smoke-color);
}
[data-overlay="white"]:before {
  background-color: var(--white-color);
}
[data-overlay="black"]:before {
  background-color: var(--black-color);
}
[data-overlay="overlay1"]:before {
  background-color: #080e1c;
}
[data-opacity="1"]:before {
  opacity: 0.1;
}
[data-opacity="2"]:before {
  opacity: 0.2;
}
[data-opacity="3"]:before {
  opacity: 0.3;
}
[data-opacity="4"]:before {
  opacity: 0.4;
}
[data-opacity="5"]:before {
  opacity: 0.5;
}
[data-opacity="6"]:before {
  opacity: 0.6;
}
[data-opacity="7"]:before {
  opacity: 0.7;
}
[data-opacity="8"]:before {
  opacity: 0.8;
}
[data-opacity="9"]:before {
  opacity: 0.9;
}
[data-opacity="10"]:before {
  opacity: 1;
}
.ripple-animation,
.play-btn:after,
.play-btn:before,
.testi-box_avater:after {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}
.ripple-animation3,
.counter-card .counter-shape:after {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple3;
  animation-name: ripple3;
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}
@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}
@-webkit-keyframes ripple2 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.14;
  }
  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.14;
  }
  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}
@-webkit-keyframes ripple3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(2.9);
    transform: scale(2.9);
    opacity: 0.2;
  }
}
@keyframes ripple3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(2.9);
    transform: scale(2.9);
    opacity: 0.2;
  }
}
@-webkit-keyframes scrollMove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes scrollMove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
.scale {
  -webkit-animation: scale 5s ease forwards;
  animation: scale 5s ease forwards;
}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.fancy-animation {
  -webkit-animation: morph 8s ease-in-out infinite;
  animation: morph 8s ease-in-out infinite;
}
@-webkit-keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
@-webkit-keyframes footerLine {
  0% {
    left: 40px;
  }
  50% {
    left: 0;
  }
  100% {
    left: 40px;
  }
}
@keyframes footerLine {
  0% {
    left: 40px;
  }
  50% {
    left: 0;
  }
  100% {
    left: 40px;
  }
}
.movingX {
  -webkit-animation: movingX 8s linear infinite;
  animation: movingX 8s linear infinite;
}
@-webkit-keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.movingCar {
  -webkit-animation: movingCar 25s linear infinite;
  animation: movingCar 25s linear infinite;
}
@-webkit-keyframes movingCar {
  0% {
    -webkit-transform: translateX(0) rotateY(0deg);
    transform: translateX(0) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateX(calc(100vw * -1 + 108%));
    transform: translateX(calc(100vw * -1 + 108%));
  }
  51% {
    -webkit-transform: translateX(calc(100vw * -1 + 108%)) rotateY(180deg);
    transform: translateX(calc(100vw * -1 + 108%)) rotateY(180deg);
  }
  100% {
    -webkit-transform: translateX(0) rotateY(180deg);
    transform: translateX(0) rotateY(180deg);
  }
}
@keyframes movingCar {
  0% {
    -webkit-transform: translateX(0) rotateY(0deg);
    transform: translateX(0) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateX(calc(100vw * -1 + 108%));
    transform: translateX(calc(100vw * -1 + 108%));
  }
  51% {
    -webkit-transform: translateX(calc(100vw * -1 + 108%)) rotateY(180deg);
    transform: translateX(calc(100vw * -1 + 108%)) rotateY(180deg);
  }
  100% {
    -webkit-transform: translateX(0) rotateY(180deg);
    transform: translateX(0) rotateY(180deg);
  }
}
.speed2 {
  -webkit-animation: speed2 10s linear infinite;
  animation: speed2 10s linear infinite;
}
@-webkit-keyframes speed2 {
  0% {
    right: 0%;
  }
  30% {
    right: 30%;
  }
  50% {
    right: 50%;
  }
  100% {
    right: 100%;
  }
}
@keyframes speed2 {
  0% {
    right: 0%;
  }
  30% {
    right: 30%;
  }
  50% {
    right: 50%;
  }
  100% {
    right: 100%;
  }
}
.sway {
  -webkit-animation: sway 8s ease-in-out infinite alternate;
  animation: sway 8s ease-in-out infinite alternate;
}
@-webkit-keyframes sway {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  50% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}
@keyframes sway {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  50% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}
.th-ani {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.th-ani:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.dance {
  -webkit-animation: dance 2s alternate infinite;
  animation: dance 2s alternate infinite;
}
@-webkit-keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
  animation: dance2 4s alternate infinite;
}
@-webkit-keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
    transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
    transform: translate3d(0, -15px, 15px);
  }
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
    transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
    transform: translate3d(0, -15px, 15px);
  }
}
.watermark-animate {
  -webkit-animation: watermark-animate 8s infinite;
  animation: watermark-animate 8s infinite;
  animation-direction: alternate-reverse;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes watermark-animate {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes watermark-animate {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@-webkit-keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
    transform: translateX(calc(-100% - var(--gap)));
  }
}
.jump {
  -webkit-animation: jumpAni 7s linear infinite;
  animation: jumpAni 7s linear infinite;
}
@-webkit-keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.jump-reverse {
  -webkit-animation: jumpReverseAni 7s linear infinite;
  animation: jumpReverseAni 7s linear infinite;
}
@-webkit-keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.spin {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.bg-color-ani,
.color-animate {
  -webkit-animation: bgColor 6s linear infinite;
  animation: bgColor 6s linear infinite;
}
@-webkit-keyframes bgColor {
  0% {
    background-color: #f2ba4c;
  }
  25% {
    background-color: #81f24c;
  }
  50% {
    background-color: #41f27d;
  }
  75% {
    background-color: #0500ff;
  }
  100% {
    background-color: #f2ba4c;
  }
}
@keyframes bgColor {
  0% {
    background-color: #f2ba4c;
  }
  25% {
    background-color: #81f24c;
  }
  50% {
    background-color: #41f27d;
  }
  75% {
    background-color: #0500ff;
  }
  100% {
    background-color: #f2ba4c;
  }
}
.zoom {
  -webkit-animation: zoom 15s linear infinite;
  animation: zoom 15s linear infinite;
}
@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@-webkit-keyframes zoom1 {
  0% {
    scale: 0.2;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 0.2;
  }
}
@keyframes zoom1 {
  0% {
    scale: 0.2;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 0.2;
  }
}
@-webkit-keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }
  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }
  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@-webkit-keyframes slideTop {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes slideTop {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.fadedown {
  -webkit-animation: fade-down 0.3s linear;
  animation: fade-down 0.3s linear;
}
@-webkit-keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.fadein,
.scalein,
.slidetopleft,
.slidebottomright,
.slideinleft,
.slideinright,
.slideindown,
.slideinup,
.rollinleft,
.rollinright {
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-name: var(--animation-name);
  animation-name: var(--animation-name);
}
.swiper-slide-active .fadein {
  --animation-name: fadein;
}
.swiper-slide-active .scalein {
  --animation-name: scalein;
}
.swiper-slide-active .slidetopleft {
  --animation-name: slidetopleft;
}
.swiper-slide-active .slidebottomright {
  --animation-name: slidebottomright;
}
.swiper-slide-active .slideinleft {
  --animation-name: slideinleft;
}
.swiper-slide-active .slideinright {
  --animation-name: slideinright;
}
.swiper-slide-active .slideinup {
  --animation-name: slideinup;
}
.swiper-slide-active .slideindown {
  --animation-name: slideindown;
}
.swiper-slide-active .rollinleft {
  --animation-name: rollinleft;
}
.swiper-slide-active .rollinright {
  --animation-name: rollinright;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(120px) translateY(120px);
    transform: translateX(120px) translateY(120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(120px) translateY(120px);
    transform: translateX(120px) translateY(120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
    transform: translateX(-100px) translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes cloudAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes cloudAni {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul,
.wp-block-categories ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0;
}
.widget_nav_menu > ul,
.widget_nav_menu .menu,
.widget_meta > ul,
.widget_meta .menu,
.widget_pages > ul,
.widget_pages .menu,
.widget_archive > ul,
.widget_archive .menu,
.widget_categories > ul,
.widget_categories .menu,
.wp-block-categories > ul,
.wp-block-categories .menu {
  margin: -20px 0 -20px 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a,
.wp-block-categories a {
  display: block;
  background-color: transparent;
  margin: 0;
  padding: 16px 45px 19px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.313;
  color: var(--black-color);
  border-radius: 0;
  border-bottom: 1px solid var(--gray-color);
  font-family: "Manrope-SemiBold";
}
.widget_nav_menu a img,
.widget_nav_menu a i,
.widget_meta a img,
.widget_meta a i,
.widget_pages a img,
.widget_pages a i,
.widget_archive a img,
.widget_archive a i,
.widget_categories a img,
.widget_categories a i,
.wp-block-categories a img,
.wp-block-categories a i {
  margin-right: 8px;
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li,
.wp-block-categories li {
  display: block;
  position: relative;
}
.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a,
.wp-block-categories li:last-child a {
  border-bottom: none;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span,
.wp-block-categories li > span {
  color: var(--body-color);
  text-align: center;
  position: absolute;
  right: 0;
  top: 14px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  pointer-events: none;
  min-width: 20px;
  text-align: right;
}
.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover,
.wp-block-categories a:hover {
  color: var(--theme-color);
}
.widget_nav_menu a:hover ~ span,
.widget_meta a:hover ~ span,
.widget_pages a:hover ~ span,
.widget_archive a:hover ~ span,
.widget_categories a:hover ~ span,
.wp-block-categories a:hover ~ span {
  color: var(--theme-color);
}
.widget_nav_menu .children li a,
.widget_meta .children li a,
.widget_pages .children li a,
.widget_archive .children li a,
.widget_categories .children li a,
.wp-block-categories .children li a {
  border-bottom: 1px solid var(--gray-color);
}
.wp-block-categories {
  list-style: none;
  padding: 0;
}
.wp-block-categories > li:last-child > a {
  border-bottom: none;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}
.widget_nav_menu .sub-menu {
  margin-left: 10px;
}
.widget_nav_menu .sub-menu li a {
  border-bottom: 1px solid var(--gray-color);
}
.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}
.th-blog ul.wp-block-archives li {
  margin: 5px 0;
}
.widget {
  padding: var(--widget-padding-y, 30px) var(--widget-padding-x, 30px);
  background-color: var(--white-color);
  border: 1px solid var(--gray-color);
  border-radius: 8px;
  margin-bottom: 40px;
  position: relative;
}
.widget[data-overlay]:before {
  z-index: -1;
}
.widget select,
.widget input,
.widget .form-select,
.widget .form-control,
.widget textarea {
  background-color: transparent;
  border-radius: 32px;
}
.widget select:focus,
.widget input:focus,
.widget .form-select:focus,
.widget .form-control:focus,
.widget textarea:focus {
  border-color: var(--theme-color);
  color: var(--white-color);
}
.widget select::-moz-placeholder,
.widget input::-moz-placeholder,
.widget .form-select::-moz-placeholder,
.widget .form-control::-moz-placeholder,
.widget textarea::-moz-placeholder {
  color: var(--white-color);
}
.widget select::-webkit-input-placeholder,
.widget input::-webkit-input-placeholder,
.widget .form-select::-webkit-input-placeholder,
.widget .form-control::-webkit-input-placeholder,
.widget textarea::-webkit-input-placeholder {
  color: var(--white-color);
}
.widget select:-ms-input-placeholder,
.widget input:-ms-input-placeholder,
.widget .form-select:-ms-input-placeholder,
.widget .form-control:-ms-input-placeholder,
.widget textarea:-ms-input-placeholder {
  color: var(--white-color);
}
.widget select::-webkit-input-placeholder,
.widget input::-webkit-input-placeholder,
.widget .form-select::-webkit-input-placeholder,
.widget .form-control::-webkit-input-placeholder,
.widget textarea::-webkit-input-placeholder {
  color: var(--white-color);
}
.widget select::-moz-placeholder,
.widget input::-moz-placeholder,
.widget .form-select::-moz-placeholder,
.widget .form-control::-moz-placeholder,
.widget textarea::-moz-placeholder {
  color: var(--white-color);
}
.widget select:-ms-input-placeholder,
.widget input:-ms-input-placeholder,
.widget .form-select:-ms-input-placeholder,
.widget .form-control:-ms-input-placeholder,
.widget textarea:-ms-input-placeholder {
  color: var(--white-color);
}
.widget select::-ms-input-placeholder,
.widget input::-ms-input-placeholder,
.widget .form-select::-ms-input-placeholder,
.widget .form-control::-ms-input-placeholder,
.widget textarea::-ms-input-placeholder {
  color: var(--white-color);
}
.widget select::placeholder,
.widget input::placeholder,
.widget .form-select::placeholder,
.widget .form-control::placeholder,
.widget textarea::placeholder {
  color: var(--white-color);
}
.widget textarea {
  min-height: 120px;
}
.widget .form-group > i {
  background-color: var(--white-color);
}
.widget .form-group input[type="checkbox"] ~ label:before {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  border-radius: 6px;
}
.widget .form-group label {
  color: var(--body-color);
  padding-left: 16px;
  text-align: left;
}
.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  font-family: var(--title-font);
  line-height: 1em;
  margin: -0.12em 0 30px 0;
}
.wp-block-search__label,
.widget_title {
  position: relative;
  padding-bottom: 17px;
  margin: -0.12em 0 30px 0;
}
.wp-block-search__label:after,
.wp-block-search__label:before,
.widget_title:after,
.widget_title:before {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--th-border-color);
  position: absolute;
  bottom: 0;
  left: 0;
}
.wp-block-search__label:after,
.widget_title:after {
  background-color: var(--theme-color);
  width: 30px;
}
.widget-form {
  --bs-gutter-x: 20px;
}
.widget_search {
  background-color: #e9f6f9;
  border: 1px solid transparent;
}
.widget_price_filter {
  padding: 40px 30px;
}
.widget .search-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.widget .search-form input {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: none;
  border-radius: 100px;
  padding-right: 25px;
}
.widget .search-form button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 16px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
}
.widget .search-form button:hover {
  background-color: var(--title-color);
}
.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  border: none;
  line-height: 1;
  padding: 9.5px 14px;
  margin-right: 2px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--black-color);
  font-size: 16px;
  font-family: var(--body-font);
  font-weight: 400;
  background-color: #e9f6f9;
  border-radius: 4px;
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
}
.wp-block-tag-cloud,
.tagcloud {
  margin-bottom: -10px;
}
.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 312px;
}
@media (max-width: 991px) {
  .sidebar-gallery {
    max-width: 100%;
  }
}
.sidebar-gallery .gallery-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  /* color: var(--white-color); */
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
}
.sidebar-gallery .gallery-btn:hover {
  color: var(--theme-color);
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
}
.sidebar-gallery .gallery-thumb:before {
  content: "";
  height: auto;
  width: 0;
  background-color: var(--title-color);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.sidebar-gallery .gallery-thumb img {
  width: 100%;
  border-radius: 4px;
}
.sidebar-gallery .gallery-thumb:hover:before {
  opacity: 0.8;
  padding-top: 135%;
  width: 135%;
  border-radius: 35%;
}
.sidebar-gallery .gallery-thumb:hover .gallery-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sidebar-gallery .wp-block-image {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  width: 100% !important;
}
.sidebar-gallery .wp-block-image img {
  width: 100%;
}
.recent-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .media-img {
  margin-right: 15px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.recent-post .media-img img {
  width: 100%;
  border-radius: 4px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.recent-post .media-img:after {
  content: "\f0c1";
  font-family: var(--icon-font);
  position: absolute;
  inset: 0;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--theme-color);
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.recent-post .post-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: var(--body-font);
  color: var(--black-color);
  text-transform: capitalize;
}
.recent-post .recent-post-meta {
  margin-bottom: 12px;
}
.recent-post .recent-post-meta a {
  text-transform: capitalize;
  font-size: 16px;
  color: var(--body-color);
  font-family: var(--body-font);
}
.recent-post .recent-post-meta a i {
  margin-right: 6px;
  color: var(--body-color);
}
.recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}
.recent-post:hover .media-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.recent-post:hover .media-img:after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  border-radius: 15%;
}
.widget_offer {
  padding: 72px 60px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .widget_offer {
    padding: 40px;
  }
}
@media (max-width: 991px) {
  .widget_offer {
    margin-bottom: 40px;
  }
}
.widget_offer .offer-banner {
  text-align: center;
}
.widget_offer .offer-banner .sub-title {
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
  letter-spacing: 0;
  margin-top: -0.4rem;
  display: block;
}
.widget_offer .offer-banner .sub-title:before,
.widget_offer .offer-banner .sub-title:after {
  display: none;
}
.widget_offer .offer-banner .box-title {
  color: var(--white-color);
  margin: -0.4rem 0px 22px;
}
.widget_offer .offer-banner .offer-text {
  font-family: var(--title-font);
  margin-bottom: 20px;
}
.widget_offer .offer-banner .th-btn {
  margin-top: 14px;
  padding: 14px 35px;
}
.widget_offer .banner-logo {
  background-color: var(--white-color);
  border-radius: 48px;
  padding: 12px 34px;
  max-width: 264px;
  display: block;
  margin: auto;
}
.widget_offer .offer-title {
  color: var(--white-color);
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 2px;
}
.widget_offer .offter-num {
  font-weight: 400;
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 0px;
}
.tour-booking .widget_subtitle {
  color: var(--title-color);
}
.tour-booking .widget_price {
  font-size: 28px;
  font-weight: 600;
  color: var(--title-color);
}
.tour-booking .th-btn {
  padding: 12px 24px;
  margin-bottom: 24px;
}
.tour-booking .review {
  display: block;
  font-size: 14px;
}
.tour-booking .review i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  background-color: var(--smoke-color);
  color: var(--theme-color);
  margin-right: 8px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.tour-booking .review i:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.sidebar-area {
  margin-left: 10px;
  margin-bottom: -10px;
}
.sidebar-area.style2 .widget {
  padding: var(--widget-padding-y, 30px) var(--widget-padding-x, 16px);
}
.sidebar-area.style3 .widget {
  padding: var(--widget-padding-y, 24px) var(--widget-padding-x, 24px);
}
.sidebar-area.style3 .widget.tour-booking {
  padding: var(--widget-padding-y, 48px) var(--widget-padding-x, 24px);
}
@media (max-width: 991px) {
  .sidebar-area {
    margin-left: 0;
  }
}
.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}
.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}
.sidebar-area select,
.sidebar-area input {
  background-color: var(--white-color);
}
.sidebar-area .widget_categories.style2 ul {
  margin: 0px 0 -15px 0;
}
.sidebar-area .wp-block-categories {
  margin: -20px 0 -20px 0;
}
.sidebar-area .sidebar-gallery {
  margin-bottom: 0 !important;
}
.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}
.sidebar-area .wp-block-search__label,
.sidebar-area .wp-block-group__inner-container h2 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1em;
  padding-bottom: 20px;
  margin: -0.12em 0 30px 0;
}
.sidebar-area .wp-block-search__label:after,
.sidebar-area .wp-block-search__label:before,
.sidebar-area .wp-block-group__inner-container h2:after,
.sidebar-area .wp-block-group__inner-container h2:before {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--th-border-color);
  position: absolute;
  bottom: 0;
  left: 0;
}
.sidebar-area .wp-block-search__label:after,
.sidebar-area .wp-block-group__inner-container h2:after {
  background-color: var(--theme-color);
  width: 40px;
}
.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}
.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.author-widget-wrap {
  position: relative;
  background-color: var(--smoke-color);
  padding: 30px 40px 30px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  border-radius: 15px;
}
@media (max-width: 1199px) {
  .author-widget-wrap {
    display: block;
  }
}
@media (max-width: 375px) {
  .author-widget-wrap {
    padding: 25px;
  }
}
.author-widget-wrap.style2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .author-widget-wrap.style2 {
    display: block;
  }
}
.author-widget-wrap .avater-content {
  position: relative;
}
.author-widget-wrap .avater {
  min-width: 189px;
  border-radius: 15px;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .author-widget-wrap .avater {
    min-width: 100%;
    margin-bottom: 15px;
  }
}
.author-widget-wrap .avater img {
  border-radius: 15px;
  width: 100%;
}
@media (max-width: 1199px) {
  .author-widget-wrap .avater img {
    width: 100%;
  }
}
.author-widget-wrap .name {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: var(--title-color);
  margin-bottom: 0px;
}
.author-widget-wrap .name a {
  color: inherit;
}
.author-widget-wrap .name a:hover {
  color: var(--theme-color);
}
.author-widget-wrap .text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color);
  margin-bottom: 25px;
}
.author-widget-wrap .author-info {
  text-align: left;
  padding-right: 20px;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .author-widget-wrap .author-info {
    padding: 10px 0;
    margin-bottom: 0;
  }
}
.author-widget-wrap .author-bio {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--body-color);
  margin-bottom: 0px;
}
.author-widget-wrap .author-social {
  display: block;
  position: absolute;
  top: 20px;
  right: 40px;
}
@media (max-width: 575px) {
  .author-widget-wrap .author-social {
    right: 0px;
  }
}
@media (max-width: 450px) {
  .author-widget-wrap .author-social {
    display: none;
  }
}
.author-widget-wrap .author-social a {
  font-size: 14px;
  color: var(--title-color);
  position: relative;
  margin-right: 10px;
}
.author-widget-wrap .author-social a:last-child {
  margin-right: 0;
  border-right: none;
}
.author-widget-wrap .author-social a:hover {
  color: var(--theme-color);
}
.donwload-media-wrap {
  margin-top: -4px;
  margin-bottom: -4px;
}
.donwload-media-wrap .download-media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--white-color);
  padding: 8px 8px 8px 20px;
  box-shadow: 0px 2px 4px 0px rgba(2, 29, 53, 0.1);
  margin-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
}
.donwload-media-wrap .download-media:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.donwload-media-wrap .download-media_icon {
  margin-right: 15px;
}
@media (max-width: 375px) {
  .donwload-media-wrap .download-media_icon {
    margin-right: 10px;
  }
}
.donwload-media-wrap .download-media_icon i {
  font-size: 16px;
  color: var(--theme-color);
}
.donwload-media-wrap .download-media_title {
  color: var(--title-color);
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: -4px;
}
@media (max-width: 375px) {
  .donwload-media-wrap .download-media_title {
    font-size: 14px;
    line-height: 24px;
  }
}
.donwload-media-wrap .download-media_text {
  color: var(--body-color);
  font-size: 14px;
}
.donwload-media-wrap .download-media_btn {
  margin-left: auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
}
.donwload-media-wrap .download-media_btn:hover {
  background: var(--title-color);
}
.widget_call {
  background-color: var(--theme-color);
  border-radius: 20px;
  position: relative;
  z-index: 2;
}
.widget-map iframe {
  min-height: 280px;
}
.widget-call .box-title {
  margin-bottom: 5px;
}
.widget-call .info-box_link {
  font-size: 18px;
  color: var(--white-color);
}
.widget-call .info-box_link:hover {
  color: var(--white-color);
}
.widget-call .info-box_icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: var(--theme-color);
  border: 1px solid var(--white-color);
  color: var(--white-color);
  border-radius: 50%;
  text-align: center;
  margin-right: 14px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget-call .info-box_icon:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.widget-call .info-box_subtitle {
  color: var(--white-color);
  text-transform: capitalize;
  margin-bottom: 5px;
}
.widget_info {
  padding: 30px;
}
.info-list {
  margin-top: -16px;
}
.info-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.info-list i {
  color: var(--theme-color);
  width: 16px;
  margin-right: 2px;
  font-size: 16px;
}
.info-list strong {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color2);
  display: block;
  margin-bottom: 0;
}
.info-list li {
  border: 1px solid #e1e4e5;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 24px;
}
.info-list li span {
  font-size: 14px;
  color: var(--body-color);
}
.price_slider_wrapper .price_label span {
  display: inline-block;
  color: var(--body-color);
}
.price_slider_wrapper .ui-slider {
  height: 8px;
  position: relative;
  width: 100%;
  background-color: transparent;
  border: 1px solid #e1e4e5;
  margin-top: 15px;
  border-radius: 8px;
  cursor: pointer;
}
.price_slider_wrapper .ui-slider-range {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: var(--theme-color);
}
.price_slider_wrapper .ui-slider-handle {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  cursor: pointer;
  position: absolute;
  margin-top: -5px;
  z-index: 2;
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  -webkit-transform: translateX(-1px);
  -ms-transform: translateX(-1px);
  transform: translateX(-1px);
}
.price_slider_wrapper .ui-slider-handle:focus {
  outline: none;
  box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
}
.price_slider_wrapper .ui-slider-handle:last-child {
  -webkit-transform: translateX(-9px);
  -ms-transform: translateX(-9px);
  transform: translateX(-9px);
}
.price_slider_wrapper button,
.price_slider_wrapper .button {
  position: relative;
  background-color: transparent;
  color: var(--theme-color);
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  text-align: center;
  border: none;
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding: 0;
  font-size: 14px;
  -webkit-transition: 0.4s ease-in;
  transition: 0.4s ease-in;
}
.price_slider_wrapper button:before,
.price_slider_wrapper .button:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 43px;
  height: 2px;
  background-color: var(--theme-color);
}
.price_slider_wrapper button:hover,
.price_slider_wrapper .button:hover {
  background-color: transparent;
}
.widget_shopping_cart .widget_title {
  margin-bottom: 35px;
  border-bottom: none;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
}
.widget_shopping_cart ul li {
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  padding: 30px 30px 30px 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  text-align: left;
}
.widget_shopping_cart .mini_cart_item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.widget_shopping_cart .cart_list a:not(.remove) {
  display: block;
  color: var(--body-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
  font-weight: 600;
  color: var(--title-color);
}
.widget_shopping_cart .cart_list a:not(.remove):hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list a.remove {
  position: absolute;
  top: 50%;
  left: 95%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--body-color);
}
.widget_shopping_cart .cart_list a.remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list img {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 0;
  top: 18px;
}
.widget_shopping_cart .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: top;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}
.widget_shopping_cart .total {
  margin-top: 20px;
  font-size: 18px;
  color: var(--title-color);
  font-family: var(--body-font);
}
.widget_shopping_cart .total strong {
  font-family: var(--title-font);
}
.widget_shopping_cart .amount {
  padding-left: 5px;
}
.widget_shopping_cart .th-btn {
  margin-right: 15px;
}
.widget_shopping_cart .th-btn:last-child {
  margin-right: 0;
}
.th-video-widget .video-thumb {
  position: relative;
}
.th-video-widget .play-btn {
  --icon-size: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.th-video-widget .play-btn i {
  font-size: 16px;
}
.th-video-widget .video-thumb-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: -0.3em;
}
.widget_recent_entries ul {
  margin: -0.3em 0 0 0;
  padding: 0;
  list-style: none;
}
.widget_recent_entries ul li > a {
  color: var(--body-color);
  font-weight: 500;
  display: inline-block;
}
.widget_recent_entries ul li > a:hover {
  color: var(--theme-color);
}
.widget_recent_entries ul li span.post-date {
  font-size: 14px;
}
.widget_recent_entries ul li:not(:last-child) {
  border-bottom: 1px solid #dadada;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.widget_recent_comments ul,
.wp-block-latest-comments ul {
  list-style-type: none;
  padding-left: 0;
}
ul.widget_recent_comments,
ol.widget_recent_comments,
.wp-block-latest-comments {
  margin-top: -0.11em;
  padding-left: 0;
}
.widget_recent_comments ol,
.widget_recent_comments ul,
.wp-block-latest-comments ol,
.wp-block-latest-comments ul {
  margin-bottom: 0;
}
.widget_recent_comments li,
.wp-block-latest-comments li {
  margin-bottom: 0;
  color: var(--body-color);
  padding-left: 30px;
  position: relative;
}
.widget_recent_comments li:before,
.wp-block-latest-comments li:before {
  content: "\f086";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--theme-color);
  font-family: var(--icon-font);
}
.widget_recent_comments.has-avatars li,
.wp-block-latest-comments.has-avatars li {
  padding-left: 0;
  padding-bottom: 0 !important;
}
.widget_recent_comments.has-avatars li:before,
.wp-block-latest-comments.has-avatars li:before {
  display: none;
}
.widget_recent_comments .avatar,
.wp-block-latest-comments .avatar {
  margin-top: 0.4em;
}
.widget_recent_comments li:not(:last-child),
.wp-block-latest-comments li:not(:last-child) {
  padding-bottom: 12px;
}
.widget_recent_comments article,
.wp-block-latest-comments article {
  line-height: 1.5;
}
.widget_recent_comments a,
.wp-block-latest-comments a {
  color: inherit;
}
.widget_recent_comments a:hover,
.wp-block-latest-comments a:hover {
  color: var(--theme-color);
}
.wp-block-latest-comments__comment {
  line-height: 1.6;
}
.wp-block-latest-comments__comment a {
  color: var(--body-color);
}
.wp-block-latest-comments__comment a:hover {
  color: var(--theme-color);
}
.wp-block-latest-comments__comment:last-child {
  margin-bottom: 0;
}
.wp-block-calendar tbody td,
.wp-block-calendar th {
  padding: 10px;
}
.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}
.wp-block-calendar span[class*="wp-calendar-nav"],
.calendar_wrap span[class*="wp-calendar-nav"] {
  position: absolute;
  top: 9px;
  left: 20px;
  font-size: 14px;
  color: var(--white-color);
  font-weight: 400;
  z-index: 1;
  line-height: 1.7;
}
.wp-block-calendar span[class*="wp-calendar-nav"] a,
.calendar_wrap span[class*="wp-calendar-nav"] a {
  color: inherit;
}
.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}
.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--white-color);
  background-color: var(--theme-color);
}
.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: var(--title-color);
  font-weight: 500;
}
.wp-block-calendar th:first-child,
.calendar_wrap th:first-child {
  border-left: 1px solid #eee;
}
.wp-block-calendar th:last-child,
.calendar_wrap th:last-child {
  border-right: 1px solid #eee;
}
.wp-block-calendar table th,
.calendar_wrap table th {
  font-weight: 500;
}
.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px 5px;
  color: #01133c;
  border: 1px solid #eee;
  text-align: center;
  background-color: transparent;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}
.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #fff;
}
.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}
.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}
.wp-block-calendar a,
.calendar_wrap a {
  color: inherit;
  text-decoration: none;
}
.wp-block-calendar a:hover,
.calendar_wrap a:hover {
  color: var(--title-color);
}
.wp-block-calendar {
  margin-bottom: 30px;
  border: none;
  padding-bottom: 0;
}
.wp-block-calendar table caption {
  color: var(--white-color);
}
ul.widget_rss,
ul.wp-block-rss,
ol.widget_rss,
ol.wp-block-rss {
  padding-left: 0;
}
.widget_rss,
.wp-block-rss {
  list-style-type: none;
}
.widget_rss ul,
.wp-block-rss ul {
  margin: -0.2em 0 -0.5em 0;
  padding: 0;
  list-style: none;
}
.widget_rss ul .rsswidget,
.wp-block-rss ul .rsswidget {
  color: var(--title-color);
  font-family: var(--theme-font);
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}
.widget_rss ul .rssSummary,
.wp-block-rss ul .rssSummary {
  font-size: 14px;
  margin-bottom: 7px;
  line-height: 1.5;
}
.widget_rss ul a,
.wp-block-rss ul a {
  display: block;
  font-weight: 600;
  color: inherit;
}
.widget_rss ul a:hover,
.wp-block-rss ul a:hover {
  color: var(--theme-color);
}
.widget_rss ul .rss-date,
.wp-block-rss ul .rss-date {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--title-color);
}
.widget_rss ul .rss-date:before,
.wp-block-rss ul .rss-date:before {
  content: "\f073";
  font-family: var(--icon-font);
  margin-right: 10px;
  font-weight: 300;
  color: var(--theme-color);
}
.widget_rss ul cite,
.wp-block-rss ul cite {
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 14px;
}
.widget_rss ul cite:before,
.wp-block-rss ul cite:before {
  content: "";
  position: relative;
  top: -1px;
  left: 0;
  width: 20px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  background-color: var(--theme-color);
}
.widget_rss li:not(:last-child),
.wp-block-rss li:not(:last-child) {
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
}
.widget_rss a:hover,
.wp-block-rss a:hover {
  color: var(--theme-color);
}
.textwidget {
  margin-top: -0.1em;
}
.sidebar-area .widget_shopping_cart .th-btn {
  margin-right: 10px;
  padding: 8px 22px;
  font-size: 14px;
}
@media (min-width: 1200px) {
  .sidebar-shop .widget {
    padding: 30px;
  }
}
@media (min-width: 992px) {
  .sidebar-shop .widget {
    margin-bottom: 24px;
  }
}
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }
  .widget_title {
    font-size: 22px;
    margin: -0.12em 0 28px 0;
  }
  .author-widget-wrap .name {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 30px;
  }
  .widget {
    --widget-padding-y: 40px;
    --widget-padding-x: 40px;
  }
}
@media (max-width: 575px) {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }
}
@media (max-width: 330px) {
  .recent-post .post-title {
    font-size: 16px;
    line-height: 24px;
  }
  .recent-post .recent-post-meta a {
    font-size: 12px;
  }
}
.footer-widget {
  margin-bottom: 50px;
}
.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}
.footer-widget .form-group > i {
  color: var(--theme-color);
  top: 18px;
}
.footer-widget .widget_title {
  padding: 0;
  margin: -0.12em 0 30px 0;
}
.footer-widget .widget_title:before,
.footer-widget .widget_title:after {
  display: none;
}
.footer-widget.widget_meta ul,
.footer-widget.widget_pages ul,
.footer-widget.widget_archive ul,
.footer-widget.widget_categories ul,
.footer-widget.widget_nav_menu ul {
  margin-top: -4px;
}
.footer-widget.widget_meta .menu,
.footer-widget.widget_meta > ul,
.footer-widget.widget_pages .menu,
.footer-widget.widget_pages > ul,
.footer-widget.widget_archive .menu,
.footer-widget.widget_archive > ul,
.footer-widget.widget_categories .menu,
.footer-widget.widget_categories > ul,
.footer-widget.widget_nav_menu .menu,
.footer-widget.widget_nav_menu > ul {
  margin-bottom: -4px;
}
.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 21px;
  font-family: var(--body-font);
  color: #888c97;
  display: block;
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 0 0 20px;
  background-color: transparent;
  border-bottom: none;
  position: relative;
}
.footer-widget.widget_meta a:before,
.footer-widget.widget_pages a:before,
.footer-widget.widget_archive a:before,
.footer-widget.widget_categories a:before,
.footer-widget.widget_nav_menu a:before {
  content: "\f105";
  position: absolute;
  font-weight: 600;
  left: 0;
  top: 2px;
  font-family: var(--icon-font);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  color: inherit;
}
.footer-widget.widget_meta a:hover,
.footer-widget.widget_pages a:hover,
.footer-widget.widget_archive a:hover,
.footer-widget.widget_categories a:hover,
.footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
  box-shadow: none;
}
.footer-widget.widget_meta a:hover:before,
.footer-widget.widget_pages a:hover:before,
.footer-widget.widget_archive a:hover:before,
.footer-widget.widget_categories a:hover:before,
.footer-widget.widget_nav_menu a:hover:before {
  color: var(--theme-color);
}
.footer-widget.widget_meta li > span,
.footer-widget.widget_pages li > span,
.footer-widget.widget_archive li > span,
.footer-widget.widget_categories li > span,
.footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}
.footer-widget.widget_meta li:last-child a,
.footer-widget.widget_pages li:last-child a,
.footer-widget.widget_archive li:last-child a,
.footer-widget.widget_categories li:last-child a,
.footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}
.footer-widget .recent-post {
  max-width: 300px;
  margin-top: -0.4em;
  margin-bottom: 20px;
}
.footer-widget .recent-post .media-img {
  max-width: 74px;
}
.footer-widget .recent-post .media-img:after {
  line-height: 74px;
}
.footer-widget .recent-post .post-title {
  color: var(--white-color);
  font-weight: 500;
}
.footer-widget .recent-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.footer-widget .recent-post .recent-post-meta a {
  font-weight: 400;
  line-height: 1.2;
}
.footer-widget .recent-post .recent-post-meta i {
  color: var(--theme-color);
}
.footer-widget .recent-post .recent-post-meta a:hover i {
  color: var(--theme-color);
}
.about-logo {
  margin-bottom: 15px;
}
.th-widget-about .about-logo {
  margin-bottom: 33px;
}
.th-widget-about .about-text {
  color: #666666;
  margin-bottom: 20px;
  margin-top: -0.5em;
}
.th-widget-about .footer-info {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #888c97;
  line-height: 26px;
  padding: 0 0 0 35px;
  max-width: 277px;
  margin-top: 0;
}
.th-widget-about .footer-info:not(:last-child) {
  margin: 0 0 35px 0;
}
.th-widget-about .footer-info i {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  position: absolute;
  left: 0;
  font-size: 10px;
}
.th-widget-about .footer-info-title {
  font-size: 18px;
  font-family: var(--title-font);
  font-weight: 600;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.footer-text {
  margin-top: -0.5em;
  margin-bottom: 25px;
}
.social-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.social-box .social-title {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 0;
}
.icon-group a {
  color: var(--white-color);
  font-size: 18px;
  margin-right: 17px;
}
.icon-group a:last-child {
  margin-right: 0;
}
@media (max-width: 1199px) {
  .footer-widget.widget_meta a,
  .footer-widget.widget_pages a,
  .footer-widget.widget_archive a,
  .footer-widget.widget_categories a,
  .footer-widget.widget_nav_menu a {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .footer-widget .widget_title {
    margin-bottom: 35px;
  }
  .th-widget-about .about-text {
    margin-bottom: 20px;
  }
  .social-box.mb-30 {
    margin-bottom: 25px;
  }
}
.th-header {
  position: relative;
  z-index: 41;
}
.th-header .icon-btn {
  border-radius: 99px;
}
.th-header .menu-area {
  position: relative;
  z-index: 2;
}
.info-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.info-card_icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  color: var(--theme-color);
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.info-card_icon i {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.info-card_icon img {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.info-card_icon:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.info-card_icon:hover i {
  color: var(--white-color);
}
.info-card_icon:hover img {
  -webkit-filter: brightness(9);
  filter: brightness(9);
}
.info-card-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px 80px;
}
@media (max-width: 1299px) {
  .info-card-wrap {
    gap: 15px 40px;
  }
}
@media (max-width: 767px) {
  .info-card-wrap {
    display: block;
  }
}
@media (max-width: 767px) {
  .info-card {
    margin-bottom: 20px;
  }
}
.info-card_icon {
  color: var(--theme-color);
}
.info-card_icon i {
  background-color: var(--icon-bg, #fff);
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  color: var(--theme-color);
  font-weight: 500;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.info-card_icon i:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.info-card_text {
  font-family: var(--body-font);
  font-size: 14px;
  color: var(--body-color);
  margin-bottom: 0;
}
.info-card_link {
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 600;
  color: var(--title-color);
  margin-bottom: 0;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.info-card_link:hover {
  color: var(--theme-color);
}
.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.sticky-wrapper {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.sticky-wrapper.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.07));
  -webkit-animation: stickyAni 0.4s ease-in-out;
  animation: stickyAni 0.4s ease-in-out;
}
@-webkit-keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
    transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
    transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}
@keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
    transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
    transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}
.main-menu a {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--black-color2);
  text-transform: capitalize;
  text-decoration: none;
  font-family: Manrope-SemiBold !important;
}
.main-menu a:hover {
  color: var(--theme-color);
}
.main-menu > ul > li {
  margin: 0 14px;
}
.main-menu > ul > li > a {
  padding: 32.5px 0;
}
.main-menu > ul > li > a:hover {
  color: var(--theme-color);
}
/* .main-menu ul {
  margin: 0;
  padding: 0;
} */
.main-menu ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
/* .main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
} */
.main-menu ul li {
  padding: 10px 20px;
}
.main-menu ul li.menu-item-has-children > a:after {
  content: "\f107";
  display: inline-block;
  position: relative;
  font-family: var(--icon-font);
  margin-left: 4px;
  font-weight: 600;
  top: 0;
  font-size: 0.9em;
  color: var(--black-color2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f106";
  color: var(--theme-color);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.main-menu ul li:last-child {
  margin-right: 0 !important;
}
.main-menu ul li:first-child {
  margin-left: 0 !important;
}
.main-menu ul li:hover > ul.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  z-index: 9;
}
.main-menu ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1) translateX(0%);
  -ms-transform: scaleY(1) translateX(0%);
  transform: scaleY(1) translateX(0%);
  z-index: 9;
}
.main-menu ul.sub-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  visibility: hidden;
  min-width: 230px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  left: -14px;
  opacity: 0;
  z-index: -1;
  box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
  border-radius: 0;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  border-bottom: 3px solid var(--theme-color);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.main-menu ul.sub-menu a {
  font-size: 16px;
  line-height: 30px;
}
.main-menu ul.sub-menu {
  padding: 18px 20px 18px 18px;
  left: -27px;
}
.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 0px 9px;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:after {
  content: "\2b";
  float: right;
  top: 1px;
  display: inline-block;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.main-menu ul.sub-menu li a {
  position: relative;
  padding-left: 0;
  text-transform: capitalize;
}
.main-menu ul.sub-menu li a:before {
  content: "\f5b0";
  position: absolute;
  top: 6px;
  left: 10px;
  font-family: var(--icon-font);
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 900;
  opacity: 0;
  visibility: visible;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.main-menu ul.sub-menu li a:hover {
  padding-left: 23px;
}
.main-menu ul.sub-menu li a:hover:before {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}
.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}
.main-menu .mega-menu-wrap {
  position: static;
}
@media (max-width: 1500px) {
  .main-menu > ul > li {
    margin: 0 13px;
  }
}
.simple-icon {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 24px;
  position: relative;
}
.simple-icon:has(.badge) {
  padding-right: 8px;
}
.simple-icon .badge {
  top: -8px;
  right: 0;
  font-size: 12px;
}
.header-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-button .th-btn {
  margin-left: 15px;
  padding: 15px 35px;
}
@media (max-width: 1299px) {
  .header-button .th-btn {
    display: none;
  }
}
.header-button .icon-btn .badge {
  font-size: 12px;
  top: 0;
  right: 0;
}
.header-button .icon-btn:hover .badge {
  background-color: var(--title-color);
}
.social-links .social-title {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  margin: 0 10px 0 0;
  font-family: var(--body-font);
  color: var(--body-color);
}
@media (max-width: 1599px) {
  .social-links .social-title {
    display: none;
  }
}
.social-links a {
  font-size: 14px;
  display: inline-block;
  color: var(--body-color);
  margin: 0 15px 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.social-links a:last-child {
  margin-right: 0 !important;
}
.social-links a:hover {
  color: var(--theme-color);
}
.social-links a:hover i {
  -webkit-animation: slideTop 0.5s;
  animation: slideTop 0.5s;
}
.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-links li {
  display: inline-block;
  position: relative;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 500;
}
.header-links li:not(:last-child) {
  margin: 0 46px 0 0;
}
.header-links li:not(:last-child):after {
  content: "";
  height: 14px;
  width: 1px;
  background-color: #999999;
  position: absolute;
  top: 5px;
  right: -27px;
}
.header-links li > i {
  color: var(--black-color2);
  margin-right: 8px;
}
.header-links li,
.header-links span,
.header-links p,
.header-links a {
  color: var(--black-color2);
}
.header-links a:hover {
  color: var(--theme-color);
}
.header-links b,
.header-links strong {
  font-weight: 600;
  margin-right: 6px;
}
.header-links .social-links a {
  font-size: 14px;
}
.header-notice {
  margin: 0;
}
@media (max-width: 1199px) {
  .header-notice {
    display: none;
  }
}
.header-top {
  padding: 10px 0;
  --body-color: $block-color2;
}
.header-top a:hover {
  color: var(--theme-color);
}
.dropdown-link {
  position: relative;
  border: 1px solid var(--light-color);
  border-radius: 100px;
  padding: 3px 12px;
  max-width: 100px;
}
.dropdown-link .dropdown-toggle {
  font-family: var(--body-font);
  background: none;
  border: none;
  width: 100%;
  color: var(--black-color2);
  font-weight: 400;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
}
@media (max-width: 375px) {
  .dropdown-link .dropdown-toggle {
    font-size: 12px;
  }
}
.dropdown-link .dropdown-menu {
  position: absolute;
  right: 0;
  border-bottom: 2px solid var(--theme-color);
  border-radius: 0;
}
.dropdown-link .gtranslate_wrapper a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--title-font);
  padding: 5px 10px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 991px) {
  .header-default.unittest-header .menu-area {
    padding: 14px 0;
  }
}
.header-layout1 {
  background-color: var(--white-color);
}
.header-layout1 .header-top {
  position: relative;
  padding: 6px 0;
  border-bottom: 1px solid #e1e4e5;
  z-index: 2;
}
.header-layout1 .header-top .header-notice {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.header-layout1 .header-top .header-notice i {
  font-size: 14px;
  margin-right: 8px;
}
.header-layout1 .menu-item.menu-item-type-custom a:after {
  content: "\f107";
  display: inline-block;
  position: relative;
  font-family: var(--icon-font);
  margin-left: 4px;
  font-weight: 600;
  top: 0;
  font-size: 0.9em;
  color: var(--black-color2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.header-layout1 .menu-item.menu-item-type-custom a:hover:after,
.header-layout1 .menu-item.menu-item-type-custom a.active:after {
  content: "\f106";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.header-layout1 .menu-item.menu-item-type-custom .sub-menu li a:after {
  display: none;
}
.header-layout1 .menu-item.menu-item-type-custom .mega-menu-title a:after {
  display: none;
}
.header-layout1 .menu-item.menu-item-type-custom .th-btn:after {
  display: none;
}
.header-layout1 .main-menu > ul > li > a:before {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  background: var(--black-color2);
  width: 0%;
  margin-top: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-layout1 .main-menu > ul > li > a:hover,
.header-layout1 .main-menu > ul > li > a.active {
  color: var(--black-color2);
}
.header-layout1 .main-menu > ul > li > a:hover:before,
.header-layout1 .main-menu > ul > li > a.active:before {
  width: 100%;
}
.header-layout1 .main-menu ul li.menu-item-has-children > a:hover:after {
  color: var(--black-color2);
}
.header-layout1 .header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
}
@media (max-width: 1799px) {
  .header-layout1 .header-right {
    margin-right: 0;
  }
}
@media (max-width: 375px) {
  .header-layout1 .header-right {
    gap: 15px;
  }
}
.header-layout1 .header-right .header-links li:not(:last-child) {
  margin: 0 28px 0 0;
}
@media (max-width: 375px) {
  .header-layout1 .header-right .header-links li:not(:last-child) {
    margin: 0 9px 0 0;
  }
}
.header-layout1 .header-right .header-links li:not(:last-child):after {
  top: 7px;
  right: -17px;
}
@media (max-width: 375px) {
  .header-layout1 .header-right .header-links li:not(:last-child):after {
    right: -6px;
  }
}
@media (max-width: 375px) {
  .header-layout1 .header-right .header-links li {
    font-size: 12px;
  }
}
.header-layout1 .header-right i {
  margin-left: 5px;
  font-size: 14px;
}
.header-layout1 .sticky-wrapper.sticky .menu-area {
  background-image: none !important;
}
@media (max-width: 1199px) {
  .header-layout1 .sticky-wrapper .menu-area {
    padding: 12px 0;
  }
}
.header-layout1 .menu-area {
  z-index: 0;
}
.header-layout1 .currency-menu {
  border: 1px solid var(--light-color);
  border-radius: 100px;
  padding: 8px 20px;
  max-width: 140px;
  text-transform: capitalize;
}
.header-layout1 .currency-menu .nice-select {
  min-width: 130px;
}
.header-layout1 .logo-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 19%;
  height: 92px;
  z-index: -1;
  background-color: var(--theme-color);
}
@media (min-width: 1930px) {
  .header-layout1 .logo-bg {
    width: 30%;
    height: 93px;
  }
}
@media (max-width: 1299px) {
  .header-layout1 .logo-bg {
    width: 28%;
  }
}
@media (max-width: 1199px) {
  .header-layout1 .logo-bg {
    width: 40%;
    height: 80px;
  }
}
@media (max-width: 991px) {
  .header-layout1 .logo-bg {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .header-layout1 .logo-bg {
    width: 60%;
  }
}
@media (max-width: 480px) {
  .header-layout1 .logo-bg {
    width: 70%;
  }
}
@media (max-width: 375px) {
  .header-layout1 .logo-bg {
    width: 80%;
  }
}
.header-layout1 .main-menu {
  position: relative;
  padding-left: 70px;
}
@media (min-width: 1930px) {
  .header-layout1 .main-menu {
    padding-left: 150px;
  }
}
@media (max-width: 1399px) {
  .header-layout1 .main-menu {
    padding-left: 40px;
  }
}
.header-layout1 .main-menu > ul > li {
  margin: 0px 21px;
}
@media (max-width: 1500px) {
  .header-layout1 .main-menu > ul > li {
    margin: 0 16px;
  }
}
@media (max-width: 1399px) {
  .header-layout1 .main-menu > ul > li {
    margin: 0 12px;
  }
}
.header-layout2 .main-menu > ul > li > a {
  padding: 38.5px 0px;
}
.header-layout3 .menu-area {
  position: relative;
  background: rgba(13, 13, 12, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
@media (max-width: 1199px) {
  .header-layout3 .menu-area {
    padding: 14px 0;
  }
}
.header-layout3 .sticky-wrapper.sticky .menu-area {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background-color: var(--black-color2);
}
.header-layout3 .main-menu > ul > li > a:before {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  background: var(--white-color);
  width: 0%;
  margin-top: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-layout3 .main-menu > ul > li > a:hover {
  color: var(--white-color);
}
.header-layout3 .main-menu > ul > li > a:hover:before {
  width: 100%;
}
.header-layout3 .main-menu ul li.menu-item-has-children > a:hover:after {
  color: var(--black-color2);
}
.header-layout3 .header-logo {
  padding: 0 70px;
}
@media (max-width: 1399px) {
  .header-layout3 .header-logo {
    padding: 0 40px;
  }
}
@media (max-width: 1299px) {
  .header-layout3 .header-logo {
    padding: 0 20px;
  }
}
@media (max-width: 375px) {
  .header-layout3 .header-logo {
    padding: 0;
    max-width: 150px;
  }
}
.header-layout3 .header-right-button {
  position: absolute;
  top: 35px;
  right: 72px;
}
@media (max-width: 1599px) {
  .header-layout3 .header-right-button {
    right: 10px;
  }
}
@media (max-width: 1199px) {
  .header-layout3 .header-right-button {
    top: 25px;
    right: 20px;
  }
}
@media (max-width: 1340px) {
  .header-layout3 .header-right-button {
    position: relative;
  }
  .header-layout3 .header-right-button .simple-btn {
    display: none;
  }
}
.header-layout3 .main-menu > ul > li {
  margin: 0px 26px;
}
@media (max-width: 1499px) {
  .header-layout3 .main-menu > ul > li {
    margin: 0 18px;
  }
}
@media (max-width: 1299px) {
  .header-layout3 .main-menu > ul > li {
    margin: 0 14px;
  }
}
.header-layout3 .main-menu > ul > li > a {
  color: var(--white-color);
  padding: 38.5px 0px;
}
.header-layout3 .main-menu > ul > li > a:after {
  color: var(--white-color) !important;
}
.header-layout4 {
  border-bottom: 1px solid var(--title-color);
}
.header-layout4 .logo-bg {
  background-color: var(--title-color);
}
.header-layout4 .menu-right-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 19%;
  height: 92px;
  z-index: -1;
  background-color: var(--title-color);
}
@media (min-width: 1930px) {
  .header-layout4 .menu-right-bg {
    width: 30%;
    height: 93px;
  }
}
@media (max-width: 1299px) {
  .header-layout4 .menu-right-bg {
    display: none;
  }
}
.header-layout4 .th-btn:hover:after {
  background-color: var(--title-color);
}
.header-layout6 {
  padding: 24px 24px 0 24px;
}
@media (max-width: 1500px) {
  .header-layout6 {
    padding: 0;
  }
}
.header-layout6 .sticky-wrapper.sticky .menu-area {
  border-radius: 0px;
}
.header-layout6 .menu-area {
  border-radius: 24px 24px 0 0;
}
@media (max-width: 480px) {
  .header-layout6 .menu-area {
    border-radius: 0;
  }
}
.header-layout7 {
  border-bottom: none;
}
.header-layout7 .logo-bg {
  background-color: var(--theme-color);
}
.header-layout7 .menu-right-bg {
  background-color: var(--theme-color);
}
.split-heading-title.animated-character {
  display: inline-block;
  -webkit-animation: fadeInChar 0.5s ease-in-out forwards;
  animation: fadeInChar 0.5s ease-in-out forwards;
}
.animated-character {
  -webkit-animation: animateCharacter 0.5s forwards;
  animation: animateCharacter 0.5s forwards;
}
@-webkit-keyframes animateCharacter {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes animateCharacter {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.title-area {
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.heading-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  white-space: break-spaces;
  gap: 0px 12px;
}
.heading-title .sec-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}
.heading-title .sec-title .split-heading-title {
  -webkit-animation:
    heading-anim-fade-out 2s ease calc(0.05s * var(--char-index)),
    heading-anim-fade-in 3s ease calc(0.05s * var(--char-index));
  animation:
    heading-anim-fade-out 2s ease calc(0.05s * var(--char-index)),
    heading-anim-fade-in 3s ease calc(0.05s * var(--char-index));
}
@-webkit-keyframes heading-anim-fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes heading-anim-fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes heading-anim-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cus-heading-animation-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cus-heading-animation-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.row:after {
  display: none;
}
.footer-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.widget-area {
  padding-top: 0px;
  padding-bottom: 46px;
}
.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.copyright-wrap {
  padding: 18px 0;
  background-color: #df1f2c;
}
.copyright-wrap .copyright-text {
  color: var(--white-color);
}
.copyright-wrap .copyright-text a {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.copyright-wrap .copyright-text a:hover {
  color: var(--theme-color);
}
.copyright-wrap.bg-title .copyright-text a:hover {
  color: var(--theme-color);
}
.newsletter-top {
  border-bottom: 1px solid #e1e4e6;
  margin-bottom: 62px;
  padding-bottom: 50px;
}
.newsletter-top .newsletter-title {
  font-size: 35px;
  font-weight: 600;
  font-family: Amaranth-Bold !important;
}
@media (max-width: 1199px) {
  .newsletter-top .newsletter-title {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .newsletter-top .newsletter-title {
    text-align: center;
  }
}
.newsletter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  max-width: 660px;
  margin-left: auto;
}
@media (max-width: 480px) {
  .newsletter-form {
    display: block;
  }
}
.newsletter-form.style2 input {
  background-color: transparent;
  border: 1px solid var(--white-color);
}
.newsletter-form.style2 input::-moz-placeholder {
  color: var(--white-color);
}
.newsletter-form.style2 input::-webkit-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style2 input:-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style2 input::-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style2 input::placeholder {
  color: var(--white-color);
}
.newsletter-form.style2 input:focus {
  color: var(--white-color);
}
.newsletter-form input {
  border: 1px solid #f9af2a;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 50px;
  border-radius: 35px;
}
.form-control {
  padding: 20px !important;
}
.newsletter-form .th-btn {
  min-width: 227px;
}
@media (max-width: 480px) {
  .newsletter-form .th-btn {
    min-width: 100%;
    margin-top: 20px;
  }
}
.th-widget-contact {
  max-width: 260px;
  margin-bottom: -0.4em;
}
.info-box_text {
  line-height: 1.75em;
  margin-top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
.info-box_text .icon {
  color: var(--body-color);
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 40px;
  display: contents !important;
  height: 40px;
  line-height: 37px;
  background-color: #e9f6f9;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.info-box_text .icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.info-box_text .icon:hover {
  background-color: var(--theme-color);
}
.info-box_text .icon:hover img {
  -webkit-filter: contrast(0) brightness(2);
  filter: contrast(0) brightness(2);
}
.info-box_text .details {
  margin-top: -0.3em;
}
.info-box_text .details p {
  margin-bottom: 0;
}
.info-box_text a {
  color: inherit;
}
.info-box_text a:hover {
  color: var(--theme-color);
}
.info-box_text:not(:last-child) {
  margin-bottom: 18px;
}
.footer-card .title {
  font-weight: 400;
  font-size: 16px;
  color: var(--white-color);
  margin-right: 16px;
}
.footer-layout1 .th-social a {
  margin-right: 10px;
}
.footer-layout1 .copyright-wrap .copyright-text {
  font-size: 14px;
  font-weight: 400;
}
.footer-layout1 .copyright-wrap .copyright-text a {
  color: inherit;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .copyright-text {
    text-align: center;
  }
}
.copyright-text a {
  color: var(--theme-color);
}
.copyright-text a:hover {
  color: var(--white-color);
}
.footer-layout2 {
  --body-color: #e9f6f9;
}
.footer-layout2 .newsletter-top {
  border-bottom: 1px solid rgba(233, 246, 249, 0.2);
}
.footer-layout2 .widget-area {
  padding-bottom: 70px;
}
@media (max-width: 991px) {
  .footer-layout2 .widget-area {
    padding-bottom: 30px;
  }
}
.footer-layout2 .footer-widget .widget_title {
  color: var(--white-color);
}
.footer-layout2 .footer-widget.widget_nav_menu a {
  font-weight: 300;
}
.footer-layout2 .footer-widget.widget_nav_menu a:not(:hover) {
  color: #e9f6f9;
}
.footer-layout2 .about-text {
  color: #e9f6f9;
  font-weight: 300;
  margin-bottom: 22px;
}
.footer-layout2 .th-social a {
  --icon-size: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
  margin-right: 10px;
}
.footer-layout2 .th-social a:hover {
  background-color: var(--theme-color);
}
@media (max-width: 1299px) {
  .footer-layout2 .th-social a {
    margin-right: 5px;
  }
}
.footer-layout2 .info-box_text .icon {
  color: var(--white-color);
  background-color: rgba(255, 255, 255, 0.2);
}
.footer-layout2 .info-box_text .icon:hover {
  background-color: var(--theme-color);
}
.footer-layout2 .info-box_text .icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.footer-layout2 .copyright-wrap {
  background-color: transparent;
  border-top: 1px solid rgba(233, 246, 249, 0.2);
  padding: 18px 0;
}
.footer-layout2 .copyright-wrap .copyright-text {
  font-size: 14px;
}
.footer-layout2 .copyright-wrap .copyright-text a {
  color: var(--white-color);
}
.footer-layout4 .widget_title {
  color: var(--white-color);
}
.footer-layout4 .newsletter-top {
  border-color: rgba(233, 246, 249, 0.3);
}
.footer-layout4 .newsletter-form input {
  border: 1px solid var(--white-color);
  background-color: transparent;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  height: 64px;
  border-radius: 32px;
}
.footer-layout4 .newsletter-form input::-moz-placeholder {
  color: var(--white-color);
}
.footer-layout4 .newsletter-form input::-webkit-input-placeholder {
  color: var(--white-color);
}
.footer-layout4 .newsletter-form input:-ms-input-placeholder {
  color: var(--white-color);
}
.footer-layout4 .newsletter-form input::-ms-input-placeholder {
  color: var(--white-color);
}
.footer-layout4 .newsletter-form input::placeholder {
  color: var(--white-color);
}
.footer-layout4 .newsletter-form input:focus {
  color: var(--white-color);
}
.footer-layout4 .about-text {
  color: #e9f6f9;
}
.footer-layout4 .footer-widget.widget_nav_menu a {
  color: var(--white-color);
}
.footer-layout4 .footer-widget.widget_nav_menu a:before {
  color: var(--white-color);
}
.footer-layout4 .footer-widget.widget_nav_menu a:hover {
  color: #113d48;
}
.footer-layout4 .footer-widget.widget_nav_menu a:hover:before {
  color: #113d48;
}
.footer-layout4 .info-box_text {
  --body-color: #fff;
}
.footer-layout4 .info-box_text_link:hover {
  color: #113d48;
}
.footer-layout4 .info-box_text .icon {
  color: var(--white-color);
  background-color: rgba(255, 255, 255, 0.2);
}
.footer-layout4 .info-box_text .icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.footer-layout4 .sidebar-gallery .gallery-thumb:before {
  background-color: #113d48;
}
.footer-layout4 .info-box_text a:hover {
  color: var(--title-color);
}
.footer-layout4 .th-social a {
  --icon-size: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
  margin-right: 10px;
}
@media (max-width: 1299px) {
  .footer-layout4 .th-social a {
    margin-right: 5px;
  }
}
.footer-layout4 .copyright-wrap {
  background-color: transparent;
  border-top: 1px solid rgba(233, 246, 249, 0.3);
}
.footer-layout4 .copyright-text {
  font-size: 14px;
}
.footer-layout4 .copyright-text a {
  color: var(--white-color);
}
.footer-layout4 .copyright-text a:hover {
  color: var(--title-color);
}
.footer-layout5 .widget-area {
  padding-top: 64px;
  padding-bottom: 64px;
}
.footer-layout6 .widget-area {
  padding-top: 120px;
}
.breadcumb-content {
  position: relative;
}
.breadcumb-menu {
  max-width: 100%;
  padding: 0;
  margin: 25px 0 -0.5em 0;
  list-style-type: none;
  position: relative;
}
.breadcumb-menu li {
  display: inline-block;
  margin-right: 3px;
  padding-right: 3px;
  list-style: none;
  position: relative;
}
.breadcumb-menu li:after {
  content: "";
  background-image: url("./img/icon/arrow-right.svg");
  background-size: 100% 100%;
  top: 3px;
  width: 18px;
  height: 16px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  margin-left: 6px;
  font-weight: 700;
  font-size: 18px;
  color: inherit;
  font-family: var(--icon-font);
}
.breadcumb-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  word-break: break-word;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 20px;
  color: var(--white-color);
}
.breadcumb-title {
  margin: -0.23em 0 -0.18em 0;
  font-size: 54px;
  font-family: var(--title-font);
  color: var(--white-color);
  font-weight: 700;
  text-transform: capitalize;
}
.th-breadcumb-bg {
  position: absolute;
  inset: 0;
}
.breadcumb-wrapper {
  position: relative;
  background-color: var(--title-color);
  background-position: left top;
  padding: 163px 0;
  overflow: hidden;
  text-align: center;
}
@media (max-width: 1199px) {
  .breadcumb-wrapper {
    padding: 150px 0;
  }
  .breadcumb-title {
    margin: -0.18em 0 -0.18em 0;
  }
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding: 130px 0;
  }
}
@media (max-width: 767px) {
  .breadcumb-title {
    font-size: 44px;
    line-height: 54px;
  }
}
@media (max-width: 575px) {
  .breadcumb-wrapper {
    padding: 130px 0;
  }
  .breadcumb-title {
    font-size: 44px;
    line-height: 54px;
  }
}
@media (max-width: 375px) {
  .breadcumb-wrapper {
    padding: 120px 0;
  }
  .breadcumb-title {
    font-size: 34px;
    line-height: 44px;
  }
  .breadcumb-menu {
    margin: 15px 0 -0.5em 0;
  }
}
.wp-block-query-pagination-numbers,
.th-pagination {
  margin-bottom: 30px;
}
.wp-block-query-pagination-numbers ul,
.th-pagination ul {
  margin: 0;
  padding: 0;
}
.wp-block-query-pagination-numbers li,
.th-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}
.wp-block-query-pagination-numbers li:last-child,
.th-pagination li:last-child {
  margin-right: 0;
}
.wp-block-query-pagination-numbers li:first-child,
.th-pagination li:first-child {
  margin-left: 0;
}
.wp-block-query-pagination-numbers span,
.wp-block-query-pagination-numbers a,
.th-pagination span,
.th-pagination a {
  display: inline-block;
  text-align: center;
  gap: 8px;
  position: relative;
  color: var(--title-color);
  border: 1px solid #e1e4e6;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  z-index: 1;
  font-size: 18px;
  font-weight: 400;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 375px) {
  .wp-block-query-pagination-numbers span,
  .wp-block-query-pagination-numbers a,
  .th-pagination span,
  .th-pagination a {
    font-size: 14px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
.wp-block-query-pagination-numbers span.next-page,
.wp-block-query-pagination-numbers a.next-page,
.th-pagination span.next-page,
.th-pagination a.next-page {
  width: auto;
  padding: 0 24px;
  border-radius: 30px;
  line-height: 46px;
}
@media (max-width: 375px) {
  .wp-block-query-pagination-numbers span.next-page,
  .wp-block-query-pagination-numbers a.next-page,
  .th-pagination span.next-page,
  .th-pagination a.next-page {
    line-height: 35px;
  }
}
.wp-block-query-pagination-numbers span img,
.wp-block-query-pagination-numbers a img,
.th-pagination span img,
.th-pagination a img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.wp-block-query-pagination-numbers span.active,
.wp-block-query-pagination-numbers span:hover,
.wp-block-query-pagination-numbers a.active,
.wp-block-query-pagination-numbers a:hover,
.th-pagination span.active,
.th-pagination span:hover,
.th-pagination a.active,
.th-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}
.wp-block-query-pagination-numbers span.active img,
.wp-block-query-pagination-numbers span:hover img,
.wp-block-query-pagination-numbers a.active img,
.wp-block-query-pagination-numbers a:hover img,
.th-pagination span.active img,
.th-pagination span:hover img,
.th-pagination a.active img,
.th-pagination a:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.wp-block-query-pagination-next {
  display: inline-block;
  text-align: center;
  position: relative;
  border: none;
  color: var(--title-color);
  background-color: var(--smoke-color2);
  min-width: 56px;
  height: 56px;
  line-height: 56px;
  z-index: 1;
  font-size: 16px;
  padding: 0 20px;
  font-weight: 500;
  border-radius: 12px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
@media (max-width: 375px) {
  .wp-block-query-pagination-next {
    font-size: 14px;
    padding: 0 15px;
  }
}
.wp-block-query-pagination-next.active,
.wp-block-query-pagination-next:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
blockquote,
.wp-block-quote {
  font-size: 18px;
  line-height: 1.777;
  padding: 22px 16px 22px 60px;
  font-weight: 400;
  display: block;
  position: relative;
  background-color: var(--white-color);
  margin: 50px 0 58px 0;
  color: var(--title-color);
  font-family: var(--body-font);
  font-style: normal;
  border: 1px solid rgba(110, 112, 112, 0.2) !important;
  border-radius: 10px;
}
@media (max-width: 1199px) {
  blockquote,
  .wp-block-quote {
    font-size: 16px;
  }
}
blockquote p,
.wp-block-quote p {
  font-size: inherit;
  font-family: inherit;
  margin-top: -0.3em;
  margin-bottom: 9px;
  line-height: inherit;
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
}
blockquote p a,
.wp-block-quote p a {
  color: inherit;
}
blockquote:before,
.wp-block-quote:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 50px;
  width: 33px;
  background-color: var(--white-color);
}
blockquote:after,
.wp-block-quote:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0px;
  height: 20px;
  width: 25px;
  background-color: var(--theme-color);
  -webkit-clip-path: path(
    "M2.21945 18.2759C0.775335 16.6762 0 14.8819 0 11.9734C0 6.8553 3.44484 2.26804 8.45438 0L9.70641 2.01506C5.03057 4.65307 4.11643 8.07633 3.75189 10.2347C4.5048 9.82818 5.49044 9.68633 6.45645 9.77992C8.98576 10.0241 10.9795 12.1898 10.9795 14.8819C10.9795 16.2393 10.4625 17.5411 9.54219 18.5009C8.62192 19.4608 7.37376 20 6.07229 20C5.35256 19.9934 4.64126 19.8376 3.97981 19.5416C3.31836 19.2457 2.71996 18.8154 2.21945 18.2759ZM16.24 18.2759C14.7959 16.6762 14.0205 14.8819 14.0205 11.9734C14.0205 6.8553 17.4654 2.26804 22.4749 0L23.7269 2.01506C19.0511 4.65307 18.137 8.07633 17.7724 10.2347C18.5253 9.82818 19.511 9.68633 20.477 9.77992C23.0063 10.0241 25 12.1898 25 14.8819C25 16.2393 24.483 17.5411 23.5627 18.5009C22.6424 19.4608 21.3943 20 20.0928 20C19.3731 19.9934 18.6618 19.8376 18.0003 19.5416C17.3389 19.2457 16.7405 18.8154 16.24 18.2759Z"
  );
  clip-path: path(
    "M2.21945 18.2759C0.775335 16.6762 0 14.8819 0 11.9734C0 6.8553 3.44484 2.26804 8.45438 0L9.70641 2.01506C5.03057 4.65307 4.11643 8.07633 3.75189 10.2347C4.5048 9.82818 5.49044 9.68633 6.45645 9.77992C8.98576 10.0241 10.9795 12.1898 10.9795 14.8819C10.9795 16.2393 10.4625 17.5411 9.54219 18.5009C8.62192 19.4608 7.37376 20 6.07229 20C5.35256 19.9934 4.64126 19.8376 3.97981 19.5416C3.31836 19.2457 2.71996 18.8154 2.21945 18.2759ZM16.24 18.2759C14.7959 16.6762 14.0205 14.8819 14.0205 11.9734C14.0205 6.8553 17.4654 2.26804 22.4749 0L23.7269 2.01506C19.0511 4.65307 18.137 8.07633 17.7724 10.2347C18.5253 9.82818 19.511 9.68633 20.477 9.77992C23.0063 10.0241 25 12.1898 25 14.8819C25 16.2393 24.483 17.5411 23.5627 18.5009C22.6424 19.4608 21.3943 20 20.0928 20C19.3731 19.9934 18.6618 19.8376 18.0003 19.5416C17.3389 19.2457 16.7405 18.8154 16.24 18.2759Z"
  );
}
blockquote cite,
.wp-block-quote cite {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  font-style: normal;
  font-family: var(--body-font);
  white-space: nowrap;
  position: absolute;
  bottom: -17px;
  left: 100px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 7px 45px 7px 20px;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 25px) 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, calc(100% - 25px) 100%, 0% 100%);
  border-radius: 0px;
  overflow: hidden;
}
@media (max-width: 375px) {
  blockquote cite,
  .wp-block-quote cite {
    left: 50px;
  }
}
blockquote cite br,
.wp-block-quote cite br {
  display: none;
}
blockquote.is-large:not(.is-style-plain),
blockquote.is-style-large:not(.is-style-plain),
blockquote.style-left-icon,
blockquote.has-text-align-right,
.wp-block-quote.is-large:not(.is-style-plain),
.wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-quote.style-left-icon,
.wp-block-quote.has-text-align-right {
  padding: 40px;
  margin-bottom: 30px;
}
@media (max-width: 375px) {
  blockquote.is-large:not(.is-style-plain),
  blockquote.is-style-large:not(.is-style-plain),
  blockquote.style-left-icon,
  blockquote.has-text-align-right,
  .wp-block-quote.is-large:not(.is-style-plain),
  .wp-block-quote.is-style-large:not(.is-style-plain),
  .wp-block-quote.style-left-icon,
  .wp-block-quote.has-text-align-right {
    padding: 25px;
  }
}
blockquote.style-left-icon,
.wp-block-quote.style-left-icon {
  font-size: 18px;
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.556;
  background-color: var(--smoke-color);
  padding-left: 160px;
}
blockquote.style-left-icon:before,
.wp-block-quote.style-left-icon:before {
  right: unset;
  left: 56px;
  top: 60px;
  font-size: 6rem;
  font-weight: 400;
  line-height: 4rem;
  color: var(--theme-color);
  text-shadow: none;
}
blockquote.style-left-icon cite,
.wp-block-quote.style-left-icon cite {
  color: var(--title-color);
}
blockquote.style-left-icon cite:before,
.wp-block-quote.style-left-icon cite:before {
  background-color: var(--title-color);
  top: 8px;
}
blockquote:not(:has(> cite)) p:last-child,
.wp-block-quote:not(:has(> cite)) p:last-child {
  margin-bottom: -0.3em;
}
blockquote p:has(cite),
.wp-block-quote p:has(cite) {
  padding-bottom: 10px;
}
blockquote p cite,
.wp-block-quote p cite {
  margin-top: 20px;
  margin-bottom: -0.5em;
  bottom: -32px;
}
.wp-block-pullquote {
  color: var(--white-color);
  padding: 0;
}
blockquote.has-very-dark-gray-color {
  color: var(--title-color) !important;
}
.wp-block-pullquote blockquote,
.wp-block-pullquote p {
  color: var(--title-color);
}
.wp-block-pullquote cite {
  position: absolute;
  color: var(--white-color) !important;
}
.wp-block-column blockquote,
.wp-block-column .wp-block-quote {
  padding: 40px 15px 40px 15px;
}
.wp-block-column blockquote:before,
.wp-block-column .wp-block-quote:before {
  width: 100%;
  height: 60px;
  font-size: 30px;
}
.wp-block-column blockquote.style-left-icon,
.wp-block-column blockquote.is-large:not(.is-style-plain),
.wp-block-column blockquote.is-style-large:not(.is-style-plain),
.wp-block-column blockquote.has-text-align-right,
.wp-block-column .wp-block-quote.style-left-icon,
.wp-block-column .wp-block-quote.is-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.has-text-align-right {
  padding: 40px 15px 40px 15px;
}
.wp-block-column blockquote cite,
.wp-block-column .wp-block-quote cite {
  font-size: 14px;
  left: 20px;
}
.wp-block-column blockquote cite:before,
.wp-block-column .wp-block-quote cite:before {
  bottom: 6px;
}
.wp-block-pullquote__citation::before,
.wp-block-pullquote cite::before,
.wp-block-pullquote footer::before {
  bottom: 7px;
}
.has-cyan-bluish-gray-background-color blockquote,
.has-cyan-bluish-gray-background-color .wp-block-quote {
  background-color: var(--white-color);
}
@media (max-width: 1199px) {
  blockquote,
  .wp-block-quote {
    padding: 22px 30px;
  }
}
@media (max-width: 767px) {
  .wp-block-pullquote.is-style-solid-color blockquote {
    max-width: 90%;
  }
  blockquote cite,
  .wp-block-quote cite {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  blockquote,
  .wp-block-quote {
    padding: 20px 20px 30px;
  }
  .wp-block-quote.is-large:not(.is-style-plain) p,
  .wp-block-quote.is-style-large:not(.is-style-plain) p {
    font-size: 1.2em;
  }
}
@media (max-width: 375px) {
  blockquote cite,
  .wp-block-quote cite {
    font-size: 14px;
  }
  blockquote cite:before,
  .wp-block-quote cite:before {
    width: 20px;
    bottom: 40px;
    right: 20px;
  }
}
.blog-meta {
  display: block;
}
.blog-meta span,
.blog-meta a {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--body-color);
  font-family: var(--body-font);
  position: relative;
  text-transform: capitalize;
  margin-right: 12px;
}
.blog-meta span i,
.blog-meta a i {
  margin-right: 8px;
  color: var(--theme-color);
}
.blog-meta span:not(:last-child),
.blog-meta a:not(:last-child) {
  padding-right: 16px;
}
.blog-meta span:not(:last-child):after,
.blog-meta a:not(:last-child):after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: #999999;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
}
.blog-meta .author img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 6px;
}
.blog-meta a:hover {
  color: var(--theme-color);
}
@media (max-width: 1199px) {
  .blog-meta span,
  .blog-meta a {
    margin-right: 15px;
  }
  .blog-meta span:not(:last-child),
  .blog-meta a:not(:last-child) {
    padding-right: 15px;
  }
}
@media (max-width: 575px) {
  .blog-meta span,
  .blog-meta a {
    margin-right: 20px;
  }
  .blog-meta span:not(:last-child),
  .blog-meta a:not(:last-child) {
    padding-right: 0;
  }
  .blog-meta span:not(:last-child):after,
  .blog-meta a:not(:last-child):after {
    display: none;
  }
}
.blog-audio,
.blog-img,
.blog-video {
  line-height: 1px;
}
.blog-audio img,
.blog-img img,
.blog-video img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-title a {
  color: inherit;
}
.blog-title a:hover {
  color: var(--theme-color);
}
.th-blog {
  margin-bottom: 30px;
}
.blog-inner-title {
  margin-top: -0.25em;
  margin-bottom: 25px;
}
.blog-inner-title i {
  color: var(--theme-color);
  margin-right: 4px;
}
.blog-single {
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
}
.blog-single .blog-title {
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 600;
}
.blog-single .blog-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  z-index: 2;
  gap: 10px 0;
}
.blog-single .blog-meta span,
.blog-single .blog-meta a {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #1c2d37;
  font-family: var(--body-font);
  position: relative;
  line-height: normal;
}
.blog-single .blog-meta span img,
.blog-single .blog-meta span svg,
.blog-single .blog-meta span i,
.blog-single .blog-meta a img,
.blog-single .blog-meta a svg,
.blog-single .blog-meta a i {
  margin-right: 11px;
  color: #1c2d37;
}
.blog-single .blog-meta span:last-child,
.blog-single .blog-meta a:last-child {
  margin-right: 0;
  padding-right: 0;
  border: 0;
}
.blog-single .blog-meta span:last-child:after,
.blog-single .blog-meta a:last-child:after {
  display: none;
}
.blog-single .blog-meta .author img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 6px;
}
.blog-single .blog-content {
  margin: 0 0 0 0;
  padding: 0;
  position: relative;
}
.blog-single .blog-content .blog-single {
  padding: 20px;
}
.blog-single .blog-content .th-btn {
  padding: 14px 35px;
}
.blog-single .blog-audio {
  line-height: 1;
}
.blog-single .blog-audio,
.blog-single .blog-img,
.blog-single .blog-video {
  position: relative;
  overflow: hidden;
  background-color: var(--smoke-color);
  margin-bottom: 0;
}
.blog-single .blog-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 10px;
  margin-bottom: 40px;
}
.blog-single .blog-img:before {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.blog-single .blog-img img {
  width: 100%;
  object-fit: cover;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
.blog-single .blog-img .slider-arrow {
  --pos-x: 30px;
  --icon-size: 44px;
  border: none;
  background-color: var(--white-color);
  color: var(--black-color2);
  box-shadow: none;
  visibility: visible;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.blog-single .blog-img .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
@media (max-width: 1199px) {
  .blog-single .blog-img .slider-arrow {
    display: none;
  }
}
.blog-single .blog-img .play-btn {
  --icon-size: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}
@media (max-width: 767px) {
  .blog-single .blog-img .play-btn {
    --icon-size: 70px;
  }
}
.blog-single .line-btn {
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: -1px;
}
.blog-single:hover .blog-img:before {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.blog-single:hover .blog-img img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.page-single .box-title {
  font-size: 40px;
}
@media (max-width: 1199px) {
  .page-single .box-title {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .page-single .box-title {
    font-size: 25px;
  }
}
.page-meta {
  margin-top: 25px;
}
.page-meta .page-tag {
  background-color: var(--title-color);
  font-size: 18px;
  color: var(--white-color);
  border-radius: 24px;
  padding: 6px 16px;
}
.page-meta .ratting {
  background: #e9f6f9;
  border-radius: 24px;
  padding: 6px 16px;
  color: var(--black-color2);
}
.page-meta .ratting i {
  color: #ffa944;
  margin-right: 10px;
}
.blog-list-single {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .blog-list-single {
    gap: 25px;
  }
}
@media (max-width: 991px) {
  .blog-list-single {
    display: block;
  }
}
.blog-list-single .blog-img {
  position: relative;
  min-width: 50%;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-list-single .blog-img:before {
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  transform: skewX(25deg);
  z-index: 2;
}
.blog-list-single .blog-img img {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .blog-list-single .blog-img {
    min-width: 100%;
    margin-bottom: 30px;
  }
}
.blog-list-single .blog-title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 1.25;
  font-weight: 500;
}
@media (max-width: 1299px) {
  .blog-list-single .blog-title {
    font-size: 26px;
  }
}
.blog-list-single:hover .blog-img:before {
  -webkit-animation: shine 1.9s;
  animation: shine 1.9s;
}
.share-links-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0 15px 0 0;
  display: inline-block;
  text-transform: capitalize;
}
.share-links {
  margin: 30px 0 0px 0;
}
.share-links > .row {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  --bs-gutter-y: 15px;
}
.share-links .wp-block-tag-cloud,
.share-links .tagcloud {
  display: inline-block;
}
.share-links .wp-block-tag-cloud a,
.share-links .tagcloud a {
  background-color: rgba(225, 228, 229, 0.2);
}
.share-links .wp-block-tag-cloud a:hover,
.share-links .tagcloud a:hover {
  background-color: var(--theme-color);
}
.share-links .share-links_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0px;
}
.share-links .share-links-title {
  font-size: 20px;
  color: var(--black-color2);
}
.share-links .social-links {
  border: 1px solid var(--theme-color);
  border-radius: 20px;
  padding: 5px 19px;
}
.share-links .social-links a {
  color: var(--black-color2);
  font-size: 16px;
}
.share-links .th-social {
  display: inline-block;
}
.share-links .th-social a {
  --icon-size: 38px;
  border-radius: 0;
  font-size: 14px;
  background-color: rgba(14, 18, 29, 0.08);
  color: var(--title-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.share-links .th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background: var(--smoke-color);
  padding: 40px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .blog-navigation {
    padding: 25px;
  }
}
.blog-navigation .nav-text {
  font-size: 18px;
  font-family: var(--title-font);
  color: var(--title-color);
  display: inline-block;
  font-weight: 600;
}
@media (max-width: 767px) {
  .blog-navigation .nav-text {
    font-size: 16px;
  }
}
.blog-navigation .nav-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}
.blog-navigation .nav-btn img {
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .blog-navigation .nav-btn img {
    display: none;
  }
}
.blog-navigation .nav-btn.next {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  text-align: right;
}
.blog-navigation .nav-btn:hover .nav-text {
  color: var(--theme-color);
}
.blog-navigation .nav-btn:hover .nav-img:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.blog-navigation .nav-btn:hover .nav-img i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.blog-navigation .nav-btn .nav-img {
  min-width: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.blog-navigation .nav-btn .nav-img img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .blog-navigation .nav-btn .nav-img {
    display: none;
  }
}
.blog-navigation .nav-btn .nav-img:after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-navigation .nav-btn .nav-img i {
  position: absolute;
  inset: 0;
  text-align: center;
  line-height: 81px;
  font-size: 24px;
  color: var(--theme-color);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  z-index: 2;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-navigation .nav-btn .nav-img img {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  border-radius: 10px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-navigation .blog-next {
  text-align: right;
}
.blog-navigation .blog-btn {
  font-size: 38px;
  color: var(--theme-color);
}
@media (max-width: 767px) {
  .blog-navigation .blog-btn {
    font-size: 25px;
  }
}
.blog-navigation .blog-btn:hover {
  color: var(--theme-color);
}
.blog-author {
  margin-top: 40px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--smoke-color);
  border-radius: 20px;
  overflow: hidden;
}
.blog-author .auhtor-img {
  width: 212px;
  min-height: 100%;
  background-color: #00ffff;
  text-align: center;
  display: grid;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .blog-author .auhtor-img {
    width: 100%;
    min-height: 200px;
  }
}
.blog-author .auhtor-img img {
  width: 96px;
  height: auto;
  object-fit: cover;
  object-position: center center;
}
.blog-author .author-name {
  font-size: 24px;
  margin-top: -0.2em;
  margin-bottom: 16px;
}
.blog-author .author-text {
  margin-bottom: 0;
}
.blog-author .media-body {
  padding: 40px 40px 40px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
@media (max-width: 767px) {
  .blog-author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .blog-author .auhtor-img img {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .blog-author .media-body {
    padding: 35px 20px 30px 20px;
  }
}
.blog-details .blog-content > p:last-child {
  margin-bottom: 0;
}
.blog-content .wp-block-search .wp-block-search__input {
  box-shadow: none;
}
@media (max-width: 1399px) {
  .blog-single .blog-title {
    font-size: 28px;
    line-height: 1.4;
  }
  .share-links {
    --blog-space-x: 20px;
  }
}
@media (max-width: 1199px) {
  .blog-single {
    --blog-space-x: 20px;
    --blog-space-y: 20px;
  }
}
@media (max-width: 991px) {
  .blog-single {
    --blog-space-x: 40px;
    --blog-space-y: 40px;
  }
  .share-links {
    --blog-space-x: 40px;
  }
}
@media (max-width: 767px) {
  .share-links {
    --blog-space-x: 20px;
  }
  .blog-single {
    --blog-space-x: 30px;
    --blog-space-y: 30px;
  }
  .blog-single .blog-title {
    font-size: 24px;
    line-height: 1.3;
  }
  .blog-single .blog-text {
    margin-bottom: 22px;
  }
  .blog-single .blog-bottom {
    padding-top: 15px;
  }
}
.blog-text {
  font-size: 18px;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .share-links-title {
    margin: 0 15px 5px 0;
  }
}
.th-comment-form {
  margin-top: 70px;
  position: relative;
}
.th-comment-form .row {
  --bs-gutter-x: 30px;
}
.th-comment-form .blog-inner-title {
  margin-bottom: 0px;
}
.th-comment-form .form-title {
  margin-top: -0.35em;
}
.th-comment-form .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}
.th-comment-form .form-text {
  margin-bottom: 25px;
}
.th-comment-form .form-group > i {
  color: var(--body-color);
}
.th-comment-form .form-group select,
.th-comment-form .form-group .form-control,
.th-comment-form .form-group .form-select,
.th-comment-form .form-group textarea,
.th-comment-form .form-group input {
  border: 1px solid #808080;
}
.th-comment-form .form-group label {
  color: var(--body-color);
  margin-bottom: -0.3rem;
}
.blog-comment-area {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .th-comment-form,
  .th-comments-wrap {
    border-radius: 10px;
  }
}
.th-comment-form:has(#wp-temp-form-div),
.th-comments-wrap:has(#wp-temp-form-div) {
  padding: 0;
  box-shadow: none;
  display: none;
}
.th-comments-wrap {
  border-radius: 0px;
}
.th-comments-wrap {
  margin-top: var(--blog-space-y, 40px);
  margin-bottom: 30px;
}
.th-comments-wrap.style2 .th-post-comment {
  border: 1px solid #e1e4e5;
  margin-bottom: 24px;
}
.th-comments-wrap.style2 .children {
  margin-left: 0;
}
.th-comments-wrap.style2 .commented-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 2;
  gap: 10px 0;
  margin-bottom: 22px;
}
.th-comments-wrap.style2 .commented-wrapp span {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: var(--body-color);
  font-family: var(--body-font);
  position: relative;
  line-height: normal;
}
.th-comments-wrap.style2 .commented-wrapp span:not(:last-child) {
  padding-right: 16px;
  margin-right: 16px;
}
.th-comments-wrap.style2 .commented-wrapp span:not(:last-child):after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--body-color);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
}
.th-comments-wrap.style2 .commented-wrapp span i {
  color: #ffa944;
}
.th-comments-wrap.style2 .reply_and_edit i {
  color: #999999;
  font-size: 24px;
}
.th-comments-wrap.style2
  ul.comment-list
  .th-comment-item:last-child
  > .th-post-comment {
  border-bottom: 1px solid #e1e4e5;
}
.th-comments-wrap .description p:last-child {
  margin-bottom: -0.5em;
}
.th-comments-wrap .comment-respond {
  margin: 30px 0;
}
.th-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.th-comments-wrap li {
  margin: 0;
}
.th-comments-wrap .th-post-comment {
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  background: var(--white-color);
  border-radius: 8px;
}
.th-comments-wrap .th-post-comment ol,
.th-comments-wrap .th-post-comment ul,
.th-comments-wrap .th-post-comment dl {
  margin-bottom: 1rem;
}
.th-comments-wrap .th-post-comment ol ol,
.th-comments-wrap .th-post-comment ol ul,
.th-comments-wrap .th-post-comment ul ol,
.th-comments-wrap .th-post-comment ul ul {
  margin-bottom: 0;
}
.th-comments-wrap ul.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: -30px;
}
.th-comments-wrap ul.comment-list ul ul,
.th-comments-wrap ul.comment-list ul ol,
.th-comments-wrap ul.comment-list ol ul,
.th-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}
.th-comments-wrap .comment-avater {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin-right: 20px;
  overflow: hidden;
}
.th-comments-wrap .comment-avater img {
  width: 100%;
}
.th-comments-wrap .comment-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: -6px;
  position: relative;
}
.th-comments-wrap .commented-on {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--body-font);
  color: var(--body-color);
  margin-bottom: 0;
}
.th-comments-wrap .commented-on i {
  color: var(--theme-color);
  margin-right: 7px;
  font-size: 0.9rem;
}
.th-comments-wrap .name {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.th-comments-wrap .comment-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.th-comments-wrap .text {
  margin-bottom: -0.4rem;
}
.th-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 80px;
}
.th-comments-wrap .reply_and_edit {
  margin-top: 0px;
  margin-bottom: -0.46em;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .th-comments-wrap .reply_and_edit {
    position: relative;
    margin-top: 12px;
  }
}
.th-comments-wrap .reply_and_edit a {
  margin-right: 10px;
}
.th-comments-wrap .reply_and_edit a:last-child {
  margin-right: 0;
}
.th-comments-wrap .reply-btn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: inline-block;
  color: var(--title-color);
  text-transform: uppercase;
}
.th-comments-wrap .reply-btn i {
  margin-right: 7px;
}
.th-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}
.form-submit input[type="submit"] {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.form-submit input[type="submit"]:hover {
  background-color: var(--title-color);
}
.wp-block-post-comments-form textarea,
.wp-block-post-comments-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="hidden"]) {
  border: 1px solid var(--th-border-color);
}
.wp-block-comment-author-name {
  font-weight: 600;
}
ul.comment-list .th-comment-item:last-child > .th-post-comment {
  border-bottom: none;
}
ul.comment-list .children .th-comment-item > .th-post-comment {
  padding-bottom: 30px;
}
ul.comment-list:has(> .th-comment-item ~ .th-comment-item):first-child
  > .th-post-comment {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--th-border-color);
}
.th-comments-wrap.th-comment-form {
  margin: 0;
}
.comment-respond .must-log-in {
  margin-bottom: 0;
  margin-top: 8px;
}
@media (max-width: 1199px) {
  .th-comments-wrap .children {
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .th-comments-wrap .th-post-comment {
    display: block;
  }
  .th-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .th-comments-wrap .comment-top {
    display: block;
  }
  .th-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .th-comments-wrap .children {
    margin-left: 40px;
  }
  .th-comments-wrap .children {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .th-comment-form {
    --blog-space-y: 30px;
  }
}
.th-hero-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.th-hero-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
}
.th-hero-bg img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
.hero-1 {
  position: relative;
  overflow: hidden;
}
.hero-1 .th-hero-bg {
  z-index: 2;
}
.hero-1 .th-hero-bg:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(13, 13, 12, 0.5);
  mix-blend-mode: normal;
  z-index: -1;
}
.hero-1 .th-swiper-custom {
  position: absolute;
  top: 47%;
  margin: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: 55px;
  z-index: 9;
}
@media (max-width: 767px) {
  .hero-1 .th-swiper-custom {
    display: none;
  }
}
.hero-1 .th-swiper-custom .swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: 25px 0 0px 0;
  line-height: 10px;
  height: 3px;
}
.hero-1 .th-swiper-custom .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  margin: 5px 7px;
  height: 2px;
  opacity: 1;
  border-radius: 0;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}
.hero-1
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:before {
  display: none;
}
.hero-1
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:hover,
.hero-1
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}
.hero-1
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.hero-1
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.hero-1
  .slider-pagination
  .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  background: var(--white-color);
  height: 3px;
}
.hero-1 .slider-arrow {
  --icon-size: 64px;
  background: rgba(250, 252, 244, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 36px;
  color: var(--white-color);
  box-shadow: none;
  border: none;
  opacity: 1;
  visibility: visible;
  left: var(--pos-x, -90px);
}
@media (max-width: 1399px) {
  .hero-1 .slider-arrow {
    display: none;
  }
}
.hero-1 .slider-arrow img {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.hero-1 .slider-arrow.slider-next {
  right: var(--pos-x, -90px);
  left: auto;
}
.hero-1 .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.hero-1:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.hero-style1 {
  position: relative;
  z-index: 9;
  max-width: 700px;
  padding: 206px 0 260px 0;
}
.hero-style1 .sub-title {
  color: var(--white-color);
  margin-bottom: 0px;
  display: block;
}
.hero-style1 .hero-title {
  color: var(--white-color);
  margin-bottom: 20px;
  margin-top: 40px;
}
.hero-style1 .hero-text {
  max-width: 582px;
  line-height: 26px;
  margin-bottom: 30px;
}
.hero-style1 .btn-group {
  gap: 16px;
}
@media (max-width: 480px) {
  .hero-style1 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .hero-style1 {
    max-width: 550px;
  }
  .hero-style1 .hero-title {
    font-size: 58px;
    line-height: 68px;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-img {
    position: static;
    max-width: 100%;
    text-align: center;
    margin: 0 auto 50px auto;
  }
  .hero-1 .hero-shape2 {
    display: none;
  }
  .hero-style1 {
    padding: 130px 0 185px 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-style1 .title1,
  .hero-style1 .title2 {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-style1 .title-img {
    max-width: 40px;
    right: 5%;
  }
}
@media (max-width: 767px) {
  .hero-style1 .hero-title {
    font-size: 44px;
    line-height: 54px;
  }
}
@media (max-width: 575px) {
  .hero-style1 {
    padding: 130px 0 180px 0;
  }
  .hero-style1 .title-img {
    right: 3%;
  }
  .hero-title {
    font-size: 42px;
    line-height: 1.24;
    margin-bottom: 35px;
  }
}
@media (max-width: 375px) {
  .hero-style1 .hero-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 330px) {
  .hero-title {
    font-size: 32px;
  }
}
.hero-2 {
  position: relative;
  z-index: 6;
}
.hero-2 .hero2-overlay {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.hero-2 .hero-slider-2 {
  max-width: 1776px;
  display: block;
  margin: auto;
  border-radius: 24px;
}
@media (min-width: 1930px) {
  .hero-2 .hero-slider-2 {
    max-width: 100%;
  }
}
.hero-2 video {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  overflow: hidden;
}
.hero-2 video source {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.hero-2 .scroll-down {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  position: absolute;
  bottom: 0px;
  left: calc(50% - 0px);
  width: 150px;
  height: 141px;
  text-align: center;
  z-index: 2;
  color: var(--white-color);
}
@media (max-width: 1699px) {
  .hero-2 .scroll-down {
    left: calc(50% - 50px);
  }
}
@media (max-width: 1599px) {
  .hero-2 .scroll-down {
    left: calc(50% - 100px);
  }
}
@media (max-width: 1599px) {
  .hero-2 .scroll-down {
    left: calc(50% - 150px);
  }
}
@media (max-width: 1299px) {
  .hero-2 .scroll-down {
    display: none;
  }
}
.hero-2 .scroll-down .scroll-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--title-font);
  font-size: 16px;
  color: var(--white-color);
  font-weight: 300;
}
.hero-2 .scroll-down .scroll-wrap span {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid var(--white-color);
}
.hero-2 .scroll-down .scroll-wrap span img {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-bottom: 4px;
}
.hero-2 .th-hero-bg {
  border-radius: 24px;
  overflow: hidden;
}
.hero-2 .th-hero-bg:before {
  content: "";
  position: absolute;
  /* inset: 0;
  background: -webkit-linear-gradient(
      bottom,
      rgba(13, 13, 12, 0.4),
      rgba(13, 13, 12, 0.4)
    ),
    url(2218.html); */
  /* background: linear-gradient(
      0deg,
      rgba(13, 13, 12, 0.4),
      rgba(13, 13, 12, 0.4)
    ),
    url(2218.html); */
  border-radius: 24px;
}
.hero-2 .th-swiper-custom {
  position: absolute;
  top: 55%;
  margin: 0;
  right: 209px;
  z-index: 9;
  width: 550px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 36px;
}
@media (max-width: 1299px) {
  .hero-2 .th-swiper-custom {
    right: 20px;
  }
}
@media (max-width: 1199px) {
  .hero-2 .th-swiper-custom {
    top: unset;
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  .hero-2 .th-swiper-custom {
    bottom: 0;
  }
}
@media (max-width: 375px) {
  .hero-2 .th-swiper-custom {
    display: none;
  }
}
.hero-2 .hero-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}
.hero-2 .hero-arrow {
  width: 32px;
  height: 32px;
  line-height: 1;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  background-color: transparent;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.hero-2 .hero-arrow img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.hero-2 .hero-arrow:hover {
  background-color: var(--white-color);
}
.hero-2 .hero-arrow:hover img {
  -webkit-filter: none;
  filter: none;
}
.hero-2 .swiper-pagination {
  position: relative;
  border-radius: 8px;
  width: 424px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.hero-2
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.hero-2
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0;
}
.hero-2 .swiper-pagination-bullet {
  width: 100%;
  height: 3px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  margin: 0;
  position: relative;
  overflow: hidden;
}
.hero-2 .swiper-pagination-bullet::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.hero-2
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.hero-2
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background-color: var(--white-color);
  -webkit-animation: slide-progress 2s cubic-bezier(0.3, 0, 0.3, 1) forwards;
  animation: slide-progress 2s cubic-bezier(0.3, 0, 0.3, 1) forwards;
}
.swiper-paused
  .hero-2
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
@-webkit-keyframes slide-progress {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-progress {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.heroThumbs {
  max-width: 848px;
  position: absolute;
  bottom: 70px;
  right: 0;
}
@media (max-width: 1199px) {
  .heroThumbs {
    display: none;
  }
}
.hero-style2 {
  position: relative;
  z-index: 6;
  max-width: 451px;
  padding: 230px 0;
}
.hero-style2 .hero-title {
  color: var(--white-color);
  font-weight: 700;
  font-size: 80px;
  color: var(--white-color);
  display: block;
  margin-top: -0.8rem;
  margin-bottom: 2px;
}
.hero-style2 .hero-text {
  color: var(--white-color);
  font-weight: 600;
  font-size: 28px;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 1299px) {
  .hero-style2 .hero-text {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 320px) {
  .hero-style2 .hero-text {
    font-size: 16px;
    line-height: 26px;
  }
}
.hero-style2 .hero-desc {
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 38px;
}
.hero-style2 .th-btn {
  padding: 19px 40px;
}
.hero-style2 .th-btn:after {
  -webkit-mask-image: url("./img/icon/arrow-right.svg");
  mask-image: url("./img/icon/arrow-right.svg");
  background-color: #202020;
}
.hero-style2 .th-btn:hover {
  color: var(--white-color);
}
.hero-style2 .th-btn:hover:after {
  background-color: var(--white-color);
}
.hero-style2 .th-btn:hover:before {
  background-color: var(--title-color);
}
.hero-card {
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  cursor: pointer;
}
.hero-card_wrapp {
  position: absolute;
  bottom: 72px;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
.hero-card .hero-img {
  position: relative;
  max-width: 168px;
  height: 168px;
  border-radius: 16px;
}
.hero-card .hero-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    bottom,
    rgba(13, 13, 12, 0.2),
    rgba(13, 13, 12, 0.2)
  );
  background: linear-gradient(
    0deg,
    rgba(13, 13, 12, 0.2),
    rgba(13, 13, 12, 0.2)
  );
  border-radius: 16px;
}
.hero-card .hero-img img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.hero-card .hero-img .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hero-card .hero-img .play-btn > i {
  width: var(--icon-size, 40px);
  height: var(--icon-size, 40px);
  line-height: var(--icon-size, 40px);
  text-align: center;
  background: transparent;
  border: 1px solid var(--white-color);
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  color: var(--white-color);
  font-size: var(--icon-font-size, 1.2em);
}
.hero-card .box-title {
  color: var(--white-color);
  margin-bottom: 0;
}
.hero-card_price {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--white-color);
}
.hero-card span {
  font-weight: 400;
  font-size: 16px;
  color: var(--white-color);
}
.hero-card span i {
  margin-right: 10px;
}
.hero-card .th-btn {
  padding: 8px 24px;
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .hero-style2 {
    padding: 180px 0;
  }
  .hero-style2 .hero-title {
    font-size: 74px;
    font-size: 84px;
  }
}
@media (max-width: 991px) {
  .hero-style2 .hero-title {
    font-size: 64px;
    line-height: 74px;
  }
  .hero-style2 .hero-text {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .hero-style2 {
    padding: 150px 0;
  }
  .hero-style2 .hero-text {
    color: var(--white-color);
  }
}
@media (max-width: 575px) {
  .hero-style2 {
    padding: 130px 0;
  }
  .hero-style2 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media (max-width: 375px) {
  .hero-style2 .sub-title {
    font-size: 16px;
  }
  .hero-style2 .hero-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.hero-3 {
  position: relative;
  z-index: 3;
  overflow: initial;
}
.hero-3 .th-hero-bg {
  width: 100%;
  height: 100%;
}
.hero-3 .th-hero-bg:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    167.93deg,
    rgba(13, 13, 12, 0) -4.13%,
    rgba(13, 13, 12, 0.7) 84.33%
  );
  background: linear-gradient(
    282.07deg,
    rgba(13, 13, 12, 0) -4.13%,
    rgba(13, 13, 12, 0.7) 84.33%
  );
}
.hero-3 video {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.hero-3 .scroll-down {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  position: absolute;
  bottom: 30px;
  right: -50px;
  width: 150px;
  height: 141px;
  text-align: center;
  z-index: 2;
  color: var(--white-color);
}
@media (max-width: 1699px) {
  .hero-3 .scroll-down {
    right: -70px;
    width: 142px;
    height: 10px;
  }
}
@media (max-width: 1699px) {
  .hero-3 .scroll-down {
    display: none;
  }
}
.hero-3 .scroll-down .scroll-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--title-font);
  font-size: 16px;
  color: var(--white-color);
  font-weight: 300;
}
.hero-3 .scroll-down .scroll-wrap span {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid var(--white-color);
}
.hero-3 .scroll-down .scroll-wrap span img {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-bottom: 4px;
}
.hero-slider-3 {
  position: relative;
}
.hero-style3 {
  position: relative;
  padding: 285px 0 292px 0;
  max-width: 760px;
  display: block;
  z-index: 4;
}
@media (max-width: 1199px) {
  .hero-style3 {
    padding: 250px 0 340px 0;
  }
}
@media (max-width: 991px) {
  .hero-style3 {
    text-align: center;
    padding: 235px 0 150px 0;
  }
}
@media (max-width: 375px) {
  .hero-style3 {
    padding: 200px 0 115px 0;
  }
}
.hero-style3 .hero-title {
  font-size: 80px;
  line-height: 96px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-top: -1.2rem;
  margin-bottom: 7px;
}
@media (max-width: 1299px) {
  .hero-style3 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}
@media (max-width: 1199px) {
  .hero-style3 .hero-title {
    font-size: 64px;
    line-height: 74px;
    margin-top: -1rem;
  }
}
@media (max-width: 767px) {
  .hero-style3 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media (max-width: 575px) {
  .hero-style3 .hero-title {
    font-size: 40px;
    line-height: 50px;
    margin-top: -0.8rem;
  }
}
@media (max-width: 375px) {
  .hero-style3 .hero-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.hero-style3 .hero-text {
  color: var(--white-color);
  font-size: 18px;
  max-width: 451px;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .hero-style3 .hero-text {
    display: block;
    margin: auto auto 40px auto;
  }
}
.hero3Thumbs {
  position: relative;
  right: 0;
  bottom: 110px;
  max-width: 424px;
  height: 186px;
}
@media (max-width: 1299px) {
  .hero3Thumbs {
    bottom: 99px;
  }
}
@media (max-width: 1199px) {
  .hero3Thumbs {
    bottom: 65px;
  }
}
@media (max-width: 991px) {
  .hero3Thumbs {
    display: none;
  }
}
.hero3-card {
  cursor: pointer;
}
.hero3-card_wrapp {
  position: absolute;
  bottom: 0;
  background-color: var(--theme-color);
  border-radius: 24px 24px 0 0;
  max-width: 424px;
  min-height: 296px;
  z-index: 9;
}
.hero3-card .hero-img {
  position: relative;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
  z-index: 2;
}
.hero3-card .hero-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    bottom,
    rgba(13, 13, 12, 0.2),
    rgba(13, 13, 12, 0.2)
  );
  background: linear-gradient(
    0deg,
    rgba(13, 13, 12, 0.2),
    rgba(13, 13, 12, 0.2)
  );
  border-radius: 16px 16px 0px 0px;
  z-index: 1;
}
.hero3-card .hero-img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hero3-card .hero-img .play-btn > i {
  background: transparent;
  --icon-size: 40px;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  font-size: 16px;
}
.hero3-swiper-custom {
  position: relative;
  right: 0;
  bottom: -186px;
  min-width: 424px;
  height: 110px;
  background-color: var(--theme-color);
  z-index: 99;
}
@media (max-width: 1399px) {
  .hero3-swiper-custom {
    min-width: 390px;
  }
}
@media (max-width: 1299px) {
  .hero3-swiper-custom {
    min-width: 100%;
  }
}
@media (max-width: 991px) {
  .hero3-swiper-custom {
    bottom: 0;
  }
}
@media (max-width: 1199px) {
  .hero3-swiper-custom .swiper-button-next,
  .hero3-swiper-custom .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 55%);
  }
}
.hero3-swiper-custom .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 32px);
}
.hero3-swiper-custom .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 32px);
}
.hero3-swiper-custom .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 40px);
  z-index: 8;
}
.hero3-swiper-custom .swiper-button-next,
.hero3-swiper-custom .swiper-button-prev {
  padding: 8px;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 48px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.hero3-swiper-custom .swiper-button-next:after,
.hero3-swiper-custom .swiper-button-prev:after {
  display: none !important;
}
.hero3-swiper-custom .swiper-button-next img,
.hero3-swiper-custom .swiper-button-prev img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.hero3-swiper-custom .swiper-button-next:hover,
.hero3-swiper-custom .swiper-button-prev:hover {
  background-color: var(--white-color);
}
.hero3-swiper-custom .swiper-button-next:hover img,
.hero3-swiper-custom .swiper-button-prev:hover img {
  -webkit-filter: none;
  filter: none;
}
.hero3-swiper-custom .swiper-pagination {
  color: var(--white-color);
}
.hero3-wrapper {
  position: relative;
  margin-top: -294px;
}
@media (max-width: 991px) {
  .hero3-wrapper {
    margin-top: 0;
  }
  .hero3-wrapper .container {
    max-width: 100%;
  }
}
.hero-4 {
  min-height: 1066px;
  border-radius: 0 0px 48px 48px;
}
@media (max-width: 1199px) {
  .hero-4 {
    border-radius: 0 0px 30px 30px;
  }
}
@media (max-width: 1699px) {
  .hero-4 .shape-mockup {
    display: block !important;
  }
}
@media (max-width: 1199px) {
  .hero-4 .shape-mockup {
    display: none !important;
  }
}
.hero-style4 {
  padding: 80px 0 0px 0;
  max-width: 960px;
  display: block;
  margin: auto;
}
.hero-style4 .hero-title {
  font-weight: 800;
  margin-bottom: 30px;
}
@media (max-width: 1299px) {
  .hero-style4 .hero-title {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .hero-style4 .hero-title {
    font-size: 55px;
  }
}
@media (max-width: 480px) {
  .hero-style4 .hero-title {
    font-size: 40px;
  }
}
@media (max-width: 450px) {
  .hero-style4 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.hero-5:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    192.5deg,
    rgba(13, 13, 12, 0.7) 4.1%,
    rgba(0, 0, 0, 0.3) 96.33%
  );
  background: linear-gradient(
    257.5deg,
    rgba(13, 13, 12, 0.7) 4.1%,
    rgba(0, 0, 0, 0.3) 96.33%
  );
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.hero-5 .th-swiper-custom {
  position: absolute;
  top: 47%;
  margin: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: 55px;
  z-index: 9;
}
@media (max-width: 1599px) {
  .hero-5 .th-swiper-custom {
    display: none;
  }
}
.hero-5 .th-swiper-custom .swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: 25px 0 0px 0;
  line-height: 10px;
  height: 3px;
}
.hero-5 .th-swiper-custom .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  margin: 5px 7px;
  height: 2px;
  opacity: 1;
  border-radius: 0;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}
.hero-5
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:before {
  display: none;
}
.hero-5
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:hover,
.hero-5
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}
.hero-5
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.hero-5
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.hero-5 .slider-arrow {
  --icon-size: 64px;
  background: rgba(250, 252, 244, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 36px;
  color: var(--white-color);
  box-shadow: none;
  border: none;
  opacity: 1;
  visibility: visible;
  left: var(--pos-x, -90px);
}
@media (max-width: 1599px) {
  .hero-5 .slider-arrow {
    display: none;
  }
}
.hero-5 .slider-arrow img {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.hero-5 .slider-arrow.slider-next {
  right: var(--pos-x, -90px);
  left: auto;
}
.hero-5 .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.hero-5:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.hero-5 .th-hero-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 22.5%;
  right: 10%;
}
@media (max-width: 1599px) {
  .hero-5 .th-hero-image {
    right: 2%;
  }
}
@media (max-width: 1299px) {
  .hero-5 .th-hero-image {
    position: relative;
    right: unset;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 180px;
  }
}
@media (max-width: 575px) {
  .hero-5 .th-hero-image {
    display: block;
  }
}
.hero-5 .th-hero-image .hero-img {
  position: relative;
  border-radius: 300px;
  min-width: 274px;
  height: 416px;
  z-index: 2;
}
@media (max-width: 375px) {
  .hero-5 .th-hero-image .hero-img {
    min-width: 100%;
    height: 100%;
  }
}
.hero-5 .th-hero-image .hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 300px;
  border: 10px solid var(--white-color);
}
.hero-5 .th-hero-image .hero-img2 {
  border-radius: 300px;
  min-width: 327px;
  height: 496px;
  margin-left: -50px;
}
@media (max-width: 575px) {
  .hero-5 .th-hero-image .hero-img2 {
    margin: 20px 0 0 0;
  }
}
@media (max-width: 375px) {
  .hero-5 .th-hero-image .hero-img2 {
    min-width: 100%;
    height: 100%;
  }
}
.hero-5 .th-hero-image .hero-img2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 300px;
  border: 10px solid var(--white-color);
}
.hero-5 .cilent-box_title {
  color: var(--title-color);
  font-weight: 700;
  display: block;
}
.hero-style5 {
  padding: 303px 0 307px 0;
  max-width: 730px;
}
@media (max-width: 1399px) {
  .hero-style5 {
    max-width: 703px;
  }
}
@media (max-width: 1299px) {
  .hero-style5 {
    text-align: center;
    display: block;
    margin: auto;
    padding: 220px 0 50px;
  }
}
@media (max-width: 991px) {
  .hero-style5 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .hero-style5 {
    padding: 180px 0 50px;
  }
}
@media (max-width: 1399px) {
  .hero-style5 .hero-title {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .hero-style5 .hero-title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .hero-style5 .hero-title {
    font-size: 40px;
  }
}
.hero-style5 .hero-text {
  font-size: 18px;
  color: var(--white-color);
  max-width: 703px;
  margin-bottom: 38px;
}
@media (max-width: 1399px) {
  .hero-style5 .hero-text {
    max-width: 650px;
  }
}
.hero-6 {
  position: relative;
  padding: 24px 24px 0 24px;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .hero-6 {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .hero-6 {
    border-radius: 0;
  }
}
.hero-6 .th-hero-bg {
  -webkit-mask-image: url("../src/img/bg/hero_bg_6_1.png");
  mask-image: url("../src/img/bg/hero_bg_6_1.png");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  z-index: 2;
}
@media (max-width: 991px) {
  .hero-6 .th-hero-bg {
    -webkit-mask-size: cover;
    mask-size: cover;
  }
}
@media (max-width: 480px) {
  .hero-6 .th-hero-bg {
    -webkit-mask-image: none !important;
    mask-image: none !important;
  }
}
.hero-6 .th-hero-bg:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(17, 61, 72, 0.6);
  border-radius: 0px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.hero-6 .hero6-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.hero-6 .hero6Thumbs {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 270px;
  min-height: 120px;
  height: 120px;
  border-radius: 16px;
}
@media (min-width: 2430px) {
  .hero-6 .hero6Thumbs {
    max-width: 365px;
  }
}
@media (max-width: 1899px) {
  .hero-6 .hero6Thumbs {
    max-width: 250px;
  }
}
@media (max-width: 1699px) {
  .hero-6 .hero6Thumbs {
    max-width: 220px;
  }
}
@media (max-width: 1499px) {
  .hero-6 .hero6Thumbs {
    max-width: 200px;
  }
}
@media (max-width: 1399px) {
  .hero-6 .hero6Thumbs {
    max-width: 190px;
  }
}
@media (max-width: 1299px) {
  .hero-6 .hero6Thumbs {
    max-width: 170px;
  }
}
@media (max-width: 1199px) {
  .hero-6 .hero6Thumbs {
    max-width: 150px;
  }
}
@media (max-width: 480px) {
  .hero-6 .hero6Thumbs {
    position: relative;
    max-width: 100%;
    border-radius: 0;
    margin-top: 25px;
  }
}
.hero-6 .th-swiper-custom {
  position: absolute;
  bottom: 8%;
  margin: 0;
  right: 24%;
  z-index: 9;
}
@media (max-width: 1599px) {
  .hero-6 .th-swiper-custom {
    display: none;
  }
}
.hero-6 .th-swiper-custom .swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: 25px 0 0px 0;
  line-height: 10px;
  height: 3px;
}
.hero-6 .th-swiper-custom .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  margin: 5px 7px;
  height: 2px;
  opacity: 1;
  border-radius: 0;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}
.hero-6
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:before {
  display: none;
}
.hero-6
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:hover,
.hero-6
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}
.hero-6
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.hero-6
  .th-swiper-custom
  .swiper-pagination-bullets
  .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.hero-6 .slider-arrow {
  --icon-size: 64px;
  background: rgba(250, 252, 244, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 36px;
  color: var(--white-color);
  box-shadow: none;
  border: none;
  opacity: 1;
  visibility: visible;
  left: var(--pos-x, -60px);
}
@media (max-width: 1599px) {
  .hero-6 .slider-arrow {
    display: none;
  }
}
.hero-6 .slider-arrow img {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.hero-6 .slider-arrow.slider-next {
  right: var(--pos-x, -90px);
  left: auto;
}
.hero-6 .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.hero-6:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.hero-6 .hero6-swiper-custom {
  position: absolute;
  bottom: 2%;
  left: 45%;
}
@media (max-width: 480px) {
  .hero-6 .hero6-swiper-custom {
    left: 50%;
  }
}
.hero-6 .hero6-swiper-custom .swiper-pagination {
  color: #ffffff00;
}
.hero-6 .hero6-swiper-custom .swiper-pagination-total {
  display: none;
}
.hero-6 .hero6-swiper-custom .swiper-pagination-current {
  color: var(--white-color);
  font-size: 48px;
  font-weight: 700;
}
.hero-style6 {
  position: relative;
  padding: 285px 0 284px 0;
  max-width: 1057px;
  display: block;
  text-align: center;
  margin: auto;
  z-index: 4;
}
@media (max-width: 1199px) {
  .hero-style6 {
    padding: 250px 0 240px 0;
  }
}
@media (max-width: 991px) {
  .hero-style6 {
    text-align: center;
    padding: 235px 0 210px 0;
  }
}
@media (max-width: 480px) {
  .hero-style6 {
    padding: 220px 0 140px 0;
  }
}
@media (max-width: 375px) {
  .hero-style6 {
    padding: 200px 0 115px 0;
  }
}
.hero-style6 .hero-title {
  font-size: 80px;
  line-height: 96px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-top: -1.2rem;
  margin-bottom: 7px;
}
@media (max-width: 1299px) {
  .hero-style6 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}
@media (max-width: 1199px) {
  .hero-style6 .hero-title {
    font-size: 64px;
    line-height: 74px;
    margin-top: -1rem;
  }
}
@media (max-width: 991px) {
  .hero-style6 .hero-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-style6 .hero-title {
    font-size: 38px;
    line-height: 48px;
    margin-top: -0.8rem;
  }
}
@media (max-width: 375px) {
  .hero-style6 .hero-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.hero-style6 .hero-text {
  color: var(--white-color);
  font-size: 18px;
  max-width: 599px;
  display: block;
  margin: auto auto 40px auto;
}
@media (max-width: 767px) {
  .hero-style6 .hero-text {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .hero-style6 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.hero6-card .hero-img {
  position: relative;
  z-index: 2;
  height: 120px;
  border-radius: 16px;
}
@media (max-width: 480px) {
  .hero6-card .hero-img {
    border-radius: 0px;
  }
}
.hero6-card .hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero6-card .hero-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(13, 13, 12, 0.5);
  border-radius: 16px;
}
@media (max-width: 480px) {
  .hero6-card .hero-img:before {
    border-radius: 0px;
  }
}
.hero6-card .hero-img img {
  border-radius: 16px;
}
@media (max-width: 480px) {
  .hero6-card .hero-img img {
    border-radius: 0px;
  }
}
.hero-7:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    192.5deg,
    rgba(13, 13, 12, 0.7) 4.1%,
    rgba(0, 0, 0, 0.3) 96.33%
  );
  background: linear-gradient(
    257.5deg,
    rgba(13, 13, 12, 0.7) 4.1%,
    rgba(0, 0, 0, 0.3) 96.33%
  );
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
}
.hero-style7 {
  padding: 216px 0;
  margin-right: 40px;
}
@media (max-width: 991px) {
  .hero-style7 {
    text-align: center;
    margin: 0;
    padding: 150px 0 40px 0;
  }
}
@media (max-width: 575px) {
  .hero-style7 {
    padding: 100px 0 40px 0;
  }
}
.hero-style7 .hero-title {
  margin-bottom: 42px;
}
@media (max-width: 1299px) {
  .hero-style7 .hero-title {
    font-size: 60px;
  }
}
@media (max-width: 1299px) {
  .hero-style7 .hero-title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .hero-style7 .hero-title {
    font-size: 40px;
  }
}
@media (max-width: 375px) {
  .hero-style7 .hero-title {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .hero-style7 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.hero-form {
  background: -webkit-linear-gradient(
    303.52deg,
    rgba(255, 255, 255, 0.2) 1.3%,
    rgba(0, 0, 0, 0.1) 117.69%
  );
  background: linear-gradient(
    146.48deg,
    rgba(255, 255, 255, 0.2) 1.3%,
    rgba(0, 0, 0, 0.1) 117.69%
  );
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 24px;
  padding: 40px;
  margin-left: -26px;
}
@media (max-width: 1299px) {
  .hero-form {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .hero-form {
    margin: 0 0 80px 0;
  }
}
@media (max-width: 375px) {
  .hero-form {
    padding: 30px 20px;
  }
}
.hero-form .sub-title2 {
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
}
@media (max-width: 375px) {
  .hero-form .sub-title2 {
    font-size: 18px;
  }
}
.hero-form .sec-title {
  font-size: 28px;
  font-weight: 600;
}
@media (max-width: 375px) {
  .hero-form .sec-title {
    font-size: 25px;
  }
}
.hero-form .form-item {
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #e1e4e6;
  border-radius: 8px;
  padding: 14px 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}
.hero-form .form-item:before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 15px;
  right: 20px;
}
.hero-form .form-item label {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin: 0;
}
.hero-form .form-item input {
  max-width: 105px;
}
.hero-form .form-item input,
.hero-form .form-item .nice-select {
  color: var(--white-color);
  height: auto;
  line-height: unset;
  background-color: transparent;
  border-radius: 0;
  border: none;
  font-weight: 400;
  padding: 0;
}
.hero-form .form-item .nice-select {
  max-width: 90px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.hero-form .form-item .nice-select:after {
  display: none;
}
.hero-form .form-item .nice-select .option {
  color: var(--title-color);
}
.hero-form .form-item .nice-select .option.selected {
  color: var(--theme-color) !important;
  background: var(--theme-color) !important;
}
.hero-form .form-item .nice-select .option.selected.focus {
  color: #fff !important;
  background: var(--theme-color) !important;
}
.hero-form .form-item .nice-select .option:hover,
.hero-form .form-item .nice-select .option.focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}
.hero-form .form-item .nice-select.open .list {
  border-bottom: 3px solid var(--theme-color);
}
.hero-8 {
  overflow: visible;
}
.hero-8 .th-hero-image {
  position: absolute;
  top: 0%;
  right: 0%;
}
@media (max-width: 1299px) {
  .hero-8 .th-hero-image {
    position: relative;
  }
}
.hero-8 .th-hero-image .hero-img {
  width: 969px;
  height: 950px;
}
@media (max-width: 1799px) {
  .hero-8 .th-hero-image .hero-img {
    width: 850px;
  }
}
@media (max-width: 1599px) {
  .hero-8 .th-hero-image .hero-img {
    width: 750px;
  }
}
@media (max-width: 1399px) {
  .hero-8 .th-hero-image .hero-img {
    width: 640px;
  }
}
@media (max-width: 1299px) {
  .hero-8 .th-hero-image .hero-img {
    width: 100%;
    height: 100%;
  }
}
.hero-8 .th-hero-image .hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-8 .booking-sec {
  position: absolute;
  width: 100%;
  bottom: 60px;
}
.hero-style8 {
  padding: 233px 0 285px 0;
  max-width: 650px;
}
@media (max-width: 1399px) {
  .hero-style8 {
    max-width: 703px;
  }
}
@media (max-width: 1299px) {
  .hero-style8 {
    text-align: center;
    display: block;
    margin: auto;
    padding: 220px 0 50px;
  }
}
@media (max-width: 991px) {
  .hero-style8 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .hero-style8 {
    padding: 180px 0 50px;
  }
}
.hero-style8 .sub-title {
  display: block;
}
.hero-style8 .hero-title {
  text-transform: uppercase;
  margin-bottom: 48px;
}
@media (max-width: 1399px) {
  .hero-style8 .hero-title {
    font-size: 60px;
  }
}
@media (max-width: 1299px) {
  .hero-style8 .hero-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .hero-style8 .hero-title {
    font-size: 40px;
  }
}
@media (max-width: 375px) {
  .hero-style8 .hero-title {
    font-size: 35px;
  }
}
.hero-style8 .hero-text {
  font-size: 18px;
  color: var(--white-color);
  max-width: 703px;
  margin-bottom: 38px;
}
@media (max-width: 1399px) {
  .hero-style8 .hero-text {
    max-width: 650px;
  }
}
.error-img {
  text-align: center;
  min-width: 926px;
  margin-left: -100px;
}
@media (max-width: 1699px) {
  .error-img {
    min-width: 100%;
  }
}
@media (max-width: 1299px) {
  .error-img {
    margin-left: 0;
  }
}
.error-title {
  margin-bottom: 20px;
}
.error-text {
  margin-bottom: 35px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.error-subtitle {
  font-size: 28px;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .error-subtitle {
    font-size: 18px;
  }
}
.error-content {
  margin-right: 90px;
}
@media (max-width: 1299px) {
  .error-content {
    margin-right: 0;
  }
}
.error-content .th-btn img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
@media (max-width: 991px) {
  .error-title {
    margin-bottom: 15px;
  }
  .error-img {
    margin-bottom: 30px;
    margin-left: 0;
  }
  .error-text {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .error-title {
    margin-bottom: 10px;
  }
}
.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-color);
  background-color: transparent;
  font-size: 22px;
  border-radius: 50%;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  color: var(--theme-color);
}
.popup-search-box button.searchClose:hover {
  color: var(--body-color);
  background-color: #fff;
  border-color: transparent;
  border-color: transparent;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform ease 0.4s;
  transition: -webkit-transform ease 0.4s;
  transition: transform ease 0.4s;
  transition:
    transform ease 0.4s,
    -webkit-transform ease 0.4s;
}
@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}
.popup-search-box form input {
  font-size: 18px;
  height: 70px;
  width: 100%;
  border: 2px solid var(--theme-color);
  background-color: transparent;
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}
.popup-search-box form input::-moz-placeholder {
  color: #fff;
}
.popup-search-box form input::-webkit-input-placeholder {
  color: #fff;
}
.popup-search-box form input:-ms-input-placeholder {
  color: #fff;
}
.popup-search-box form input::-ms-input-placeholder {
  color: #fff;
}
.popup-search-box form input::placeholder {
  color: #fff;
}
.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  right: 12px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
  -ms-transform: scale(1.001);
  transform: scale(1.001);
}
.popup-search-box form button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 0;
}
.popup-search-box.show form {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.sidemenu-wrapper .th-social a {
  box-shadow: none;
}
.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: 2px solid;
  width: 50px;
  height: 50px;
  line-height: 48px;
  font-size: 24px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--white-color);
  border-radius: 50%;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.sidemenu-wrapper .closeButton:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidemenu-wrapper .sidemenu-content {
  background-color: #fff;
  width: 450px;
  margin-left: auto;
  padding: 80px 30px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transition: right ease 1s;
  transition: right ease 1s;
}
@media (max-width: 450px) {
  .sidemenu-wrapper .sidemenu-content {
    width: 300px;
  }
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content .th-widget-about .footer-info-title {
  color: var(--title-color);
}
.sidemenu-wrapper .sidemenu-content .widget_title {
  color: var(--title-color);
}
.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
}
.sidemenu-wrapper .info-box_icon {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}
.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.woocommerce-message,
.woocommerce-info {
  position: relative;
  padding: 11px 20px 11px 50px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
.woocommerce-message a,
.woocommerce-info a {
  color: var(--white-color);
  text-decoration: underline;
}
.woocommerce-message a:hover,
.woocommerce-info a:hover {
  color: var(--title-color);
}
.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 400;
  margin-right: 10px;
  font-size: 18px;
  position: absolute;
  left: 20px;
  top: 11px;
}
.woocommerce-checkout select,
.woocommerce-checkout .form-control,
.woocommerce-checkout .form-select,
.woocommerce-checkout textarea,
.woocommerce-checkout input {
  border: 1px solid var(--smoke-color);
  background-color: var(--smoke-color);
}
.woocommerce-checkout select:focus,
.woocommerce-checkout .form-control:focus,
.woocommerce-checkout .form-select:focus,
.woocommerce-checkout textarea:focus,
.woocommerce-checkout input:focus {
  background-color: var(--white-color);
  border-color: var(--theme-color);
}
.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
}
.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.woocommerce-form-login-toggle .woocommerce-info a {
  color: inherit;
}
.woocommerce-form-login-toggle .woocommerce-info a:hover {
  color: var(--title-color);
}
.woocommerce-form-register,
.woocommerce-form-coupon,
.woocommerce-form-login {
  padding: 35px 40px 35px 40px;
  background-color: var(--white-color);
  border-radius: 8px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .woocommerce-form-register,
  .woocommerce-form-coupon,
  .woocommerce-form-login {
    padding: 40px 20px;
  }
}
.woocommerce-form-register .form-group,
.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
  margin-bottom: 20px;
}
.woocommerce-form-register .form-group:last-child,
.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}
.woocommerce-form-register .form-group select,
.woocommerce-form-register .form-group .form-control,
.woocommerce-form-register .form-group .form-select,
.woocommerce-form-register .form-group textarea,
.woocommerce-form-register .form-group input,
.woocommerce-form-coupon .form-group select,
.woocommerce-form-coupon .form-group .form-control,
.woocommerce-form-coupon .form-group .form-select,
.woocommerce-form-coupon .form-group textarea,
.woocommerce-form-coupon .form-group input,
.woocommerce-form-login .form-group select,
.woocommerce-form-login .form-group .form-control,
.woocommerce-form-login .form-group .form-select,
.woocommerce-form-login .form-group textarea,
.woocommerce-form-login .form-group input {
  background-color: var(--white-color);
}
.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 0;
  font-weight: 500;
  font-size: 14px;
}
nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}
nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}
nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}
nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}
.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}
.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}
.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
}
table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}
table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}
table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}
table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}
table.variations .label,
.woocommerce-grouped-product-list-item .label {
  border: none;
}
table.variations__label,
.woocommerce-grouped-product-list-item__label {
  border: none !important;
  font-weight: 600;
}
table.variations__price,
.woocommerce-grouped-product-list-item__price {
  border: none !important;
}
table.variations__price .price,
table.variations__price .amount,
.woocommerce-grouped-product-list-item__price .price,
.woocommerce-grouped-product-list-item__price .amount {
  font-size: 18px !important;
}
table.variations del,
.woocommerce-grouped-product-list-item del {
  margin-left: 12px;
}
.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--th-border-color);
}
.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}
.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}
.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}
.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}
.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}
.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}
.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}
.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}
table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}
table.variations td {
  padding: 0;
}
table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}
table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}
.woosq-product .product .woocommerce-grouped-product-list-item__quantity,
.woosq-product .product .woocommerce-grouped-product-list-item__label,
.woosq-product .product .woocommerce-grouped-product-list-item__price {
  width: auto !important;
}
.woocommerce-grouped-product-list-item__label:after,
.woocommerce-grouped-product-list-item__price:after {
  display: none;
}
.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}
.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}
.wooscp-table-items td.woocommerce-product-attributes-item__value {
  padding-left: 15px !important;
}
.wooscp-table-items a.added_to_cart.wc-forward {
  margin-left: 15px;
  text-decoration: underline;
}
.tinvwl_added_to_wishlist.tinv-modal.tinv-modal-open {
  z-index: 1111;
}
table.woocommerce-product-attributes {
  margin-bottom: 30px;
}
#woosq-popup .product_meta {
  margin-top: 20px;
}
#woosq-popup .product_title {
  font-size: 24px;
  margin-bottom: 5px;
}
#woosq-popup .single-product .product .actions {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
#woosq-popup .single-product .product .actions > div {
  height: auto;
  overflow: visible;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
#woosq-popup
  .single-product
  .product
  .actions
  > div
  .quantity.style2.woocommerce-grouped-product-list-item__quantity {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.login-tab {
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login-tab button.nav-link {
  background-color: var(--smoke-color);
  color: var(--title-color);
  padding: 11px 39px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px ​15px 0;
}
.login-tab button.nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 900;
  font-size: 12px;
}
.star-rating:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  color: #e1e1e1;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
}
.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}
.star-rating span:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  top: 0;
  position: absolute;
  left: 0;
  color: #ff9c00;
  letter-spacing: 3px;
}
.rating-select label {
  margin: 0;
  margin-right: 10px;
}
.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
}
.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}
.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\e28b";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}
.rating-select p.stars a:hover ~ a::before {
  content: "\e28b";
  font-weight: 400;
}
.rating-select p.stars:hover a::before {
  content: "\e28b";
  font-weight: 700;
}
.rating-select p.stars.selected a.active::before {
  content: "\e28b";
  font-weight: 700;
}
.rating-select p.stars.selected a.active ~ a::before {
  content: "\e28b";
  font-weight: 400;
}
.rating-select p.stars.selected a:not(.active)::before {
  content: "\e28b";
  font-weight: 700;
}
@media (max-width: 767px) {
  .woocommerce-message,
  .woocommerce-info {
    font-size: 14px;
    line-height: 22px;
    padding: 10px 15px 10px 37px;
  }
  .woocommerce-message:before,
  .woocommerce-info:before {
    font-size: 16px;
    top: 10px;
    left: 15px;
  }
}
.th-product {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  background: var(--white-color);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
}
@media (max-width: 1299px) {
  .th-product {
    padding: 25px;
  }
}
.th-product .product-title {
  font-size: 20px;
  margin: 2px 0 2px 0;
  font-weight: 600;
}
@media (max-width: 1299px) {
  .th-product .product-title {
    font-size: 18px;
  }
}
.th-product .product-title a {
  color: inherit;
}
.th-product .product-title a:hover {
  color: var(--theme-color);
}
.th-product .product-category {
  color: var(--body-color);
  margin-bottom: 0px;
  display: block;
}
.th-product .product-category:hover {
  color: var(--theme-color);
}
.th-product .product-tag {
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0 12px;
  min-width: 60px;
  border-radius: 4px;
  z-index: 3;
  line-height: 24px;
}
.th-product .woocommerce-product-rating {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  margin-top: 3px;
  margin-bottom: -0.4rem;
}
.th-product .product-content {
  margin-bottom: -0.4rem;
}
.th-product .star-rating {
  margin: 0 auto 0 auto;
  width: 93px;
}
.th-product .price {
  display: block;
  color: var(--theme-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  font-family: var(--body-font);
}
.th-product .price del {
  margin-left: 10px;
  font-weight: 400;
  color: var(--body-color);
}
.th-product .product-img {
  --space: 0px;
  background-color: var(--smoke-color);
  overflow: hidden;
  position: relative;
  margin: 0 0 20px 0;
  border-radius: 8px;
  text-align: center;
  z-index: 2;
}
.th-product .product-img:before {
  --space: 0px;
  content: "";
  height: calc(100% - var(--space) * 2);
  width: calc(100% - var(--space) * 2);
  border-radius: inherit;
  position: absolute;
  top: var(--space);
  left: var(--space);
  background-color: var(--title-color);
  z-index: 1;
  border-radius: 8px;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.th-product .product-img img {
  width: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.th-product .actions {
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.th-product .actions .icon-btn {
  --btn-size: 45px;
  line-height: 42px;
  font-size: 16px;
  border-radius: 50%;
  border-color: var(--white-color);
  background-color: var(--white-color);
  color: var(--title-color);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}
.th-product .actions .icon-btn:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.th-product .actions > * {
  margin: 0 var(--icon-gap-x, 5px);
}
.th-product .actions > * > a {
  margin: 0;
}
.th-product
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.th-product
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 45px;
  height: 45px;
  line-height: 42px;
  display: inline-block;
}
.th-product
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.th-product
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  position: relative;
  top: 0;
  left: 0;
  line-height: inherit;
  margin: 0;
  font-size: 24px;
}
.th-product .tinv-wishlist a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 50%;
}
.th-product .tinv-wishlist a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.th-product .add_to_cart_button.added {
  display: none;
}
.th-product .added_to_cart {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 42px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 0;
  text-align: center;
  border-radius: 99px;
  border: none;
}
.th-product .added_to_cart:after {
  content: "\f07a";
  position: relative;
  font-family: var(--icon-font);
  font-size: 16px;
  font-weight: 700;
}
.th-product .added_to_cart:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.th-product .action-btn {
  background-color: var(--white-color);
  font-size: 14px;
  font-family: var(--title-font);
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  padding: 13px 25px;
}
.th-product .product-actions {
  position: absolute;
  left: 50%;
  bottom: -10%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.th-product .product-actions .th-btn {
  padding: 13px 15px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.th-product .product-actions .th-btn:hover:before {
  background-color: var(--theme-color);
}
.th-product:hover .product-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.th-product:hover .product-img:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  visibility: visible;
  opacity: 1;
}
.th-product:hover .product-actions {
  visibility: visible;
  opacity: 1;
  bottom: 35%;
}
.th-product:hover .product-actions .th-btn {
  background: var(--white-color);
}
.th-product:hover .product-actions .th-btn:before {
  background: var(--theme-color);
}
.th-product:hover .actions {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}
.th-product:hover .actions .icon-btn {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.th-product.list-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  text-align: left;
  height: 100%;
  padding: 10px;
}
@media (max-width: 991px) {
  .th-product.list-view {
    gap: 10px;
  }
}
.th-product.list-view .product-img {
  --space: 0px;
  width: 100%;
  width: 50%;
  margin: 0;
}
.th-product.list-view .product-img:before {
  --space: 0px;
}
.th-product.list-view .product-category {
  font-size: 13px;
  line-height: 23px;
  margin-top: -3px;
}
.th-product.list-view .star-rating {
  margin: 5px 0 0 0;
  width: 93px;
  font-size: 12px;
}
.th-product.list-view .product-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 4px 0 13px;
}
.th-product.list-view .actions {
  --icon-gap-x: 2px;
  width: 100%;
  right: 0;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.th-product.list-view .actions .icon-btn {
  --btn-size: 45px;
  --btn-font-size: 14px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: none;
}
.th-product.list-view .actions .icon-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.th-product.list-view
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.th-product.list-view
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.th-product.list-view
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.th-product.list-view
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  font-size: 20px;
}
.th-product.list-view .tinv-wishlist a {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.th-product.list-view .added_to_cart {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.th-product.list-view .added_to_cart:after {
  font-size: 16px;
}
.th-product.list-view .tag {
  top: 8px;
  right: 8px;
  padding: 0px 15px;
}
.product-list-area {
  margin-bottom: -24px;
}
.swiper-slide.style2 .th-product {
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06);
}
#productCarousel .slick-arrow {
  top: 37.5%;
}
.mfp-content {
  margin: 1.5rem auto;
}
.mfp-content .product-details-img {
  padding-top: 15px;
}
.mfp-content .product-about {
  padding-top: 20px;
  padding-bottom: 20px;
}
.mfp-content .container {
  position: relative;
}
.mfp-content .product-big-img {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.woosq-popup {
  border-radius: 10px;
}
.woosq-popup .product_meta > span > a:after,
.woosq-popup .product_meta > span > span:after {
  display: none;
}
.woosq-product > .product .summary {
  display: grid;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.woosq-product .thumbnails .slick-list {
  display: block;
}
.woosq-product .thumbnails .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background-color: var(--theme-color);
  opacity: 0.4;
}
.woosq-product .thumbnails .slick-dots li button:before {
  display: none;
}
.woosq-product .thumbnails .slick-dots li.slick-active button {
  opacity: 1;
}
.woosq-product .tinv-wraper {
  display: none;
}
.th-sort-bar {
  padding: 0;
  margin: 0 0 35px 0;
  background-color: transparent;
  border-radius: 0px;
}
.th-sort-bar .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 15px;
}
.th-sort-bar select {
  height: 50px;
  border: 0;
  background-color: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 154px;
  font-size: 16px;
  padding: 0 8px;
  margin: 0;
  color: var(--title-color);
  background-position: right 0px center;
}
.th-sort-bar .woocommerce-result-count {
  padding-left: 0;
  margin-bottom: 0;
  color: var(--theme-color);
}
.th-sort-bar .sorting-filter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}
.th-sort-bar .nav {
  border: 1px solid #e9f6f9;
  border-radius: 4px;
  padding: 4px;
}
.th-sort-bar .nav a {
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  border: 0;
  background-color: transparent;
  text-align: center;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--body-color);
  border-radius: 5px;
}
.th-sort-bar .nav a.active {
  color: var(--white-color);
  background: var(--theme-color);
}
.product-thumb-area {
  position: relative;
  margin-right: 10px;
}
.product-thumb-area .product-thumb-tab {
  position: absolute;
  top: 0;
  left: 0;
}
.product-thumb-tab {
  --thumb: 120px;
  --gap: 10px;
  max-width: var(--thumb);
  margin-left: calc(0px - var(--thumb) / 2);
  position: relative;
  z-index: 3;
  display: grid;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 100%;
}
.product-thumb-tab .tab-btn {
  background-color: var(--white-color);
  cursor: pointer;
  height: var(--thumb);
  width: var(--thumb);
  padding: var(--gap);
}
.product-thumb-tab .tab-btn img {
  max-width: 100%;
}
.product-thumb-tab .tab-btn:not(:last-of-type) {
  margin-bottom: 20px;
}
.product-thumb-tab .indicator {
  position: absolute;
  top: calc(var(--pos-y) + var(--gap));
  left: calc(var(--pos-x) + var(--gap));
  width: calc(var(--width-set) - var(--gap) * 2);
  height: calc(var(--height-set) - var(--gap) * 2);
  border: 2px solid var(--theme-color);
  pointer-events: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.product-big-img {
  background-color: var(--smoke-color);
  text-align: center;
  overflow: hidden;
  border-radius: 16px;
}
.product-big-img .img {
  width: 100%;
  border-radius: 16px;
}
.product-big-img .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.product-big-img .slider-pagination {
  position: absolute;
  bottom: 40px;
  left: 0;
}
.product-big-img .slick-dots {
  margin: -11px 0 0 0 !important;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  --border-color: #c0c0c0;
}
.img-magnifier-container {
  position: relative;
}
.img-magnifier-glass {
  position: absolute;
  box-shadow: inset 0 10px 18px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: none;
  width: 200px;
  height: 200px;
}
.magnify {
  position: relative;
  cursor: none;
}
.magnify .magnifier {
  height: 180px;
  width: 180px;
  position: absolute;
  z-index: 20;
  box-shadow: inset 0 10px 18px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-size: 700%;
  background-repeat: no-repeat;
  margin-left: -90px !important;
  margin-top: -90px !important;
  pointer-events: none;
  display: none;
}
.magnify .magnified {
  display: block;
  z-index: 10;
  margin: auto;
  width: 100%;
  height: 100%;
}
.magnify img {
  width: 100%;
  height: 100%;
}
.quantity {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.quantity > .screen-reader-text {
  display: inline-block;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 10px;
}
.quantity .qty-btn,
.quantity .qty-input {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: none;
  border-right: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  text-align: center;
  color: var(--body-color);
  font-size: 18px;
  font-weight: 600;
}
.quantity .qty-btn:last-child,
.quantity .qty-input:last-child {
  border-right: none;
}
.quantity .qty-btn {
  font-size: 16px;
}
.product_meta {
  font-weight: 700;
  font-size: 16px;
  font-family: var(--body-font);
  margin: 35px 0 0 0;
}
.product_meta > span {
  display: block;
  margin-bottom: 5px;
  color: var(--title-color);
  font-weight: bold;
}
.product_meta > span:last-child {
  margin-bottom: 0;
}
.product_meta > span a {
  color: inherit;
}
.product_meta > span a:hover {
  color: var(--theme-color);
}
.product_meta > span > a,
.product_meta > span > span {
  position: relative;
  color: var(--body-color);
  font-weight: 400;
}
.product_meta > span > a:after,
.product_meta > span > span:after {
  content: ",";
  margin-right: 5px;
}
.product_meta > span > a:last-child:after,
.product_meta > span > span:last-child:after {
  display: none;
}
.product_meta > span > a:first-child,
.product_meta > span > span:first-child {
  margin-left: 7px;
}
.product-tab-style1 {
  border-bottom: 1px solid #e4e4e4;
  margin: 95px 0 40px 0;
}
.product-tab-style1 li {
  margin: 0 45px 0 0;
}
.product-tab-style1 li:last-child {
  margin-right: 0;
}
.product-tab-style1 a {
  font-family: var(--title-font);
  background-color: transparent;
  border-radius: 0;
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 0 0 17px 0;
  text-transform: capitalize;
}
.product-tab-style1 a:before {
  content: "";
  position: absolute;
  left: 0;
  top: unset;
  bottom: -1px;
  height: 2px;
  width: 0;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.product-tab-style1 a:hover,
.product-tab-style1 a.active {
  color: var(--title-color);
}
.product-tab-style1 a:hover:before,
.product-tab-style1 a.active:before {
  width: 100%;
}
.product-tab-style2 {
  border-bottom: 1px solid #e4e4e4;
  margin: 95px 0 40px 0;
}
.product-tab-style2 li {
  margin: 0 45px 0 0;
}
.product-tab-style2 li:last-child {
  margin-right: 0;
}
.product-tab-style2 a {
  font-family: var(--title-font);
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 0 0 17px 0;
  text-transform: capitalize;
}
.product-tab-style2 a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 1px;
  width: 0;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.product-tab-style2 a.active:before {
  width: 100%;
}
#productTabContent {
  margin-bottom: -10px;
}
#additional_information {
  margin-bottom: 40px;
}
.product-inner-list > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.product-inner-list li {
  position: relative;
  padding-left: 15px;
}
.product-inner-list li:before {
  content: "-";
  position: absolute;
  left: 0;
}
.share-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  margin-bottom: 8px;
}
.share-title i {
  color: var(--theme-color);
}
.summary-content .product-title,
.product-about .product-title {
  margin: 0 0 5px 0;
}
.summary-content .product_title,
.product-about .product_title {
  margin-bottom: 20px !important;
}
.summary-content > .price,
.product-about > .price {
  font-family: var(--body-font);
  font-size: 20px;
  font-weight: 800;
  color: var(--theme-color);
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.summary-content > .price del,
.product-about > .price del {
  color: var(--gray-color);
  font-weight: 500;
  margin-left: 15px;
}
.summary-content .woocommerce-product-rating,
.summary-content .product-rating,
.product-about .woocommerce-product-rating,
.product-about .product-rating {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  top: 2px;
  font-size: 16px;
  line-height: 20px;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  margin-bottom: 15px;
}
.summary-content .woocommerce-product-rating .star-rating,
.summary-content .product-rating .star-rating,
.product-about .woocommerce-product-rating .star-rating,
.product-about .product-rating .star-rating {
  width: 80px;
  font-size: 12px;
  margin-right: 8px;
}
.summary-content .woocommerce-review-link,
.product-about .woocommerce-review-link {
  color: var(--body-color);
}
.summary-content .checklist,
.product-about .checklist {
  margin: 29px 0 29px 0;
}
.summary-content .actions,
.product-about .actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
  margin: 35px 0 25px 0;
}
.summary-content .actions .th-btn,
.product-about .actions .th-btn {
  padding: 17px 44.5px;
}
.summary-content .actions .th-btn .btn-icon,
.product-about .actions .th-btn .btn-icon {
  padding: 10.5px 15px 10.5px 15px;
}
.summary-content .actions .icon-btn,
.product-about .actions .icon-btn {
  --btn-size: 60px;
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 99px;
}
.summary-content .actions .icon-btn:hover,
.product-about .actions .icon-btn:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.summary-content .share,
.product-about .share {
  margin-top: 25px;
}
.summary-content .th-social a,
.product-about .th-social a {
  --icon-size: 40px;
  line-height: 38px;
  font-size: 14px;
  border: 1px solid var(--th-border-color);
  border-radius: 0;
}
.summary-content .th-social a:hover,
.product-about .th-social a:hover {
  border-color: var(--theme-color);
}
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  border-radius: 99px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
}
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  position: relative;
  top: 0;
  left: 0;
  line-height: inherit;
  margin: 0;
  font-size: 24px;
}
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt:hover,
.summary-content
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt:hover,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt:hover,
.product-about
  .tinv-wishlist
  .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt:hover {
  background-color: var(--title-color);
}
.summary-content .tinv-wishlist a,
.product-about .tinv-wishlist a {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 0;
}
.summary-content .tinv-wishlist a:hover,
.product-about .tinv-wishlist a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.summary-content .quantity,
.product-about .quantity {
  position: relative;
}
.summary-content .quantity:after,
.product-about .quantity:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--title-color);
  position: absolute;
  top: 0;
  left: 55px;
}
.summary-content .quantity .qty-input,
.product-about .quantity .qty-input {
  width: 101px;
  height: 60px;
  background-color: var(--title-color);
  color: var(--white-color);
  padding: 0 21px;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  border-radius: 48px;
}
.summary-content .quantity .qty-btn,
.product-about .quantity .qty-btn {
  color: var(--white-color);
  background-color: transparent;
  position: absolute;
  right: 21px;
  height: auto;
  width: auto;
  border: none;
  font-size: 14px;
  line-height: 16px;
}
.summary-content .quantity .quantity-minus,
.product-about .quantity .quantity-minus {
  bottom: 12px;
  color: rgba(255, 255, 255, 0.2);
}
.summary-content .quantity .quantity-plus,
.product-about .quantity .quantity-plus {
  top: 12px;
}
.woocommerce-Reviews .th-post-comment {
  border-radius: 20px;
}
.woocommerce-Reviews .th-post-comment .text {
  margin-bottom: -0.5em;
}
.woocommerce-Reviews .th-comments-wrap {
  padding: 0;
  margin-right: 0 !important;
  box-shadow: none;
  background-color: transparent;
}
.woocommerce-Reviews .th-comment-form {
  box-shadow: none;
  margin-right: 0 !important;
  margin-top: 28px;
  margin-bottom: 10px;
}
.woocommerce-Reviews .th-comment-form input,
.woocommerce-Reviews .th-comment-form .form-control {
  background-color: var(--white-color);
}
.woocommerce-Reviews .th-comment-form .blog-inner-title {
  margin-bottom: 10px;
}
.woocommerce-Reviews .comment-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0 24px;
  margin: -12px !important;
  margin-bottom: 28px !important;
}
.woocommerce-Reviews .comment-list li {
  width: 50%;
}
@media (max-width: 991px) {
  .woocommerce-Reviews .comment-list li {
    width: 100%;
  }
}
.woocommerce-Reviews .th-post-comment {
  border: 1px solid var(--th-border-color) !important;
  padding: 40px !important;
  box-shadow: 0px 4px 30px rgba(1, 15, 28, 0.06);
  margin: 12px;
  border-radius: 10px;
}
.woocommerce-Reviews .th-post-comment .name {
  margin-bottom: 3px;
}
.woocommerce-Reviews .th-post-comment .commented-on {
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .woocommerce-Reviews .comment-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
#description {
  margin-bottom: 30px;
}
.product-details .th-comments-wrap {
  margin-top: 0;
}
.product-details .border-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.product-details .border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 80px;
  background-color: var(--theme-color);
}
.product-inner-title {
  font-size: 32px;
  border-bottom: 1px solid var(--th-border-color);
  padding: 0 0 7px 0;
  margin: 0 0 30px 0;
}
.related-product-wrapper {
  padding-top: 115px;
}
@media (max-width: 1299px) {
  .product-thumb-tab {
    --thumb: 100px;
    margin-left: -40px;
  }
  .product-thumb-area {
    margin-right: 0;
  }
}
@media (max-width: 1199px) {
  .th-sort-bar select {
    min-width: auto;
  }
  .product-tab-style1 {
    margin: 55px 0 40px 0;
  }
  .summary-content,
  .product-about {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .product-big-img {
    margin-bottom: 40px;
  }
  .product-thumb-tab {
    margin-left: -10px;
  }
  .th-product-box.list-view .product-img {
    max-width: 150px;
  }
  .th-sort-bar .row {
    --bs-gutter-x: 20px;
  }
  .th-sort-bar .nav a:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .th-sort-bar .nav a:last-child:before {
    display: none;
  }
  .woosq-product > .product .thumbnails {
    max-height: 400px;
    min-height: 200px;
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .th-sort-bar {
    text-align: center;
    padding: 10px 15px 15px 15px;
  }
  .th-sort-bar .nav {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .th-sort-bar select {
    margin: 0 auto;
  }
  .th-sort-bar .woocommerce-result-count {
    padding-left: 0;
  }
  .th-product-box.list-view .product-img {
    max-width: 130px;
  }
  .th-product-box.list-view .actions {
    --btn-size: 30px;
    --btn-font-size: 10px;
    --icon-gap-x: 2px;
  }
}
@media (max-width: 575px) {
  .product-about .actions {
    gap: 15px 15px;
  }
  .product-thumb-tab {
    --thumb: 74px;
    --gap: 6px;
  }
  .product-thumb-tab .tab-btn:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
@media (max-width: 375px) {
  .th-product.list-view .product-img {
    max-width: 130px;
  }
  .th-product.list-view .product-content {
    padding: 0 15px;
  }
}
.productSlide {
  position: relative;
}
.productSlide .product-tag {
  font-size: 14px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: var(--theme-color);
  text-transform: uppercase;
  color: var(--white-color);
  padding: 0 12px;
  border-radius: 8px;
  min-width: 60px;
  z-index: 3;
  line-height: 24px;
}
.product-thumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.product-thumb .tab-btn {
  border: 1px solid var(--title-color);
  width: 60px;
  height: 60px;
}
@media (max-width: 375px) {
  .product-thumb .tab-btn {
    width: 40px;
    height: 40px;
  }
}
.product-thumb .tab-btn.active {
  border: 1px solid var(--theme-color);
}
.product-thumb-wrap {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 2;
}
@media (max-width: 1199px) {
  .product-thumb-wrap {
    left: 10px;
    bottom: 10px;
  }
}
@media (max-width: 991px) {
  .product-thumb-wrap {
    left: 20px;
    bottom: 20px;
  }
}
.woocommerce-cart-form {
  text-align: center;
}
.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}
.cart_table thead {
  background-color: #ecf0f1;
}
.cart_table thead th {
  border: none !important;
}
.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 800;
  border: none;
  padding: 27px 15px;
}
.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}
.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}
.cart_table .product-quantity {
  color: var(--title-color);
}
.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}
.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.cart_table .cart-productimage {
  display: inline-block;
  border: 2px solid var(--smoke-color);
}
.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}
.cart_table .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart_table td.product-quantity {
  min-width: 155px;
}
.cart_table .qty-btn {
  border: 2px solid var(--smoke-color) !important;
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 4px;
}
.cart_table .qty-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--smoke-color);
  width: 60px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: var(--title-color);
  font-weight: 700;
  margin: 0 3px;
  border-radius: 4px;
  padding: 0;
}
.cart_table .qty-input::-moz-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-webkit-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input:-ms-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-ms-input-placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::placeholder {
  color: var(--title-color);
}
.cart_table .qty-input::-webkit-outer-spin-button,
.cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart_table .qty-input[type="number"] {
  -moz-appearance: textfield;
}
.cart_table .actions {
  text-align: right;
  vertical-align: middle;
  padding-left: 20px;
}
.cart_table .actions > .th-btn {
  font-size: 16px;
  padding: 20px 28px;
  margin-right: 15px;
}
.cart_table .actions > .th-btn:last-child {
  margin-right: 0;
}
.cart_table .th-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.cart_table .th-cart-coupon input {
  width: calc(100% - 200px);
  margin-right: 10px;
}
.cart_table .th-cart-coupon .th-btn {
  font-size: 16px;
  padding: 20px 25px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.cart_totals {
  border: 1px solid #ecf0f1;
}
.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}
.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: #f9fbfb;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}
.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}
.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}
.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}
.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form {
  display: none;
}
.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}
.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}
.cart_totals .amount {
  font-weight: 700;
}
.cart_totals .order-total .amount {
  color: var(--theme-color);
}
.empty-notice {
  margin: 40px auto;
}
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .th-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cart_table .actions {
    text-align: center;
  }
  .cart_table .cart-productimage {
    max-width: 100px;
  }
}
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .th-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .th-btn:last-child {
    margin-right: auto;
  }
  .cart_table .th-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .th-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }
  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}
.wc-block-components-quantity-selector {
  height: 40px;
}
.wc-block-components-quantity-selector
  input.wc-block-components-quantity-selector__input {
  height: 40px;
}
.wc-block-components-product-badge {
  background: var(--theme-color);
  color: var(--white-color);
}
.wc-block-cart__submit-button {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.wc-block-grid .wc-block-grid__product-onsale,
.wc-block-grid__product-image .wc-block-grid__product-onsale {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
  padding: 12px 20px;
  border: none !important;
}
.woocommerce-checkout .form-group,
.woocommerce-checkout .form-row {
  margin-bottom: 0;
}
.woocommerce-checkout .form-select,
.woocommerce-checkout .select2-container,
.woocommerce-checkout .form-control {
  margin-bottom: 0;
}
.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: 0;
}
.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: 0;
}
.woocommerce-checkout .select2-dropdown {
  border: none;
  border-top: none;
}
.woocommerce-checkout .select2-container--default .select2-selection--single {
  border-radius: 27px;
}
.woocommerce-checkout
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.woocommerce-checkout
  .select2-container--default
  .select2-selection--single
  .form-control:focus {
  color: var(--body-color);
}
.woocommerce-checkout .shipping_address {
  margin-top: -3px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid transparent;
  padding: 0;
}
.woocommerce-form-login select,
.woocommerce-form-login .form-select,
.woocommerce-form-login .form-control,
.woocommerce-form-login .select2,
.woocommerce-form-login .select2-container,
.woocommerce-form-coupon select,
.woocommerce-form-coupon .form-select,
.woocommerce-form-coupon .form-control,
.woocommerce-form-coupon .select2,
.woocommerce-form-coupon .select2-container,
.woocommerce-checkout select,
.woocommerce-checkout .form-select,
.woocommerce-checkout .form-control,
.woocommerce-checkout .select2,
.woocommerce-checkout .select2-container {
  margin-bottom: var(--bs-gutter-x);
}
#ship-to-different-address {
  margin-top: 15px;
}
.select2-container--default .select2-selection--single {
  height: 56px;
  border: 1px solid var(--gray-color);
  border-radius: 4px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 56px;
  padding-left: 25px;
  padding-right: 25px;
}
.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  content: "\f107";
  font-family: var(--icon-font);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin: 0;
  border: none;
  top: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 56px;
  line-height: 56px;
  margin-right: 30px;
}
span.select2-selection.select2-selection--single:focus {
  outline: none;
}
.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
  vertical-align: top;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}
.checkout-ordertable th {
  font-weight: 800;
  text-align: left;
}
.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}
.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}
.checkout-ordertable input[type="hidden"] ~ label {
  color: var(--theme-color);
}
.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}
.woocommerce-checkout-payment {
  text-align: left;
}
.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.woocommerce-checkout-payment ul li {
  padding-top: 12px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0;
  font-size: 16px;
}
.woocommerce-checkout-payment ul input[type="radio"] ~ label {
  margin-bottom: 17px;
  color: var(--body-color);
}
.woocommerce-checkout-payment ul input[type="radio"] ~ label img {
  margin-bottom: -2px;
  margin-left: 10px;
}
.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}
.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}
.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}
.th-checkout-wrapper form.woocommerce-form {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }
  .woocommerce-checkout-payment ul input[type="radio"] ~ label img {
    max-width: 150px;
  }
  .checkout-ordertable th,
  .checkout-ordertable td {
    padding: 5px 20px 5px 60px;
  }
}
.components-button {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.components-button:hover {
  background-color: var(--title-color);
}
.tinv-wishlist input[type="checkbox"] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  vertical-align: middle;
  width: auto;
  height: auto;
}
.tinv-wishlist .tinv-header {
  margin-top: -0.8rem;
  text-transform: capitalize;
}
.tinv-wishlist .cart-empty {
  padding: 14px 25px;
  font-weight: 700;
  font-size: 14px;
  padding-left: 45px;
  border-radius: 8px;
}
.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  margin-top: 10px;
  font-weight: 700;
}
.tinv-wishlist p.return-to-shop .button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.tinv-wishlist table {
  border: none;
}
.tinv-wishlist table th {
  color: var(--title-color);
}
.tinv-wishlist table td,
.tinv-wishlist table th {
  padding: 15.3px 10px;
  border-bottom: 1px solid var(--th-border-color);
  text-align: center;
}
.tinv-wishlist table thead {
  background-color: var(--smoke-color);
}
.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
  width: 40px;
  text-align: center;
}
.tinv-wishlist .product-thumbnail {
  width: 110px;
}
.tinv-wishlist .stock.in-stock {
  margin-bottom: 0;
}
.tinv-wishlist ins {
  text-decoration: none;
}
.tinv-wishlist .product-remove button {
  border: none;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  border-radius: 0;
  padding-top: 0;
}
.tinv-wishlist .product-remove button i {
  line-height: 22px;
  font-size: 16px;
}
.tinv-wishlist .tinvwl-mobile {
  display: none;
}
.tinv-wishlist .social-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 295px;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tinv-wishlist .social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
}
.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 16px;
}
.tinv-wishlist .product-stock .stock {
  display: block;
}
.tinv-wishlist .product-stock span {
  display: inline;
}
.tinv-wishlist .product-stock i {
  margin-right: 5px;
}
.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}
.tinv-wishlist button.button {
  border: none;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--theme-color);
  color: #fff;
  padding: 1px 15px;
  min-width: 140px;
}
.tinv-wishlist button.button i {
  font-size: 18px !important;
  margin-right: 3px !important;
}
.tinv-wishlist button.button:hover {
  background-color: var(--title-color);
  color: #fff;
}
.tinv-wishlist .tinvwl-buttons-group button i {
  font-size: 14px;
}
.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}
.tinv-wishlist td.product-name a {
  color: var(--body-color);
}
.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}
.tinv-wishlist td.product-price del {
  margin-left: 8px;
  font-size: 0.9em;
}
.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}
.tinv-wishlist .social-buttons li {
  display: inline-block;
  margin-right: 0;
}
.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  margin-left: 3px;
}
.tinv-wishlist .social-buttons li a.social:first-child {
  margin-left: 0;
}

.th-btn {
  padding: 10px 20px;
  background-color: #00475e;
  color: #fff;
  border-radius: 25px;
}

.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}
.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
@media (max-width: 991px) {
  .tinvwl-full {
    display: none;
  }
  .tinv-wishlist .tinvwl-mobile {
    display: block;
  }
  .tinvwl-txt {
    display: inline-block !important;
  }
  .product-stock {
    width: 40px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
    border-bottom: 1px solid var(--th-border-color);
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove,
  .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }
  .tinv-wishlist table td,
  .tinv-wishlist table th {
    border: 1px solid var(--th-border-color);
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  .product-name {
    text-align: center;
  }
  .tinv-wishlist table td,
  .tinv-wishlist table th {
    border-bottom: none;
  }
  .tinv-wishlist table tfoot {
    border-bottom: 1px solid var(--th-border-color);
  }
  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tinv-wishlist .social-buttons ul {
    margin-left: unset;
    margin-top: 5px;
  }
  .tinvwl-txt {
    display: inline-block !important;
  }
}
.contact-form {
  background-color: var(--white-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 16px;
  padding: 40px;
}
@media (max-width: 1299px) {
  .contact-form {
    padding: 30px;
  }
}
.contact-form select::-moz-placeholder,
.contact-form .form-control::-moz-placeholder,
.contact-form .form-select::-moz-placeholder,
.contact-form textarea::-moz-placeholder,
.contact-form input::-moz-placeholder {
  color: var(--body-color);
}
.contact-form select::-webkit-input-placeholder,
.contact-form .form-control::-webkit-input-placeholder,
.contact-form .form-select::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder,
.contact-form input::-webkit-input-placeholder {
  color: var(--body-color);
}
.contact-form select:-ms-input-placeholder,
.contact-form .form-control:-ms-input-placeholder,
.contact-form .form-select:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder,
.contact-form input:-ms-input-placeholder {
  color: var(--body-color);
}
.contact-form select::-webkit-input-placeholder,
.contact-form .form-control::-webkit-input-placeholder,
.contact-form .form-select::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder,
.contact-form input::-webkit-input-placeholder {
  color: var(--body-color);
}
.contact-form select::-moz-placeholder,
.contact-form .form-control::-moz-placeholder,
.contact-form .form-select::-moz-placeholder,
.contact-form textarea::-moz-placeholder,
.contact-form input::-moz-placeholder {
  color: var(--body-color);
}
.contact-form select:-ms-input-placeholder,
.contact-form .form-control:-ms-input-placeholder,
.contact-form .form-select:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder,
.contact-form input:-ms-input-placeholder {
  color: var(--body-color);
}
.contact-form select::-ms-input-placeholder,
.contact-form .form-control::-ms-input-placeholder,
.contact-form .form-select::-ms-input-placeholder,
.contact-form textarea::-ms-input-placeholder,
.contact-form input::-ms-input-placeholder {
  color: var(--body-color);
}
.contact-form select::placeholder,
.contact-form .form-control::placeholder,
.contact-form .form-select::placeholder,
.contact-form textarea::placeholder,
.contact-form input::placeholder {
  color: var(--body-color);
}
.contact-form .nice-select .option {
  color: var(--title-color);
}
.contact-form .nice-select .option.selected {
  color: var(--theme-color) !important;
  background: var(--theme-color) !important;
}
.contact-form .nice-select .option.selected.focus {
  color: #fff !important;
  background: var(--theme-color) !important;
}
.contact-form .nice-select .option:hover,
.contact-form .nice-select .option.focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}
.contact-form .nice-select.open .list {
  border-bottom: 3px solid var(--theme-color);
}
.contact-form i {
  color: #888c97;
}
.contact-form textarea.form-control,
.contact-form textarea {
  min-height: 159px;
  padding-top: 16px;
  padding-bottom: 17px;
}
.contact-form .nice-select:after {
  border-bottom: 1px solid var(--body-color);
  border-right: 1px solid var(--body-color);
}
.contact-form .th-btn {
  padding: 19.5px 42px;
}
.contact-form .th-btn i {
  color: var(--white-color);
}
.contact-form.style2 textarea.form-control,
.contact-form.style2 textarea {
  min-height: 130px;
}
.contact-form.style2 select,
.contact-form.style2 .form-control,
.contact-form.style2 .form-select,
.contact-form.style2 textarea,
.contact-form.style2 input {
  height: 64px;
}
.contact-form2 {
  background-color: var(--white-color);
  border-radius: 16px 16px 0 0;
  padding: 40px;
}
@media (max-width: 575px) {
  .contact-form2 {
    padding: 30px;
  }
}
.contact-form2 .nice-select .option {
  color: var(--title-color);
}
.contact-form2 .nice-select .option.selected {
  color: var(--theme-color) !important;
  background: var(--theme-color) !important;
  font-weight: 400;
}
.contact-form2 .nice-select .option.selected.focus {
  color: #fff !important;
  background: var(--theme-color) !important;
}
.contact-form2 .nice-select .option:hover,
.contact-form2 .nice-select .option.focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}
.contact-form2 .nice-select.open .list {
  border-bottom: 3px solid var(--theme-color);
}
.form-btn-wrapp {
  background-color: var(--theme-color);
  border-radius: 0px 0px 16px 16px;
  padding: 16px 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .form-btn-wrapp {
    display: block;
  }
}
@media (max-width: 480px) {
  .form-btn-wrapp .th-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
.form-btn-wrapp .th-btn:hover {
  color: var(--white-color);
}
.form-btn-wrapp .th-btn:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.form-btn-wrapp .contact-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
.form-btn-wrapp .contact-info_link {
  color: var(--white-color);
  margin-bottom: 0;
}
.form-btn-wrapp .contact-info_link a {
  color: inherit;
}
.about-contact-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  border: 1px solid #e1e4e6;
  border-radius: 8px;
  padding: 24px 24px 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 300px;
}
@media (max-width: 375px) {
  .about-contact-grid {
    gap: 14px;
    padding: 15px 15px 18px;
  }
}
.about-contact-grid:not(:last-child) {
  margin-bottom: 30px;
}
.about-contact-grid .about-contact-icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 70px;
  height: 70px;
  line-height: 68px;
  text-align: center;
  background: var(--title-color);
  border-radius: 50%;
  color: var(--white-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 375px) {
  .about-contact-grid .about-contact-icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.about-contact-grid .about-contact-icon img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.about-contact-grid .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: -0.3em;
  margin-bottom: 2px;
}
.about-contact-grid .about-contact-details-text {
  color: var(--title-color);
  margin-bottom: 0;
}
.about-contact-grid .about-contact-details-text a:not(:hover) {
  color: initial;
}
.about-contact-grid:hover .about-contact-icon {
  background: var(--theme-color);
}
.contact-form-area {
  padding-top: 152px;
}
@media (max-width: 991px) {
  .contact-form-area {
    padding-top: 0px;
  }
}
.contact-map {
  position: relative;
  line-height: 0px;
  border-radius: 30px;
}
@media (max-width: 991px) {
  .contact-map {
    margin-bottom: 40px;
  }
}
.contact-map .contact-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.contact-map .contact-icon i {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 24px;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
}
@media (max-width: 991px) {
  .contact-map .contact-icon i {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}
.contact-map .contact-icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  z-index: 3;
}
.contact-map .contact-icon img {
  -webkit-animation: jumpAni 2s ease-in-out infinite alternate;
  animation: jumpAni 2s ease-in-out infinite alternate;
}
.contact-map .contact-icon:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0;
  margin: auto;
  top: 90%;
  width: 38px;
  height: 18px;
  border-radius: 50%;
  background: rgba(13, 13, 12, 0.2);
  z-index: -1;
}
.contact-map .contact-icon:before {
  content: "";
  position: absolute;
  left: -40px;
  right: 0;
  margin: auto;
  top: 50%;
  width: 130px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--white-color);
  z-index: -1;
}
@-webkit-keyframes location-anim {
  0% {
    -webkit-transform: rotate(5deg) translate(5px, 5px);
    transform: rotate(5deg) translate(5px, 5px);
  }
  100% {
    -webkit-transform: rotate(5deg) translate(-5px, -5px);
    transform: rotate(5deg) translate(-5px, -5px);
  }
}
@keyframes location-anim {
  0% {
    -webkit-transform: rotate(5deg) translate(5px, 5px);
    transform: rotate(5deg) translate(5px, 5px);
  }
  100% {
    -webkit-transform: rotate(5deg) translate(-5px, -5px);
    transform: rotate(5deg) translate(-5px, -5px);
  }
}
@-webkit-keyframes location-anim2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes location-anim2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.contact-map iframe {
  width: 100%;
  height: 365px;
  border-radius: 30px;
}
.contact-map.style2 iframe {
  width: 100%;
  height: 398px;
  border-radius: 0;
}
.contact-map.style3 iframe {
  border-radius: 16px;
}
.contact-text {
  max-width: 369px;
  font-size: 18px;
}
.cantact-area6 {
  padding: 188px 0;
}
@media (max-width: 991px) {
  .cantact-area6 {
    padding: 100px 0;
  }
}
.global-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.global-img:after {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.global-img img {
  width: 100%;
  object-fit: cover;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
@media (max-width: 991px) {
  .global-img {
    min-width: 100%;
    margin-bottom: 30px;
  }
}
.global-img:hover:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.global-img:hover img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.img-box1 {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .img-box1 {
    margin-bottom: 50px;
  }
}
.img-box1 .img1 {
  position: relative;
  overflow: hidden;
}
@media (max-width: 1299px) {
  .img-box1 .img1 {
    max-width: 280px;
  }
}
@media (max-width: 480px) {
  .img-box1 .img1 {
    max-width: 100%;
  }
}
.img-box1 .img1 img {
  border-radius: 156px 156px 0px 156px;
}
.img-box1 .img2 {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .img-box1 .img2 {
    max-width: 280px;
  }
}
@media (max-width: 1299px) {
  .img-box1 .img2 {
    max-width: 220px;
  }
}
@media (max-width: 1199px) {
  .img-box1 .img2 {
    right: 40%;
  }
}
@media (max-width: 991px) {
  .img-box1 .img2 {
    right: 20%;
  }
}
@media (max-width: 767px) {
  .img-box1 .img2 {
    right: 0%;
  }
}
@media (max-width: 480px) {
  .img-box1 .img2 {
    display: none;
  }
}
.img-box1 .img2 img {
  border-radius: 156px 156px 156px 0px;
}
.img-box1 .img3 {
  position: absolute;
  bottom: -20px;
  right: 0;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .img-box1 .img3 {
    max-width: 280px;
  }
}
@media (max-width: 1299px) {
  .img-box1 .img3 {
    max-width: 220px;
  }
}
@media (max-width: 1199px) {
  .img-box1 .img3 {
    right: 40%;
  }
}
@media (max-width: 991px) {
  .img-box1 .img3 {
    right: 20%;
  }
}
@media (max-width: 767px) {
  .img-box1 .img3 {
    right: 0%;
  }
}
@media (max-width: 480px) {
  .img-box1 .img3 {
    display: none;
  }
}
.img-box1 .img3 img {
  border-radius: 156px 0px 156px 156px;
}
.img-box2 {
  position: relative;
}
@media (max-width: 1199px) {
  .img-box2 {
    margin-bottom: 80px;
  }
}
@media (max-width: 575px) {
  .img-box2 img {
    width: 100%;
  }
}
.img-box2 .img1 img {
  border-radius: 24px;
}
.img-box2 .img2 {
  position: absolute;
  bottom: -13.4%;
  right: 4.5%;
}
@media (max-width: 767px) {
  .img-box2 .img2 {
    position: relative;
    right: unset;
    bottom: 0;
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .img-box2 .img2 {
    position: relative;
    margin-top: 20px;
  }
}
.img-box2 .img2 img {
  width: 100%;
  border-radius: 24px;
}
.img-box2 .img2 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.img-box2 .img2 .play-btn i {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.about-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  max-width: 400px;
}
.about-item:not(:last-child) {
  margin-bottom: 30px;
}
.about-item.style2 .about-item_img {
  background-color: var(--smoke-color);
}
.about-item_img {
  min-width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--theme-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.about-item_img img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.about-item_img:hover {
  background-color: var(--title-color);
}
.about-item_img:hover img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.about-item .box-title {
  margin-bottom: 7px;
}
@media (max-width: 375px) {
  .about-item .box-title {
    font-size: 20px;
  }
}
.about-item_text {
  margin-bottom: -0.3rem;
}
.about-shape {
  position: absolute;
}
.about-shape:before {
  content: "";
  position: absolute;
  left: -47%;
  bottom: 17%;
  width: 396px;
  height: 396px;
  border-radius: 50%;
  background: #e9f6f9;
  z-index: -1;
}
.about-rating {
  width: 62px;
  height: 62px;
  line-height: 62px;
  border-radius: 50%;
  background-color: var(--white-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  text-align: center;
  padding: 10px;
}
.about-rating i {
  font-size: 16px;
  color: #eb5757;
  display: block;
}
.about-rating span {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: var(--black-color);
}
.about-emoji {
  width: 62px;
  height: 62px;
  line-height: 62px;
  border-radius: 50%;
  background-color: var(--white-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  text-align: center;
}
.img-box3 {
  position: relative;
  margin-left: -110px;
}
@media (max-width: 1599px) {
  .img-box3 {
    margin-left: 0;
  }
}
@media (max-width: 1199px) {
  .img-box3 {
    margin-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .img-box3 img {
    width: 100%;
  }
}
.img-box3 .img1 img {
  border-radius: 24px;
}
.img-box3 .img2 {
  position: absolute;
  top: 20%;
  right: 12%;
}
@media (max-width: 767px) {
  .img-box3 .img2 {
    right: 0;
  }
}
@media (max-width: 575px) {
  .img-box3 .img2 {
    position: relative;
    margin-top: 20px;
  }
}
.img-box3 .img2 img {
  border-radius: 24px;
  border: 5px solid var(--white-color);
}
.img-box3 .img3 {
  position: relative;
  margin-top: 30px;
  z-index: 3;
}
@media (max-width: 575px) {
  .img-box3 .img3 {
    margin-top: 20px;
  }
}
.img-box3 .img3 img {
  border-radius: 24px;
  border: 5px solid var(--white-color);
}
.img-box4 {
  position: relative;
}
@media (max-width: 1199px) {
  .img-box4 {
    margin-bottom: 70px;
  }
}
@media (max-width: 375px) {
  .img-box4 {
    margin-bottom: 10px;
  }
}
.img-box4 .img1 img {
  border-radius: 24px;
}
.img-box4 .img2 {
  position: absolute;
  bottom: 0%;
  left: -10%;
  z-index: 2;
}
@media (max-width: 1199px) {
  .img-box4 .img2 {
    left: 0;
  }
}
@media (max-width: 375px) {
  .img-box4 .img2 {
    position: relative;
    top: 15px;
  }
}
.img-box4 .img2:before {
  content: "";
  position: absolute;
  inset: -5px;
  left: 1px;
  bottom: 1px;
  background-color: var(--white-color);
  border-radius: 24px;
  z-index: -1;
}
.img-box4 .img2:after {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    bottom,
    rgba(13, 13, 12, 0.3),
    rgba(13, 13, 12, 0.3)
  );
  background: linear-gradient(
    0deg,
    rgba(13, 13, 12, 0.3),
    rgba(13, 13, 12, 0.3)
  );
  border-radius: 24px;
}
.img-box4 .img2 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.img-box4 .img2 img {
  width: 100%;
  border-radius: 24px;
}
.about-area6 {
  position: relative;
  background-color: var(--white-color);
  border-radius: 24px;
  padding: 60px;
  z-index: 4;
  margin-right: -55%;
}
@media (max-width: 1199px) {
  .about-area6 {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .about-area6 {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .about-area6 {
    padding: 30px;
  }
}
@media (max-width: 375px) {
  .about-area6 {
    padding: 30px 10px;
  }
}
.img-box5 .img1 img {
  width: 100%;
  border-radius: 24px;
}
.img-box6 {
  position: relative;
  min-width: 680px;
}
@media (max-width: 1199px) {
  .img-box6 {
    margin-bottom: 50px;
  }
}
.img-box6 .img1 {
  position: relative;
  overflow: hidden;
}
@media (max-width: 1299px) {
  .img-box6 .img1 {
    max-width: 280px;
  }
}
@media (max-width: 480px) {
  .img-box6 .img1 {
    max-width: 100%;
  }
}
.img-box6 .img1 img {
  border-radius: 100px 0px;
}
.img-box6 .img2 {
  position: absolute;
  top: 0;
  right: 0px;
  min-width: 328px;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .img-box6 .img2 {
    max-width: 280px;
  }
}
@media (max-width: 1299px) {
  .img-box6 .img2 {
    min-width: 220px;
    right: 13%;
  }
}
@media (max-width: 1199px) {
  .img-box6 .img2 {
    right: 35%;
  }
}
@media (max-width: 991px) {
  .img-box6 .img2 {
    right: 15%;
  }
}
@media (max-width: 767px) {
  .img-box6 .img2 {
    min-width: 180px;
    max-width: 220px;
    right: 23%;
  }
}
@media (max-width: 480px) {
  .img-box6 .img2 {
    display: none;
  }
}
.img-box6 .img2 img {
  border-radius: 50px 0px 0px 0px;
  -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
  -ms-transform: matrix(1, 0, 0, -1, 0, 0);
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.img-box6 .img3 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  min-width: 328px;
}
@media (max-width: 1399px) {
  .img-box6 .img3 {
    max-width: 280px;
  }
}
@media (max-width: 1299px) {
  .img-box6 .img3 {
    min-width: 220px;
    right: 13%;
  }
}
@media (max-width: 1199px) {
  .img-box6 .img3 {
    right: 35%;
  }
}
@media (max-width: 991px) {
  .img-box6 .img3 {
    right: 15%;
  }
}
@media (max-width: 767px) {
  .img-box6 .img3 {
    min-width: 180px;
    max-width: 220px;
    right: 23%;
  }
}
@media (max-width: 480px) {
  .img-box6 .img3 {
    display: none;
  }
}
.img-box6 .img3 img {
  border-radius: 50px 0px 0px 0px;
}
.resort-image {
  border-radius: 24px;
}
.resort-image img {
  border-radius: 24px;
}
.resort-content {
  max-width: 530px;
}
.resort-content .box-title {
  font-size: 40px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .resort-content .box-title {
    font-size: 30px;
  }
}
.resort-content .resort-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.resort-content .resort-rating .star-rating {
  font-size: 14px;
}
.resort-content .woocommerce-review-link {
  color: var(--black-color2);
  font-size: 16px;
  font-weight: 600;
}
.resort-content .resort-price {
  font-size: 16px;
  font-weight: 500;
  color: var(--body-color);
}
.resort-content .resort-price .currency {
  font-size: 24px;
  font-weight: 500;
  color: var(--theme-color);
}
.resort-content .resort-text {
  font-size: 18px;
}
.resort-content .resort-list ul {
  padding: 0;
}
.resort-content .resort-list ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  list-style: none;
  margin-bottom: 10px;
}
.resort-content .resort-list ul li .title {
  font-weight: 500;
  font-size: 18px;
  color: var(--black-color2);
  min-width: 82px;
}
.resort-content .th-btn {
  padding: 10px 30px;
}
.img-box7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 28px;
}
@media (max-width: 991px) {
  .img-box7 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.img-box7 .img1,
.img-box7 .img2 {
  border-radius: 200px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .img-box7 .img1,
  .img-box7 .img2 {
    min-width: auto;
  }
}
.img-box8 .img3 {
  overflow: hidden;
  border-radius: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .img-box8 .img3 {
    margin-top: 30px;
  }
}
.img-box8 .img3 img {
  border-radius: 60px;
}
.img-box8 img {
  border-radius: 200px;
}
.choose-wrapp {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}
.choose-wrapp .img1 {
  overflow: hidden;
  border-radius: 24px;
}
@media (max-width: 991px) {
  .choose-wrapp .img1 {
    margin-bottom: 0;
  }
}
.choose-wrapp .img1 img {
  width: 100%;
  border-radius: 24px;
}
.th-team {
  position: relative;
}
.th-team .team-img {
  position: relative;
  overflow: hidden;
}
.th-team .team-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.th-team .team-desig {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: -0.45em;
  color: var(--black-color2);
}
.th-team .th-social {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.th-team .th-social a {
  --icon-size: 32px;
  background-color: transparent;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  font-size: 14px;
}
.th-team .th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.th-team .box-title {
  margin-bottom: 0;
}
.teamSlider1 .swiper-pagination-bullets .swiper-pagination-bullet {
  border: 1px solid var(--title-color);
}
.teamSlider1
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--theme-color);
}
.teamSlider4 .slider-arrow {
  left: var(--pos-x, -60px);
}
.teamSlider4 .slider-arrow.slider-next {
  right: var(--pos-x, -60px);
  left: auto;
}
.team-box {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index: 2;
  --space: 56px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.team-box.style2 {
  border: 1px solid var(--theme-color);
  border-radius: 16px;
  padding-top: 16px;
}
.team-box .team-img {
  max-width: calc(100% - var(--space) * 2);
  margin: 0 auto;
  border: 3px solid var(--white-color);
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  z-index: 3;
}
.team-box .team-img img {
  width: 100%;
  border-radius: 50%;
  -webkit-transition: all 1.3s ease 0s;
  transition: all 1.3s ease 0s;
}
.team-box .team-content {
  position: relative;
  padding: 130px 16px 16px 16px;
  margin-top: -100px;
  background-color: var(--white-color);
  border-radius: 16px;
  z-index: 2;
}
.team-box .media-body {
  position: relative;
  border-radius: 16px;
  background-color: #e9f6f9;
  padding: 24px 28px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: 2;
  overflow: hidden;
}
.team-box .box-title {
  color: var(--black-color2);
}
.team-box .box-title a {
  color: inherit;
}
.team-box .team-desig {
  margin-bottom: 12px;
}
.team-box:hover .team-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.team-box:hover .media-body {
  background-color: var(--theme-color);
}
.team-box:hover .box-title {
  color: var(--white-color);
}
.team-box:hover .team-desig {
  color: var(--white-color);
}
.team-box:hover .th-social a {
  border: 1px solid var(--white-color);
  color: var(--white-color);
}
.team-grid {
  position: relative;
  background-color: var(--smoke-color);
  text-align: center;
  border-radius: 16px;
  --space: 72px;
}
.team-grid.style2 {
  --space: 56px;
  margin: 16px;
  height: 528px;
  border-radius: 12px;
}
@media (max-width: 1399px) {
  .team-grid.style2 {
    margin: 0;
  }
}
.team-grid.style2 .team-img {
  position: relative;
  border-radius: 12px;
  z-index: 2;
  height: 528px;
}
.team-grid.style2 .team-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--title-color);
  opacity: 0.8;
  z-index: 1;
}
.team-grid.style2 .team-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.team-grid.style2 .team-img2 {
  margin-top: 0;
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.team-grid.style2 .team-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  border-radius: 12px;
}
.team-grid.style2 .media-body {
  border-radius: 12px;
}
.team-grid .team-img {
  border-radius: 16px 16px 0 0;
}
.team-grid .team-img img {
  -webkit-transition: all 1.3s ease 0s;
  transition: all 1.3s ease 0s;
}
.team-grid .team-img2 {
  width: 280px;
  height: 280px;
  margin: 0 auto;
  margin-top: -100px;
  border: 3px solid var(--white-color);
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  z-index: 3;
}
.team-grid .team-img2 img {
  width: 100%;
  border-radius: 50%;
  -webkit-transition: all 1.3s ease 0s;
  transition: all 1.3s ease 0s;
}
.team-grid .team-content {
  position: relative;
  padding: 24px 16px 16px 16px;
  border-radius: 16px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 2;
}
.team-grid .media-body {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  padding: 24px 28px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 2;
  overflow: hidden;
}
.team-grid .box-title {
  color: var(--black-color2);
}
.team-grid .box-title a {
  color: inherit;
}
.team-grid .team-desig {
  margin-bottom: 12px;
}
.team-grid:hover .team-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.team-grid:hover .team-img2 img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.team-grid:hover .media-body {
  background-color: var(--theme-color);
}
.team-grid:hover .box-title {
  color: var(--white-color);
}
.team-grid:hover .team-desig {
  color: var(--white-color);
}
.team-grid:hover .th-social a {
  border: 1px solid var(--white-color);
  color: var(--white-color);
}
.team-area3 .slider-arrow {
  top: 35%;
  left: var(--pos-x, -60px);
}
.team-area3 .slider-arrow.slider-next {
  right: var(--pos-x, -60px);
  left: unset;
}
.team-details .box-title {
  font-size: 28px;
}
@media (max-width: 1199px) {
  .team-about {
    margin-bottom: 20px;
  }
}
.team-about .th-social a:not(:hover) {
  background-color: #e4e4e4;
  color: var(--title-color);
}
.team-about.style2 {
  margin-left: 45px;
}
@media (max-width: 1199px) {
  .team-about.style2 {
    margin: 0;
  }
}
.team-about.style2 .team-about_title {
  font-weight: 600;
  margin: 0 0 3px 0px;
}
.team-about.style2 .team-about_desig {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
}
.team-about.style2 .team-about_text {
  line-height: 26px;
  max-width: 465px;
}
.team-about_title {
  font-size: 40px;
  margin: -0.8rem 0 20px 0px;
}
.team-about_desig {
  font-weight: 500;
  color: var(--body-color);
  margin-bottom: 23px;
}
.team-about .box-title {
  font-size: 28px;
}
.team-about .th-btn {
  padding: 19.5px 38.9px;
}
.about-card-img {
  position: relative;
  background-color: var(--white-color);
  padding: 40px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
}
.about-card-img img {
  width: 100%;
}
@media (max-width: 1299px) {
  .about-card-img {
    padding: 30px;
  }
}
@media (max-width: 1199px) {
  .about-card-img {
    margin-bottom: 40px;
  }
}
@media (max-width: 375px) {
  .about-card-img {
    padding: 20px;
  }
}
@media (min-width: 1300px) {
  .about-card {
    margin-left: 50px;
  }
}
.about-card_title {
  margin-bottom: 15px;
}
.about-card_desig {
  color: var(--body-color);
  margin-bottom: 22px;
  margin-top: -0.5em;
}
.about-card_text {
  margin-bottom: 26px;
  margin-top: 32px;
}
.about-card .th-social a {
  border-radius: 10px;
}
.team-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.team-info ul li {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.team-info ul li:last-child {
  margin-bottom: 0;
}
.team-info ul li b {
  font-family: var(--title-font);
  width: 100%;
  max-width: 200px;
  font-weight: 600;
  font-size: 18px;
  color: var(--title-color);
  line-height: 28px;
}
.team-info ul li span,
.team-info ul li a {
  color: var(--body-color);
}
.team-info ul li a:hover {
  color: var(--theme-color);
}
@media (max-width: 575px) {
  .team-info ul li b {
    max-width: 140px;
  }
}
.team-contact-form {
  padding: 50px;
  background-color: var(--smoke-color);
}
.team-contact-form textarea {
  height: 120px;
}
.team-contact-form .form-title {
  margin-top: -0.3em;
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .team-contact-form {
    padding: 40px 20px;
  }
}
.skill-feature {
  text-align: left;
}
.skill-feature.style2 .skill-feature_title {
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--white-color);
  margin-bottom: 10px;
}
.skill-feature.style2 .progress {
  height: 6px;
  background-color: #4d5765;
  border-radius: 3px;
}
.skill-feature.style2 .progress-bar {
  height: 6px;
  margin: 0px;
}
.skill-feature.style2 .progress-value {
  font-family: var(--title-font);
  color: var(--white-color);
}
.skill-feature:not(:last-child) {
  margin-bottom: 30px;
}
.skill-feature_title,
.skill-feature .progress-value {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: -0.5em;
  font-family: var(--title-font);
  color: var(--title-color);
}
.skill-feature .progress {
  position: relative;
  height: 8px;
  background-color: #e4e4e4;
  overflow: visible;
  border-radius: 4px;
}
.skill-feature .progress-bar {
  background-color: var(--theme-color);
  height: 4px;
  margin: 2px;
  border-radius: inherit;
  overflow: visible;
  position: relative;
}
.skill-feature .progress-value {
  position: absolute;
  top: -30px;
  right: -8px;
}
.testiSlider1 {
  overflow: visible;
  margin: 0 -30px !important;
}
@media (max-width: 991px) {
  .testiSlider1 {
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .testiSlider1 {
    margin: 0 !important;
  }
}
.testiSlider1 .swiper-slide {
  margin-top: 98px !important;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .testiSlider1 .swiper-slide {
    margin-top: 58px !important;
  }
}
.testiSlider1 .swiper-slide.swiper-slide-active {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
  width: calc(716px) !important;
}
@media (max-width: 991px) {
  .testiSlider1 .swiper-slide.swiper-slide-active {
    width: 60% !important;
    margin-bottom: 40px !important;
  }
}
@media (max-width: 767px) {
  .testiSlider1 .swiper-slide.swiper-slide-active {
    width: 100% !important;
  }
}
.testiSlider1 .swiper-slide.swiper-slide-active .testi-card-quote {
  background-color: var(--theme-color);
}
.testiSlider1 .swiper-slide.swiper-slide-active .testi-card-quote img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.testiSlider1 .swiper-wrapper {
  visibility: visible;
}
.testiSlider6 {
  overflow: visible;
  margin: 0 -30px !important;
}
@media (max-width: 991px) {
  .testiSlider6 {
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .testiSlider6 {
    margin: 0 !important;
  }
}
.testiSlider6 .swiper-slide {
  margin-top: 40px !important;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .testiSlider6 .swiper-slide {
    margin-top: 58px !important;
  }
}
.testiSlider6 .swiper-slide.swiper-slide-active {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
  width: calc(672px) !important;
}
@media (max-width: 991px) {
  .testiSlider6 .swiper-slide.swiper-slide-active {
    width: 65% !important;
    margin-bottom: 0px !important;
  }
}
@media (max-width: 767px) {
  .testiSlider6 .swiper-slide.swiper-slide-active {
    width: 100% !important;
  }
}
.testiSlider6 .swiper-slide.swiper-slide-active .testi-card-quote {
  background-color: var(--theme-color);
}
.testiSlider6 .swiper-slide.swiper-slide-active .testi-card-quote img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.testiSlider6 .swiper-wrapper {
  visibility: visible;
}
.testi-card {
  position: relative;
  padding: 50px;
  background: #fff5e3;
  border-radius: 24px;
  margin: 10px;
}
.testi-card.style2 .testi-card_profile {
  width: 316px;
  height: 153px;
  background-color: var(--theme-color);
  margin: -40px 0 -50px -60px;
  padding: 10px 30px 50px 30px;
}
@media (max-width: 480px) {
  .testi-card.style2 .testi-card_profile {
    margin: -24px 0 -50px -60px;
  }
}
.testi-card.style2 .testi-card_desig {
  color: var(--white-color);
}
.testi-card.style2 .box-title {
  color: var(--white-color);
}
@media (max-width: 991px) {
  .testi-card {
    padding: 30px 30px 64px;
  }
}
@media (max-width: 575px) {
  .testi-card {
    padding: 25px 25px 64px;
  }
}
.testi-card_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .testi-card_wrapper {
    display: block;
  }
}
.testi-card_wrapper:nth-child(even) .testi-card {
  margin-top: 0px;
}
.testi-card_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}
.testi-card_avater {
  min-width: 72px;
}
@media (max-width: 575px) {
  .testi-card_avater {
    min-width: 40px;
  }
}
.testi-card_avater img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.testi-card .box-title {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .testi-card .box-title {
    font-size: 20px;
  }
}
.testi-card_review i {
  color: #ffa944;
  font-size: 14px;
  margin-right: 3px;
}
@media (max-width: 991px) {
  .testi-card_review i {
    font-size: 12px;
  }
}
.testi-card_text {
  color: var(--black-color2);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: -0.3rem;
}
@media (max-width: 575px) {
  .testi-card_text {
    font-size: 18px;
  }
}
.testi-card-quote {
  width: 78px;
  height: 78px;
  line-height: 70px;
  text-align: center;
  background-color: var(--white-color);
  border: 4px solid var(--white-color);
  border-radius: 50%;
  position: absolute;
  left: 48%;
  bottom: -30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.testi-review {
  position: relative;
  height: 520px;
  background-size: auto;
  z-index: 3;
  margin-top: 100px;
}
@media (max-width: 375px) {
  .testi-review {
    margin-bottom: 50px;
  }
}
.testi-box {
  position: absolute;
  text-align: center;
}
@media (max-width: 375px) {
  .testi-box {
    position: relative;
    left: auto;
    top: auto;
  }
}
.testi-box:after {
  content: "";
  position: absolute;
  left: 30%;
  top: -29.5%;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  width: 19px;
  height: 14px;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
  border-bottom: solid 12px transparent;
  border-top: solid 12px var(--theme-color);
  opacity: 0;
}
@media (max-width: 375px) {
  .testi-box:after {
    display: none;
  }
}
.testi-box:first-child {
  top: 50%;
  right: 29%;
}
@media (max-width: 375px) {
  .testi-box:first-child {
    right: auto;
    top: auto;
  }
}
.testi-box:nth-child(2) {
  top: 48%;
  right: 43%;
}
@media (max-width: 375px) {
  .testi-box:nth-child(2) {
    right: auto;
    top: auto;
  }
}
.testi-box:nth-child(3) {
  top: 9%;
  left: 38%;
}
@media (max-width: 375px) {
  .testi-box:nth-child(3) {
    left: auto;
    top: auto;
  }
}
.testi-box:nth-child(4) {
  top: 27%;
  left: 24%;
}
@media (max-width: 375px) {
  .testi-box:nth-child(4) {
    left: auto;
    top: auto;
  }
}
.testi-box:nth-child(5) {
  top: 52%;
  left: 28%;
}
@media (max-width: 767px) {
  .testi-box:nth-child(5) {
    left: 10%;
  }
}
@media (max-width: 375px) {
  .testi-box:nth-child(5) {
    left: auto;
    top: auto;
  }
}
.testi-box_content {
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 16px;
  padding: 24px 35px;
  min-width: 447px;
  margin-bottom: 10px;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  margin-top: -300px;
  margin-left: -165px;
  z-index: 4;
}
@media (max-width: 575px) {
  .testi-box_content {
    min-width: 350px;
    padding: 24px;
    margin-top: -282px;
    margin-left: -115px;
  }
}
@media (max-width: 375px) {
  .testi-box_content {
    min-width: 300px;
    margin: -270px 0 0 0;
  }
}
.testi-box_text {
  font-weight: 500;
  font-size: 18px;
  color: var(--black-color2);
}
@media (max-width: 575px) {
  .testi-box_text {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .testi-box_text {
    font-size: 14px;
  }
}
.testi-box .media-body {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}
.testi-box_review i {
  color: #ffa944;
  margin-right: 3px;
}
.testi-box_avater {
  position: relative;
  max-width: 36px;
  display: block;
  margin: auto auto 40px auto;
}
.testi-box_avater span {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 4px;
  height: 4px;
  display: block;
  border-radius: 50%;
  background-color: var(--white-color);
  z-index: 2;
}
.testi-box_avater:after,
.testi-box_avater:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: rgba(28, 168, 203, 0.5);
  z-index: 1;
}
.testi-box_avater:after {
  background-color: var(--theme-color);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.testi-box_avater img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.testi-box .box-title {
  font-weight: 600;
  margin-bottom: 0px;
}
.testi-box_desig {
  margin-bottom: 0;
}
.testi-box.item-active:after {
  opacity: 1;
}
.testi-box.item-active .media-body {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.testi-box.item-active .testi-box_avater img {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  transform: scale(2.5);
}
.testi-box.item-active .testi-box_avater:before,
.testi-box.item-active .testi-box_avater:after {
  display: none;
}
.testi-box.item-active .testi-box_avater span {
  display: none;
}
.testi-box.item-active .testi-box_content {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.testiSlide3 {
  position: relative;
  z-index: 9;
}
.testi-grid {
  position: relative;
  text-align: center;
  max-width: 563px;
  display: block;
  margin: auto;
}
.testi-grid_author {
  max-width: 80px;
  display: block;
  margin: auto auto 30px auto;
}
.testi-grid_author img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.testi-grid .box-title {
  margin-bottom: 0;
}
.testi-grid_text {
  font-size: 18px;
  color: var(--black-color2);
  font-weight: 500;
  margin-bottom: 30px;
}
.testi-grid-thumb {
  position: relative;
  margin-top: -450px;
  height: 700px;
}
@media (max-width: 990px) {
  .testi-grid-thumb {
    display: none;
  }
}
@media (max-width: 575px) {
  .testi-grid-thumb {
    margin-bottom: 50px;
  }
}
.testi-grid-thumb .swiper-wrapper {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  visibility: visible;
}
.testi-grid-thumb .swiper-slide {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  content: "";
  position: absolute;
  margin: 0;
  max-width: 64px;
  height: 64px;
}
.testi-grid-thumb .swiper-slide img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.testi-grid-thumb .swiper-slide:first-child {
  top: 5%;
  left: 15%;
}
.testi-grid-thumb .swiper-slide:nth-child(2) {
  top: 30%;
  left: 5%;
}
.testi-grid-thumb .swiper-slide:nth-child(3) {
  bottom: 30%;
  left: 13%;
}
.testi-grid-thumb .swiper-slide:nth-child(4) {
  top: 10%;
  right: 10%;
}
.testi-grid-thumb .swiper-slide:nth-child(5) {
  top: 40%;
  right: 5%;
}
.testi-grid-thumb .swiper-slide:nth-child(6) {
  bottom: 30%;
  right: 13%;
}
.testi-grid-thumb .swiper-slide-thumb-active .box-img img {
  object-fit: cover;
  -webkit-transform: scale(1.6);
  -ms-transform: scale(1.6);
  transform: scale(1.6);
}
@media (max-width: 575px) {
  .testi-grid-thumb .swiper-slide-thumb-active .box-img img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.testi-area3 {
  height: 700px;
}
.testi-image-wrapp {
  margin-left: -25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 1199px) {
  .testi-image-wrapp {
    margin: 0 0 50px 0;
  }
}
.testi-image-wrapp .testi-img {
  border-radius: 150px 0 150px 0;
}
.testi-image-wrapp .testi-img img {
  border-radius: 150px 0 150px 0;
  border: 10px solid var(--white-color);
}
.testi-image-wrapp .testi-img.style2 {
  position: relative;
  z-index: 2;
  margin-left: -45px;
}
.testi-grid2-thumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 5px;
}
.testi-grid2-thumb .swiper-slide-thumb-active .box-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  border-color: var(--theme-color);
}
.testi-grid2-thumb .icon-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.testi-grid2-thumb .slider-arrow {
  box-shadow: none;
  border: 1px solid var(--title-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
}
.testi-grid2-thumb .slider-arrow:hover {
  border-color: var(--theme-color);
}
.testi-grid2-thumb .box-img {
  width: 72px;
  height: 72px;
}
.testi-grid2-thumb .box-img img {
  border-radius: 50%;
  cursor: pointer;
  border: 5px solid transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.testi-grid2 .box-text {
  font-weight: 500;
  font-size: 20px;
  color: var(--black-color2);
  margin-bottom: 20px;
}
.testi-grid2 .box-title {
  margin-bottom: 0;
}
.testi-grid2 .box-desig {
  font-weight: 400;
  font-size: 16px;
  color: #6e7070;
  display: block;
  margin-bottom: 10px;
}
.testi-grid2 .box-review {
  margin-bottom: 35px;
}
.testi-grid2 .box-review i {
  color: #ffa944;
  font-size: 14px;
}
.testi-grid2-thumb.style2 {
  gap: 40px;
}
@media (max-width: 1399px) {
  .testi-grid2-thumb.style2 {
    display: block;
  }
}
.testi-area7 {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left center;
}
.testi-image-wrapp2 {
  position: relative;
  height: 539px;
}
@media (max-width: 575px) {
  .testi-image-wrapp2 {
    display: none;
  }
}
.testi-image-wrapp2 .testi-img {
  position: absolute;
  right: 0;
  width: 449px;
  height: 539px;
}
@media (max-width: 1199px) {
  .testi-image-wrapp2 .testi-img {
    right: 10%;
  }
}
@media (max-width: 575px) {
  .testi-image-wrapp2 .testi-img {
    display: none;
  }
}
.testi-image-wrapp2 .testi-img2 {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 276px;
  height: 297.67px;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #ffffff;
  -webkit-mask-image: url("../src/img/testimonial/testi_shape_2.png");
  mask-image: url("../src/img/testimonial/testi_shape_2.png");
  z-index: 2;
}
@media (max-width: 767px) {
  .testi-image-wrapp2 .testi-img2 {
    display: none;
  }
}
.testi-image-wrapp2 .testi-shape {
  content: "";
  position: absolute;
  inset: -10px;
  top: 18%;
  left: 3.3%;
  width: 300px;
}
@media (max-width: 767px) {
  .testi-image-wrapp2 .testi-shape {
    display: none;
  }
}
.testi-image-wrapp2 .testi-shape2 {
  content: "";
  position: absolute;
  top: -7px;
  right: -18px;
  width: 472px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .testi-image-wrapp2 .testi-shape2 {
    right: 8%;
  }
}
@media (max-width: 575px) {
  .testi-image-wrapp2 .testi-shape2 {
    display: none;
  }
}
.available-list {
  padding-left: 30px;
}
@media (max-width: 575px) {
  .available-list {
    padding: 0;
  }
}
.available-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.available-list li {
  position: relative;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding-left: 30px;
  margin-bottom: 4px;
  text-transform: capitalize;
  color: #4d5765;
}
.available-list li:after {
  content: "\f00c";
  font-family: var(--icon-font);
  font-weight: 500;
  color: var(--theme-color);
  font-size: 1.1em;
  position: absolute;
  top: 1px;
  left: 0;
}
.available-list li.unavailable:after {
  content: "\f00c";
  right: 4px;
  color: #e4e4e4;
  font-weight: 400;
}
.available-list li img {
  max-width: 18px;
  margin-right: 10px;
}
.available-list li:last-child {
  margin-bottom: 0;
}
.available-list li.unavailable {
  font-weight: 400;
  color: var(--body-color);
}
.available-list li.unavailable img {
  opacity: 0.2;
}
.price-area .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
}
.price-card {
  position: relative;
  z-index: 2;
  border-radius: 16px;
  background-color: var(--smoke-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.price-card_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
  padding: 40px 0 40px 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
@media (max-width: 375px) {
  .price-card_wrapp {
    padding: 40px 0 40px 25px;
  }
}
.price-card_icon {
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
  background: var(--theme-color);
  margin-bottom: 0px;
}
.price-card_icon img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.price-card_content {
  padding: 0 95px 30px;
}
@media (max-width: 1299px) {
  .price-card_content {
    padding: 0 40px 30px;
  }
}
@media (max-width: 375px) {
  .price-card_content {
    padding: 0 40px 30px 25px;
  }
}
.price-card .box-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--black-color2);
  position: relative;
  margin-bottom: 0;
}
.price-card_price {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--black-color2);
}
.price-card_price .duration {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.price-card_text {
  color: var(--title-color);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}
.price-card .checklist {
  margin-top: 30px;
}
.price-card .checklist li {
  font-size: 16px;
  color: var(--title-color);
  font-weight: 400;
}
.price-card .checklist li:not(:last-child) {
  margin-bottom: 15px;
}
.price-card .checklist li i {
  font-size: 24px;
  margin-top: 2px;
}
.price-card .price-btn {
  margin: 0 58px 0px;
  padding-bottom: 32px;
}
.price-card .th-btn {
  font-size: 18px;
  padding: 21px 20px;
}
.price-card .offer-tag {
  position: absolute;
  right: -1px;
  top: 109px;
  background: var(--white-color);
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  padding: 5px 19px 5px 36px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10px 50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10px 50%);
  opacity: 0;
}
.price-card.active .offer-tag {
  opacity: 1;
}
.price-card:hover,
.price-card.active {
  background-color: var(--title-color);
}
.price-card:hover .price-card_icon,
.price-card.active .price-card_icon {
  background-color: var(--smoke-color);
}
.price-card:hover .price-card_icon img,
.price-card.active .price-card_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-filter: none;
  filter: none;
}
.price-card:hover .price-card_price,
.price-card.active .price-card_price {
  color: var(--white-color);
}
.price-card:hover .price-card_price .duration,
.price-card.active .price-card_price .duration {
  color: var(--white-color);
}
.price-card:hover .price-card_text,
.price-card.active .price-card_text {
  color: var(--white-color);
}
.price-card:hover .box-title,
.price-card.active .box-title {
  color: var(--white-color);
}
.price-card:hover .checklist li,
.price-card.active .checklist li {
  color: var(--white-color);
}
.price-card:hover .checklist li:before,
.price-card.active .checklist li:before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_214_43761)'%3E%3Cpath d='M14.166 2.78184C12.9403 2.0728 11.5172 1.66699 9.99935 1.66699C5.39697 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39697 18.3337 9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 9.42958 18.2753 8.87216 18.166 8.33366' stroke='%23E9F6F9' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M6.66602 10.417C6.66602 10.417 7.91602 10.417 9.58268 13.3337C9.58268 13.3337 14.215 5.69477 18.3327 4.16699' stroke='%23E9F6F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_214_43761'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.price-card:hover .th-btn,
.price-card.active .th-btn {
  background-color: transparent;
  border: 1px solid var(--white-color);
}
.price-card:hover .th-btn:hover,
.price-card.active .th-btn:hover {
  border-color: var(--theme-color);
}
.counter-card {
  position: relative;
  background-color: #e9f6f9;
  padding: 93px 28px;
  width: 264px;
  height: 264px;
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
  margin-top: 158px;
}
.counter-card.style2 {
  margin-top: 25px;
}
@media (max-width: 1199px) {
  .counter-card.style2 {
    margin: 50px auto 25px auto;
  }
}
.counter-card.style3 {
  margin: 25px 0 25px 0;
}
@media (max-width: 991px) {
  .counter-card.style3 {
    margin: 25px auto 25px auto;
  }
}
@media (max-width: 767px) {
  .counter-card.style3 {
    display: block;
    margin: 25px auto 25px auto;
  }
}
@media (max-width: 767px) {
  .counter-card_wrapp:not(:first-child) {
    margin: 0px auto 0px auto;
  }
}
@media (max-width: 1199px) {
  .counter-card {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .counter-card {
    width: 250px;
    height: 250px;
    padding: 81px 28px;
    margin-top: 150px;
  }
}
@media (max-width: 575px) {
  .counter-card {
    margin-top: 20px;
  }
}
.counter-card .counter-shape {
  position: absolute;
  inset: -24px;
  width: 312px;
  height: 312px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .counter-card .counter-shape {
    width: 290px;
    height: 290px;
    inset: -20px;
  }
}
.counter-card .counter-shape span {
  position: absolute;
  top: unset;
  bottom: 55px;
  right: 15px;
  width: 24px;
  height: 24px;
  display: block;
  border-radius: 50%;
  background-color: rgba(28, 168, 203, 0.2);
  z-index: 2;
}
@media (max-width: 767px) {
  .counter-card .counter-shape span {
    bottom: 51px;
    right: 13px;
  }
}
.counter-card .counter-shape:after,
.counter-card .counter-shape:before {
  content: "";
  position: absolute;
  bottom: 20%;
  right: 7%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: var(--theme-color);
}
.counter-card .counter-shape:after {
  background-color: var(--theme-color);
}
.counter-card-wrap {
  margin: 50px 0 25px 0;
}
@media (max-width: 1199px) {
  .counter-card-wrap {
    margin: 0;
  }
}
@media (max-width: 575px) {
  .counter-card-wrap:nth-child(even) {
    margin: 70px 0 50px 0;
  }
}
.counter-card-wrap:nth-child(even) .counter-card {
  margin-top: 0px;
}
.counter-card-wrap:nth-child(even) .counter-card .counter-shape span {
  top: 24px;
  right: 43px;
}
@media (max-width: 767px) {
  .counter-card-wrap:nth-child(even) .counter-card .counter-shape span {
    top: 22px;
    right: 39px;
  }
}
.counter-card-wrap:nth-child(even) .counter-card .counter-shape:before,
.counter-card-wrap:nth-child(even) .counter-card .counter-shape:after {
  bottom: unset;
  top: 10%;
  right: 16%;
}
.counter-card .box-number {
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  text-transform: capitalize;
  color: var(--black-color2);
  margin-bottom: 7px;
}
.counter-card .counter-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--black-color2);
}
.counter-card:hover .counter-shape {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}
.counter-card-wrap.style2 {
  margin: 25px 0 25px 0;
}
.counter-sec2 {
  position: relative;
  z-index: 1;
}
.counter-box {
  border: 1px solid #e1e4e6;
  border-radius: 8px;
  text-align: center;
  padding: 60px 20px;
}
.counter-box-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  max-width: 782px;
}
@media (max-width: 1299px) {
  .counter-box-wrap {
    max-width: 100%;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .counter-box-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 320px) {
  .counter-box-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
.counter-box_number {
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: var(--black-color2);
  margin-bottom: 0;
}
.counter-box_title {
  font-size: 18px;
  font-weight: 400;
  color: var(--black-color2);
  margin-bottom: -0.4rem;
}
@media (max-width: 1299px) {
  .counter-box_title {
    font-size: 16px;
  }
}
.blog-card {
  background-color: var(--white-color);
  position: relative;
  z-index: 3;
  border-radius: 30px;
  border: 1px solid #e3e8f5;
}
.blog-card .blog-img {
  position: relative;
  border-radius: 30px 30px 0px 0px;
  overflow: hidden;
}
.blog-card .blog-img:before {
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: skewX(25deg);
  -ms-transform: skewX(25deg);
  transform: skewX(25deg);
  z-index: 2;
}
.blog-card .blog-img img {
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-card .blog-content {
  padding: 15px 30px 30px 40px;
}
@media (max-width: 1299px) {
  .blog-card .blog-content {
    padding: 20px;
  }
}
.blog-card .blog-tags {
  font-size: 12px;
  font-weight: 700;
  color: var(--theme-color);
  letter-spacing: 0.48px;
  text-transform: uppercase;
  margin-top: -0.3rem;
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e1e5eb;
}
.blog-card .box-title {
  font-weight: 600;
  letter-spacing: -0.24px;
  margin-bottom: 5px;
}
.blog-card .box-title a {
  background-image: -webkit-linear-gradient(
    right,
    var(--theme-color),
    var(--theme-color)
  );
  background-image: linear-gradient(
    to left,
    var(--theme-color),
    var(--theme-color)
  );
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 2px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.blog-card .box-title a:hover {
  background-size: 100% 2px;
}
.blog-card_text {
  margin-bottom: 8px;
}
.blog-card_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.blog-card_author {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}
.blog-card_author img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.blog-card_info a {
  font-size: 12px;
  color: var(--body-color);
  font-weight: 400;
}
.blog-card_name {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
  margin-bottom: -0.3rem;
}
.blog-card:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-card:hover .blog-img:before {
  -webkit-animation: shine 1.9s;
  animation: shine 1.9s;
}
.blog-card .blog-btn {
  border-bottom: 1px solid #e1e5eb;
  margin-bottom: 25px;
  padding-bottom: 30px;
}
.blog-card .line-btn {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
}
@media (max-width: 991px) {
  .blog-card .box-title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .blog-card .blog-content {
    padding: 30px;
  }
  .blog-card .box-title {
    font-size: 24px;
  }
}
@media (max-width: 410px) {
  .blog-card .box-title {
    font-size: 22px;
  }
}
@media (max-width: 350px) {
  .blog-card .blog-content {
    padding: 18px;
  }
  .blog-card .box-title {
    font-size: 20px;
  }
}
.blog-box {
  position: relative;
}
.blog-box .blog-img {
  margin-bottom: 35px;
  border-radius: 16px;
}
.blog-box .blog-img img {
  border-radius: 16px;
}
.blog-box .blog-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-box .blog-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-box_wrapper {
  position: absolute;
  left: 20px;
  top: 20px;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.blog-box .blog-date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-color);
  padding: 24px 8px 15px;
  color: var(--white-color);
  text-align: center;
  font-size: 12px;
  width: 70px;
  height: 126px;
  -webkit-clip-path: path("M70 0H0V126L70 107V0Z");
  clip-path: path("M70 0H0V126L70 107V0Z");
  z-index: 2;
}
.blog-box .blog-date .date {
  font-family: var(--title-font);
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  display: block;
  margin-bottom: 10px;
}
.blog-box .blog-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 78px;
  height: 134px;
  background-color: var(--white-color);
  -webkit-clip-path: path("M78 0H0V134L78 113.794V0Z");
  clip-path: path("M78 0H0V134L78 113.794V0Z");
}
.blog-box .box-title {
  line-height: 34px;
  margin-bottom: 30px;
}
.blog-box .blog-meta {
  margin: -0.3rem 0 18px 0;
}
@media (max-width: 375px) {
  .blog-box .blog-meta span,
  .blog-box .blog-meta a {
    font-size: 14px;
  }
}
.blog-box .th-btn {
  padding: 10px 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-box .th-btn:after {
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("../src/img/icon/arrow-right2.svg");
  mask-image: url("../src/img/icon/arrow-right2.svg");
}
.blog-box:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-grid.style2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
@media (max-width: 767px) {
  .blog-grid.style2 {
    display: block;
  }
}
.blog-grid.style2 .blog-content {
  padding: 47px 40px 51px 40px;
}
@media (max-width: 375px) {
  .blog-grid.style2 .blog-content {
    padding: 30px;
  }
}
.blog-grid.style2 .blog-img {
  min-width: 424px;
  margin-bottom: 0;
}
@media (max-width: 1299px) {
  .blog-grid.style2 .blog-img {
    min-width: 312px;
  }
}
@media (max-width: 1199px) {
  .blog-grid.style2 .blog-img {
    min-width: 367px;
  }
}
@media (max-width: 991px) {
  .blog-grid.style2 .blog-img {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .blog-grid.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid.style2 .blog-img img {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .blog-grid.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid .blog-img {
  position: relative;
  overflow: hidden;
  height: 220px;
  border-radius: 16px;
  margin-bottom: 40px;
}
.blog-grid .blog-img img {
  border-radius: 16px;
}
.blog-grid .blog-meta span,
.blog-grid .blog-meta a {
  color: var(--black-color2);
}
.blog-grid .blog-content {
  padding: 47px 40px 40px 40px;
}
@media (max-width: 375px) {
  .blog-grid .blog-content {
    padding: 30px;
  }
}
.blog-grid .blog-meta {
  margin: -0.3rem 0 20px 0;
}
.blog-grid .blog-tag {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--theme-color);
  padding: 9px 20px;
  color: var(--white-color);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.blog-grid .box-title {
  margin-bottom: 32px;
  color: var(--black-color2);
}
.blog-grid .box-title a {
  background-image: -webkit-linear-gradient(
    right,
    var(--theme-color),
    var(--theme-color)
  );
  background-image: linear-gradient(
    to left,
    var(--theme-color),
    var(--theme-color)
  );
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 2px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.blog-grid .box-title a:hover {
  background-size: 100% 2px;
}
.blog-grid .th-btn {
  background-color: transparent;
  color: var(--black-color2);
  border: 1px solid var(--gray-color);
  padding: 10px 25px;
}
.blog-grid .blog-img {
  overflow: hidden;
}
.blog-grid .blog-img img {
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-grid .blog-meta {
  margin: -0.4em 0 18px 0;
}
@media (max-width: 375px) {
  .blog-grid .blog-meta span,
  .blog-grid .blog-meta a {
    font-size: 14px;
  }
}
.blog-grid .blog-meta span > i,
.blog-grid .blog-meta a > i {
  color: var(--theme-color);
  font-size: 14px;
}
.blog-grid:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-grid2 {
  background-color: var(--smoke-color);
  border-radius: 16px;
}
.blog-grid2.style2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
@media (max-width: 767px) {
  .blog-grid2.style2 {
    display: block;
  }
}
.blog-grid2.style2 .blog-grid2_content {
  padding: 24px 24px 24px 0px;
}
@media (max-width: 767px) {
  .blog-grid2.style2 .blog-grid2_content {
    padding: 24px;
  }
}
.blog-grid2.style2 .blog-img {
  min-width: 424px;
  height: 225px;
  margin-bottom: 0;
}
@media (max-width: 1299px) {
  .blog-grid2.style2 .blog-img {
    min-width: 312px;
  }
}
@media (max-width: 1199px) {
  .blog-grid2.style2 .blog-img {
    min-width: 367px;
  }
}
@media (max-width: 991px) {
  .blog-grid2.style2 .blog-img {
    min-width: 250px;
  }
}
@media (max-width: 767px) {
  .blog-grid2.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid2.style2 .blog-img img {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .blog-grid2.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid2 .blog-img {
  position: relative;
  overflow: hidden;
  height: 247px;
  border-radius: 16px;
  margin-bottom: 24px;
}
.blog-grid2 .blog-img img {
  border-radius: 16px;
}
.blog-grid2 .blog-meta span,
.blog-grid2 .blog-meta a {
  color: var(--black-color2);
}
.blog-grid2_content {
  padding: 0px 24px 24px 24px;
}
.blog-grid2 .blog-meta {
  margin: -0.3rem 0 20px 0;
}
.blog-grid2 .blog-tag {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--theme-color);
  padding: 9px 20px;
  color: var(--white-color);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.blog-grid2 .box-title {
  margin-bottom: 32px;
  color: var(--black-color2);
}
.blog-grid2 .box-title a {
  background-image: -webkit-linear-gradient(
    right,
    var(--theme-color),
    var(--theme-color)
  );
  background-image: linear-gradient(
    to left,
    var(--theme-color),
    var(--theme-color)
  );
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 2px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.blog-grid2 .box-title a:hover {
  background-size: 100% 2px;
}
.blog-grid2 .th-btn.style4 {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 10px 25px;
}
.blog-grid2 .th-btn.style4:after {
  background-color: var(--theme-color);
}
.blog-grid2 .th-btn.style4:hover {
  color: var(--white-color);
}
.blog-grid2 .blog-img {
  overflow: hidden;
}
.blog-grid2 .blog-img img {
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-grid2 .blog-meta {
  margin: -0.4em 0 18px 0;
}
@media (max-width: 375px) {
  .blog-grid2 .blog-meta span,
  .blog-grid2 .blog-meta a {
    font-size: 14px;
  }
}
.blog-grid2 .blog-meta span > i,
.blog-grid2 .blog-meta a > i {
  color: var(--theme-color);
  font-size: 14px;
}
.blog-grid2:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-grid3 {
  background-color: var(--smoke-color);
  border-radius: 16px;
}
.blog-grid3.style2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
@media (max-width: 767px) {
  .blog-grid3.style2 {
    display: block;
  }
}
.blog-grid3.style2 .blog-grid_content {
  padding: 24px 24px 24px 0px;
}
@media (max-width: 767px) {
  .blog-grid3.style2 .blog-grid_content {
    padding: 24px;
  }
}
.blog-grid3.style2 .blog-img {
  min-width: 424px;
  height: 225px;
  margin-bottom: 0;
}
@media (max-width: 1299px) {
  .blog-grid3.style2 .blog-img {
    min-width: 312px;
  }
}
@media (max-width: 1199px) {
  .blog-grid3.style2 .blog-img {
    min-width: 367px;
  }
}
@media (max-width: 991px) {
  .blog-grid3.style2 .blog-img {
    min-width: 250px;
    max-width: 250px;
  }
}
@media (max-width: 767px) {
  .blog-grid3.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid3.style2 .blog-img img {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .blog-grid3.style2 .blog-img {
    min-width: 100%;
  }
}
.blog-grid3 .blog-img {
  position: relative;
  overflow: hidden;
  height: 247px;
  border-radius: 16px;
  margin-bottom: 24px;
}
.blog-grid3 .blog-img img {
  border-radius: 16px;
}
.blog-grid3 .blog-meta span,
.blog-grid3 .blog-meta a {
  color: var(--black-color2);
}
.blog-grid3 .blog-grid_content {
  padding: 0px 24px 24px 24px;
}
.blog-grid3 .blog-meta {
  margin: -0.3rem 0 20px 0;
}
.blog-grid3 .blog-tag {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--theme-color);
  padding: 9px 20px;
  color: var(--white-color);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.blog-grid3 .box-title {
  margin-bottom: 32px;
  color: var(--black-color2);
}
.blog-grid3 .box-title a {
  background-image: -webkit-linear-gradient(
    right,
    var(--theme-color),
    var(--theme-color)
  );
  background-image: linear-gradient(
    to left,
    var(--theme-color),
    var(--theme-color)
  );
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 2px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.blog-grid3 .box-title a:hover {
  background-size: 100% 2px;
}
.blog-grid3 .th-btn.style4 {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 10px 25px;
}
.blog-grid3 .th-btn.style4:after {
  background-color: var(--theme-color);
}
.blog-grid3 .th-btn.style4:hover {
  color: var(--white-color);
}
.blog-grid3 .blog-img {
  overflow: hidden;
}
.blog-grid3 .blog-img img {
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.blog-grid3 .blog-meta {
  margin: -0.4em 0 18px 0;
}
@media (max-width: 375px) {
  .blog-grid3 .blog-meta span,
  .blog-grid3 .blog-meta a {
    font-size: 14px;
  }
}
.blog-grid3 .blog-meta span > i,
.blog-grid3 .blog-meta a > i {
  color: var(--theme-color);
  font-size: 14px;
}
.blog-grid3:hover .blog-img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-title-area {
  max-width: 613px;
  margin: auto;
  display: block;
  text-align: center;
}
.blog-area {
  padding-bottom: 60px;
}
.brand-area .th-container {
  --main-container: 1648px;
}
.brand-box {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: auto;
  position: relative;
  display: block;
}
.brand-box:hover a img.gray {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.brand-box:hover a img.original {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.brand-box img {
  margin: 0 auto;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.brand-box img.gray {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.brand-box img.original {
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.elements-sec .th-container {
  --main-container: 1744px;
}
.elements-item {
  text-align: center;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.moveFromTop {
  -webkit-animation-name: moveFromTop;
  animation-name: moveFromTop;
}
@-webkit-keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.tags-container > canvas {
  position: relative;
  top: -1px;
  left: -1px;
}
.tags-container {
  width: 100vw;
  height: 200px;
  margin-top: 0vw;
  overflow: hidden;
}
.checklist ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
  margin-bottom: 0;
}
.checklist li {
  position: relative;
  color: var(--body-color);
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  padding-left: 40px;
}
.checklist li:before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.3151 21.9311 10.6462 21.8 10' stroke='%231CA8CB' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M8 12.5C8 12.5 9.5 12.5 11.5 16C11.5 16 17.0588 6.83333 22 5' stroke='%231CA8CB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 32px;
  font-weight: 600;
  color: var(--theme-color);
  margin-right: 10px;
}
.checklist li:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .checklist.mb-45 {
    margin-bottom: 40px;
  }
}
.checklist.style4 ul li:before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.3151 21.9311 10.6462 21.8 10' stroke='%2327AE60' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M8 12.5C8 12.5 9.5 12.5 11.5 16C11.5 16 17.0588 6.83333 22 5' stroke='%2327AE60' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.checklist.style5 ul li:before {
  content: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 1.5C6.65 1.5 2 6.15 2 12C2 17.85 6.65 22.5 12.5 22.5C18.35 22.5 23 17.85 23 12C23 6.15 18.35 1.5 12.5 1.5ZM12.5 21C7.55 21 3.5 16.95 3.5 12C3.5 7.05 7.55 3 12.5 3C17.45 3 21.5 7.05 21.5 12C21.5 16.95 17.45 21 12.5 21Z' fill='%23EB5757'/%3E%3Cpath d='M16.55 17.25L12.5 13.2L8.45 17.25L7.25 16.05L11.3 12L7.25 7.95L8.45 6.75L12.5 10.8L16.55 6.75L17.75 7.95L13.7 12L17.75 16.05L16.55 17.25Z' fill='%23EB5757'/%3E%3C/svg%3E%0A");
}
.mega-hover {
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.mega-hover:after,
.mega-hover:before {
  content: "";
  position: absolute;
  pointer-events: none;
  opacity: 1;
  z-index: -1;
}
.mega-hover:before {
  top: 0;
  right: 51%;
  bottom: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.mega-hover:after {
  top: 50%;
  right: 0;
  bottom: 50%;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
}
.mega-hover:hover:before {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 900ms linear;
  transition: all 900ms linear;
}
.mega-hover:hover:after {
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 900ms linear;
  transition: all 900ms linear;
}
.bg-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}
.bg-img img {
  width: 100%;
  height: 100%;
}
.th-video {
  position: relative;
  border-radius: 10px;
}
.th-video img {
  border-radius: inherit;
}
.th-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-20 {
  border-radius: 20px;
}
@media (max-width: 767px) {
  .rounded-20 {
    border-radius: 10px;
  }
}
.btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px 30px;
}
.filter-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .filter-menu {
    margin-bottom: 50px;
  }
}
@media (max-width: 991px) {
  .filter-menu {
    margin-top: -10px;
    margin-bottom: 45px;
    gap: 6px;
  }
}
.filter-menu .tab-btn {
  background-color: var(--smoke-color);
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  color: var(--title-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.filter-menu .tab-btn:hover,
.filter-menu .tab-btn.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.filter-menu .th-btn {
  border-radius: 8px;
  padding: 15px 30px;
  background-color: var(--smoke-color);
  color: var(--body-color);
  min-width: auto;
}
.filter-menu .th-btn:before {
  background-color: var(--theme-color);
}
.filter-menu .th-btn:hover,
.filter-menu .th-btn.active {
  border-color: var(--theme-color);
  color: var(--white-color);
}
.filter-menu .th-btn:hover:before,
.filter-menu .th-btn.active:before {
  border-radius: 3px;
}
@media (max-width: 991px) {
  .filter-menu .th-btn {
    padding: 13px 20px;
  }
}
@media (max-width: 1199px) {
  p.mb-40 {
    margin-bottom: 35px;
  }
  p.mb-45 {
    margin-bottom: 38px;
  }
}
.global-image {
  position: relative;
  overflow: hidden;
  border-radius: 40px;
}
.global-image:before {
  content: "";
  z-index: 1;
  position: absolute;
  background: var(--theme-color);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: scaleX(0) translateX(0);
  -ms-transform: scaleX(0) translateX(0);
  transform: scaleX(0) translateX(0);
  -webkit-animation: 1s overlay ease-in-out forwards;
  animation: 1s overlay ease-in-out forwards;
}
.global-image img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  -webkit-animation: 1s 0.5s loaded cubic-bezier(0.49, 0, 0.5, 1) forwards;
  animation: 1s 0.5s loaded cubic-bezier(0.49, 0, 0.5, 1) forwards;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  display: block;
}
@-webkit-keyframes loaded {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes loaded {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes overlay {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  55% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes overlay {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  55% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.call-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}
.call-btn .btn-title {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 500;
}
.mouse-pointer {
  position: fixed;
  top: 50%;
  left: -100px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 9999;
  -webkit-transition-duration: 0.9s;
  transition-duration: 0.9s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 0.94, 0.336, 1);
  transition-timing-function: cubic-bezier(0.19, 0.94, 0.336, 1);
  border-radius: 50%;
  background: var(--theme-color);
  overflow: hidden;
}
.mouse-pointer:before {
  content: "";
  inset: 3px;
  position: absolute;
  background: var(--white-color);
  border-radius: inherit;
  border: 1px solid var(--theme-color);
}
.mouse-pointer .icon {
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0.225, 1, 0.316, 0.99);
  transition: 0.4s cubic-bezier(0.225, 1, 0.316, 0.99);
}
.mouse-pointer .icon i {
  margin: 0px 3px;
}
.mouse-pointer.large {
  display: none;
}
.mouse-pointer.small {
  width: 25px;
  height: 25px;
}
.mouse-pointer.right {
  width: 50px;
  height: 50px;
}
.mouse-pointer.right.large .icon {
  opacity: 0;
}
.mouse-pointer.right.large {
  background: transparent !important;
}
.mouse-pointer.zoom,
.mouse-pointer.open {
  width: 80px;
  height: 80px;
}
.mouse-pointer .icon i {
  margin: 0px 3px;
}
.mouse-pointer.right .icon {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.touch .mouse-pointer {
  display: none;
}
.mouse-pointer.transparent {
  display: none;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.why-area .th-container {
  --main-container: 1350px;
}
@media (max-width: 1199px) {
  .why-area .th-container {
    --main-container: 100%;
    max-width: 100%;
  }
}
.appointment-area {
  position: relative;
  padding: 100px;
}
.appointment-area .title-area .sec-title {
  margin-bottom: 28px;
}
@media (max-width: 991px) {
  .appointment-area {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  .appointment-area {
    padding: 80px 40px;
  }
}
@media (max-width: 575px) {
  .appointment-area {
    padding: 80px 20px;
  }
}
.appointment-radio {
  position: relative;
  border-radius: 15px;
  background: #eff0f4;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
}
@media (max-width: 1299px) {
  .appointment-radio {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1199px) {
  .appointment-radio {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991px) {
  .appointment-radio {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .appointment-radio {
    grid-template-columns: repeat(1, 1fr);
  }
}
.appointment-radio-wrapper {
  margin: 14px 0 40px 0;
}
.appointment-radio-wrapper .sec-title {
  font-size: 16px;
}
.appointment-radio label {
  font-size: 16px;
  color: var(--body-color);
}
.appointment-radio .class-name {
  text-wrap: nowrap;
}
.appointment-radio .class-name:last-child input[type="radio"] ~ label:after {
  display: none;
}
.appointment-radio input[type="radio"] ~ label {
  z-index: 2;
  margin-top: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.appointment-radio input[type="radio"] ~ label::before {
  top: 0px;
  left: 0px;
  border-radius: 0;
  border: none;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #9da0a7;
  border-radius: 99px;
  line-height: 1.7;
  z-index: -1;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.appointment-radio input[type="radio"]:checked ~ label {
  color: var(--theme-color);
}
.appointment-radio input[type="radio"]:checked ~ label:before {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}
.loadcontent {
  display: none;
}
.th-loader {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
}
.sec-desc {
  max-width: 470px;
}
.choose-feature {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  max-width: 410px;
}
@media (max-width: 1199px) {
  .choose-feature {
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .choose-feature {
    display: block;
    max-width: 100%;
    margin: auto;
  }
}
.choose-feature .box-icon {
  position: relative;
  z-index: 2;
  min-width: 80px;
}
@media (max-width: 1199px) {
  .choose-feature .box-icon {
    min-width: 60px;
  }
}
.choose-feature .box-icon:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #e8ecf6;
  border-radius: 999px;
  z-index: -1;
}
@media (max-width: 767px) {
  .choose-feature .box-icon:before {
    top: 15px;
    left: 50%;
  }
}
.choose-feature .box-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-left: 10px;
}
.choose-feature .box-title {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 700;
}
.choose-feature_text {
  margin-bottom: -0.3rem;
}
.choose-feature:hover .box-icon img {
  -webkit-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}
.why-checklist {
  border-bottom: 1px solid var(--th-border-color);
  padding-bottom: 40px;
}
.why-checklist ul li {
  position: relative;
  border-radius: 20px;
  border: 1px solid var(--smoke-color2);
  background: var(--white-color);
  padding: 10px;
  font-size: 20px;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  line-height: 30px;
  min-width: 300px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin: 0;
}
@media (max-width: 991px) {
  .why-checklist ul li {
    min-width: 50%;
  }
}
@media (max-width: 375px) {
  .why-checklist ul li {
    font-size: 16px;
    line-height: 26px;
  }
}
.why-checklist ul li:before,
.why-checklist ul li:after {
  content: "";
  position: absolute;
  width: 84px;
  height: 90px;
  background: var(--theme-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.why-checklist ul li:before {
  left: -10px;
  top: -10px;
  -webkit-clip-path: path("M0 0H84L0 90V0Z");
  clip-path: path("M0 0H84L0 90V0Z");
}
.why-checklist ul li:after {
  left: -6px;
  top: 0;
  -webkit-clip-path: path("M0 0H84L0 90V0Z");
  clip-path: path("M0 0H84L0 90V0Z");
  opacity: 0.2;
}
.why-checklist ul li .check-img {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--white-color);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  z-index: 4;
}
.why-checklist ul li .check-img:before {
  content: "";
  position: absolute;
  inset: 10px;
  background-color: rgba(41, 70, 184, 0.1);
  z-index: 1;
  border-radius: inherit;
}
.video-box1 {
  position: relative;
}
@media (max-width: 1024px) {
  .video-box1 {
    padding: 80px 0;
  }
}
.video-box1 img {
  border-radius: 0 80px 0 80px;
}
@media (max-width: 991px) {
  .video-box1 img {
    border-radius: 0 40px 0 40px;
  }
}
.video-box1 .play-btn {
  --icon-size: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}
@media (max-width: 991px) {
  .video-box1 .play-btn {
    --icon-size: 100px;
  }
}
@media (max-width: 767px) {
  .video-box1 .play-btn {
    --icon-size: 80px;
  }
}
.appointment-img1 {
  position: relative;
  z-index: 2;
  width: 939.929px;
  height: 612px;
  margin-right: -30%;
}
@media (max-width: 1699px) {
  .appointment-img1 {
    margin-right: -20%;
  }
}
@media (max-width: 1500px) {
  .appointment-img1 {
    margin-right: 0;
    width: 850px;
  }
}
@media (max-width: 1199px) {
  .appointment-img1 {
    width: 100%;
    height: 100%;
    margin: 50px 0 0 0;
  }
}
@media (max-width: 767px) {
  .choose-feature {
    text-align: center;
    padding: 0px 20px;
  }
  .choose-feature .box-icon {
    position: static;
    margin: 0 auto 20px auto;
    padding-top: 0;
    padding-left: 0;
  }
}
@media (max-width: 575px) {
  .choose-feature {
    max-width: 100%;
    width: 100%;
  }
  .choose-feature .box-text {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}
.feature-box {
  background: var(--white-color);
  padding: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
}
@media (max-width: 575px) {
  .feature-box {
    display: block;
    text-align: center;
  }
}
.feature-box:not(:first-child) {
  margin-top: 30px;
}
.feature-box:nth-child(2) .feature-box_step {
  background-color: var(--theme-color);
}
.feature-box:nth-child(3) .feature-box_step {
  background-color: var(--theme-color);
}
.feature-box .box-title {
  margin-bottom: 5px;
}
.feature-box_step {
  width: 86px;
  height: 100px;
  background-color: #00a1de;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
@media (max-width: 575px) {
  .feature-box_step {
    display: block;
    margin: auto auto 20px auto;
  }
}
.feature-box_text {
  margin-bottom: 0;
}
.feature-box .box-number {
  font-size: 30px;
  line-height: 30px;
  color: var(--white-color);
  text-align: center;
  font-family: var(--title-font);
  font-weight: 600;
  display: block;
  padding: 15px 0;
  margin-bottom: 0;
}
.feature-box .step {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--body-font);
  display: block;
  text-align: center;
}
.feature-image {
  width: 678.838px;
  height: 669px;
  margin-left: 50px;
}
@media (max-width: 1299px) {
  .feature-image {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1199px) {
  .feature-image {
    margin: 0;
  }
}
.why-content {
  max-width: 594px;
}
.why-content .checklist li {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}
.why-content .checklist li:before {
  content: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.2' width='26' height='26' rx='5' fill='%23F68A0A'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.4996 9.56685L9.60117 14.1326L9.60095 17.0845C9.60082 18.708 9.58812 20.2556 9.57267 20.5235C9.55364 20.8537 9.5589 21.0053 9.58906 20.9941C9.61349 20.985 12.699 17.4257 16.4458 13.0845C20.1926 8.74332 23.2977 5.14811 23.3461 5.09519C23.3946 5.04227 23.4261 4.99941 23.4161 5.00001C23.4061 5.00056 20.2937 7.05566 16.4996 9.56685Z' fill='%23F68A0A'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.65376 12.5395L9.6311 14.1338V18.2077C9.63116 19.3671 9.59351 20.4723 9.59976 20.6636C9.60746 20.8994 9.60533 21.0077 9.59313 20.9997C9.58325 20.9932 8.33528 18.4513 6.8199 15.3511C5.3045 12.2509 4.0486 9.68341 4.02901 9.64562C4.00943 9.60783 3.99669 9.57721 4.00074 9.57764C4.00477 9.57804 4.40641 9.8771 7.65376 12.5395Z' fill='%23F68A0A'/%3E%3C/svg%3E%0A");
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 32px;
  font-weight: 600;
  color: var(--theme-color);
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .why-sec2 {
    background-position: left center;
  }
}
.why-sec3 {
  background-position: top center;
}
@media (max-width: 1199px) {
  .why-sec3 {
    background-position: right center;
  }
}
.history-item {
  background-color: var(--white-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 375px) {
  .history-item {
    display: block;
  }
}
.history-item-right {
  max-width: 596px;
  display: block;
  margin-left: auto;
}
@media (max-width: 1199px) {
  .history-item-right {
    max-width: 100%;
    margin-left: 0;
  }
}
.history-item-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 21px;
}
@media (max-width: 767px) {
  .history-item-wrap {
    display: block;
  }
}
.history-item-wrap:not(:last-child) {
  margin-bottom: 30px;
}
.history-item-img {
  min-width: 140px;
}
.history-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.history-item .box-title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
}
.history-item-text {
  line-height: 26px;
  margin-bottom: -0.3rem;
}
.history-item-details {
  padding: 30px;
}
@media (max-width: 480px) {
  .history-item-details {
    padding: 30px 30px 30px 20px;
  }
}
.history-item-date {
  background: var(--white-color);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: var(--theme-color);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  padding: 25px;
  margin-right: 20px;
  position: relative;
  max-width: 142px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  -webkit-filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.05));
}
@media (max-width: 767px) {
  .history-item-date {
    max-width: none;
    display: block;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.history-item-date:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  border-left: solid 20px var(--white-color);
  border-bottom: solid 17px transparent;
  border-top: solid 17px transparent;
}
@media (max-width: 767px) {
  .history-item-date:after {
    bottom: -20px;
    top: auto;
    right: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    transform: translate(50%, 0);
    border-top: solid 20px var(--white-color);
    border-left: solid 17px transparent;
    border-right: solid 17px transparent;
    border-bottom: 0;
  }
}
@media (max-width: 767px) {
  .history-item .history-wrap-date {
    max-width: none;
    display: block;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .history-item .history-wrap-date:after {
    bottom: -20px;
    top: auto;
    right: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    transform: translate(50%, 0);
    border-top: solid 20px var(--white-color);
    border-left: solid 17px transparent;
    border-right: solid 17px transparent;
    border-bottom: 0;
  }
}
.choose-content .sec-title {
  font-weight: 500;
  letter-spacing: normal;
}
.choose-content .box-text {
  font-size: 14px;
}
.choose-content .checklist {
  margin-bottom: 35px;
}
.choose-content .checklist li {
  color: var(--body-color);
  font-size: 14px;
  line-height: 16px;
}
.choose-content .checklist li:after {
  color: var(--body-color);
}
.why-content-area {
  background-color: var(--white-color);
  padding: 60px;
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.06);
}
@media (max-width: 1299px) {
  .why-content-area {
    padding: 40px;
  }
}
@media (max-width: 991px) {
  .why-content-area {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .why-content-area {
    padding: 30px 10px;
  }
}
.why-item {
  position: relative;
  background-color: var(--white-color);
  padding: 30px;
  border: 1.3px solid #e4e4e4;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
@media (max-width: 1299px) {
  .why-item {
    padding: 25px;
  }
}
.why-item_icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1.3px solid var(--theme-color);
  background-color: var(--white-color);
  border-radius: 50%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.why-item_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.why-item_text {
  line-height: 26px;
  margin-bottom: -0.4rem;
}
.why-item .box-title {
  font-weight: 600;
  line-height: 34px;
  margin: 23px 0 13px 0;
}
@media (max-width: 1299px) {
  .why-item .box-title {
    font-size: 20px;
    line-height: 30px;
  }
}
.why-item .icon-btn {
  border-radius: 99px;
  background-color: var(--white-color);
  border: 1px solid var(--th-border-color);
  color: var(--title-color);
  position: relative;
  z-index: 3;
  display: block;
  text-align: center;
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.why-item .icon-btn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}
.why-item:hover .why-item_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.why-item:hover .why-item_icon {
  border: 1px solid var(--theme-color);
}
.why-item:hover .why-item_icon:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.why-item:hover .why-item_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.why-item_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
@media (max-width: 1299px) {
  .why-item_wrapper {
    gap: 20px;
  }
}
@media (max-width: 480px) {
  .why-item_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.why-img1 {
  position: relative;
  margin-left: 55px;
}
@media (max-width: 480px) {
  .why-img1 {
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .why-img1 .img1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.why-img1 .img2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
@media (max-width: 480px) {
  .why-img1 .img2 {
    display: none;
  }
}
.why-img1 .img2:before {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 275px;
  height: 336px;
  background-color: var(--white-color);
  z-index: -1;
}
.why-img1 .img3 {
  position: relative;
  margin: -48px 0 0 50px;
}
@media (max-width: 480px) {
  .why-img1 .img3 {
    display: none;
  }
}
.why-img1 .img3:before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 318px;
  height: 416px;
  background-color: var(--white-color);
}
.why-img1 .img3 img {
  -webkit-clip-path: polygon(0 0, 58% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 58% 0, 100% 100%, 0% 100%);
}
.feature-wrapper.style3 {
  margin-bottom: 60px;
}
.feature-wrapper.style3 .feature-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  background-color: #1e2127;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.feature-wrapper.style3 .feature-icon:hover {
  background-color: var(--theme-color);
}
.feature-wrapper.style3 .header-info_label {
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  font-weight: 500;
  letter-spacing: -0.32px;
}
.feature-wrapper.style3 .header-info_link {
  font-family: var(--body-font);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.48px;
}
.work-area {
  margin-bottom: 40px;
}
.work-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
.work-item:first-child {
  max-width: 290px;
}
@media (max-width: 1299px) {
  .work-item:first-child {
    max-width: 250px;
  }
}
@media (max-width: 767px) {
  .work-item:first-child {
    max-width: 100%;
  }
}
.work-item:first-child:before {
  right: -15px;
}
@media (max-width: 1199px) {
  .work-item:first-child:before {
    right: -10px;
  }
}
@media (max-width: 767px) {
  .work-item {
    margin-bottom: 20px;
  }
}
.work-item:before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 18.5L0 0L10.6446 18.5L0 37L20 18.5Z' fill='%233E66F3'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 0px;
  right: -40px;
  font-family: var(--icon-font);
  width: 20px;
  height: 37px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 900;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
@media (max-width: 1199px) {
  .work-item:before {
    right: -20px;
  }
}
@media (max-width: 767px) {
  .work-item:before {
    display: none;
  }
}
.work-item:last-child:before {
  display: none;
}
.work-item_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (max-width: 767px) {
  .work-item_wrapper {
    display: block;
  }
}
.work-item_number {
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  display: block;
  background-color: #1e2127;
  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.work-item .work-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #c7c7c7;
}
.download-btn {
  background-color: var(--title-color);
  max-width: 190px;
  height: 55px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 999px;
}
.appointment-sec {
  background: -webkit-linear-gradient(top, #edf0f4 2.66%, #edf0f4 90.4%);
  background: linear-gradient(180deg, #edf0f4 2.66%, #edf0f4 90.4%);
}
@media (max-width: 991px) {
  .video-box3 {
    padding-top: 120px;
  }
}
.video-box3 .play-btn {
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .video-box3 .play-btn {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.video-box3 .play-btn > i {
  background-color: var(--theme-color);
  color: var(--white-color);
  --icon-size: 80px;
}
.video-box4.style2 .play-btn {
  top: 40%;
}
@media (max-width: 991px) {
  .video-box4 {
    padding-top: 120px;
  }
}
.video-box4 .play-btn {
  position: absolute;
  top: 50%;
  right: 16%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .video-box4 .play-btn {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.video-box4 .play-btn > i {
  background-color: var(--theme-color);
  color: var(--white-color);
  --icon-size: 80px;
}
.marquee-wrapper .marquee {
  position: relative;
  --duration: 120s;
  --gap: 0px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: var(--gap);
}
.marquee-wrapper .marquee:first-child {
  --duration: 100s;
}
.marquee-wrapper .marquee:last-child {
  --duration: 80s;
}
.marquee-wrapper .marquee.marquee--reverse .marquee-group {
  animation-direction: reverse;
}
.marquee-wrapper .marquee .marquee-group {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  -webkit-animation: scroll var(--duration) linear infinite;
  animation: scroll var(--duration) linear infinite;
}
.marquee-wrapper .marquee .marquee-group .text {
  font-family: var(--title-font);
  font-weight: 700;
  font-size: 74px;
  line-height: 56px;
  text-transform: uppercase;
  color: var(--theme-color);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #888c97;
}
.marquee-wrapper .marquee .marquee-group .text img {
  margin: 0 20px 20px 20px;
}
.categorySlider .swiper-pagination-bullets {
  max-width: 250px;
  display: block;
  margin: auto;
  overflow: hidden;
}
.category-area {
  padding: 165px 0 120px 0;
  margin-top: -55px;
  padding-bottom: 55px;
}
.category-area .th-container {
  --main-container: 1750px;
}
.category-area .swiper .swiper-slide {
  width: 312px;
}
.category-card {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}
.category-card.style2 .box-img {
  border-radius: 50%;
  border: 10px solid var(--smoke-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.category-card.style2 .box-img img {
  border-radius: 50%;
}
.category-card.style2:hover .box-img {
  border-color: var(--theme-color);
}
.category-card .box-img {
  border-radius: 24px;
  margin-bottom: 22px;
}
.category-card .box-img img {
  border-radius: 24px;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
.category-card .box-title {
  margin-bottom: 0;
}
.category-card .line-btn {
  text-transform: capitalize;
  color: var(--body-color);
}
.category-card .line-btn:before {
  display: none;
}
.category-card:hover .box-img img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.categorySlider2 .swiper-pagination-bullets {
  max-width: 250px;
  display: block;
  margin: auto;
  overflow: hidden;
}
.category-slider3 .swiper-pagination-bullets {
  max-width: 250px;
  display: block;
  margin: auto auto 10px auto;
  overflow: hidden;
}
.categorySlider4 .swiper-pagination-bullets {
  max-width: 250px;
  display: block;
  margin: auto;
  overflow: hidden;
}
.category-area3 .th-container {
  --main-container: 1689px;
}
.category-area4 .th-container {
  --main-container: 1656px;
}
.category-card {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}
.category-card.style3 .box-img {
  border-radius: 24px;
  border: 10px solid var(--smoke-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.category-card.style3 .box-img img {
  border-radius: 24px;
}
.category-card.style3:hover .box-img {
  border-color: var(--theme-color);
}
.category-card .box-img {
  border-radius: 24px;
  margin-bottom: 22px;
}
.category-card .box-img img {
  border-radius: 24px;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
.category-card .box-title {
  margin-bottom: 0;
}
.category-card .line-btn {
  text-transform: capitalize;
  color: var(--body-color);
}
.category-card .line-btn:before {
  display: none;
}
.category-card:hover .box-img img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.accordion-card {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 4px;
  border: 1px solid var(--title-color);
  overflow: hidden;
  background-color: transparent;
  text-align: left;
  position: relative;
  z-index: 3;
}
.accordion-card:not(:last-child) {
  margin-bottom: 24px;
}
.accordion-card .accordion-button {
  /* font-size: 14px !important; */
  font-weight: 600;
  font-family: var(--title-font);
  border: 0;
  color: var(--title-color);
  background-color: transparent;
  border-radius: 0;
  padding: 12px 45px 12px 30px;
  min-height: 75px;
  gap: 10px;
  margin-bottom: 0;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}
.accordion-card .accordion-button:after {
  content: "\f107";
  height: 100%;
  width: auto;
  line-height: 1;
  background-color: transparent;
  background-image: none;
  font-family: var(--icon-font);
  color: var(--title-color);
  font-weight: 500;
  font-size: 18px;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: 30px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.accordion-card .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-card .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.accordion-card .accordion-button:not(.collapsed):after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  color: var(--body-color);
}
.accordion-card .accordion-collapse {
  border: none;
}
.accordion-card .accordion-body {
  border-radius: 0;
  border: none;
  padding: 30px 30px 30px;
}
.accordion-card .faq-text {
  color: var(--body-color);
  margin-bottom: -0.48em;
  margin-top: -0.48em;
}
@media (max-width: 991px) {
  .accordion-card .accordion-button {
    font-size: 20px;
    min-height: 70px;
  }
}
@media (max-width: 575px) {
  .accordion-card .accordion-button {
    padding: 12px 45px 12px 20px;
  }
  .accordion-card .accordion-body {
    padding: 0px 20px 30px;
  }
}
.faq-img1 {
  margin-left: -180px;
  width: 817px;
  height: 617px;
}
.faq-img1.style2 {
  margin-left: -280px;
}
@media (max-width: 1399px) {
  .faq-img1.style2 {
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1399px) {
  .faq-img1 {
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1199px) {
  .faq-img1 {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .accordion-card .accordion-button {
    font-size: 16px;
  }
}
.faq-bg-image {
  position: absolute;
  top: 0;
  right: -30px;
  width: 737px;
  height: 100%;
  z-index: 1;
}
.faq-bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1299px) {
  .faq-bg-image {
    display: none;
  }
}
.faq-area {
  position: relative;
  overflow: hidden;
}
.faq-area2 {
  max-width: 1720px;
  display: block;
  margin: auto;
  background-color: #f8f8f8;
}
.faq-img2 {
  position: relative;
  z-index: 2;
  margin-left: -80px;
}
@media (max-width: 1500px) {
  .faq-img2 {
    margin-left: 0;
    margin-right: 40px;
  }
}
@media (max-width: 1199px) {
  .faq-img2 {
    margin: 0 0 50px 0;
  }
}
.faq-img2 .img1 {
  -webkit-clip-path: polygon(55% 0px, 100% 38%, 100% 100%, 0px 100%, 0% 38%);
  clip-path: polygon(55% 0px, 100% 38%, 100% 100%, 0px 100%, 0% 38%);
}
.offer-deals-wrapp {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
}
@media (max-width: 1199px) {
  .offer-deals-wrapp {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .offer-deals-wrapp {
    display: block;
  }
}
.accordion-item {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  background: transparent;
  overflow: hidden;
  min-width: 336px;
}
@media (max-width: 767px) {
  .accordion-item {
    min-width: 100%;
  }
}
.accordion-item_info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.accordion-item_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
}
@media (max-width: 575px) {
  .accordion-item_wrapp {
    display: block;
  }
}
.accordion-item_number {
  border: 1px solid var(--gray-color);
  width: 69px;
  height: 32px;
  padding: 2px 24.5px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: var(--gray-color);
}
.accordion-item_content {
  color: #fff;
  padding: 0 30px 0px 110px;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
}
@media (max-width: 575px) {
  .accordion-item_content {
    padding: 0 30px 0px 0px;
  }
}
.accordion-item .box-title {
  margin-bottom: 0;
}
.accordion-item i {
  font-size: 20px;
  font-weight: 400;
  color: var(--title-color);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
@media (max-width: 575px) {
  .accordion-item i {
    font-size: 24px;
  }
}
.accordion-item .accordion-button {
  font-size: 30px;
  font-weight: 600;
  font-family: var(--title-font);
  border: 0;
  color: var(--title-color);
  background: transparent;
  padding: 20px 0 20px 0;
  gap: 10px;
  margin-bottom: 0;
  text-align: left;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  border-radius: 0 !important;
}
.accordion-item .accordion-button:after {
  display: none;
}
.accordion-item .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  border-bottom: none;
  background-color: transparent;
  padding: 20px 0 0px 0;
}
.accordion-item .accordion-button:not(.collapsed) .accordion-item_number {
  border-color: var(--theme-color);
  color: var(--theme-color);
}
.accordion-item .accordion-button:not(.collapsed) i {
  color: var(--theme-color);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.accordion-item .accordion-button:not(.collapsed):after {
  content: "\f068";
}
.accordion-item .accordion-button.collapsed {
  border-bottom: 1px solid var(--gray-color);
}
.accordion-item .accordion-collapse {
  border: none;
}
.accordion-item .accordion-collapse .accordion-body {
  border-color: var(--theme-color);
}
.accordion-item .accordion-body {
  background-color: transparent;
  border: none;
  padding: 0px;
  border-bottom: 1px solid var(--gray-color);
}
.accordion-item .faq-img {
  height: 100%;
}
.accordion-item .faq-img img {
  height: 100%;
  object-fit: cover;
}
.accordion-item-wrapp {
  padding: 0;
}
.accordion-item-wrapp li {
  list-style: none;
}
.according-img-tab {
  position: absolute;
  right: 40px;
  top: 20px;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 1399px) {
  .according-img-tab {
    right: 20px;
  }
}
@media (max-width: 1299px) {
  .according-img-tab {
    top: 0;
    max-width: 250px;
  }
}
@media (max-width: 1199px) {
  .according-img-tab {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .according-img-tab {
    right: 0;
  }
}
@media (max-width: 767px) {
  .according-img-tab {
    position: relative;
    margin-top: 50px;
  }
}
.according-img-tab img {
  border-radius: 16px;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transition: all 1.3s ease;
  transition: all 1.3s ease;
}
.faq-price {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--theme-color);
  display: block;
  margin-bottom: 10px;
}
.th-accordion_images {
  position: absolute;
  right: 40px;
  top: 10px;
  margin-top: 35px;
}
.th-accordion_images img {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.th-accordion_images img.active {
  opacity: 1;
  visibility: visible;
}
.th-accordion_images img {
  border-radius: 16px;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
  opacity: 0;
  -webkit-transition: all 1.3s ease;
  transition: all 1.3s ease;
}
.th-accordion_images img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 0;
}
.th-accordion_images img.active {
  opacity: 1;
}
.offer-deals-counter {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 0;
}
@media (max-width: 1299px) {
  .offer-deals-counter {
    grid-template-columns: auto auto;
    gap: 20px;
  }
}
@media (max-width: 1199px) {
  .offer-deals-counter {
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 480px) {
  .offer-deals-counter {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 375px) {
  .offer-deals-counter {
    grid-template-columns: auto;
  }
}
.offer-deals-counter li {
  position: relative;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  list-style: none;
  padding: 30px 20px;
  width: 114px;
}
.offer-deals-counter li:not(:last-child):before {
  content: ":";
  position: absolute;
  right: -16%;
  top: 35%;
  font-size: 35px;
  color: var(--title-color);
  font-weight: 700;
}
@media (max-width: 1399px) {
  .offer-deals-counter li:not(:last-child):before {
    right: -11%;
  }
}
@media (max-width: 1299px) {
  .offer-deals-counter li:not(:last-child):before {
    display: none;
  }
}
.offer-deals-counter li .count-number {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: var(--title-color);
}
.offer-deals-counter li .count-name {
  font-weight: 400;
  color: var(--title-color);
}
.th-loader {
  position: relative;
}
.loadcontent {
  display: none;
}
.tour-box {
  position: relative;
  background-color: var(--white-color);
  border: 1px solid #bcced2;
  border-top: transparent;
  border-radius: 16px;
  overflow: hidden;
}
.tour-box.style-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #bcced2;
}
@media (max-width: 1299px) {
  .tour-box.style-flex {
    display: block;
  }
}
.tour-box.style-flex .tour-content {
  padding: 0;
}
@media (max-width: 1299px) {
  .tour-box.style-flex .tour-content {
    padding: 30px;
  }
}
@media (max-width: 375px) {
  .tour-box.style-flex .tour-content {
    padding: 20px;
  }
}
.tour-box.style-flex .tour-action {
  gap: 30px;
}
.tour-box.style-flex .tour-box_img {
  border-radius: 16px 0px 0 0;
  width: 50%;
}
@media (max-width: 1299px) {
  .tour-box.style-flex .tour-box_img {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .tour-box.style-flex .tour-box_img {
    margin-bottom: 0;
  }
}
.tour-box.style-flex .tour-box_img img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px 0px 0 0;
}
.tour-box.style2 .box-title {
  font-size: 24px;
  font-weight: 600;
}
.tour-box.style2 .woocommerce-review-link {
  font-weight: 600;
}
.tour-box.style2 .tour-content {
  padding: 24px;
}
.tour-box.style2 .tour-list ul {
  padding: 0;
}
.tour-box.style2 .tour-list ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--title-font);
  border-bottom: 1px solid #bcced2;
  color: var(--black-color);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.tour-box.style2 .tour-list ul li span {
  color: var(--body-color);
}
.tour-box.style2 .tour-action {
  margin-top: 30px;
}
@media (max-width: 340px) {
  .tour-box.style2 .tour-action {
    display: block;
  }
}
.tour-box.style2 .tour-box_price {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.tour-box.style2 .currency {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--body-font);
  color: var(--black-color2);
}
@media (max-width: 1299px) {
  .tour-box.style2 .currency {
    font-size: 18px;
  }
}
.tour-box.style3 .tour-box_img:before {
  display: none;
}
.tour-box.style4 {
  padding: 16px;
  border: 1px solid #bcced2;
}
.tour-box.style4 .tour-box_img {
  border-radius: 16px;
}
.tour-box.style4 .tour-content {
  padding: 24px 0 0 0;
}
.tour-box_img {
  position: relative;
  border-radius: 16px 16px 0 0;
  z-index: 2;
  overflow: hidden;
}
@media (max-width: 991px) {
  .tour-box_img {
    margin-bottom: 0;
  }
}
.tour-box_img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 88.18%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 88.18%
  );
}
.tour-box_img img {
  width: 100%;
    height: 300px; /* Set your desired height */
    object-fit: cover; /* Ensures the image covers the box while maintaining its aspect ratio */
    transition: 1.3s all ease;
    border-radius: 16px 16px 0 0; /* Uncomment if needed */
}
.tour-box .tour-content {
  padding: 24px 24px 16px 24px;
}
.tour-box .box-title {
  color:#080808;
 
    width: 100% !important;

  font-size: 18px !important;
  font-weight: 500;
  margin-bottom: 5px;
}
.new-title-card .box-title{
 background: linear-gradient(to right, rgb(226, 31, 45), #f9af28) !important; 
  padding: 10px !important; 
  font-size: 18px !important;

}
.tour-box .tour-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 13px;
}
.tour-box .star-rating {
  font-size: 14px;
}
.tour-box .star-rating span:before {
  color: #ff833e;
}
.tour-box .woocommerce-review-link {
  font-size: 16px;
  line-height: 16px;
  color: var(--black-color2);
}
.tour-box_price {
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--body-color);
  margin-bottom: 35px;
}
.tour-box .currency {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: var(--black-color2);
}
.tour-box .tour-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tour-box .tour-action i {
  margin-right: 8px;
}
.tour-box .tour-action span {
  font-weight: 400;
  font-size: 16px;
  color: var(--title-color);
}
.tour-box .tour-action .th-btn {
  border: 1px solid var(--gray-color);
  font-weight: 500;
  padding: 10px 19.5px;
}
@media (max-width: 1199px) {
  .tour-box .tour-action .th-btn {
    padding: 10px 15px;
  }
}
.tour-box .tour-action .th-btn:after {
  -webkit-mask-image: url("./img/icon/arrow-right3.svg");
  mask-image: url("./img/icon/arrow-right3.svg");
}
.tour-box:hover {
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 16px;
}
.tour-box:hover .tour-box_img img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.tour-slider {
  position: relative;
}
.tourSlider2 {
  margin-right: -23% !important;
}
.tourSlider2 .swiper-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tour-card {
  position: relative;
  --space: 16px;
}
@media (max-width: 480px) {
  .tour-card {
    width: 83%;
  }
}
.tour-card_img {
  border-radius: 16px;
}
.tour-card_img img {
  border-radius: 16px;
}
.tour-card .tour-content {
  position: absolute;
  top: var(--space);
  left: var(--space);
  width: calc(100% - var(--space) * 2);
  height: calc(100% - var(--space) * 2);
  z-index: 2;
  background: rgba(17, 61, 72, 0.5);
  border: 1px solid var(--white-color);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tour-card .box-title {
  color: var(--white-color);
  font-weight: 600;
  margin-bottom: 10px;
}
@media (max-width: 1399px) {
  .tour-card .box-title {
    font-size: 20px;
  }
}
.tour-card .tour-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 20px;
}
.tour-card .star-rating {
  font-size: 14px;
}
.tour-card .star-rating span:before {
  color: #ff833e;
}
.tour-card .woocommerce-review-link {
  font-size: 16px;
  line-height: 16px;
  color: var(--white-color);
}
.tour-card_price {
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--white-color);
  margin-bottom: 35px;
}
.tour-card .currency {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: var(--white-color);
}
.tour-card .tour-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tour-card .tour-action i {
  margin-right: 8px;
}
.tour-card .tour-action span {
  font-weight: 400;
  font-size: 16px;
  color: var(--white-color);
}
.tour-card .tour-action .th-btn {
  border: 1px solid var(--white-color);
  font-weight: 500;
  padding: 10px 19.5px;
  color: var(--white-color);
}
.tour-card .tour-action .th-btn:after {
  -webkit-mask-image: url("./img/icon/arrow-right3.svg");
  mask-image: url("./img/icon/arrow-right3.svg");
}
.tour-card .tour-action .th-btn:hover {
  color: var(--black-color2);
}
.tour-card .tour-action .th-btn:hover:before {
  background-color: var(--white-color);
}
.tour-card .tour-location span {
  color: var(--white-color);
}
.tour-card:hover .tour-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.tour-text {
  font-size: 18px;
  color: var(--title-color);
  text-align: center;
  max-width: 687px;
  display: block;
  margin: auto auto 60px auto;
}
@media (max-width: 1199px) {
  .tour-text {
    max-width: 100%;
  }
}
.style-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tour-page-single .box-title {
  font-size: 40px;
}
@media (max-width: 1199px) {
  .tour-page-single .box-title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .tour-page-single .box-title {
    font-size: 34px;
  }
}
@media (max-width: 575px) {
  .tour-page-single .box-title {
    font-size: 28px;
  }
}
.tour-page-single .box-text {
  font-size: 18px;
}
.tour-page-single .tour-price {
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: var(--body-color);
}
.tour-page-single .tour-price .currency {
  font-size: 28px;
  font-weight: 600;
  color: var(--theme-color);
}
.tour-slider1 {
  position: relative;
}
.tour-slider1 .tour-slider-img img {
  border-radius: 8px;
}
.tour-slider1 .slider-arrow {
  top: auto;
  bottom: 60px;
  left: -23px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.tour-slider1 .slider-arrow.slider-next {
  left: auto;
  right: -23px;
}
.tour-tab {
  border: 0;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
}
.tour-tab .nav-item .nav-link {
  border: none;
  border-radius: 8px;
  background: var(--smoke-color);
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  padding: 7px 22px;
}
.tour-tab .nav-item .nav-link.active {
  background: var(--theme-color);
  color: var(--white-color);
}
.tour-snapshot {
  border: 1px solid #e1e4e5;
  padding: 40px 24px;
  margin-bottom: 64px;
}
.tour-snapshot .box-title {
  font-size: 28px;
  margin-top: -0.3rem;
}
.tour-snap-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 1299px) {
  .tour-snap-wrapp {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .tour-snap-wrapp {
    grid-template-columns: auto;
  }
}
.tour-snap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}
.tour-snap .icon i {
  font-size: 20px;
  color: var(--title-color);
}
.tour-snap span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: -0.3rem;
}
.tour-snap .title {
  font-weight: 400;
  display: block;
  color: var(--body-color);
}
.tour-snap .line-btn {
  text-transform: capitalize;
  padding-bottom: 0;
}
.tour-gallery-card {
  position: relative;
}
.tour-gallery-card .gallery-img {
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media (max-width: 991px) {
  .tour-gallery-card .gallery-img {
    margin-bottom: 0;
  }
}
.tour-gallery-card .gallery-img img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.tour-gallery-card .icon-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  line-height: normal;
  border: 0;
  background: transparent;
  color: var(--white-color);
  font-size: 32px;
  opacity: 0;
}
.tour-gallery-card:hover .tour-gallery-card-img {
  border-color: var(--white-color);
}
.tour-gallery-card:hover .icon-btn {
  opacity: 1;
}
.category-trips-single,
.category-trips-single-inner-wrap,
.category-feat-ribbon,
.category-feat-ribbon-txt {
  background-color: var(--theme-color2) !important;
  color: white !important;
}
.wpte-elementor-widget svg,
.wpte-trip-category svg,
.wpte-trip-category-title svg,
.wpte-icon svg {
  width: 0.5em;
}
.trip-facts-value li label {
  margin-top: 0;
}
.wp-travel-engine-archive-outer-wrap .advanced-search-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 300px;
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
}
.category-trips-single.wpte_new-layout,
.category-trips-single-inner-wrap,
.category-trip-detail-wrap,
.category-trip-desti {
  min-width: 275px;
}
.category-trips-single.wpte_new-layout,
.category-trips-single-inner-wrap,
.category-trip-prc-title-wrap {
  padding: 0 30px 0 0;
}
.wp-travel-engine-archive-repeater-wrap,
.category-main-wrap {
  margin: 0 0px;
  width: 100%;
}
.category-list .category-trips-single-inner-wrap {
  border: 1px solid #bcced2;
}
.category-list
  .category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn {
  border-radius: 48px;
}
.category-list
  .category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a,
.category-list
  .category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper {
  border-radius: 16px 0px 0 0px !important;
}
.category-list
  .category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a
  .wpte-trip-feat-img-gallery
  .owl-item
  img,
.category-list
  .category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper
  .wpte-trip-feat-img-gallery
  .owl-item
  img {
  border-radius: 16px 0px 0 0;
}
.category-trip-desc {
  color: var(--body-color);
}
.category-trips-single-inner-wrap {
  position: relative;
  background-color: var(--white-color);
  border: 1px solid #bcced2;
  border-top: transparent;
  border-radius: 16px !important;
  overflow: hidden;
}
.wp-travel-toolbar {
  padding: 24px !important;
  padding-top: 0;
  padding-right: 24px;
  border: 1px solid var(--gray-color);
  border-radius: 8px;
  margin-bottom: 24px;
}
.wp-travel-toolbar span {
  font-size: 16px;
}
.wp-travel-toolbar .wpte-trip__adv-field {
  font-size: 16px;
  color: var(--title-color);
}
.wp-travel-toolbar
  .wte-ordering
  .wpte-trip__adv-field
  .wpte__select-options
  ul {
  overflow: hidden;
}
.wp-travel-toolbar
  .wte-ordering
  .wpte-trip__adv-field
  .wpte__select-options
  ul
  li {
  padding: 7px 8px;
  color: var(--title-color);
}
.wp-travel-toolbar .wte-filterby-dropdown:before {
  display: none;
}
.wp-travel-toolbar .wte-view-modes ul {
  border: 1px solid #e9f6f9;
  border-radius: 4px;
  padding: 4px;
}
.wp-travel-toolbar .wte-view-modes ul li {
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  border: 0;
  background-color: transparent;
  text-align: center;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--body-color);
  border-radius: 5px;
  margin: 0;
}
.wp-travel-toolbar .wte-view-modes ul li.active {
  color: var(--white-color);
  background: var(--theme-color);
}
.wp-travel-toolbar .wte-view-modes ul li.active a {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.wp-travel-toolbar .wte-view-modes ul li a {
  height: 32px !important;
  width: 32px !important;
  line-height: 32px;
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper {
  border-radius: 16px 16px 0 0 !important;
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a
  .wpte-trip-feat-img-gallery
  .owl-item,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper
  .wpte-trip-feat-img-gallery
  .owl-item {
  position: relative;
  overflow: hidden;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a
  .wpte-trip-feat-img-gallery
  .owl-item:before,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper
  .wpte-trip-feat-img-gallery
  .owl-item:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 88.18%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 88.18%
  );
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a
  .wpte-trip-feat-img-gallery
  .owl-item:after,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper
  .wpte-trip-feat-img-gallery
  .owl-item:after {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a
  .wpte-trip-feat-img-gallery
  .owl-item
  img,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper
  .wpte-trip-feat-img-gallery
  .owl-item
  img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a:hover
  .wpte-trip-feat-img-gallery
  .owl-item
  img,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper:hover
  .wpte-trip-feat-img-gallery
  .owl-item
  img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-fig
  > a:hover
  .wpte-trip-feat-img-gallery
  .owl-item:after,
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .wpte-gallery-wrapper:hover
  .wpte-trip-feat-img-gallery
  .owl-item:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.category-main-wrap.category-grid
  .category-trip-prc-title-wrap
  .category-trip-title {
  font-size: 20px;
  color: var(--black-color2);
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-discount {
  padding: 7px 16px !important;
  font-weight: 400 !important;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .wishlist-toggle.active
  svg
  path {
  fill: #1ca8cb;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .price-holder
  .actual-price {
  font-size: 28px;
  font-weight: 600;
  color: var(--black-color2);
}
.category-trips-single
  .category-trips-single-inner-wrap
  .price-holder
  .striked-price {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.category-trips-single.wpte_new-layout
  .category-trips-single-inner-wrap
  .category-trip-detail-wrap
  .category-trip-dates
  span {
  font-size: 14px;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn {
  position: relative;
  background-color: var(--theme-color);
  font-weight: 500;
  padding: 18.8px 35px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 48px;
  text-transform: capitalize;
  overflow: hidden;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn:before {
  content: "";
  width: 0;
  height: 100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: -5%;
  background-color: var(--title-color);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn:hover,
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn.active {
  color: var(--white-color);
  box-shadow: none;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn:hover.th-icon
  i,
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn.active.th-icon
  i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn:hover:before,
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn.active:before {
  width: 110%;
}
.wpte-elementor-widget .category-slider,
.wpte-elementor-widget .category-slider:not(.full-width) {
  margin: 0 15px;
}
@media (max-width: 767px) {
  .wpte-elementor-widget
    .category-trips-single
    .category-trips-single-inner-wrap
    .category-trip-detail-wrap
    .category-trip-prc-wrap
    > * {
    max-width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    border-left: 0;
    padding-left: 0;
  }
}
.wpte-trip-category .wpte-trip-category-img-wrap .wpte-trip-category-overlay {
  background-color: rgba(28, 168, 203, 0.8);
}
.wpte-trip-category
  .wpte-trip-category-img-wrap
  .wpte-trip-category-btn
  .wpte-trip-cat-btn {
  border-radius: 48px;
}
.page-content {
  display: none;
}
#wp-travel-trip-wrapper {
  padding-top: var(--section-space);
  padding-bottom: var(--section-space);
}
@media (max-width: 991px) {
  #wp-travel-trip-wrapper {
    padding-top: 65px;
  }
}
#wp-travel-trip-wrapper .page-title {
  font-size: 48px;
}
.post-type-archive .wp-travel-engine-archive-outer-wrap,
.tax-destination .wp-travel-engine-archive-outer-wrap,
.tax-activities .wp-travel-engine-archive-outer-wrap,
.tax-trip_types .wp-travel-engine-archive-outer-wrap {
  padding-top: 0;
  padding-bottom: 20px;
}
.tax-trip_types .wp-travel-engine-archive-outer-wrap {
  padding-top: 0;
  padding-bottom: 20px;
}
.single-trip .trip-content-area {
  padding-top: 120px;
}
.wp-travel-engine-archive-repeater-wrap
  .category-main-wrap
  .category-trips-single {
  padding: 0px 0px;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-detail-wrap
  .button:hover,
.category-list
  .category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-viewmre-btn:hover {
  background-color: var(--theme-color);
}
.category-list .category-trips-single:last-of-type {
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .single-trip .entry-header .entry-title {
    font-size: 24px;
  }
  .single-trip .trip-content-area {
    padding-top: 80px;
  }
  .wte-related-trips-wrapper {
    padding-bottom: 76px;
  }
}
.trip-search-result .wp-travel-engine-archive-outer-wrap {
  max-width: 1320px;
  width: 100%;
  padding: 105px 15px 90px 15px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .trip-search-result .wp-travel-engine-archive-outer-wrap {
    padding: 65px 15px 80px 15px;
  }
}
.page-header .page-title {
  font-size: 48px;
}
.wpte-booking-area .wpte-bf-discount-tag,
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-discount {
  background: var(--theme-color) !important;
}
.trip-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .trip-pagination {
    margin: 0px !important;
  }
}
.trip-pagination .page-numbers {
  background-color: transparent;
  border: 1px solid var(--theme-color);
  border-radius: 6px;
  min-width: 50px;
  min-height: 50px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  color: var(--title-color);
}
.trip-pagination .page-numbers:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.trip-pagination .page-numbers.current {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.trip-pagination .pagination {
  margin-bottom: 0;
}
.page-header {
  display: none;
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-feat-ribbon
  .category-feat-ribbon-txt::before {
  display: none !important;
}
.wte-coupon-whole-wrap .coupon .wp-travel-engine-coupons-apply-btn:hover,
.wpte-bf-checkout
  .wpte-bf-checkout-form
  form
  .wpte-bf-submit
  input[type="submit"]:hover,
.wte-process-layout .wte-process-tab-controller .wte-process-btn-next:hover,
.wte-process-layout .wte-process-tab-controller .wte-process-btn-next:hover {
  opacity: 1;
  background-color: var(--theme-color2);
  border-color: var(--theme-color2);
}
.wpte-trip-category .wpte-trip-category-img-wrap figure {
  border-radius: 10px;
}
.vs-comment-form #respond {
  padding-top: 0px;
}
@media (max-width: 1199px) {
  .category-trips-single .category-trips-single-inner-wrap .price-holder {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2px;
  }
}
@media (max-width: 991px) {
  .wp-travel-engine-archive-outer-wrap {
    padding: 75px 0px 80px 0px;
  }
  .category-trips-single
    .category-trips-single-inner-wrap
    .price-holder
    > span
    + span {
    margin-left: 0px !important;
  }
  .category-list
    .category-trips-single.wpte_new-layout
    .category-trips-single-inner-wrap
    .category-trip-detail-wrap
    .category-trip-desti,
  .category-trips-single.wpte_new-layout
    .category-trips-single-inner-wrap
    .category-trip-detail-wrap
    .category-trip-desti {
    margin-bottom: 30px !important;
    max-width: 100% !important;
  }
}
@media (max-width: 767px) {
  .category-list
    .category-trips-single.wpte_new-layout
    .category-trips-single-inner-wrap
    .category-trip-detail-wrap
    .category-trip-desti {
    padding-right: 0;
    margin-bottom: 30px;
    min-width: 100%;
  }
}
@media (max-width: 575px) {
  .wp-travel-engine-archive-outer-wrap {
    display: block !important;
  }
}
.category-trips-single
  .category-trips-single-inner-wrap
  .category-trip-aval-time
  .category-trip-avl-tip-inner-wrap
  .category-available-months {
  color: var(--black-color2);
}
.wptravelengine_css_v2 .advanced-search-wrapper .sidebar {
  padding: var(--widget-padding-y, 30px) var(--widget-padding-x, 30px);
  background-color: var(--white-color);
  border: 1px solid var(--gray-color);
  border-radius: 8px;
  margin-bottom: 40px;
  position: relative;
  box-shadow: none !important;
}
.single-trip .entry-header .entry-title {
  font-size: 40px;
  font-weight: 600;
}
.single-trip .wpte-trip-feat-img-gallery img {
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
}
.wpte-booking-area .wpte-bf-btn.wte-book-now {
  position: relative;
}
.wpte-booking-area .wpte-bf-btn.wte-book-now:before {
  content: "";
  width: 0;
  height: 100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: -5%;
  background-color: var(--title-color) !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}
.wpte-booking-area .wpte-bf-btn.wte-book-now:hover,
.wpte-booking-area .wpte-bf-btn.wte-book-now.active {
  color: var(--white-color);
  box-shadow: none;
  background-color: var(--title-color) !important;
}
.wpte-booking-area .wpte-bf-btn.wte-book-now:hover:before,
.wpte-booking-area .wpte-bf-btn.wte-book-now.active:before {
  width: 110%;
}
.wpte-booking-area .wpte-booking-footer-text {
  padding: 24px 10px 24px 24px !important;
  text-align: left !important;
}
#tabs-container .tab-content label {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;
}
.expand-all-button input[type="checkbox"] {
  visibility: visible;
  opacity: 1;
  padding: 0;
  width: 56px !important;
  height: 26px !important;
}
.wrapper--style,
.wptravelengine_css_v2 #tabs-container,
.wte_enquiry_contact_form,
.wte-trip-facts {
  border: 1px solid #bcced2;
  border-radius: 0;
  box-shadow: none;
}
.wrapper--style input.enquiry-submit:hover,
.wptravelengine_css_v2 #tabs-container input.enquiry-submit:hover,
.wte_enquiry_contact_form input.enquiry-submit:hover,
.wte-trip-facts input.enquiry-submit:hover {
  border-color: var(--title-color);
  background-color: var(--title-color);
  color: var(--white-color);
}
.wpte-tabs-container.fixed-header .nav-tab-wrapper {
  display: none !important;
}
.wpte-dashboard
  .wpte-lrf-content-area
  .wpte-bookings-contents
  .wpte-booked-trip-wrap
  .wpte-booked-trip-content
  .wpte-booked-trip-buttons-right
  a {
  font-size: 14px;
  font-weight: 400;
}
.wpte-dashboard
  .wpte-lrf-content-area
  .wpte-lrf-form
  .wpte-lrf-field.lrf-submit
  .wpte-lrf-btn {
  font-weight: 400;
  padding: 14px 24px;
  font-size: 14px;
}
.wpte-booking-details-wrapper .wpte-trip-link {
  font-size: 16px;
  font-weight: 500;
}
.service-inner-img {
  border-radius: 10px;
}
.service-inner-img img {
  border-radius: 10px;
}
.service-img {
  border-radius: 8px;
}
.service-img img {
  border-radius: 8px;
}
.resort-grid-list {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  border: 1px solid #e1e4e5;
  border-radius: 8px;
  padding: 40px 20px;
  list-style: none;
  margin: 0;
  gap: 20px;
}
@media (max-width: 1399px) {
  .resort-grid-list {
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 1199px) {
  .resort-grid-list {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 767px) {
  .resort-grid-list {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 575px) {
  .resort-grid-list {
    grid-template-columns: auto;
  }
}
.resort-grid-list li {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}
.resort-grid-list li .resort-grid-list-icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 50px;
  height: 50px;
  line-height: 45px;
  border-radius: 4px;
  border: 1px solid #e1e4e5;
  text-align: center;
}
.resort-grid-list li .resort-grid-list-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color2);
  margin-bottom: 0;
}
.resort-grid-list li .resort-grid-list-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-color);
  margin-bottom: -0.3em;
}
.feature-item {
  position: relative;
  background: #e9f6f9;
  border-radius: 24px;
  padding: 24px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.feature-item_icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  display: block;
  margin: auto;
  background: rgba(28, 168, 203, 0.1);
  border-radius: 50px;
  margin-bottom: 24px;
}
.feature-item_text {
  font-weight: 400;
  line-height: 26px;
  margin-bottom: -0.4rem;
}
.feature-item .box-title {
  font-size: 22px;
  margin-bottom: 7px;
}
@media (max-width: 1299px) {
  .feature-item .box-title {
    font-size: 20px;
  }
}
.feature-item img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.feature-item:hover {
  background-color: var(--theme-color);
}
.feature-item:hover .feature-item_icon {
  background-color: var(--white-color);
}
.feature-item:hover .feature-item_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.feature-item:hover .feature-item_text {
  color: var(--white-color);
}
.feature-item:hover .box-title {
  color: var(--white-color);
}
.feature-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.feature-wrapper .feature-icon i {
  font-size: 46px;
  font-weight: 300;
  line-height: 56px;
}
.feature-wrapper .header-info_link {
  font-family: var(--title-font);
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: var(--title-color);
  margin-bottom: -0.5rem;
}
.feature-wrapper .header-info_link:hover {
  color: var(--theme-color);
}
.feature-wrapper .header-info_link a {
  color: inherit;
}
.feature-wrapper .header-info_label {
  font-family: var(--body-font);
  color: var(--body-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.feature-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  max-width: 289px;
}
.feature-circle-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin: 40px 0;
  padding: 40px 0;
}
@media (max-width: 767px) {
  .feature-circle-wrap {
    display: block;
  }
}
@media (max-width: 767px) {
  .feature-circle:not(:last-child) {
    margin-bottom: 30px;
  }
}
.feature-circle .box-title {
  line-height: 34px;
  margin-bottom: 0px;
}
.feature-circle_text {
  margin-bottom: 0px;
  font-size: 14px;
}
.feature-circle .progressbar {
  position: relative;
  max-height: 100px;
}
.feature-circle .circle-num {
  font-size: 30px;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  text-align: center;
}
.choose-about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 25px;
  max-width: 621px;
}
.choose-about:not(:last-child) {
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .choose-about {
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .choose-about {
    display: block;
  }
}
.choose-about_icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: var(--theme-color);
  border-radius: 16px;
}
@media (max-width: 375px) {
  .choose-about_icon {
    display: block;
    margin: auto auto 20px auto;
  }
}
.choose-about_text {
  margin-bottom: 0;
}
@media (max-width: 375px) {
  .choose-about_text {
    text-align: center;
  }
}
.choose-about img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.choose-about .box-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 3px;
}
@media (max-width: 375px) {
  .choose-about .box-title {
    text-align: center;
  }
}
.choose-about:hover .choose-about_icon img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.choose-area {
  position: relative;
  z-index: 2;
  -webkit-clip-path: path(
    "M1920 0H0V597V603V647.237L162.995 713.921C283.181 763.091 411.793 788.383 541.648 788.383H1378.35C1508.21 788.383 1636.82 763.091 1757.01 713.921L1920 647.237V603V597V0Z"
  );
  clip-path: path(
    "M1920 0H0V597V603V647.237L162.995 713.921C283.181 763.091 411.793 788.383 541.648 788.383H1378.35C1508.21 788.383 1636.82 763.091 1757.01 713.921L1920 647.237V603V597V0Z"
  );
}
@media (min-width: 1930px) {
  .choose-area {
    -webkit-clip-path: path(
      "M0 0H3333V645.762L2852.45 764.707C2773.84 784.164 2693.16 794 2612.18 794H720.818C639.838 794 559.158 784.164 480.551 764.707L0 645.762V0Z"
    );
    clip-path: path(
      "M0 0H3333V645.762L2852.45 764.707C2773.84 784.164 2693.16 794 2612.18 794H720.818C639.838 794 559.158 784.164 480.551 764.707L0 645.762V0Z"
    );
  }
}
@media (max-width: 1199px) {
  .choose-area {
    -webkit-clip-path: none;
    clip-path: none;
  }
}
@media (max-width: 1199px) {
  .choose-tab-area {
    margin: 80px 0;
  }
}
.choose-image {
  position: relative;
  margin-left: -110px;
}
@media (max-width: 1199px) {
  .choose-image {
    margin: 0;
  }
}
.choose-image .choose-text {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.06);
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.06);
  font-family: var(--title-font);
  font-weight: 900;
  font-size: 114px;
  line-height: 94px;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 38%;
  left: -58.5%;
  margin: 0;
}
.choose-text {
  color: #888c97;
}
.mission-content .checklist li {
  color: var(--white-color);
}
.mission-content .mission-text {
  color: var(--gray-color);
}
.features-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 1299px) {
  .features-wrapper {
    display: block;
  }
}
.features-wrapper .features-img {
  min-width: 300px;
}
@media (max-width: 1299px) {
  .features-wrapper .features-img {
    min-width: 100%;
    margin-top: 20px;
  }
}
.features-wrapper .features-img img {
  width: 100%;
}
.features-content .box-title {
  margin-bottom: 5px;
}
.features-content .checklist li:not(:last-child) {
  margin-bottom: 5px;
}
.feature-text {
  font-size: 18px;
  max-width: 773px;
  display: block;
  margin: auto;
}
.gallery-area .row {
  --bs-gutter-x: 17px;
}
.gallery-card:nth-child(2) {
  margin-top: 17px;
}
.gallery-card .box-img {
  border-radius: 24px;
}
@media (max-width: 991px) {
  .gallery-card .box-img {
    margin-bottom: 10px;
  }
}
.gallery-card .box-img img {
  border-radius: 24px;
}
.gallery-card .box-img .icon-btn {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 3;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border: none;
  font-size: 40px;
  font-weight: 300;
  color: var(--white-color);
}
.gallery-card .box-img .icon-btn:hover {
  background-color: transparent;
}
.gallery-card:hover .icon-btn {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.gallery-box-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
.gallery-box-wrapp .gallery-box.style2:nth-child(5) {
  margin-top: 24px;
}
.gallery-box {
  position: relative;
}
.gallery-box.style2:nth-child(2) {
  margin-top: 0;
}
.gallery-box.style2:nth-child(1) {
  margin-bottom: 24px;
}
.gallery-box.style2:hover .gallery-img {
  -webkit-transform: skewY(4deg);
  -ms-transform: skewY(4deg);
  transform: skewY(4deg);
}
.gallery-box.style2:hover .icon-btn {
  -webkit-transform: skewY(0deg);
  -ms-transform: skewY(0deg);
  transform: skewY(0deg);
}
.gallery-box.style2 .gallery-img img {
  width: 100%;
}
@media (max-width: 991px) {
  .gallery-box.style2 .gallery-img {
    margin-bottom: 0;
  }
}
.gallery-box.style3 .gallery-img {
  border-radius: 8px;
}
.gallery-box.style3 .gallery-img:after {
  border-radius: 8px;
}
.gallery-box.style3 .gallery-img img {
  border-radius: 8px;
  width: 100%;
}
@media (max-width: 991px) {
  .gallery-box.style4 .gallery-img {
    margin-bottom: 0;
  }
}
.gallery-box.style5 .gallery-img {
  height: 360px;
}
.gallery-box.style5 .gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-box_wrapp:first-child .gallery-box {
  margin-top: 99px;
}
@media (max-width: 991px) {
  .gallery-box_wrapp:first-child .gallery-box {
    margin-top: 0;
  }
}
.gallery-box_wrapp:last-child .gallery-box {
  margin-top: 348px;
}
@media (max-width: 1500px) {
  .gallery-box_wrapp:last-child .gallery-box {
    margin-top: 24px;
  }
}
@media (max-width: 1299px) {
  .gallery-box_wrapp:last-child .gallery-box {
    margin-top: 0;
  }
}
.gallery-box:nth-child(2) {
  margin-top: 24px;
}
@media (max-width: 1299px) {
  .gallery-box:nth-child(2) {
    margin-bottom: 24px;
  }
}
@media (max-width: 991px) {
  .gallery-box:nth-child(2) {
    margin-bottom: 0;
  }
}
.gallery-box .gallery-img {
  position: relative;
  overflow: hidden;
  z-index: 2;
  border-radius: 16px;
}
@media (max-width: 991px) {
  .gallery-box .gallery-img {
    margin-bottom: 0;
  }
}
.gallery-box .gallery-img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    bottom,
    rgba(13, 13, 12, 0.5),
    rgba(13, 13, 12, 0.5)
  );
  background: linear-gradient(
    0deg,
    rgba(13, 13, 12, 0.5),
    rgba(13, 13, 12, 0.5)
  );
  border-radius: 16px;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  z-index: 1;
}
.gallery-box .gallery-img .icon-btn {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 3;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border: none;
  font-size: 40px;
  font-weight: 300;
  color: var(--white-color);
}
.gallery-box .gallery-img .icon-btn:hover {
  background-color: transparent;
}
.gallery-box .gallery-img img {
  border-radius: 16px;
}
.gallery-box:hover .gallery-img:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.gallery-box:hover .icon-btn {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.gallery-row4 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 12px;
}
@media (max-width: 1199px) {
  .gallery-row4 {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 767px) {
  .gallery-row4 {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 575px) {
  .gallery-row4 {
    grid-template-columns: auto;
  }
}
.gallery-thumb.style2 {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  margin: 0 8px;
}
.gallery-thumb.style2 img {
  position: relative;
  width: 100%;
  border-radius: 24px;
}
.gallery-thumb.style2 .gallery-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  color: var(--white-color);
  font-size: 24px;
}
.gallery-thumb2 {
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}
.gallery-thumb2.style2 .gallery-btn {
  font-size: 50px;
}
.gallery-thumb2 img {
  width: 100%;
  height: 100%;
}
.gallery-thumb2:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 0.6;
}
.gallery-thumb2:hover .gallery-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.gallery-thumb2:before {
  content: "";
  height: 100%;
  width: 100%;
  background: var(--theme-color);
  border-radius: 24px;
  position: absolute;
  inset: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.gallery-thumb2 .gallery-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white-color);
  display: block;
  margin: 0 auto;
  font-size: 25px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  z-index: 1;
}
.gallery-area3 {
  border-bottom: 1px solid rgba(233, 246, 249, 0.2);
  padding-bottom: 64px;
}
.destination-slider .swiper-slide {
  border-radius: 24px;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}
.destination-slider .swiper-slide.swiper-slide-active {
  border-radius: 24px;
  -webkit-filter: none !important;
  filter: none !important;
}
.destination-slider .destination-box .destination-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.destination-box {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  --space: 40px;
}
@media (max-width: 991px) {
  .destination-box {
    --space: 20px;
  }
}
@media (max-width: 575px) {
  .destination-box {
    padding: 10px;
    border-radius: 20px;
    --space: 10px;
  }
}
.destination-box .destination-img {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  z-index: 2;
}
.destination-box .destination-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.3) 90%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.3) 90%
  );
  border-radius: 24px;
}
@media (max-width: 575px) {
  .destination-box .destination-img {
    border-radius: 15px;
  }
}
.destination-box .destination-img img {
  max-width: 430px;
  max-height: 636px;
  width: 100%;
  object-fit: cover;
  border-radius: 24px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .destination-box .destination-img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
  }
}
.destination-box .destination-content {
  position: absolute;
  bottom: 40px;
  z-index: 2;
  max-width: 423px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
@media (max-width: 991px) {
  .destination-box .destination-content {
    max-width: 380px;
    gap: 60px;
  }
}
@media (max-width: 767px) {
  .destination-box .destination-content {
    max-width: 100%;
    display: block;
    margin: auto;
    text-align: center;
  }
}
.destination-box .box-title {
  margin-bottom: 0px;
}
@media (max-width: 375px) {
  .destination-box .box-title {
    font-size: 16px;
  }
}
.destination-box .box-title a {
  color: var(--white-color);
}
.destination-box .destination-subtitle {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 8px;
}
.destination-box .th-btn {
  padding: 11.5px 34px;
}
.destination-box .th-btn:after {
  -webkit-mask-image: url("./img/icon/arrow-right3.svg");
  mask-image: url("./img/icon/arrow-right3.svg");
  width: 16px;
  height: 16px;
}
@media (max-width: 1199px) {
  .destination-box .th-btn {
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  .destination-box .th-btn {
    padding: 7px 20px;
    margin-top: 10px;
  }
  .destination-box .th-btn:after {
    display: none;
  }
}
@media (max-width: 375px) {
  .destination-box .th-btn {
    padding: 7px 10px;
  }
}
.destination-box:hover .destination-img:before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.destination-list {
  position: relative;
  height: 636px;
  border-radius: 24px;
  z-index: 2;
  -webkit-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
  cursor: pointer;
}
@media (max-width: 767px) {
  .destination-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 110px;
  }
}
.destination-list:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 77.91%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 77.91%
  );
  border-radius: 24px;
  z-index: -1;
}
.destination-list-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
@media (max-width: 767px) {
  .destination-list-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.destination-list-wrap {
  -webkit-box-flex: 100%;
  -webkit-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.destination-list-wrap.style2.active {
  -webkit-box-flex: 205%;
  -webkit-flex: 205%;
  -ms-flex: 205%;
  flex: 205%;
}
.destination-list-wrap.active {
  -webkit-box-flex: 324%;
  -webkit-flex: 324%;
  -ms-flex: 324%;
  flex: 324%;
}
@media (max-width: 1199px) {
  .destination-list-wrap.active {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .destination-list-wrap.active .destination-list {
    max-height: 345px;
  }
}
.destination-list-wrap.active .th-btn {
  opacity: 1;
  visibility: visible;
}
.destination-list-wrap.active .box-content {
  visibility: visible;
  opacity: 1;
  width: 100%;
  max-height: 325px;
}
.destination-list-wrap.active .destination-content {
  left: 40px;
  bottom: 40px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
@media (max-width: 991px) {
  .destination-list-wrap.active .destination-content {
    left: 20px;
    bottom: 20px;
  }
}
.destination-list .destination-content {
  position: absolute;
  left: 5px;
  bottom: 70px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media (max-width: 767px) {
  .destination-list .destination-content {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    bottom: 20px;
    left: 20px;
  }
}
.destination-list .box-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: var(--white-color);
  margin-bottom: 0;
}
@media (max-width: 375px) {
  .destination-list .box-title {
    font-size: 18px;
    line-height: 28px;
  }
}
.destination-list .destination-subtitle {
  color: var(--white-color);
}
.destination-list .th-btn {
  position: absolute;
  right: 40px;
  bottom: 40px;
  padding: 10px 30px;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 991px) {
  .destination-list .th-btn {
    right: 20px;
    bottom: 20px;
  }
}
.destination-list .box-icon {
  padding: 30px 40px;
  border-bottom: 3px solid var(--white-color);
}
.destination-list .box-content {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-height: 0;
  padding: 30px 30px 40px 40px;
  overflow: hidden;
}
.destination-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--theme-color);
}
.destination-item {
  background-color: var(--smoke-color);
  border-radius: 16px;
  text-align: center;
  height: 500px;
  flex-direction: column; 
}
.destination-item_img {
  border-radius: 16px 16px 0px 0px;
  height: 300px;
}
.destination-item_img img {
  flex: 0; /* Do not allow the image section to grow */
  height: 300px; /* Set a fixed height for the image */
  overflow: hidden; /* Hide overflow */
  display: flex; /* Use flex to center image */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}
.destination-item_img img {
  width: 100%; /* Make the image fill the container */
  height: auto; /* Keep aspect ratio */
  max-height: 100%; /* Limit height to prevent overflow */
}

@media (max-width: 991px) {
  .destination-item_img {
    margin-bottom: 0;
  }
}
.destination-item .destination-content {
  padding: 30px 0 24px 0;
}
.destination-item .box-title {
  color: var(--black-color2);
  margin-bottom: 0;
}
.destination-item .destination-text {
  color: var(--black-color2);
  margin-bottom: 20px;
}
.destination-item .th-btn {
  padding: 10px 25px;
  border: 1px solid var(--theme-color);
  font-weight: 500;
}
.destination-item .th-btn:after {
  -webkit-mask-image: url("../src/img/icon/arrow-right2.svg");
  mask-image: url("../src/img/icon/arrow-right2.svg");
  width: 15px;
  height: 15px;
}
.destination-item2 {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}
.destination-item2 .box-img {
  position: relative;
  border: 10px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 991px) {
  .destination-item2 .box-img {
    margin-bottom: 0;
  }
}
.destination-item2 .box-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(4, 4, 4, 0) 44.5%,
    rgba(13, 13, 12, 0.6) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(4, 4, 4, 0) 44.5%,
    rgba(13, 13, 12, 0.6) 100%
  );
  border-radius: 24px;
  z-index: 10;
}
.destination-item2 .box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.destination-item2 .box-content {
  position: absolute;
  left: 34px;
  bottom: 34px;
  z-index: 11;
}
.destination-item2 .box-title {
  color: var(--white-color);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .destination-item2 .box-title {
    font-size: 22px;
  }
}
.destination-item2 .box-text {
  color: var(--white-color);
  display: block;
}
.destination-item2:hover .box-img {
  border-color: var(--white-color);
}
.destination-area6 .th-container {
  --main-container: 1472px;
}
.destination-slider2 .swiper-slide {
  box-shadow: none;
  border-radius: 24px;
  overflow: hidden;
}
.destination-slider2 .swiper-slide.swiper-slide-active {
  border-radius: 24px;
}
.destination-slider2 .swiper-slide.swiper-slide-active .destination-img {
  width: 425px;
  height: 470px;
  display: block;
  margin: auto;
}
.destination-slider2 .swiper-slide.swiper-slide-active .destination-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.destination-box2 {
  position: relative;
}
.destination-box2 .destination-img {
  position: relative;
  border-radius: 24px;
  height: 470px;
  overflow: hidden;
  box-shadow: none;
}
.destination-box2 .destination-img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(13, 13, 12, 0.5);
  border-radius: 24px;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  z-index: 1;
}
.destination-box2 .destination-img .icon-btn {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 3;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border: none;
  font-size: 40px;
  font-weight: 300;
  color: var(--white-color);
}
.destination-box2 .destination-img .icon-btn:hover {
  background-color: transparent;
}
.destination-box2 .destination-img img {
  width: 100%;
  height: 100%;
  object-fit: calc(100%, 100%);
  border-radius: 24px;
}
.destination-box2:hover .destination-img:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.destination-box2:hover .icon-btn {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.search-form-area .search-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.search-form-area .search-form input {
  background-color: var(--smoke-color);
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: none;
  height: 60px;
  border-radius: 100px;
  padding-right: 25px;
}
.search-form-area .search-form button {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  font-size: 18px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  padding: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  text-align: center;
}
.search-form-area .search-form button:hover {
  background-color: var(--title-color);
}
.destination-checklist {
  padding: 24px;
  border: 1px solid #e1e4e6;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 480px) {
  .destination-checklist {
    display: block;
    border: none;
    padding: 0;
  }
}
.destination-checklist .checklist.style2 {
  min-width: 387px;
}
@media (max-width: 1299px) {
  .destination-checklist .checklist.style2 {
    min-width: 250px;
  }
}
@media (max-width: 480px) {
  .destination-checklist .checklist.style2 {
    min-width: 100%;
    margin-bottom: 20px;
  }
}
.destination-checklist .checklist.style2 li {
  color: var(--black-color2);
}
.destination-checklist .checklist.style2:last-child {
  border-left: 1px solid #e1e4e5;
}
@media (max-width: 480px) {
  .destination-checklist .checklist.style2:last-child {
    border: none;
  }
}
.destination-checklist .checklist.style2:last-child li {
  padding-left: 24px;
}
@media (max-width: 480px) {
  .destination-checklist .checklist.style2:last-child li {
    padding-left: 0;
  }
}
.destination-checklist .checklist.style2:last-child li:before {
  display: none;
}
.destination-checklist .checklist.style3 ul li {
  color: var(--black-color2);
}
.destination-checklist .checklist.style3 ul li:before {
  display: none;
}
.activities-checklist {
  padding: 24px;
  border: 1px solid #e1e4e6;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1399px) {
  .activities-checklist {
    display: block;
  }
}
@media (max-width: 480px) {
  .activities-checklist {
    border: none;
    padding: 0;
  }
}
.activities-checklist .checklist.style2 {
  min-width: 387px;
}
@media (max-width: 1299px) {
  .activities-checklist .checklist.style2 {
    min-width: 250px;
  }
}
@media (max-width: 480px) {
  .activities-checklist .checklist.style2 {
    min-width: 100%;
    margin-bottom: 20px;
  }
}
.activities-checklist .checklist.style2 li {
  color: var(--black-color2);
}
.activities-checklist .checklist.style2:last-child {
  border-left: 1px solid #e1e4e5;
  padding-left: 24px;
}
@media (max-width: 1399px) {
  .activities-checklist .checklist.style2:last-child {
    border: none;
    padding-left: 0;
  }
}
@media (max-width: 480px) {
  .activities-checklist .checklist.style2:last-child {
    border: none;
  }
}
.destinationSlider6 .slider-arrow {
  left: var(--pos-x, -60px);
}
.destinationSlider6 .slider-arrow.slider-next {
  right: var(--pos-x, -60px);
  left: auto;
}
.cta-area {
  padding: 145px 0;
}
.newsletter-form.style3 {
  display: block;
}
.newsletter-form.style3 input {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  border: none;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .newsletter-form.style3 input {
    margin-bottom: 0;
  }
}
.newsletter-form.style3 input::-moz-placeholder {
  color: var(--white-color);
}
.newsletter-form.style3 input::-webkit-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style3 input:-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style3 input::-ms-input-placeholder {
  color: var(--white-color);
}
.newsletter-form.style3 input::placeholder {
  color: var(--white-color);
}
.cta-title {
  font-weight: 800;
  font-size: 100px;
  line-height: 96px;
  text-transform: uppercase;
  margin-bottom: 15px !important;
}
@media (max-width: 1299px) {
  .cta-title {
    font-size: 80px;
    line-height: 96px;
  }
}
@media (max-width: 991px) {
  .cta-title {
    font-size: 70px;
    line-height: 86px;
  }
}
@media (max-width: 767px) {
  .cta-title {
    font-size: 60px;
    line-height: 76px;
  }
}
@media (max-width: 375px) {
  .cta-title {
    font-size: 50px;
    line-height: 66px;
  }
}
@media (max-width: 350px) {
  .cta-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.app-mockup {
  position: relative;
  margin-bottom: -170px;
  z-index: 2;
}
@media (max-width: 991px) {
  .app-mockup {
    margin-bottom: -40px;
  }
}
.app-mockup:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 315.67px;
  height: 7.89px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-filter: blur(13.15px);
  filter: blur(13.15px);
}
.main-menu ul.mega-menu {
  position: absolute;
  top: 100%;
  left: 0 !important;
  right: 0 !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1200px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  max-height: 500px;
  overflow: overlay;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition:
    opacity 500ms ease,
    visibility 500ms ease,
    -webkit-transform 700ms ease;
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    -webkit-transform 700ms ease;
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease,
    -webkit-transform 700ms ease;
  z-index: 99;
  background-color: var(--white-color);
  padding: 20px 10px;
}
@media (max-width: 1599px) {
  .main-menu ul.mega-menu {
    width: 1170px;
    left: -10rem !important;
  }
}
@media (max-width: 1299px) {
  .main-menu ul.mega-menu {
    left: -18rem !important;
  }
}
.main-menu ul.mega-menu li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}
.main-menu ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}
.main-menu ul.mega-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 0px;
}
.main-menu ul.mega-menu::-webkit-scrollbar-track {
  background: #fff;
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}
.main-menu ul.mega-menu::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 25%,
    transparent 20%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 0px;
}
.main-menu ul.mega-menu li li {
  padding: 2px 0;
}
.main-menu ul.mega-menu li a {
  display: inline-block;
  text-transform: capitalize;
}
.main-menu ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after,
.main-menu ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}
.main-menu ul.mega-menu > li > a:hover {
  padding-left: 0;
}
@media (max-width: 991px) {
  .main-menu ul.mega-menu {
    padding: 10px 0 !important;
  }
}
.main-menu ul.mega-menu .th-mobile-menu ul li ul li {
  padding-left: 0px;
}
.main-menu ul.mega-menu .th-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 12px;
  margin-right: 10px;
  display: none;
}
.mega-menu-box {
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.mega-menu-box .mega-menu-img {
  position: relative;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.mega-menu-box .mega-menu-img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}
.mega-menu-box .mega-menu-img img {
  -webkit-transition: -webkit-filter 500ms ease;
  transition: -webkit-filter 500ms ease;
  transition: filter 500ms ease;
  transition:
    filter 500ms ease,
    -webkit-filter 500ms ease;
  -webkit-filter: blur(0px);
  filter: blur(0px);
}
.mega-menu-box .mega-menu-img .btn-wrap {
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition:
    opacity 600ms linear,
    -webkit-transform 500ms ease;
  transition:
    opacity 600ms linear,
    -webkit-transform 500ms ease;
  transition:
    transform 500ms ease,
    opacity 600ms linear;
  transition:
    transform 500ms ease,
    opacity 600ms linear,
    -webkit-transform 500ms ease;
  -webkit-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  opacity: 0;
}
.mega-menu-box .mega-menu-img .btn-wrap .th-btn {
  padding: 6px 18px;
  font-size: 14px;
  color: var(--white-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.mega-menu-box .mega-menu-img .btn-wrap .th-btn:after {
  display: none;
}
.mega-menu-box:hover .btn-wrap {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}
.mega-menu-box:hover .mega-menu-img img {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.mega-menu-box .mega-menu-title {
  margin-bottom: 0;
  text-align: center;
  line-height: normal;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 14px 0;
}
.mega-menu-box .mega-menu-title span {
  color: var(--theme-color);
  text-transform: capitalize;
  font-size: 16px;
  margin-right: 5px;
}
.mega-menu-box .mega-menu-title span:after {
  display: none;
}
.mega-menu-box .mega-menu-title a {
  color: var(--black-color2);
  line-height: normal;
}
.mega-menu-box .mega-menu-title a:after {
  display: none;
}
.mega-menu-box .mega-menu-title a:hover {
  color: var(--title-color);
}
@media (max-width: 991px) {
  .mega-menu-box .mega-menu-title a {
    padding: 0;
  }
  .mega-menu-box .mega-menu-title a:before {
    display: none;
  }
}
.mfp-wrap {
  -webkit-transform: translateY(-65px);
  -ms-transform: translateY(-65px);
  transform: translateY(-65px);
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.mfp-wrap.mfp-ready {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.popup-login-register {
  position: relative;
  max-width: 606px;
  display: block;
  margin: auto;
  background: var(--white-color);
  border-radius: 16px;
  padding: 40px;
}
.popup-login-register ul {
  gap: 30px;
  border-bottom: 3px solid var(--th-border-color);
  margin-bottom: 28px;
}
.popup-login-register ul li .nav-menu {
  position: relative;
  background: transparent;
  font-size: 18px;
  color: var(--body-color);
  border: none;
  font-family: var(--title-font);
  font-weight: 600;
  padding: 0;
  padding-bottom: 20px;
}
.popup-login-register ul li .nav-menu:before {
  content: "";
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0;
}
.popup-login-register ul li .nav-menu.active {
  color: var(--black-color2);
}
.popup-login-register ul li .nav-menu.active:before {
  background-color: var(--theme-color);
}
.popup-login-register .box-title {
  font-size: 28px;
  font-weight: 700;
}
.booking-form {
  position: relative;
  background-color: var(--white-color);
  border: 2px solid var(--theme-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 16px;
  padding: 21px 32px;
  z-index: 3;
  margin-top: -55px;
}
@media (max-width: 1199px) {
  .booking-form {
    padding: 20px 20px;
  }
}
.booking-form.style2 {
  position: relative;
  left: 0;
  bottom: 0;
  margin-top: 0;
  padding: 27px 32px;
  border-radius: 16px 0px 0px 0px;
  box-shadow: none;
  border: none;
  min-width: 896px;
}
@media (max-width: 1399px) {
  .booking-form.style2 {
    min-width: 849px;
  }
}
@media (max-width: 1299px) {
  .booking-form.style2 {
    min-width: 780px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style2 {
    min-width: 640px;
  }
}
@media (max-width: 991px) {
  .booking-form.style2 {
    min-width: 100%;
    box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.15);
    border-radius: 16px;
  }
}
.booking-form.style2 .input-wrap .form-group {
  margin-bottom: 0;
  height: 41px;
}
@media (max-width: 1199px) {
  .booking-form.style2 .input-wrap .form-group {
    height: 100%;
    padding-bottom: 20px;
    padding-left: 13px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style2 .input-wrap .form-group .search-input {
    min-width: 95%;
  }
}
.booking-form.style2 .input-wrap .form-group:not(:first-child) {
  border-left: 1px solid #e1e4e5;
  padding-left: 25px;
}
@media (max-width: 1199px) {
  .booking-form.style2 .input-wrap .form-group:not(:first-child) {
    border-left: none;
    padding-left: 12px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style2 .input-wrap .form-group .nice-select:after {
    right: 20px;
  }
}
@media (max-width: 1299px) {
  .booking-form.style2 .input-wrap .form-btn .th-btn {
    padding: 15px 35px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style2 .input-wrap .form-btn .th-btn {
    width: 100%;
  }
}
.form_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}
@media (max-width: 1299px) {
  .input-wrap.style2 .form-group:not(:first-child) {
    border-left: transparent;
  }
}
.input-wrap .form-group {
  margin-bottom: 0;
  height: 41px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
@media (max-width: 991px) {
  .input-wrap .form-group {
    margin-bottom: 15px;
    padding-left: 25px;
  }
  .input-wrap .form-group .search-input {
    min-width: 160px;
  }
}
@media (max-width: 767px) {
  .input-wrap .form-group .search-input {
    min-width: 95%;
  }
}
.input-wrap .form-group:not(:first-child) {
  border-left: 1px solid #e1e4e5;
  padding-left: 25px;
}
@media (max-width: 991px) {
  .input-wrap .form-group:not(:last-child) {
    border: none;
  }
}
.input-wrap .form-group .icon i {
  font-size: 24px;
  color: var(--theme-color);
}
@media (max-width: 1199px) {
  .input-wrap .form-group .icon i {
    font-size: 18px;
  }
}
.input-wrap .form-group label {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: var(--black-color);
  margin: 0;
}
.input-wrap .form-group input {
  padding: 0 0 0 37px;
  font-size: 16px;
  font-weight: 600;
}
.input-wrap .form-group .nice-select {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.input-wrap .form-group .nice-select:after {
  top: 10px;
  right: -20px;
}
@media (max-width: 991px) {
  .input-wrap .form-group .nice-select:after {
    right: 20px;
  }
}
.input-wrap .form-group .nice-select.open .list {
  -webkit-animation: fade-down 0.3s linear;
  animation: fade-down 0.3s linear;
}
.input-wrap .form-group .nice-select .list {
  overflow-y: scroll;
  z-index: 9;
}
.input-wrap .form-group .nice-select .list {
  width: 200px;
}
.input-wrap .form-group select,
.input-wrap .form-group .form-control,
.input-wrap .form-group .form-select,
.input-wrap .form-group textarea,
.input-wrap .form-group .nice-select,
.input-wrap .form-group input {
  height: 30px;
  line-height: 30px;
  border: none;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--black-color2);
}
@media (max-width: 1199px) {
  .input-wrap .form-group select,
  .input-wrap .form-group .form-control,
  .input-wrap .form-group .form-select,
  .input-wrap .form-group textarea,
  .input-wrap .form-group .nice-select,
  .input-wrap .form-group input {
    font-size: 14px;
  }
}
.input-wrap .form-group .nice-select .option {
  color: var(--title-color);
  display: block;
}
.input-wrap .form-group .nice-select .option p {
  display: block;
}
.input-wrap .form-group .nice-select .option.selected {
  color: var(--theme-color) !important;
  background: var(--theme-color) !important;
  font-weight: 400;
}
.input-wrap .form-group .nice-select .option.selected.focus {
  color: #fff !important;
  background: var(--theme-color) !important;
}
.input-wrap .form-group .nice-select .option:hover,
.input-wrap .form-group .nice-select .option.focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}
.input-wrap .form-group .nice-select.open .list {
  border-bottom: 3px solid var(--theme-color);
}
.input-wrap .form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .input-wrap .form-btn {
    display: block;
  }
  .input-wrap .form-btn .th-btn {
    width: 100%;
  }
}
.input-wrap .form-btn .th-btn {
  padding: 15px 45px;
}
@media (max-width: 767px) {
  .input-wrap .form-btn .th-btn {
    width: 100%;
  }
}
.booking-form.style3 {
  border-radius: 100px;
  border: none;
  border-bottom: 2px solid var(--theme-color);
  box-shadow: none;
}
@media (max-width: 767px) {
  .booking-form.style3 {
    border-radius: 20px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style3 .th-btn {
    display: none;
  }
}
.booking-form.style4 {
  border-radius: 100px;
  border: none;
  border: 2px solid var(--theme-color);
  box-shadow: none;
  margin: 60px 0 0 0;
}
@media (max-width: 767px) {
  .booking-form.style4 {
    border-radius: 20px;
  }
}
@media (max-width: 1199px) {
  .booking-form.style4 .th-btn {
    display: none;
  }
}
.booking-client-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.booking-client-box .client-thumb-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.booking-client-box .client-thumb-group .thumb {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.booking-client-box .client-thumb-group .thumb:not(:first-child) {
  margin-left: -30px;
}
.booking-client-box .client-thumb-group .thumb img {
  border-radius: 50%;
  height: 60px;
}
@media (max-width: 991px) {
  .booking-client-box .client-thumb-group .thumb img {
    height: 50px;
  }
}
.booking-client-box .client-thumb-group .thumb.icon {
  background: var(--theme-color);
  border: 1px solid var(--white-color);
  font-size: 16px;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  margin-left: -30px;
}
@media (max-width: 991px) {
  .booking-client-box .client-thumb-group .thumb.icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.booking-client-box .client-thumb-group .thumb.icon i {
  color: var(--white-color);
}
.booking-client-box .cilent-box_counter {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: var(--black-color2);
  margin-bottom: 0;
}
.wpte-trip-sfilter-wrapper {
  position: relative;
  background-color: var(--white-color);
  border: 2px solid var(--theme-color);
  box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.25);
  border-radius: 16px;
  padding: 25px 32px;
  z-index: 3;
}
.wpte-trip-sfilter-wrapper .wpte-trip__adv-field {
  border: none;
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit {
  width: 100%;
  text-align: center;
  height: 50px;
  padding: 20px 52px;
  border-radius: 48px !important;
  background-color: var(--theme-color);
  position: relative;
  overflow: hidden;
  z-index: 3;
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit:before {
  content: "";
  width: 0;
  height: 100%;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: -5%;
  background-color: var(--title-color);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit:hover,
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit.active {
  color: var(--white-color);
  box-shadow: none;
  background-color: var(--theme-color);
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit:hover.th-icon i,
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit.active.th-icon i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit:hover:before,
.wpte-trip-sfilter-wrapper .wpte-trip__search-submit.active:before {
  width: 110%;
}
.wpte-trip__adv-field.wpte__select-field.options-open
  .wpte__select-options
  ul
  li {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--body-font);
  color: var(--title-color);
  padding: 5px 10px;
}
.wpte-trip__adv-field.wpte__select-field.options-open
  .wpte__select-options
  ul
  li:hover
  > span {
  color: var(--theme-color) !important;
}
.wpte-trip__adv-field .icon {
  color: var(--theme-color);
  font-size: 20px;
}
.wpte-trip-sfilter-wrapper .wpte-trip__adv-field {
  border: none;
  border-right: 1px solid #e1e4e5 !important;
  padding-right: 25px;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .wpte-trip-sfilter-wrapper .wpte-trip__adv-field {
    border-right: none !important;
    padding-right: 0;
    margin-right: 0;
  }
}
.wpte-trip-sfilter-wrapper .wpte-trip__adv-field:after {
  right: 25px !important;
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-fields {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.wpte-trip-sfilter-wrapper .wpte-trip__search-fields .wpte__select-field {
  height: 41px;
}
.wpte-trip__adv-field.wpte__select-field .wpte__select-options {
  border-bottom: 3px solid var(--theme-color);
}
.hero-booking {
  position: relative;
  background-color: var(--white-color);
  z-index: 9;
  padding: 27px 32px;
  border-radius: 16px 0px 0px 0px;
  box-shadow: none;
  border: none;
  min-width: 896px;
}
.hero-booking.style2 {
  padding: 0;
}
@media (max-width: 1399px) {
  .hero-booking {
    min-width: 849px;
  }
}
@media (max-width: 1299px) {
  .hero-booking {
    min-width: 780px;
  }
}
@media (max-width: 1199px) {
  .hero-booking {
    min-width: 640px;
  }
}
@media (max-width: 991px) {
  .hero-booking {
    min-width: 100%;
    box-shadow: 0px 20px 20px rgba(204, 204, 204, 0.15);
    border-radius: 16px;
  }
}
.hero-booking .advanced-search-field strong {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  width: 100%;
  display: block;
}
.hero-booking .advanced-search-field .nice-select {
  line-height: 40px !important;
  width: 100%;
}
.hero-booking .advanced-search-field .nice-select:after {
  top: 19px;
}
.hero-booking input {
  position: relative;
  padding: 20px 52px;
  font-size: 16px;
  border-radius: 48px;
  line-height: 16px !important;
}
.hero-booking .class-wte-advanced-search-wrapper {
  margin: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .hero-booking .class-wte-advanced-search-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .hero-booking .class-wte-advanced-search-wrapper {
    grid-template-columns: auto;
  }
}
.hero-3 h3 {
  display: none;
}
.color-scheme-wrap {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99;
  background: var(--white-color);
  padding: 20px 30px;
  border-radius: 10px 0 0 10px;
  display: inline-block;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 0px 4px 0px rgba(51, 51, 51, 0.2);
}
.color-scheme-wrap .switchIcon {
  position: absolute;
  left: 0;
  top: 10px;
  border: 0;
  background: var(--theme-color);
  color: var(--white-color);
  height: 45px;
  width: 45px;
  border-radius: 5px 0 0 5px;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.color-scheme-wrap .color-scheme-wrap-title {
  font-size: 22px;
  border-bottom: 1px solid var(--th-border-color);
  padding-bottom: 6px;
  color: var(--title-color);
  margin-bottom: 30px;
}
.color-scheme-wrap .color-scheme-wrap-title i {
  font-size: 18px;
  margin-right: 3px;
}
.color-scheme-wrap .color-switch-btns {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 18px;
  margin-bottom: 25px;
}
.color-scheme-wrap .color-switch-btns button {
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 34px;
  color: var(--theme-color);
  text-align: left;
}
.color-scheme-wrap.active {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
}
.tour-tabs {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 16px;
  border-bottom: 0;
  margin-bottom: 60px;
}
.tour-tabs .th-btn {
  background-color: var(--white-color);
  border-radius: 48px;
  padding: 8px 30px;
  color: var(--theme-color);
}
.tour-tabs .th-btn:hover,
.tour-tabs .th-btn.active {
  color: var(--white-color);
}
.tour-tabs .th-btn:hover img,
.tour-tabs .th-btn.active img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.px-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}
.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}
.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}
.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}
.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}
.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}
.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}
.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}
.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}
.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mt-n1 {
  margin-top: -0.25rem;
}
.mt-n2 {
  margin-top: -0.45rem;
}
.mt-n3 {
  margin-top: -0.8rem;
}
.mt-n4 {
  margin-top: -1.5rem;
}
.mt-n5 {
  margin-top: -3rem;
}
.mb-n1 {
  margin-bottom: -0.25rem;
}
.mb-n2 {
  margin-bottom: -0.45rem;
}
.mb-n3 {
  margin-bottom: -0.8rem;
}
.mb-n4 {
  margin-bottom: -1.5rem;
}
.mb-n5 {
  margin-bottom: -3rem;
}
.mt-24 {
  margin-top: 24px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-55 {
  margin-top: 55px;
}
@media (max-width: 1500px) {
  .m-4 {
    margin: 0 !important;
  }
}
.fs-48 {
  font-size: 48px;
}
@media (max-width: 767px) {
  .fs-48 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .fs-48 {
    font-size: 30px;
  }
}
.space,
.space-top {
  padding-top: var(--section-space);
}
.space,
.space-bottom {
  padding-bottom: var(--section-space);
}
.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}
.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}
.space-extra2,
.space-extra2-top {
  padding-top: calc(var(--section-space) - 40px);
}
.space-extra2,
.space-extra2-bottom {
  padding-bottom: calc(var(--section-space) - 40px);
}
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
  .space-extra2,
  .space-extra2-top {
    padding-top: 70px;
  }
  .space-extra2,
  .space-extra2-bottom {
    padding-bottom: 70px;
  }
}
.space-extra3 {
  position: relative;
  margin: -35px 0;
  z-index: 8;
}
.section-four .swiper-pagination {
  display: none !important;
}

.tour-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.tour-card {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
}

/* Header styles */
header {
  /* background-color: #333; */
  padding: 10px;
  position: relative;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

nav ul li {
  position: relative;
}

nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #ff9900; /* Change color on hover */
}

/* Dropdown menu container */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  padding: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%; /* Align the dropdown below the menu item */
  left: 0;
  border-radius: 8px;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

/* Show dropdown when hovering over the parent li */
nav ul li.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Dropdown sections styling */
.package-group {
  margin-bottom: 20px;
}

.package-group h4 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

.package-group h4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 5px;
  background-color: #ff9900;
  border-radius: 50%;
  transform: translateY(-50%);
}

/* Links inside dropdown */
.package-group ul {
  list-style: none;
  padding-left: 0;
}

.package-group ul li {
  margin-bottom: 5px;
}

/* Flight arrival animation */
@keyframes flightArrival {
  0% {
    opacity: 0;
    transform: translateX(-100px); /* Start off-screen to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* End at the default position */
  }
}

/* Links inside dropdown */
.package-group ul li a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  padding-left: 10px;
  display: flex;
  align-items: center;
  transition:
    color 0.3s ease,
    transform 0.3s;
}

/* Animation for flight icon */
.package-group ul li a i {
  margin-right: 8px;
  animation: flightArrival 1.2s ease-in-out; /* Applying the flight animation */
  animation-fill-mode: forwards;
}

.package-group ul li a:hover {
  color: #ff9900;
  transform: translateX(5px);
}

.package-group ul li a:hover i {
  transform: translateX(5px);
}

.package-group ul li a::before {
  content: "→"; /* Add an arrow before each link */
  margin-right: 8px;
  opacity: 0;
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.package-group ul li a:hover::before {
  opacity: 1;
  transform: translateX(5px);
}
.prince {
  color: #1e8fb3;
  font-size: 20px;
  margin-left: 10px;
}
.tournew {
  display: block !important;
}
.boxnew a {
  font-family: Amaranth-Bold !important;
  font-size: 22px !important;
}
.hero-title {
  font-family: Amaranth-Bold !important;
  font-size: 50px !important;
}
.sec-title {
  font-family: Amaranth-Bold !important;
}
.heading-new {
  font-family: Amaranth-Bold !important;
}
.sec-new {
  font-family: Amaranth-Bold !important;
}

.swipper-new .swiper-pagination {
  bottom: -20px !important ;
}

/* Keyframe for fadeIn effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the default position */
  }
}

/* Applying the fadeIn animation */
.fadeIn {
  animation: fadeIn 1.5s ease-in-out forwards;
}

/* Control the delay for different elements */
[data-ani-delay="0.2s"] {
  animation-delay: 0.2s;
}
[data-ani-delay="0.4s"] {
  animation-delay: 0.4s;
}
[data-ani-delay="0.6s"] {
  animation-delay: 0.6s;
}

/* Optional: Adjust image transition for a smooth fade */
.new-swipper .th-hero-bg {
  transition: background-image 1.5s ease-in-out; /* Smooth transition for the image */
}

.new-swipper .swiper {
  width: 100% !important;
  height: 100vh !important; /* Full screen height */
}

.new-swipper .hero-inner {
  position: relative !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.new-swipper .th-hero-bg {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-size: cover !important;
  background-position: center !important;
  z-index: -1 !important; /* Ensure background is behind content */
}

.gallery-area {
  margin-top: 100px !important;
  margin-bottom: 50px !important;
}
.new-tag {
  margin-top: 50px !important;
}
.new-tag a {
  margin-right: 10px !important;
}

.tour-navigation {
  margin-bottom: 20px;
}

.tour-navigation ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding: 10px; /* Add some padding */
  background-color: #df1f2c; /* Semi-transparent white background */
  /* border-radius: 5px; Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.tour-navigation li {
  cursor: pointer;
  color: #f9af2a; /* Bootstrap primary color */
  transition:
    color 0.3s ease,
    background-color 0.3s ease; /* Smooth transition */
  padding: 5px 10px; /* Add some padding for better click area */
  border-radius: 3px; /* Rounded corners for each item */
  font-family: Manrope-SemiBold;
  font-weight: 600;
}

.tour-navigation li:hover {
  background-color: #df1f2c; /* Light blue background on hover */
  color: #ffffff; /* Darker shade for the text on hover */
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background-video {
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interaction with the iframe */
}

.th-hero-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-style1 {
  position: relative;
  z-index: 1;
}

.blog-content-section-new {
  width: 100vw; /* Full viewport width */
  margin-left: calc(
    -50vw + 50%
  ); /* Center the section by compensating for the container's margin */
  background-color: #f5f5f5; /* Optional background color */
  padding: 20px 0; /* Adjust the padding as needed */
  margin-bottom: 40px;
}
.blog-content-section-new .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.modal-title {
  font-family: Manrope-SemiBold;
}

.modal-body p {
  font-family: Manrope-SemiBold;
}

.hero-description {
  font-size: 1.2rem; /* Change the font size as needed */
  color: white; /* Change the color to match your design */
  margin-top: 10px; /* Add some spacing above the description */
}
.mt-5{
  font-family: Manrope-SemiBold;
  
}


.about-us-section {
  background-color: #f8f9fa; /* Light background for contrast */
  padding: 50px 0;
}

.about-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.about-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
}

.about-text {
  font-size: 1.125rem;
  line-height: 1.8;
  margin-bottom: 15px;
  color: #555555;
}

.about-content p:last-child {
  margin-bottom: 0;
}


.about-contact-details-text{
  color: #000000 !important;
  font-family: Manrope-SemiBold !important;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Place the video behind the content */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%);
}
.img-container{
  position: relative;
  width: 100%;          /* Full width of the container */ /* Aspect ratio of 16:9 (height / width * 100) */
  overflow: hidden;  
}
.img-container img{
  width: 100%;       /* Full width of the container */
  height: auto;      /* Automatic height based on width */
  height: 420px !important; /* Set a maximum height */
  object-fit: cover; 
}

.new-card {
  position: relative;
  background-color: var(--white-color);
  border: 1px solid #bcced2;
  border-top: transparent;
  border-radius: 16px;
  overflow: hidden;
  /* width: 100%; Make the card take full width of its container */
/* Set a maximum width */
  height: 500px; /* Set a fixed height */
  margin: 15px; /* Add spacing between cards */
  display: flex; /* Use flexbox for content alignment */
  flex-direction: column; /* Align items in a column */
  justify-content: space-between; /* Space between items */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .new-card  {
      max-width: 100%; /* Allow cards to take full width on smaller screens */
      height: 300px; /* Adjust the height for smaller screens */
      margin: 10px; /* Adjust margins for smaller screens */
  }
}

.new-card img{
  width: 100% !important; /* Full width */
  height: 350px !important; /* Fixed height */
  object-fit: cover !important; /* Crop to fill, preserving aspect ratio */
}
.faq-text{
  font-size: 13px !important;
}
.accordion-button{
  font-size: 16px !important;
}
.mt-5{
  padding-bottom: 20px;
}
.tour-box {
  position: relative !important; /* Required for absolute positioning of the overlay */
  border: 1px solid #e1e1e1 !important; /* Border */
  border-radius: 8px !important; /* Rounded corners */
  overflow: hidden !important; /* Avoid overflow from child elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Shadow for depth */
  transition: transform 0.3s !important; /* Smooth hover effect */
}

.tour-box:hover {
  transform: scale(1.05) !important; /* Scale effect on hover */
}

.tour-box_img {
  position: relative !important; /* Positioning context for the overlay */
}

.image-overlay {
  position: absolute !important; /* Absolutely position the overlay */
  top: 0 !important; /* Aligns to the top */
  left: 0 !important; /* Aligns to the left */
  width: 100% !important; /* Full width */
  height: 100% !important; /* Full height */
  display: flex !important; /* Use flexbox to center text */
  flex-direction: column !important; /* Align children vertically */
  justify-content: center !important; /* Center vertically */
  align-items: center !important; /* Center horizontally */
 /* Semi-transparent white background for better text visibility */
  color: #333 !important; /* Text color for contrast */
  text-align: center !important; /* Center the text */
  opacity: 1 !important; /* Always visible */
  transition: none !important; /* No transition since it's always visible */
}

.box-title {
  font-size: 1.5rem !important; /* Font size for the title */
  margin: 0 !important; /* Remove default margin */
}

.box-description {
  font-size: 1rem !important; /* Font size for description */
  margin-top: 8px !important; /* Space between title and description */
}




@media (max-width: 768px) {
  .boxnew a {
    font-size: 14px !important;
  }
  .boxnew{
    line-height: 1;
  }
  .tour-box{
    margin-bottom: 20px;

  }


}
 

/* 
@media (max-width: 1024px) {
  .swiper-pagination {
    display: none !important;
  }
} */

